import { NativeModules, Platform } from "react-native"

const { RNAppMetadata } = NativeModules

export const getMetaInfo = async (key: string): Promise<string> => {
  try {
    const res = await RNAppMetadata.getAppMetadataBy(key)
    return res
  } catch (err) {
    console.log("getMetaInfoError", err)
    return ""
  }
}
const Channels = {
  guanfang: 1,
  ios: 2,
}
export const getChannel = async () => {
  if (Platform.OS === "web") {
    return 1
  }
  if (Platform.OS === "ios") {
    return 2
  }
  const res = await getMetaInfo("CHANNEL_VALUE")
  console.log("channel", res)
  return Channels[res] || 1
}
