import React, { Component, ErrorInfo, ReactNode } from "react"
import {
  Alert,
  Linking,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from "react-native"
import { colors } from "../../theme"
import Clipboard from "@react-native-clipboard/clipboard"

interface FallbackProps {
  errorMessage: string
  resetError: () => void
  copyErrorToClipboard: () => void
  openTicket: () => void
}

const Fallback = (props: FallbackProps) => {
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.content}>
        <View style={styles.header}>
          {/* <Image source={metamaskErrorImage} style={styles.errorImage} /> */}
          <Text style={styles.title}>出错了</Text>
          <Text style={styles.subtitle}>以下是错误信息</Text>
        </View>
        <View style={styles.errorContainer}>
          <Text style={styles.error}>{props.errorMessage}</Text>
        </View>
        <View style={styles.header}>
          <TouchableOpacity style={styles.button} onPress={props.resetError}>
            <Text style={styles.buttonText}>重试</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.text}>
            <Text>如有必要请向我们反馈错误:</Text>
          </Text>
          <View style={styles.reportTextContainer}>
            <Text style={styles.text}>提供错误截图</Text>
            <Text style={[styles.reportStep, styles.text]}>
              <Text onPress={props.copyErrorToClipboard} style={styles.link}>
                复制
              </Text>{" "}
              错误到剪贴板
            </Text>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

interface Props {
  children: ReactNode
  catchErrors: "always" | "dev" | "prod" | "never"
}

interface State {
  error: Error | null
  errorInfo: ErrorInfo | null
}

/**
 * This component handles whenever the user encounters a JS error in the
 * app. It follows the "error boundary" pattern in React. We're using a
 * class component because according to the documentation, only class
 * components can be error boundaries.
 *
 * - [Documentation and Examples](https://github.com/infinitered/ignite/blob/master/docs/Error-Boundary.md)
 * - [React Error Boundaries](https://reactjs.org/docs/error-boundaries.html)
 */
export class ErrorBoundary extends Component<Props, State> {
  state = { error: null, errorInfo: null }

  // If an error in a child is encountered, this will run
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    })

    // You can also log error messages to an error reporting service here
    // This is a great place to put BugSnag, Sentry, crashlytics, etc:
    // reportCrash(error)
  }

  // Reset the error back to null
  resetError = () => {
    this.setState({ error: null, errorInfo: null })
  }

  // To avoid unnecessary re-renders
  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    return nextState.error !== this.state.error
  }

  // Only enable if we're catching errors in the right environment
  isEnabled(): boolean {
    return (
      this.props.catchErrors === "always" ||
      (this.props.catchErrors === "dev" && __DEV__) ||
      (this.props.catchErrors === "prod" && !__DEV__)
    )
  }

  getErrorMessage = () => `View: ${this.props.children}\n${this.state.error.toString()}`

  copyErrorToClipboard = async () => {
    await Clipboard.setString(this.getErrorMessage())
    Alert.alert("已经复制成功", "", [{ text: "完成" }], {
      cancelable: true,
    })
  }

  openTicket = () => {
    //TODO:官网反馈渠道
    const url = "https://www.cbatime.com/"
    Linking.openURL(url)
  }

  // Render an error UI if there's an error; otherwise, render children
  render() {
    return this.isEnabled() && this.state.error ? (
      <Fallback
        errorMessage={this.getErrorMessage()}
        resetError={this.resetError}
        copyErrorToClipboard={this.copyErrorToClipboard}
        openTicket={this.openTicket}
      />
    ) : (
      this.props.children
    )
  }
}

const BOLD: TextStyle = { fontWeight: "bold" }

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingHorizontal: 24,
    flex: 1,
  },
  header: {
    alignItems: "center",
  },
  errorImage: {
    width: 50,
    height: 50,
    marginTop: 24,
  },
  title: {
    color: colors.palette.black,
    fontSize: 24,
    lineHeight: 34,
    ...BOLD,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 20,
    color: colors.palette.lightGrey,
    marginTop: 8,
    textAlign: "center",
  },
  errorContainer: {
    backgroundColor: colors.palette.orange,
    borderRadius: 8,
    marginTop: 24,
  },
  error: {
    color: colors.palette.black,
    padding: 8,
    fontSize: 14,
    lineHeight: 20,
  },
  button: {
    marginTop: 24,
    borderColor: colors.palette.orangeDarker,
    borderWidth: 1,
    borderRadius: 50,
    padding: 12,
    paddingHorizontal: 34,
  },
  buttonText: {
    color: colors.palette.orangeDarker,
    textAlign: "center",
    fontWeight: "500",
  },
  textContainer: {
    marginTop: 24,
  },
  text: {
    color: colors.palette.black,
    fontSize: 14,
    lineHeight: 20,
  },
  link: {
    color: colors.palette.orangeDarker,
  },
  reportTextContainer: {
    paddingLeft: 14,
    marginTop: 16,
    marginBottom: 24,
  },
  reportStep: {
    marginTop: 14,
  },
})
