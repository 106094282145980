/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n"
import "./utils/ignoreWarnings"
import { useFonts } from "expo-font"
import React, { ReactNode, useEffect, useState } from "react"
import { initialWindowMetrics, SafeAreaProvider } from "react-native-safe-area-context"
import * as Linking from "expo-linking"
import { useInitialRootStore } from "./models"
import { AppNavigator, useNavigationPersistence } from "./navigators"
import { ErrorBoundary } from "./screens/ErrorScreen/ErrorBoundary"
import * as storage from "./utils/storage"
import { customFontsToLoad, maxWebWidth } from "./theme"
import { setupReactotron } from "./services/reactotron"
import Config from "./config"
import { SlienceUpdate } from "./utils/SlienceUpdate"
import { Platform, UIManager, View } from "react-native"
import { loadString } from "./utils/storage"
import { ProtocolTipModal } from "./modal/ProtocolTipModal"
import { GestureHandlerRootView, StatusBar } from "./utils/CompatibleUtils"

// @ts-ignore
window._frameTimestamp = null

// Set up Reactotron, which is a free desktop app for inspecting and debugging
// React Native apps. Learn more here: https://github.com/infinitered/reactotron
setupReactotron({
  // clear the Reactotron window when the app loads/reloads
  clearOnLoad: true,
  // generally going to be localhost
  host: "localhost",
  // Reactotron can monitor AsyncStorage for you
  useAsyncStorage: true,
  // log the initial restored state from AsyncStorage
  logInitialState: true,
  // log out any snapshots as they happen (this is useful for debugging but slow)
  logSnapshots: false,
})

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"

// Web linking configuration
const prefix = Linking.createURL("/")
const config = {
  screens: {
    LoginFromMessage: "LoginFromMessage/:id",
    ShowOrder: "ShowOrder/:id",
  },
}

const WebWrapper: React.FC = ({ children }: { children: ReactNode }) => {
  return (
    <View
      style={{ height: "100%", width: "100%", maxWidth: maxWebWidth, alignSelf: "center", flex: 1 }}
    >
      {children}
    </View>
  )
}

const Wrapper = Platform.OS === "web" ? WebWrapper : React.Fragment

const GestureHandlerRootViewWrapper = ({ children }: { children: ReactNode }) => {
  if (Platform.OS === "web") {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        {children}
      </View>
    )
  } else {
    return <GestureHandlerRootView style={{ flex: 1 }}>{children}</GestureHandlerRootView>
  }
}

interface AppProps {
  hideSplashScreen: () => Promise<void> | void
}

/**
 * This is the root component of our app.
 */
function App(props: AppProps) {
  const { hideSplashScreen } = props
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  const [areFontsLoaded] = useFonts(customFontsToLoad)
  const [modalShow, setModalShow] = useState(false)

  const getTipScreen = async () => {
    const privacyAgreement = await loadString("privacyAgreement")
    const userProtocol = await loadString("userProtocol")
    if ((Platform.OS === "ios" && !privacyAgreement) || !userProtocol) {
      setModalShow(true)
    }
  }

  const { rehydrated } = useInitialRootStore(() => {
    // This runs after the root store has been initialized and rehydrated.

    // If your initialization scripts run very fast, it's good to show the splash screen for just a bit longer to prevent flicker.
    // Slightly delaying splash screen hiding for better UX; can be customized or removed as needed,
    // Note: (vanilla Android) The splash-screen will not appear if you launch your app via the terminal or Android Studio. Kill the app and launch it normally by tapping on the launcher icon. https://stackoverflow.com/a/69831106
    // Note: (vanilla iOS) You might notice the splash-screen logo change size. This happens in debug/development mode. Try building the app for release.
    setTimeout(hideSplashScreen, 500)

    getTipScreen()
  })

  if (modalShow && Platform.OS === "ios") {
    return (
      <ProtocolTipModal isBack={false} modalShow={modalShow} onClose={() => setModalShow(false)} />
    )
  }

  if (Platform.OS === "android") {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
      UIManager.setLayoutAnimationEnabledExperimental(true)
    }
  }

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  // if (!rehydrated || !isNavigationStateRestored || !areFontsLoaded) return null

  if (!rehydrated) return null

  const linking = {
    prefixes: [prefix],
    config,
  }

  // otherwise, we're ready to render the app
  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <StatusBar animated={true} barStyle="light-content" />
      <Wrapper>
        <GestureHandlerRootViewWrapper>
          <ErrorBoundary catchErrors={Config.catchErrors}>
            <AppNavigator
              linking={linking}
              // initialState={initialNavigationState}
              // onStateChange={onNavigationStateChange}
            />
          </ErrorBoundary>
        </GestureHandlerRootViewWrapper>
      </Wrapper>
    </SafeAreaProvider>
  )
}

// const UpdateApp = Platform.OS === "web" ? App : SlienceUpdate(App)

export const Myapp = App
