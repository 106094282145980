import React, { useImperativeHandle, forwardRef, useEffect, useState, Fragment } from "react"
import { StyleSheet } from "react-native"
import { fitSize, colors } from "../../../theme"
import Drawer from "react-modern-drawer"
import "react-modern-drawer/dist/index.css"
import { useStores } from "../../../models"
import { typeState, FuzzySearchContent } from "./FuzzySearchContent"

interface FuzzySearchDrawerProps {
  onFilter: (value: typeState) => void
  children?: any
}

interface FuzzySearchDrawerRef {
  toggleDrawer: (value: boolean) => void
  isOpen: boolean
}

export const FuzzySearchDrawer = forwardRef<FuzzySearchDrawerRef, FuzzySearchDrawerProps>(
  (props, ref) => {
    const [open, setOpen] = React.useState(false)
    const { accountStore } = useStores()
    const { onFilter, children } = props

    const toggleDrawer = () => {
      setOpen((prevState) => !prevState)
    }

    useImperativeHandle(ref, () => ({
      toggleDrawer: (value) => {
        setOpen(value)
      },
      isOpen: open,
    }))

    useEffect(() => {
      if (open) {
        accountStore.setShowBottomBar(false)
      } else {
        accountStore.setShowBottomBar(true)
      }
      return () => {
        accountStore.setShowBottomBar(true)
      }
    }, [open])

    return (
      <Fragment>
        <Drawer
          open={open}
          onClose={toggleDrawer}
          direction="right"
          style={style.drawerStyle}
          size={fitSize(336)}
          overlayColor={colors.palette.neutral100 + "66"}
        >
          <FuzzySearchContent
            clearModal={(value: typeState) => {
              setOpen(false)
              onFilter(value)
            }}
          />
        </Drawer>
        {children}
      </Fragment>
    )
  },
)

const style = StyleSheet.create({
  drawerStyle: {
    backgroundColor: colors.palette.neutral900,
    flex: 1,
    width: fitSize(336),
  },
  overlayStyle: {
    backgroundColor: colors.palette.neutral100 + "66",
  },
})
