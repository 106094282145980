import {
  View,
  StatusBar,
  StyleSheet,
  Text,
  Platform,
  Image,
  ScrollView,
  FlatList,
} from "react-native"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { Images, colors, fitSize } from "../../theme"
import { Screen, Touchable, TouchableDeep } from "../../components"
import { activityDetail, api, resourceItem } from "../../services/api"
import { hideLoad, showError, showInfo, showLoad } from "../../utils/showUtil"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { GToast, goBack } from "../../navigators"
import { fontName } from "../../theme/fonts"
import { SizeImage } from "../../components/SizeImage"
import { useAutoList } from "../../hooks/useAutoList"
import { HookFlatList } from "../../components/list/HookFlatList"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"
import { useTokenAuthorization } from "../../hooks/useTokenAuthorization"
import { CopyBox } from "../PayProcess/ShowOrder"
import ReadMore from "@fawazahmed/react-native-read-more"
import { HorizontalFlatList } from "@idiosync/horizontal-flatlist"
import { useFocusEffect } from "@react-navigation/native"
import { RarityIcon } from "../TabHome/components/RarityIcon"
interface TimeItemProps {
  item: resourceItem
}

export const TimeItem: FC<TimeItemProps> = ({ item }) => {
  return (
    <View style={style.timeItemContainer}>
      <Touchable
        onPress={() => {
          if (!!item) {
            GToast.current.showVideo(
              true,
              {
                url: item?.video,
                poster: item?.image,
                seek: 0,
                info: {
                  id: item?.id,
                  name: item?.name,
                  cardBoxName: item?.cardBoxName,
                  seriesName: item?.seriesName,
                  rarityIcon: item?.rarityIcon,
                },
              },
              {},
              () => {},
              true,
            )
          }
        }}
        style={{
          width: fitSize(102),
          height: fitSize(102),
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SizeImage source={{ uri: item?.image }} style={style.timeItemIcon} resizeMode="contain" />
        {/* <Image source={Images.screen.activity.video_play} style={style.videoPlayIcon} /> */}
      </Touchable>
      <View style={style.timeItemContent}>
        <View style={style.timeItemInfo}>
          <RarityIcon icon={item?.rarityIcon} />
          <Text style={style.timeItemName} numberOfLines={1}>
            {item?.name}
          </Text>
        </View>
        <Text style={style.actionName} numberOfLines={1}>
          {item?.actionName}
        </Text>
      </View>
    </View>
  )
}

interface ActivityNewProps extends RootStackNavProps<"ActivityNew"> {}

const MIN_STEP = 1
const PL = 50
export const ActivityNew: React.FC<ActivityNewProps> = observer(({ navigation, route }) => {
  const [activityData, setActivityData] = useState<activityDetail | null>(null)
  const [count, setCount] = useState(MIN_STEP)
  const { bottom, top } = useSafeAreaInsets()
  const { authorization } = useTokenAuthorization()
  const { accountStore } = useStores()
  const [data, setData] = useState([])

  const init = async () => {
    try {
      const res = await api.getActivityDetail(route.params.id)
      if (res.kind === "ok") {
        if (Platform.OS === "web") {
          window.document.title = res.data.name
        }
        setActivityData(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useFocusEffect(
    useCallback(() => {
      init()
    }, [route]),
  )

  const fetchTimeList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getActivityBoxTimeList(route.params.id, lastId, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchTimeList,
      pageLimit: PL,
    },
    [],
  )

  const generateOrder = async () => {
    try {
      showLoad("生产订单中")
      const res = await api.purchaseCardBagItem(count, activityData?.id, 0, 0)
      if (res.kind === "ok") {
        hideLoad()
        navigation.navigate("ShowOrder", { id: res.data.outTradeNo })
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      hideLoad()
      console.log("error", error)
    }
  }

  const onReady = ({ canExpand, expandedLinesCount }) => {
    // console.log('on ready', {canExpand, expandedLinesCount});
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <ScrollView
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={[
          style.scrollView,
          { paddingBottom: fitSize(180), display: activityData ? "flex" : "none" },
        ]}
      >
        <SizeImage
          source={{ uri: activityData?.imageMain }}
          style={style.imageBox}
          resizeMode={"cover"}
        />
        <View
          style={[
            {
              height: fitSize(61) + top,
            },
            style.backBox,
          ]}
        >
          <Touchable
            onPress={() => {
              if (accountStore.beforeLogin.url) {
                navigation.navigate("Root", { screen: "TabOne" })
              } else {
                goBack()
              }
            }}
            style={style.backContent}
          >
            <Image style={style.backImage} source={Images.components.header_back} />
          </Touchable>
        </View>
        <View style={style.textContainer}>
          <View style={style.textInfoContainer}>
            <View style={style.textInfoContent}>
              <Text style={style.nameText} numberOfLines={1}>
                {activityData?.name ?? ""}
              </Text>
              <Text style={[style.saleAmuntText]}>
                <Text style={style.unitIcon}>{activityData && "¥"}</Text>
                {activityData?.saleAmount ?? ""}
              </Text>
            </View>
            {/* <ReadMore
              numberOfLines={1}
              style={style.description}
              animate={true}
              seeMoreText={
                <>
                  <Image source={Images.screen.activity.more_text} style={style.moreIcon} />
                </>
              }
              seeLessText={
                <>
                  <Image source={Images.screen.activity.more_text} style={style.revealIcon} />
                </>
              }
            >
              {activityData?.description}
            </ReadMore> */}
            <Text style={style.description}>{activityData?.description}</Text>
          </View>
          <View
            style={[
              style.row,
              { paddingHorizontal: fitSize(14), marginBottom: fitSize(34), marginTop: fitSize(10) },
            ]}
          >
            <Image source={Images.components.warning} style={style.warningIcon} />
            <Text style={style.warningText}>
              本平台售出的数字藏品均为虚拟数字商品，而非实物，因区块链技术特性，平台售出的数字藏品不支持退换。
            </Text>
          </View>
          <View
            style={[
              style.timeListContainer,
              {
                height: fetchList?.data.length > 3 ? fitSize(332) : fitSize(178),
                display: fetchList?.data.length > 0 ? "flex" : "none",
              },
            ]}
          >
            <View style={style.timeListHeader}>
              <Text style={style.titleWarpText}>可获得时刻</Text>
              <Touchable
                onPress={() => {
                  navigation.navigate("OpenTimeList", { ...route.params })
                }}
              >
                <Image source={Images.screen.activity.open_right} style={style.openRightIcon} />
              </Touchable>
            </View>
            <HorizontalFlatList
              {...fetchList}
              renderItem={({ item }) => {
                return <TimeItem item={item as unknown as resourceItem} />
              }}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              numRows={fetchList?.data.length > 3 ? 2 : 1}
              refreshing={false}
              bounces={false}
              keyExtractor={(item, index) => index + ""}
            />
          </View>
          <View style={style.titleWarp}>
            <Text style={style.titleWarpText}>商品规格</Text>
          </View>
          <View style={style.titleWarpContent}>
            <Text style={style.titleWarpContentText}>{activityData?.specification}</Text>
          </View>
          <View style={style.titleWarp}>
            <Text style={style.titleWarpText}>合约地址</Text>
          </View>
          <View style={style.titleWarpContent}>
            <View style={style.rowBox}>
              <Text style={style.titleWarpContentText} numberOfLines={1}>
                {activityData?.contract}
              </Text>
              <CopyBox text={activityData?.contract ?? "-"} />
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={[style.bottomContainer, { height: fitSize(130) + bottom }]}>
        <View style={style.bottomContent}>
          <View style={style.bottomBody}>
            <Text style={style.numberText}>购买数量</Text>
            <View style={style.row}>
              {/* <Text style={style.orderLimit}>每单限购{activityData?.orderLimit ?? 0}件</Text> */}
              {activityData?.buyLimitIs === 1 ? (
                <Text style={style.buyLimitText}>每人限购{activityData?.buyLimit ?? 0}件</Text>
              ) : null}
            </View>
          </View>
          <View style={style.rowCenter}>
            <TouchableDeep
              onPress={() => {
                if (count <= 1) {
                  return
                } else {
                  setCount((c) => c - MIN_STEP)
                }
              }}
              style={style.minusBox}
            >
              <Image source={Images.components.a_minus} style={style.minusIcon} />
            </TouchableDeep>
            <View style={style.countBox}>
              <Text style={style.countText}>{count}</Text>
            </View>
            <TouchableDeep
              onPress={() => {
                if (activityData?.buyLimitIs === 1 && count >= activityData?.buyLimit) {
                  GToast.current.showToast("不可超出限购数量", "TOAST", 3000)
                  return
                } else {
                  setCount((c) => c + MIN_STEP)
                }
              }}
              style={style.addBtn}
            >
              <Image source={Images.components.a_add} style={style.addIcon} />
            </TouchableDeep>
          </View>
        </View>
        <TouchableDeep
          onPress={() => {
            if (accountStore.user.isReal === 0) {
              showInfo("未实名", "TOAST")
              navigation.navigate("RealName")
              return
            }
            if (accountStore.token) {
              generateOrder()
            } else {
              GToast.current.doLogin("ActivityNew", route.params)
            }
          }}
          style={style.saleStatusNameBtn}
          disabled={activityData?.saleStatus !== 1}
        >
          <Text style={style.saleStatusNameText}>{activityData?.saleStatusName}</Text>
        </TouchableDeep>
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  revealIcon: {
    width: fitSize(14),
    height: fitSize(14),
    transform: [{ rotate: "180deg" }],
  },
  moreIcon: {
    width: fitSize(14),
    height: fitSize(14),
  },
  bottomBox: {
    width: fitSize(14),
    height: fitSize(14),
    // position: "absolute",
    // right: 0,
    // bottom: 0,
  },
  upBox: {
    width: fitSize(14),
    height: fitSize(14),
    // position: "absolute",
    // right: 0,
    // top: 4,
  },
  rowBox: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  videoPlayIcon: {
    height: fitSize(20),
    width: fitSize(20),
  },
  actionName: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(12),
    fontWeight: "400",
    color: "#666666",
    alignSelf: "center",
    marginTop: fitSize(5),
  },
  timeItemName: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral100,
  },
  rarityIcon: {
    minWidth: fitSize(14),
    maxWidth: fitSize(24),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  timeItemInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  timeItemContent: {
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  },
  timeItemIcon: {
    width: fitSize(68),
    height: fitSize(68),
  },
  timeItemContainer: {
    width: fitSize(102),
    height: fitSize(140),
    flexDirection: "column",
    marginRight: fitSize(5),
    marginBottom: fitSize(24),
  },
  titleWarpContent: {
    width: fitSize(347),
    padding: fitSize(10),
    marginLeft: fitSize(16),
    borderRadius: fitSize(6),
    marginTop: fitSize(10),
    borderWidth: 1,
    borderColor: "#333333",
    minHeight: fitSize(39),
  },
  titleWarpContentText: {
    color: "#666666",
    fontSize: fitSize(12),
    fontWeight: "400",
  },
  titleWarp: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: fitSize(16),
    marginTop: fitSize(20),
    overflow: "hidden",
  },
  timeListContent: {
    width: fitSize(347),
    marginTop: fitSize(14),
    height: fitSize(300),
    flexDirection: "column",
    backgroundColor: "red",
  },
  openRightIcon: {
    width: fitSize(14),
    height: fitSize(14),
  },
  titleWarpText: {
    color: colors.palette.neutral100,
    fontSize: fitSize(14),
    fontWeight: "600",
    fontFamily: fontName.PingFang_SC,
  },
  timeListHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(20),
  },
  timeListContainer: {
    flexDirection: "column",
    width: "100%",
    paddingHorizontal: fitSize(14),
    // overflow: "hidden",
  },
  warningText: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(10),
    fontWeight: "400",
    color: "#666666",
  },
  warningIcon: {
    width: fitSize(12),
    height: fitSize(12),
    marginRight: fitSize(3),
    marginTop: fitSize(3),
  },
  description: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(14),
    fontWeight: "400",
    color: colors.palette.neutral100,
  },
  unitIcon: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(13),
    fontWeight: "600",
    color: colors.palette.neutral100,
    alignSelf: "flex-end",
    marginRight: fitSize(1),
  },
  saleAmuntText: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(20),
    fontWeight: "600",
    color: colors.palette.neutral100,
  },
  nameText: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(18),
    fontWeight: "600",
    color: colors.palette.neutral100,
  },
  textInfoContent: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: fitSize(13),
  },
  textInfoContainer: {
    width: "100%",
    flexDirection: "column",
    paddingHorizontal: fitSize(14),
    paddingTop: fitSize(12),
    marginBottom: fitSize(5),
    minHeight: fitSize(70),
  },
  textContainer: {
    flexGrow: 1,
    flexDirection: "column",
    width: "100%",
  },
  backImage: {
    width: fitSize(20),
    height: fitSize(20),
  },
  backContent: {
    width: fitSize(40),
    height: fitSize(40),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00000022",
    borderRadius: fitSize(50),
  },
  backBox: {
    width: "100%",
    position: "absolute",
    top: 20,
    left: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: fitSize(252),
    height: fitSize(192),
  },
  imageBox: {
    width: "100%",
    height: fitSize(375),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.palette.neutral900,
    overflow: "hidden",
  },
  scrollView: {
    paddingBottom: fitSize(80),
    backgroundColor: colors.palette.neutral900,
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  content: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
  bottomContainer: {
    width: "100%",
    backgroundColor: "#1A1A23",
    position: "absolute",
    bottom: 0,
  },
  bottomContent: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: fitSize(16),
    paddingTop: fitSize(16),
    justifyContent: "space-between",
  },
  saleStatusNameText: {
    color: "#333",
    fontSize: fitSize(16),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  saleStatusNameBtn: {
    width: fitSize(343),
    height: fitSize(40),
    borderRadius: fitSize(6),
    backgroundColor: colors.palette.neutral100,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: fitSize(20),
    marginBottom: fitSize(15),
  },
  addIcon: {
    width: fitSize(12),
    height: fitSize(12),
  },
  addBtn: {
    width: fitSize(36),
    height: fitSize(30),
    backgroundColor: "#333333",
    borderTopRightRadius: fitSize(2),
    borderBottomRightRadius: fitSize(2),
    justifyContent: "center",
    alignItems: "center",
  },
  countText: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
  },
  countBox: {
    width: fitSize(41),
    height: fitSize(30),
    backgroundColor: "#333333",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: fitSize(4),
  },
  minusIcon: {
    width: fitSize(12),
    height: fitSize(2),
  },
  minusBox: {
    width: fitSize(36),
    height: fitSize(30),
    backgroundColor: "#333333",
    borderTopLeftRadius: fitSize(2),
    borderBottomLeftRadius: fitSize(2),
    justifyContent: "center",
    alignItems: "center",
  },
  buyLimitText: {
    color: "#999999",
    fontSize: fitSize(10),
    marginLeft: fitSize(4),
    fontFamily: fontName.PingFang_SC,
  },
  orderLimit: {
    color: "#999999",
    fontSize: fitSize(10),
    marginLeft: fitSize(4),
    fontFamily: fontName.PingFang_SC,
  },
  numberText: {
    color: colors.palette.neutral100,
    fontSize: fitSize(16),
    marginLeft: fitSize(4),
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  bottomBody: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  row: {
    flexDirection: "row",
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
})
