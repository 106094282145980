import "react-native-get-random-values"
import { Instance, SnapshotIn, SnapshotOut, flow, types } from "mobx-state-tree"
import { AccountApi } from "../../services/api/account-api"
import { settingModel } from "./setting"
import { userInfoModel, userInfoStoreSnapshot } from "./userInfo"
import { withEnvironment } from "../extensions/with-environment"
import { RootPublicStackList } from "../../type/RouteType"
import { api } from "../../services/api"

const tipScreenModel = types.model({
  cardDetail: types.boolean,
  privacyAgreement: types.boolean,
  userProtocol: types.boolean,
  isEnabledUserInfo: types.boolean,
})

const tipScreenModelInfo = {
  cardDetail: false,
  privacyAgreement: true,
  userProtocol: true,
  isEnabledUserInfo: false,
}

type tabName = "tabOne" | "tabTwo" | "tabThree" | "tabFour"

export const AccountModel = types
  .model("AccountStore")
  .props({
    token: types.optional(types.string, ""),
    user: types.optional(userInfoModel, {}),
    setting: types.optional(settingModel, {}),
    isOK: false,
    showTab: true,
    showBottomBar: true,
    manualExit: false,
    isGray: false,
    tipScreen: types.optional(tipScreenModel, tipScreenModelInfo),
    currentAccount: "",
    tabScrollUp: types.optional(
      types.model({
        tabOne: types.boolean,
        tabTwo: types.boolean,
        tabThree: types.boolean,
        tabFour: types.boolean,
      }),
      { tabOne: false, tabTwo: false, tabThree: false, tabFour: false },
    ),
    beforeLogin: types.optional(
      types.model({
        url: types.string,
        params: types.frozen(),
      }),
      { url: "", params: {} },
    ),
  })
  .extend(withEnvironment)
  .views((store) => ({
    get isAuthenticated() {
      return !!store.token
    },
  }))
  .actions((self) => {
    const accountApi = new AccountApi(api)
    function setAuthToken(data: string) {
      api.apisauce.setHeader("Authorization", data)
      api.newApisauce.setHeader("Authorization", data)
      self.token = data
    }
    function removeAuth() {
      if (self.token !== undefined) {
        api.apisauce.deleteHeader("Authorization")
        self.token = undefined
      }
    }
    return {
      getUserInfo: flow(function* () {
        try {
          const result = yield accountApi.getInfo()
          if (result.kind === "ok") {
            self.user = result.data
            return result
          } else if (result.kind === "forbidden" || result?.code === 403) {
            removeAuth()
            throw result
          } else {
            console.log(result.kind, "result")
            __DEV__ && console.tron.log(result.kind)
            throw result
          }
        } catch (error) {
          console.log("error", error)
        }
      }),
      doMessageLogin: function (token: string, maasToken?: string) {
        console.log("settoken", token)
        console.log("setmaastoken", maasToken)
        setAuthToken(token)
      },
      removeAuth,
      clearUserInfo: () => {
        self.user = undefined
        self.token = undefined
      },
      changeLanguage: (value: string) => {
        self.setting.language = value
      },
      changeUserName: (data: string) => {
        self.user.nickname = data
      },
      changeUser: (data: userInfoStoreSnapshot) => {
        self.user = data
      },
      /**
       * 用于第一次进入某些页面展示对应的骨面屏
       * @param key tipScreenSnapshotIn
       */
      setShowTip: <K extends keyof tipScreenSnapshotIn>(key: K) => {
        self.tipScreen[key] = true
      },
      setShowTipValue: <K extends keyof tipScreenSnapshotIn>(key: K, value: boolean) => {
        self.tipScreen[key] = value
      },
      setShowProtocolTip: <K extends keyof tipScreenSnapshotIn>(key: K) => {
        self.tipScreen[key] = false
      },
      changeUserImage: (id: string, image: string) => {
        self.user.avatar = image
      },
      changeOk: (isOk: boolean = true) => {
        self.isOK = isOk
      },
      setTab: (data: boolean) => {
        self.showTab = data
      },
      setGray: (data: boolean) => {
        self.isGray = data
      },
      setInviteReturnUrl<K extends keyof RootPublicStackList>(url?: K, params?: any) {
        self.beforeLogin.url = url ?? ""
        self.beforeLogin.params = params
      },
      setShowBottomBar: (value: boolean) => {
        self.showBottomBar = value
      },
      setCurrentAccount(value: string) {
        self.currentAccount = value
      },
      setManualExit(value: boolean) {
        self.manualExit = value
      },
      setTabScrollUp(key: tabName, value: boolean) {
        self.tabScrollUp = { ...self.tabScrollUp, [key]: value }
      },
    }
  })
  .preProcessSnapshot((snapshot) => {
    // 重置 authToken
    const { showBottomBar, tabScrollUp, ...rest } = snapshot
    return rest
  })

type AccountStoreType = Instance<typeof AccountModel>
export interface AccountStore extends AccountStoreType {}
type AccountStoreSnapshotType = SnapshotOut<typeof AccountModel>
export interface AccountStoreSnapshot extends AccountStoreSnapshotType {}
export const createAccountStoreDefaultModel = () => types.optional(AccountModel, {})
export interface tipScreenSnapshotIn extends SnapshotIn<typeof tipScreenModel> {}

