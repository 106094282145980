import { Platform, View, useWindowDimensions } from "react-native"

import React, { ReactNode } from "react"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { SizeImage } from "./SizeImage"
import { colors } from "../theme"

interface FlexViewProps {
  children: ReactNode
  bgColor?: string
  bgImage?: number
}

export const FlexView: React.FC<FlexViewProps> = ({ children, bgColor, bgImage }) => {
  const windowHeight = useWindowDimensions().height
  const { top, bottom } = useSafeAreaInsets()
  const Frame = bgImage ? SizeImage : View
  return (
    <Frame
      source={bgImage}
      resizeMode={"cover"}
      style={[
        {
          backgroundColor: bgColor || colors.background,
          flexGrow: 1,
          height: Platform.OS === "web" ? windowHeight : undefined,
          paddingTop: top,
          paddingBottom: bottom,
        },
      ]}
    >
      {children}
    </Frame>
  )
}
