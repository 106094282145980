import React from "react"
import {
  Platform,
  StyleSheet,
  View,
  useWindowDimensions,
  Text,
  ImageBackground,
  Image,
} from "react-native"
import Modal from "react-native-modal/dist/modal"
import { deviceHeight, fitSize } from "../theme"
import { Touchable } from "../components"
import { SizeImage } from "../components/SizeImage"
import { Images } from "../theme"
import { translate } from "../i18n"

type modalInfoType = {
  link: string
  forcibly: number
  version: string
}

type announcementInfoType = {
  id: string
  image: string
  url: {
    type: number
    route: string
    params: {
      messageId: number
    }
  }
  title: string
  pushTime: number
  rich_text: string
  bannerType: string
}

interface SystemInfoModalProps {
  modalShow: boolean
  contentData?: modalInfoType
  modalShowFC?: (value: boolean) => void
  isBack: boolean
  announcementData?: announcementInfoType
  onClick?: () => void
  back?: () => void
}

export const SystemInfoModal: React.FC<SystemInfoModalProps> = ({
  modalShow,
  contentData,
  modalShowFC = (value: boolean) => {},
  isBack,
  announcementData,
  onClick,
  back,
}) => {
  const { height } = useWindowDimensions()
  const compatibleHeight = Platform.OS === "web" ? height : deviceHeight

  return (
    <Modal
      hasBackdrop={true}
      coverScreen={true}
      isVisible={modalShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={{
        margin: 0,
        backgroundColor: !isBack ? "#202530" : undefined,
        zIndex: 9999,
        overflow: "hidden",
      }}
      backdropOpacity={0.7}
      onBackButtonPress={() => {}}
      onBackdropPress={() => {}}
      swipeDirection={"down"}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
    >
      <ImageBackground source={Images.route.version_modal} style={styles.container}>
        <View
          style={{
            marginTop: fitSize(110),
            alignSelf: "center",
            paddingHorizontal: fitSize(35),
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: fitSize(20),
              color: "#333333",
              fontWeight: "500",
              fontFamily: "PingFang_SC",
            }}
          >{`${contentData?.version}${translate("modal.systemInfoModal.versionNumber")}`}</Text>
          <Text
            style={{
              fontSize: fitSize(12),
              color: "#999999",
              fontWeight: "400",
              fontFamily: "PingFang_SC",
              marginTop: fitSize(17),
              marginBottom: fitSize(22),
            }}
          >
            {translate("modal.systemInfoModal.info")}
          </Text>
        </View>
        {isBack && (
          <Touchable
            style={styles.backBtn}
            onPress={() => {
              onClick()
            }}
          >
            <SizeImage source={Images.route.version_modal_btn} style={styles.version_modal_btn} />
          </Touchable>
        )}
      </ImageBackground>
      <Touchable style={{ alignSelf: "center", marginTop: fitSize(16) }} onPress={back}>
        <Image
          source={Images.components.modal_clear}
          style={{
            width: fitSize(16),
            height: fitSize(16),
          }}
        />
      </Touchable>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: fitSize(276),
    height: fitSize(322),
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
  },
  content: {
    width: fitSize(275),
    height: fitSize(350),
    padding: fitSize(15),
    paddingBottom: fitSize(15),
    borderBottomColor: "#9a9a9a88",
    borderBottomWidth: 1,
    borderTopColor: "#9a9a9a88",
    borderTopWidth: 1,
  },
  titleBox: {
    flexDirection: "column",
    height: fitSize(50),
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  titleText: {
    color: "#ffffff",
    fontSize: fitSize(15),
    marginBottom: fitSize(10),
  },
  timeText: {
    color: "#9a9a9a",
    fontSize: fitSize(13),
    marginBottom: fitSize(10),
  },
  contentText: {
    width: "100%",
    flexWrap: "wrap",
    color: "#9a9a9a",
  },
  backBtn: {
    alignSelf: "center",
    marginTop: fitSize(20),
  },
  backText: {
    textAlign: "center",
    fontSize: fitSize(15),
    color: "#ffffff",
    paddingTop: fitSize(9),
  },
  version_modal_btn: {
    width: fitSize(185),
    height: fitSize(46),
  },
})
