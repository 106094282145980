import { StyleSheet, useWindowDimensions, Platform } from "react-native"
import { colors, fitSize } from "../../theme"
import { Screen, Sheader } from "../../components"
import React, { useState } from "react"
import { SceneRendererProps, TabView } from "react-native-tab-view"

import { MyTabBar } from "./components/MyTabBar"
import { RootStackNavProps } from "../../type/RouteType"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { BoughtView } from "./components/BoughtView"
import { useTypeTabNavigation } from "../../navigators/useTypeNavigation"
import { useSecond } from "../../hooks/useSecond"
import { navigationRef } from "../../navigators"

export type routeProp = {
  key: string
  title: string
}

interface OrderMainProps extends RootStackNavProps<"OrderBuy"> {}

export const routes = [
  { key: "all", title: translate("screen.orderBuy.tabOne") },
  { key: "paying", title: translate("screen.orderBuy.tabTwo") },
  { key: "sending", title: translate("screen.orderBuy.tabThree") },
  { key: "complete", title: translate("screen.orderBuy.tabFour") },
  { key: "cancel", title: translate("screen.orderBuy.tabFive") },
]

export const OrderBuy: React.FC<OrderMainProps> = ({ navigation, route }) => {
  const [navState, setNavState] = useState({
    index: 0,
    routes: routes,
  })
  const { width } = useWindowDimensions()
  const [change, setChange] = useState(0)
  const tabNavigation = useTypeTabNavigation()

  const _handleIndexChange = (index: number) => {
    setNavState({ ...navState, index })
    setChange(index)
  }

  const _renderScene = ({
    route,
  }: SceneRendererProps & {
    route: routeProp
  }) => {
    switch (route.key) {
      case "all":
        return <BoughtView type={0} change={change} />
      case "paying":
        return <BoughtView type={1} change={change} />
      case "sending":
        return <BoughtView type={2} change={change} />
      case "complete":
        return <BoughtView type={3} change={change} />
      case "cancel":
        return <BoughtView type={4} change={change} />
      default:
        return null
    }
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("screen.orderBuy.header")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
        leftAction={() => {
          /**
           * @todo 重置路由后，使用顶层navigationRef 跳转到指定页面
           */
          navigationRef.navigate("Root", {
            screen: "TabFour",
          })
        }}
      />
      <TabView
        initialLayout={{ width: width }}
        lazy={true}
        animationEnabled={false}
        navigationState={navState}
        renderScene={_renderScene}
        onIndexChange={(index) => {
          _handleIndexChange(index)
        }}
        renderTabBar={(props) => {
          return (
            <MyTabBar
              height={Platform.OS === "web" ? fitSize(37) : fitSize(30)}
              textColor={colors.palette.neutral50}
              textTintColor={colors.palette.neutral100}
              bgColor={colors.transparent}
              split={3}
              indicatorLeft={fitSize(23.5)}
              leftSpace={fitSize(2)}
              width={fitSize(63)}
              indicatorLeftWidth={fitSize(20)}
              noyTextFloating={true}
              {...props}
            />
          )
        }}
      />
    </Screen>
  )
}
const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
