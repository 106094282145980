import { observer } from "mobx-react-lite"
import { RootStackNavProps } from "../../type"
import { Screen, Touchable } from "../../components"
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  View,
  TextStyle,
  Animated,
  Dimensions,
  ViewStyle,
} from "react-native"
import { Images, colors, fitSize } from "../../theme"
import { DetailedInfo, UserCollectItem, api } from "../../services/api"
import { useAutoList } from "../../hooks/useAutoList"
import React, { FC, useEffect, useState, useCallback, Fragment } from "react"
import dayjs from "dayjs"
import { fontName } from "../../theme/fonts"
import { ImageFilter } from "./components/ImageFilter"
import { GToast } from "../../navigators"
import { SizeImage } from "../../components/SizeImage"
import { FlashList } from "@shopify/flash-list"
import { HookFlatList } from "../../components/list/HookFlatList"
import { AllSeriesModal } from "./components/AllSeriesModal"
import { useFocusEffect } from "@react-navigation/native"
interface ItemProps {
  item: UserCollectItem
}

interface GuideDetailsProps extends RootStackNavProps<"GuideDetails"> {}

const PL = 20
export const GuideDetails: React.FC<GuideDetailsProps> = observer(({ navigation, route }) => {
  const [info, setInfo] = useState<DetailedInfo>()
  const [showHeader, setShowHeader] = useState(false)
  const [showSelectSeries, setShowSelectSeries] = useState(false)
  const [useId, setUseId] = useState<number>()
  const [useHaveNum, setUseHaveNum] = useState<number>()

  const fetchGetUserCollectList = async (lastId?: number, size = PL) => {
    try {
      let res = await api.getUserCollectList(
        lastId,
        size,
        route.params.seriesId,
        route.params.rarityId,
        route.params.cardBoxRarityId,
        route.params.cardBoxId,
      )
      if (res.kind === "ok") {
        console.log("res.data", res.data)
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchGetUserCollectList,
      pageLimit: PL,
    },
    [],
  )

  const fetchGetDetailedInfo = async () => {
    try {
      const res = await api.getDetailedInfo(route.params.cardBoxRarityId)
      if (res.kind === "ok") {
        if (Platform.OS === "web") {
          window.document.title = res.data.name
        }
        setInfo(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchGetDetailedInfo()
      fetchList.onRefresh()
    }, [route]),
  )

  const Item: FC<ItemProps> = useCallback(({ item }) => {
    const $grayColor: TextStyle = {
      color: item?.isCollect ? colors.palette.neutral100 : colors.palette.neutral30,
    }
    const $viceGrayColor: TextStyle = {
      color: item?.isCollect ? colors.palette.neutral100 : colors.palette.neutral30,
    }
    const $borderColor: ViewStyle = {
      borderColor: item?.isCollect ? colors.palette.neutral100 : colors.palette.neutral30,
    }

    return (
      <Fragment>
        {item.isRead === 0 ? (
          <Image source={Images.components.red_tip} style={style.red} />
        ) : (
          <View style={[style.haveNum, { display: item?.haveNum > 1 ? "flex" : "none" }]}>
            <Text style={style.haveNumText}>{item.haveNum}</Text>
          </View>
        )}

        <Touchable
          style={[style.card, $borderColor]}
          onPress={() => {
            if (item.haveNum > 1) {
              setShowSelectSeries(true)
              setUseId(item.id)
              setUseHaveNum(item.haveNum)
            } else {
              navigation.navigate("AssetDetails", {
                id: item.id,
              })
            }
          }}
          disabled={!item?.isCollect}
        >
          <View style={style.cardImg}>
            <ImageFilter
              isHave={item?.isCollect}
              source={Platform.OS === "web" ? { uri: item?.image } : item?.image}
            />
            {/* <Image
              source={{ uri: item.image }}
              style={{
                width: fitSize(96),
                height: fitSize(96),
              }}
            /> */}
          </View>
          <Text style={[style.cardName, $grayColor]} numberOfLines={1}>
            {item?.name}
            {/* <LinerGradient
            colors={["transparent", "#000000"]}
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 1 }}
            style={style.linerGradient}
          /> */}
          </Text>
          <Text style={[style.cardActionName, $viceGrayColor]}>{item?.actionName}</Text>
          <Text style={[style.cardActionName, $viceGrayColor]}>
            {dayjs(item?.actionAriseTime).format("YYYY.MM.DD")}
          </Text>
        </Touchable>
      </Fragment>
    )
  }, [])

  const Header = () => {
    return (
      <View
        style={[
          style.headerContent,
          {
            position: !showHeader ? "absolute" : "relative",
            top: !showHeader ? fitSize(205) : 0,
          },
        ]}
      >
        <View style={style.headerInfo}>
          <View style={style.headerLeftInfo}>
            <View style={style.row}>
              <SizeImage source={{ uri: info?.backgroundImage }} style={style.headerRarityIcon} />
              <Text style={style.rarityText}>COLLECTION</Text>
            </View>
            <Text style={style.headerName}>
              {/* {info?.name}·{info?.rarityName} */}
              {info?.name}
            </Text>
          </View>
          <View style={style.headerRightInfo}>
            <Text style={style.headerOneNumber}>
              {info?.haveNum}
              <Text style={style.headerTwoNumber}>/{info?.totalAssetsNum}</Text>
            </Text>
            <Text style={style.label}>系列收集</Text>
          </View>
        </View>
        <Text style={style.info} numberOfLines={2}>
          {info?.description}
        </Text>
      </View>
    )
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Touchable onPress={() => navigation.goBack()} style={style.backBox}>
        <Image style={style.backIcon} source={Images.components.header_back} resizeMode="cover" />
      </Touchable>
      <AllSeriesModal
        onSelect={(value) => {
          setShowSelectSeries(false)
          navigation.navigate("AssetDetails", {
            id: useId,
            assetId: value,
          })
        }}
        modalShow={showSelectSeries}
        id={useId}
        onBack={() => {
          setShowSelectSeries(false)
        }}
        assetTotal={useHaveNum}
      />
      {/* <View style={style.header}></View> */}
      {showHeader && <Header />}
      <View style={style.body}>
        <HookFlatList
          isTransverseLoad={true}
          isShow={fetchList.data.length > 0}
          {...fetchList}
          scrollEventThrottle={16}
          data={fetchList.data}
          contentContainerStyle={{
            paddingBottom: fitSize(80),
          }}
          numColumns={3}
          ListHeaderComponent={
            <>
              <SizeImage
                source={{ uri: info?.image }}
                style={[
                  style.headerImg,
                  {
                    marginBottom: !showHeader ? fitSize(125) : 0,
                    height: fitSize(215),
                  },
                ]}
              />
              {!showHeader && <Header />}
            </>
          }
          showsHorizontalScrollIndicator={false}
          onScroll={(e) => {
            const y = e.nativeEvent.contentOffset.y
            if (y > fitSize(205)) {
              setShowHeader(true)
            } else {
              setShowHeader(false)
            }
          }}
          estimatedItemSize={fitSize(168)}
          // estimatedListSize={{ width: fitSize(104), height: fitSize(168) }}
          onEndReachedThreshold={0.3}
          showsVerticalScrollIndicator={false}
          renderItem={({ item, index }) => {
            return <Item item={item as unknown as UserCollectItem} />
          }}
        />
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  red: {
    width: fitSize(12),
    height: fitSize(12),
    position: "absolute",
    top: -5,
    right: fitSize(2),
    zIndex: 100,
  },
  haveNum: {
    minWidth: fitSize(16),
    height: fitSize(16),
    position: "absolute",
    top: -4,
    right: fitSize(2),
    zIndex: 100,
    backgroundColor: colors.palette.neutral40,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
  },
  haveNumText: {
    fontSize: fitSize(11),
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
    color: colors.palette.neutral30,
  },
  linerGradient: {
    height: fitSize(13),
    width: fitSize(23),
    position: "absolute",
    right: 1.5,
    top: 3,
  },
  row: {
    flexDirection: "row",
  },
  info: {
    color: colors.palette.neutral50,
    fontSize: fitSize(12),
    fontFamily: fontName.PingFang_SC,
    fontWeight: "400",
  },
  label: {
    color: colors.palette.neutral100,
    fontSize: fitSize(10),
    fontFamily: fontName.PingFang_SC,
    fontWeight: "400",
  },
  headerTwoNumber: {
    color: colors.palette.neutral50,
    fontSize: fitSize(12),
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
  },
  headerOneNumber: {
    color: colors.palette.neutral100,
    fontSize: fitSize(12),
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
  },
  headerRightInfo: {
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  headerName: {
    color: colors.palette.neutral100,
    fontSize: fitSize(24),
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
  },
  rarityText: {
    color: colors.palette.neutral100,
    fontSize: fitSize(14),
    fontFamily: fontName.YouSheBiaoTiHei_2,
    position: "absolute",
    left: 22,
  },
  headerRarityIcon: {
    width: fitSize(48),
    height: fitSize(20),
  },
  headerLeftInfo: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  headerInfo: {
    width: "100%",
    height: fitSize(53),
    marginBottom: fitSize(7),
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  stickyStuff: {
    backgroundColor: colors.palette.neutral900,
  },
  headerContent: {
    // flexGrow: 1,
    width: "100%",
    flexDirection: "column",
    paddingTop: fitSize(20),
    paddingHorizontal: fitSize(14),
    height: fitSize(125),
    backgroundColor: colors.palette.neutral900,
  },
  headerImg: {
    width: "100%",
  },
  cardTime: {
    fontWeight: "400",
    fontSize: fitSize(11),
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(2),
  },
  cardActionName: {
    fontWeight: "400",
    fontSize: fitSize(11),
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(4),
  },
  cardName: {
    fontWeight: "500",
    fontSize: fitSize(14),
    fontFamily: fontName.PingFang_SC_Semibold,
    marginTop: fitSize(4),
    textAlign: "center",
    width: "100%",
    paddingHorizontal: 2,
  },
  cardImg: {
    width: fitSize(96),
    height: fitSize(96),
    alignItems: "center",
    justifyContent: "center",
  },
  backBox: {
    position: "absolute",
    left: 10,
    zIndex: 999,
    top: 10,
  },
  backIcon: {
    width: fitSize(20),
    height: fitSize(20),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  textStyle: {
    color: colors.palette.neutral100,
  },
  leftStyle: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    width: "100%",
    minHeight: fitSize(290),
    marginBottom: fitSize(10),
  },
  body: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    marginBottom: fitSize(20),
    // marginTop: fitSize(16),
    // paddingHorizontal: fitSize(14),
  },
  content: {
    width: "100%",
    flexGrow: 1,
    marginBottom: fitSize(20),
  },
  card: {
    width: fitSize(104),
    height: fitSize(168),
    borderRadius: fitSize(2),
    borderWidth: 1,
    overflow: "hidden",
    marginLeft: fitSize(15),
    marginBottom: fitSize(24),
    flexDirection: "column",
    alignItems: "center",
  },
})
