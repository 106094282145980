import React, { FC } from "react"
import { fitSize } from "../../../theme"
import { bannerColumnItem } from "../../../services/api"
import { UniversalPlaybackContainer } from "./UniversalPlaybackContainer"

interface TeachingVideoProps {
  data: bannerColumnItem[]
}

export const TeachingVideo: FC<TeachingVideoProps> = ({ data }) => {
  return <UniversalPlaybackContainer data={data} h={fitSize(183)} w={fitSize(325)} />
}
