import { StatusBar } from "react-native"

let ProgressData,
  checkPushyUpdate,
  downloadAndInstallApk,
  downloadUpdate,
  isFirstTime,
  isRolledBack,
  markSuccess,
  switchVersion,
  switchVersionLater,
  Video,
  GestureHandlerRootView,
  ViewShot,
  CameraRoll,
  PermissionsAndroid,
  // WeChat,
  DeviceInfo,
  createThumbnail,
  InAppPurchases

export {
  ProgressData,
  checkPushyUpdate,
  downloadAndInstallApk,
  downloadUpdate,
  isFirstTime,
  isRolledBack,
  markSuccess,
  switchVersion,
  switchVersionLater,
  Video,
  GestureHandlerRootView,
  ViewShot,
  CameraRoll,
  PermissionsAndroid,
  // WeChat,
  DeviceInfo,
  StatusBar,
  createThumbnail,
  InAppPurchases,
}
