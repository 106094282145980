import { Platform, ScrollView, StyleSheet, View } from "react-native"
import React, { Fragment, useRef, useState } from "react"
import { fitSize } from "../../../theme"
import { bannerColumnItem } from "../../../services/api"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { Image } from "react-native"
import { SizeImage } from "../../../components/SizeImage"
import { Images } from "../../../theme"
import { useStores } from "../../../models"
import { GToast } from "../../../navigators"
import { VideoModal } from "../../../modal/VideoModal"
import { MultiPlatformVideoPlayer } from "../../../components/MultiPlatformVideoPlayer"
import { TouchableDeep, Touchable } from "../../../components/Button"

interface UniversalPlaybackContainerProps {
  data: bannerColumnItem[]
  isFullScreen?: boolean
  h?: number
  w?: number
}

export const UniversalPlaybackContainer = (props: UniversalPlaybackContainerProps) => {
  const { data, isFullScreen = true, h, w } = props
  const [list, setList] = useState(data)
  const navigation = useTypeNavigation()
  const { accountStore } = useStores()
  const isSafari = /apple/i.test(navigator.vendor)
  const [showVideo, setShowVideo] = useState(false)
  const [videoURL, setVideoURL] = useState({ src: "", poster: "" })
  const [fullScreen, setFullScreen] = useState(false)
  const videoRef = useRef(null)
  const [isPaused, setIsPaused] = useState(false)

  const goToActivity = (item: bannerColumnItem, currentIndex?: number) => {
    if (item.type === 1) {
      if (item.link && item.link.includes("https://")) {
        if (Platform.OS === "web") {
          window.location.href = item?.link
        } else {
          navigation.navigate("ActivityWebView", { uri: item?.link })
        }
      } else {
        const route: any = item.link.split("router://")[1]
        if (!accountStore.token) {
          GToast.current.doLogin()
        } else {
          navigation.navigate(route)
        }
      }
    } else {
      videoRef.current.toggleVideo()
      setVideoPaused(currentIndex)
    }
  }

  const setDataVideoState = (currentIndex: number) => {
    const arr: bannerColumnItem[] = data.map((item, index) => {
      if (index === currentIndex) {
        return { ...item, isPaused: false }
      } else {
        return { ...item, isPaused: true }
      }
    })
    setList(arr)
  }

  const handlePlay = (item: bannerColumnItem, currentIndex?: number) => {
    if (isSafari && Platform.OS === "web") {
      setShowVideo(true)
      setVideoURL({ src: item.image, poster: item.poster })
      setIsPaused(true)
      setDataVideoState(currentIndex)
    } else {
      setDataVideoState(currentIndex)
    }
  }

  const handleFullScreen = (item: bannerColumnItem, currentIndex: number) => {
    const updatedList = list.map((listItem) => {
      return { ...listItem, isPaused: true }
    })
    setList(updatedList)
    GToast.current.showControlVideoModal(true, {
      src: item?.image,
      poster: item.poster,
      seek: 0,
    })
    setFullScreen(true)
  }

  const setVideoPaused = (currentIndex) => {
    const updatedData = data.map((item, index) => {
      if (index === currentIndex) {
        return { ...item, isPaused: true }
      } else {
        return item
      }
    })
    setList(updatedData)
  }

  const clearVideo = () => {
    setShowVideo(false)
    setFullScreen(false)
    setVideoURL({ src: "", poster: "" })
  }

  return (
    <Fragment>
      <ScrollView
        style={styles.container}
        horizontal
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        pagingEnabled
      >
        {list?.map((item, index) => {
          const isControl = item.type === 2
          return (
            <View style={[styles.card, { width: w, height: h }]} key={index}>
              <React.Fragment>
                {isControl && item.isPaused ? (
                  <Touchable onPress={() => handlePlay(item, index)} style={[styles.videoPlayBox]}>
                    <SizeImage
                      source={Images.screen.home.time_video_pause}
                      style={styles.timeVideoPause}
                    />
                  </Touchable>
                ) : null}
                {isControl ? (
                  <Touchable
                    onPress={() => handleFullScreen(item, index)}
                    style={[styles.videoFullScreenBox]}
                  >
                    <SizeImage
                      source={Images.screen.home.fill_screen}
                      style={styles.timeVideoFillScreen}
                    />
                  </Touchable>
                ) : null}
                {/* {isControl && !item?.isPaused ? (
                  <Touchable
                    onPress={() => {
                      item.muted = !item.muted
                    }}
                    style={[
                      {
                        zIndex: 2147483647,
                        position: "absolute",
                        right: 0,
                        top: 0,
                        width: fitSize(50),
                        paddingRight: fitSize(10),
                        paddingTop: fitSize(10),
                        height: fitSize(50),
                        alignItems: "flex-end",
                        justifyContent: "flex-start",
                      },
                    ]}
                  >
                    <Image
                      source={
                        Images.components[
                          item.muted ? "default_not_circle_muted" : "not_circle_muted"
                        ]
                      }
                      style={{ width: fitSize(16), height: fitSize(16) }}
                    />
                  </Touchable>
                ) : null} */}
                <TouchableDeep
                  key={item.id}
                  onPress={() => goToActivity(item, index)}
                  style={[styles.child, { height: h }]}
                  disabled={item.isPaused && isControl}
                >
                  {item?.type === 1 ? (
                    <SizeImage
                      source={{ uri: item.image }}
                      style={[styles.imageStyle, { height: h }]}
                      resizeMode={"stretch"}
                    />
                  ) : item?.type === 2 && !item?.isPaused ? (
                    <Fragment>
                      {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
                        <MultiPlatformVideoPlayer
                          uri={item?.image}
                          isLook={false}
                          style={{
                            width: w,
                            height: h,
                            overflow: "hidden",
                          }}
                          h={h}
                          w={w}
                          isPaused={item?.isPaused}
                          poster={item?.link}
                          loop={false}
                          index={index}
                          ref={videoRef}
                          muted={item.muted}
                        />
                      ) : null}
                    </Fragment>
                  ) : item?.type === 2 && item?.isPaused ? (
                    <SizeImage
                      source={{ uri: item?.link ?? "" }}
                      style={[
                        {
                          width: w,
                          height: h,
                          overflow: "hidden",
                        },
                      ]}
                      resizeMode={"stretch"}
                    />
                  ) : null}
                </TouchableDeep>
              </React.Fragment>
            </View>
          )
        })}
      </ScrollView>
    </Fragment>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: fitSize(8),
    overflow: "hidden",
    marginRight: fitSize(14),
  },
  container: {
    width: "100%",
    height: fitSize(260),
  },
  imageStyle: {
    width: "100%",
  },
  videoPlayBox: {
    zIndex: 2147483647,
    position: "absolute",
    left: fitSize(145),
    top: 70,
  },
  videoFullScreenBox: {
    zIndex: 2147483647,
    position: "absolute",
    right: 10,
    bottom: 10,
  },
  child: {
    width: "100%",
    justifyContent: "center",
  },
  timeVideoPause: {
    width: fitSize(36),
    height: fitSize(36),
  },
  timeVideoFillScreen: {
    width: fitSize(16),
    height: fitSize(16),
  },
})
