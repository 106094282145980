import { Image, StyleSheet, View } from "react-native"
import { Images, colors, fitSize } from "../../theme"
import React from "react"
import { Screen, Text, Touchable } from "../../components"
import { GToast } from "../../navigators"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { observer } from "mobx-react-lite"
import { translate } from "../../i18n"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { fontName } from "../../theme/fonts"
import { SizeImage } from "../../components/SizeImage"
import { api } from "../../services/api"

interface SettingProps extends RootStackNavProps<"Setting"> {}

export const Setting: React.FC<SettingProps> = observer(({ navigation, route }) => {
  const { bottom } = useSafeAreaInsets()
  const store = useStores()
  const { accountStore } = useStores()

  const exitQuery = () => {
    GToast.current.showAlert(
      translate("setting.sureExit"),
      () => {},
      () => {
        fetchLogout()
      },
    )
  }

  const fetchLogout = async () => {
    try {
      const res = await api.logout()
      if (res.kind === "ok") {
        exit()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const exit = () => {
    /**
     * TODO 退出登录 但是不删除本地数据
     */
    // store.removeAll()
    //@ts-ignore
    navigation.replace("LoginScreen")
    accountStore.setManualExit(true)
    GToast.current.showToast(translate("setting.alreadyExit"), "TOAST", 2000)
  }

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("setting.personSetting")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <View style={style.btnContent}>
          <Touchable style={style.btnLineBox} onPress={() => navigation.push("ChooseAvatar")}>
            <Text style={style.btnLeftText}>{translate("setting.avator")}</Text>
            <View style={style.rowCenter}>
              <SizeImage
                style={style.avatar}
                source={
                  accountStore.user?.avatar
                    ? { uri: accountStore.user?.avatar }
                    : Images.test.m_owner1
                }
                resizeMode="cover"
              />
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
          <Touchable style={style.btnBox} onPress={() => navigation.push("NicknameEdit")}>
            <Text style={style.btnLeftText}>{translate("setting.nickName")}</Text>
            <View style={style.rowCenter}>
              <Text style={style.nikeName}>{accountStore.user?.nickname}</Text>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
        </View>

        <View style={style.btnContent}>
          <Touchable style={style.btnBox} onPress={() => navigation.push("AccountSecurity")}>
            <Text style={style.btnLeftText}>{translate("setting.accountSafe")}</Text>
            <View style={style.rowCenter}>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
        </View>

        <View style={style.btnContent}>
          <Touchable style={style.btnLineBox} onPress={() => navigation.push("About")}>
            <Text style={style.btnLeftText}>{translate("setting.about")}</Text>
            <View style={style.rowCenter}>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
          <Touchable style={style.btnBox} onPress={() => navigation.push("RelationService")}>
            <Text style={style.btnLeftText}>{translate("setting.relationService")}</Text>
            <View style={style.rowCenter}>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
        </View>
        <Touchable onPress={exitQuery} style={[style.box, { bottom: bottom + fitSize(20) }]}>
          <Text tx={"setting.alreadyExit"} style={style.boxText}></Text>
        </Touchable>
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  nikeName: {
    fontSize: fitSize(13),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  btnBox: {
    width: "100%",
    height: fitSize(55),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  backIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginLeft: fitSize(8),
  },
  avatar: {
    width: fitSize(34),
    height: fitSize(34),
    borderRadius: fitSize(34),
    overflow: "hidden",
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  btnLeftText: {
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  btnLineBox: {
    width: "100%",
    minHeight: fitSize(55),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: colors.palette.neutral30,
  },
  btnContent: {
    flexDirection: "column",
    width: fitSize(347),
    minHeight: fitSize(55),
    borderRadius: fitSize(6),
    backgroundColor: colors.palette.neutral60,
    marginTop: fitSize(10),
    paddingHorizontal: fitSize(14),
    marginBottom: fitSize(14),
  },
  content: {
    backgroundColor: colors.palette.neutral900,
    flex: 1,
    alignItems: "center",
  },
  boxText: {
    fontSize: fitSize(14),
    color: colors.palette.primary500,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  box: {
    position: "absolute",
    width: fitSize(310),
    height: fitSize(50),
    backgroundColor: colors.palette.neutral60,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: fitSize(6),
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
})
