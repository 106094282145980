import { Animated, ViewStyle } from "react-native"
import { ImageStyle } from "react-native-fast-image"
import { Images, fitSize } from "../../../theme"
import { SizeImage } from "../../../components/SizeImage"
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import { useStores } from "../../../models"

export const TipsUpIcon = forwardRef((props, ref) => {
  const { accountStore } = useStores()
  const tipsTop = useRef(new Animated.Value(fitSize(0))).current
  const fadeAnim = useRef(new Animated.Value(!accountStore.tipScreen.cardDetail ? 0.7 : 0)).current

  const setFadeAnimate = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }

  useEffect(() => {
    setTipAnimate()
  }, [])

  useImperativeHandle(
    ref,
    () => {
      return {
        setTipAnimate: () => setTipAnimate(),
      }
    },
    [],
  )

  const setTipAnimate = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(tipsTop, {
          toValue: -25,
          duration: 1000,
          useNativeDriver: false,
        }),
        Animated.timing(tipsTop, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: false,
        }),
      ]),
      { iterations: 2 },
    ).start(() => {
      setFadeAnimate()
      accountStore.setShowTip("cardDetail")
    })
  }

  return (
    <Animated.View
      style={[
        $valueAnimated,
        {
          opacity: fadeAnim,
          transform: [{ translateY: tipsTop }],
        },
      ]}
    >
      <SizeImage source={Images.components.tip_down} style={$percentageImg} resizeMode="cover" />
    </Animated.View>
  )
})

const $percentageImg: ImageStyle = {
  width: "100%",
  height: "100%",
  transform: [{ rotate: "180deg" }],
}

const $valueAnimated: ViewStyle = {
  height: fitSize(40),
  width: fitSize(40),
  position: "absolute",
  left: "45%",
  top: -10,
}
