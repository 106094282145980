import { Image, StyleSheet, Text, View } from "react-native"
import { Images, colors, fitSize } from "../../../theme"
import { UserAssetItem } from "../../../services/api"
import { SizeImage } from "../../../components/SizeImage"
import { fontName } from "../../../theme/fonts"
import { Touchable } from "../../../components"
import { DashedLine } from "../../TabStore/StoreScreen"
import dayjs from "dayjs"
import { RarityIcon } from "../../TabHome/components/RarityIcon"

export type UserAssetItemObj = UserAssetItem & { isSelect: boolean }

interface AssetItemProps {
  item: UserAssetItemObj
  setCard: (id: number) => void
}

export const AssetItem = (props: AssetItemProps, ref) => {
  const { item, setCard } = props

  const handlePress = () => {
    setCard(item.id)
  }

  return (
    <Touchable onPress={handlePress} disabled={item.isSelect}>
      <SizeImage
        source={{
          uri: item.frameHImage,
        }}
        resizeMode="contain"
        style={[
          style.item,
          { backgroundColor: item.isSelect ? colors.palette.neutral100 + "28" : "transparent" },
        ]}
      >
        <View
          style={{
            width: fitSize(92),
            height: fitSize(92),
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SizeImage
            source={{ uri: encodeURI(item.image) }}
            style={style.image}
            resizeMode="contain"
          />
        </View>
        <View style={style.itemContainer}>
          <View style={style.nameBox}>
            <Text style={style.name}>{item?.name}</Text>
            {item.isSelect ? (
              <Image source={Images.common.copy_success} style={[style.box]} />
            ) : (
              <View style={[style.box]} />
            )}
          </View>
          <View style={style.actionContent}>
            <Text style={style.actionNameText}>{item?.actionName}</Text>
            <DashedLine />
            <Text style={style.actionAriseTimeText}>
              {dayjs(item?.actionAriseTime).format("YYYY.MM.DD")}
            </Text>
          </View>
          <View style={style.rowCenter}>
            <Text style={style.cardBoxNameText}>{item?.cardBoxName}</Text>
            <DashedLine />
            <Text style={style.seriesNameText}>{item?.seriesName}</Text>
          </View>
          <View style={style.row}>
            <RarityIcon icon={item?.rarityIcon} />
            <View style={style.rowCenter}>
              <Text style={style.idText}>#{item?.assetNumber}</Text>
              <Text style={style.circulationText}>/{item?.circulation}</Text>
            </View>
          </View>
        </View>
      </SizeImage>
    </Touchable>
  )
}

const style = StyleSheet.create({
  row: {
    flexDirection: "row",
    marginTop: fitSize(16),
    alignItems: "center",
  },
  rarityIcon: {
    minWidth: fitSize(14),
    maxWidth: fitSize(24),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  idText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
  },
  circulationText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
    paddingTop: fitSize(1),
  },
  actionContent: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: fitSize(10),
  },
  actionNameText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  actionAriseTimeText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  cardBoxNameText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  seriesNameText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  name: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
    alignSelf: "flex-end",
  },
  nameBox: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    height: fitSize(29),
  },
  box: {
    borderRadius: fitSize(20),
    width: fitSize(20),
    height: fitSize(20),
    borderWidth: 1,
    borderColor: colors.palette.neutral100,
    position: "absolute",
    left: 155,
    top: -10,
  },
  itemContainer: {
    flexDirection: "column",
    flexGrow: 1,
    paddingLeft: fitSize(16),
    alignItems: "flex-start",
  },
  image: {
    width: fitSize(74),
    height: fitSize(74),
  },
  item: {
    width: fitSize(306),
    height: fitSize(141),
    flexDirection: "row",
    alignItems: "center",
    marginBottom: fitSize(10),
    borderRadius: fitSize(8),
    alignSelf: "center",
    padding: fitSize(14),
  },
})
