import {
  BackHandler,
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  useWindowDimensions,
} from "react-native"
import React, { useEffect, useState } from "react"

import { Images, colors } from "../theme"
import Modal from "react-native-modal"
import { Touchable } from "../components"
import { fitSize, deviceHeight } from "../theme/layout"
import { MultiPlatformVideoPlayer } from "../components/MultiPlatformVideoPlayer"
import * as ScreenOrientation from "expo-screen-orientation"
import { SizeImage } from "../components/SizeImage"
import { fontName } from "../theme/fonts"
import { useTypeNavigation } from "../navigators/useTypeNavigation"

interface ControlVideoModalProps {
  isShow: boolean
  closeModal: () => void
  data: {
    src: string
    poster: string
    seek: number
  }
  showClose?: boolean
}

const WIDTH = Dimensions.get("window").width
const HEIGHT = fitSize(211)

export const ControlVideoModal: React.FC<ControlVideoModalProps> = ({
  closeModal,
  data,
  isShow,
  showClose,
}) => {
  const windowHeight = useWindowDimensions().height
  const [orientation, setOrientation] = useState("PORTRAIT_UP")
  const [muted, setMuted] = useState(true)
  const [videoPause, setVideoPause] = useState(false)
  const navigation = useTypeNavigation()

  const closeUI = () => {
    closeModal()
    changeScreenVerticalOrientation()
    setOrientation("PORTRAIT_UP")
  }

  const compatibleHeight = Platform.OS === "web" ? windowHeight : deviceHeight

  const changeScreenCrossOrientation = async () => {
    try {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT)
    } catch (error) {
      console.log("error cross", error)
    }
  }

  const changeScreenVerticalOrientation = async () => {
    try {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
    } catch (error) {
      console.log("error vertical", error)
    }
  }

  useEffect(() => {
    const backAction = () => {
      if (!isShow) {
        return false
      } else {
        return true
      }
    }
    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction)
    return () => backHandler?.remove()
  }, [isShow])

  useEffect(() => {
    if (!isShow) {
      changeScreenVerticalOrientation()
      setOrientation("PORTRAIT_UP")
    }
  }, [!isShow])

  return (
    <Modal
      useNativeDriverForBackdrop
      hasBackdrop={true}
      coverScreen={false}
      isVisible={isShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={[{ margin: 0, backgroundColor: colors.palette.neutral900 }]}
      onBackButtonPress={showClose ? closeUI : () => {}}
      onBackdropPress={showClose ? closeUI : () => {}}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      statusBarTranslucent
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
    >
      <Touchable style={styles.backBox} onPress={closeUI}>
        <Image source={Images.components.header_back} style={styles.backIcon} />
      </Touchable>
      {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
        <MultiPlatformVideoPlayer
          uri={data.src}
          isPaused={videoPause}
          muted={muted}
          h={orientation === "PORTRAIT_UP" ? HEIGHT : Dimensions.get("window").height}
          w={WIDTH}
          isLook={false}
          loop={true}
          isReset={false}
        />
      ) : null}
      {Platform.OS !== "web" ? (
        <Touchable
          onPress={() => {
            setMuted((prev) => !prev)
          }}
          style={[
            {
              zIndex: 2147483647,
              position: "absolute",
              right: 10,
              top: orientation === "PORTRAIT_UP" ? fitSize(325) : fitSize(40),
            },
          ]}
        >
          <Image
            source={Images.components[muted ? "default_not_circle_muted" : "not_circle_muted"]}
            style={{ width: fitSize(18), height: fitSize(18) }}
          />
        </Touchable>
      ) : (
        <Touchable
          onPress={() => {
            setMuted((prev) => !prev)
          }}
          style={[
            {
              zIndex: 2147483647,
              position: "absolute",
              right: 10,
              top: fitSize(425),
            },
          ]}
        >
          <Image
            source={Images.components[muted ? "default_not_circle_muted" : "not_circle_muted"]}
            style={{ width: fitSize(18), height: fitSize(18) }}
          />
        </Touchable>
      )}
      <View
        style={{
          width: "100%",
          height: fitSize(18),
          position: "absolute",
          bottom: orientation === "PORTRAIT_UP" ? fitSize(305) : fitSize(20),
          flexDirection: "row",
          alignItems: "center",
          paddingHorizontal: fitSize(12),
          justifyContent: "space-between",
          display: Platform.OS === "web" ? "none" : "flex",
        }}
      >
        <Touchable onPress={() => setVideoPause((prev) => !prev)}>
          <Image
            source={Images.components[videoPause ? "video_play" : "video_pause"]}
            style={{ width: fitSize(18), height: fitSize(18) }}
          />
        </Touchable>
        <Touchable
          onPress={() => {
            if (orientation === "PORTRAIT_UP") {
              changeScreenCrossOrientation()
              setOrientation("LANDSCAPE_RIGHT")
            } else {
              changeScreenVerticalOrientation()
              setOrientation("PORTRAIT_UP")
            }
          }}
        >
          <Image
            source={Images.components[orientation ? "vertical_video" : "horizontal_video"]}
            style={{ width: fitSize(18), height: fitSize(18) }}
          />
        </Touchable>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  backBox: {
    position: "absolute",
    top: Platform.select({
      ios: fitSize(40),
      android: fitSize(40),
      web: fitSize(10),
    }),
    left: fitSize(12),
    zIndex: 999,
  },
  backIcon: {
    width: fitSize(20),
    height: fitSize(20),
  },
})
