export const InjectJS = `
window.jijiAppDebug = true
;(function () {
  var callbackId = 0
  var callbacks = {}

  var bridgeSend = function (data) {
    if (window.jijiAppDebug) {
      console.log("onSendBriage: " + JSON.stringify(data))
    }
    ReactNativeWebView.postMessage(JSON.stringify(data))
  }

  var history = window.history
  var pushState = history.pushState
  history.pushState = function (state) {
    setTimeout(function () {
      bridgeSend({
        type: "history-state-changed",
        navState: { url: location.href, title: document.title },
      })
    }, 100)
    return pushState.apply(history, arguments)
  }

  function qrCodeResponse(data, callback) {
    var result = data.data
    var regex = new RegExp(callback.regex)
    if (!result) {
      if (callback.reject) {
        callback.reject(new Error("Cancelled"))
      }
    } else if (regex.test(result)) {
      if (callback.resolve) {
        callback.resolve(result)
      }
    } else {
      if (callback.reject) {
        callback.reject(new Error("Doesn't match"))
      }
    }
  }
  function sendAPIrequest(permission, params) {
    var messageId = callbackId++;
    var params = params || {};

    bridgeSend({
        type: 'api-request',
        permission: permission,
        messageId: messageId,
        params: params
    });

    return new Promise(function (resolve, reject) {
        params['resolve'] = resolve;
        params['reject'] = reject;
        callbacks[messageId] = params;
    });
}

  function Unauthorized() {
    this.name = "Unauthorized"
    this.id = 4100
    this.code = 4100
    this.message = "The requested method and/or account has not been authorized by the user."
  }
  Unauthorized.prototype = Object.create(Error.prototype)

  function UserRejectedRequest() {
    this.name = "UserRejectedRequest"
    this.id = 4001
    this.code = 4001
    this.message = "The user rejected the request."
  }
  UserRejectedRequest.prototype = Object.create(Error.prototype)
  ReactNativeWebView.onMessage = function (message) {
    if (window.jijiAppDebug) {
      console.log("onMessage: " + message)
    }
    data = JSON.parse(message)
    var id = data.messageId
    var callback = callbacks[id]
    if (callback) {
      if (data.type === "api-response") {
        if (data.isAllowed) {
          callback.resolve(data.data)
        } else {
          callback.reject(new UserRejectedRequest())
        }
      }
    }
  }
  var JIJI = function () {}
  JIJI.prototype.sendAPIrequest = sendAPIrequest
  window.JIJI = new JIJI()
})()
`

const getWebviewUrl = `
	const __getFavicon = function(){
		let favicon = undefined;
		const nodeList = document.getElementsByTagName("link");
		for (let i = 0; i < nodeList.length; i++)
		{
			const rel = nodeList[i].getAttribute("rel")
			if (rel === "icon" || rel === "shortcut icon")
			{
				favicon = nodeList[i]
			}
		}
		return favicon && favicon.href
	}
	window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(
		{
			type: 'GET_WEBVIEW_URL',
			payload: {
				url: location.href,
				icon: __getFavicon()
			}
		}
	))
`
export const JS_WEBVIEW_URL = `
	(function () {
		${getWebviewUrl}
	})();
`
