const zh = {
  common: {
    ok: "OK!",
    cancel: "Cancel",
    copy: "复制成功",
    back: "Back",
    inputAddress: "请输入区块链地址",
    load: "加载中",
    editSuccess: "修改成功",
    saveSuccess: "保存成功",
    saveFail: "保存失败",
  },
  errorScreen: {
    title: "出问题了!",
    reset: "重启App",
    baiduTip: "【重要通知】您的浏览器不支持某些功能，建议使用其他浏览器或者下载APP",
  },
  emptyStateComponent: {
    generic: {
      heading: "So empty... so sad",
      content: "No data found yet. Try clicking the button to refresh or reload the app.",
      button: "Let's try this again",
    },
  },
  screen: {
    realName: {
      title: "请进行实名认证",
      nameInput: "姓名",
      nameInputTip: "请填写姓名",
      numberInput: "身份证",
      numberInputTip: "请填写身份证",
      button: "确认",
    },
    messageList: {
      header: "公告",
    },
    challengeDetails: {
      countdownEnd: "{{time}}将关闭",
      countdownStart: "{{time}}将开始",
      remaining: "剩余{{remaining}}张",
      hotText: "热门挑战",
      payOut: "您要付出",
      havePaid: "您已付出",
      ruleTitle: "规则说明",
      tipTitle: "重要提示",
      nowGet: "立即获取",
      closeTip: "这张卡将被销毁",
      notSelectTip: "暂未选择销毁卡",
      switch: "更换",
      select: "请选择",
      atWill: "任意时刻",
      restrict: "仅限{{name}}时刻",
      synthesisSuccess: "合成开始",
      assetDrawerTitle: "更换卡片",
      submit: "确定",
    },
    compScreen: {
      title: "挑战",
      header: "挑战",
      headerRight: "挑战记录",
      startCountdown: "距离开始时间",
      closeCountdown: "距离结束时间",
      day: "天",
      hour: "时",
      minute: "分",
      second: "秒",
      submitBtn: "开始挑战",
      hotText: "热门挑战",
      finish: "已结束",
      remainderTxt: "剩余{{number}}张",
    },
    settingNewPassword: {
      title: "设置新密码",
      titleTip: "您的心密码必须不同于以前使用的密码",
      inputPhone: "手机号",
      inputPlaceholder: "请输入手机号",
      inputCode: "验证码",
      inputCodePlaceholder: "请输入验证码",
      inputPassword: "新密码",
      inputPasswordPlaceholder: "请输入新密码",
      inputPasswordAgain: "确认新密码",
      inputPasswordAgainPlaceholder: "请再次输入新密码",
      getCode: "获取验证码",
      submit: "完成并登录",
      tip: "必须是6-20个字符之间，至少包含英文字",
      countdown: "重新发送({{time}})",
      success: "成功设置新密码",
    },
    passwordLogin: {
      title: "密码登录",
      inputPlaceholder: "请输入账号",
      passwordPlaceholder: "请输入密码",
      btnText: "立即登录",
      forgetPassword: "忘记密码",
    },
    verificationCode: {
      title: "输入验证码",
      tip: "验证码已发送至 +86 {{phone}}",
      countdown: "重新发送({{time}})",
      resend: "重新发送",
    },
    loginScreen: {
      title: "登录发现更多精彩",
      button: "本机号码一键登录",
      otherLogin: "其他方式",
      thirdLogin: "使用第三方账号登录",
      wechat: "微信",
      qq: "QQ",
      weibo: "微博",
      did: "DID",
      tip: "已阅读并同意",
      userProtocol: "《用户协议》",
      privacyAgreement: "《隐私政策》",
      mobileService: "《中国移动认证服务条款》",
      inputPlaceholder: "请输入手机号",
      phoneLabel: "+86",
      modal: {
        title: "服务协议及隐私保护",
        content: "为了更好地保障您的合法权益，请您阅读并同意以下协议",
        tip: "，未注册手机号将自动注册。",
        btn: "同意并继续",
      },
    },
    phoneLogin: {
      title: "手机号登录",
      inputPlaceholder: "请输入手机号",
      btnText: "获取验证码",
      codePlaceholder: "请输入验证码",
      passwordLogin: "密码登录",
      tip: "未注册的手机号验证后将自动注册",
    },
    forgotPassword: {
      header: "忘记密码",
      inputPlaceholder: "请输入手机号",
      btnText: "获取验证码",
      codePlaceholder: "请输入验证码",
      codeBtnText: "确定",
      codeSuccess: "验证码发送成功",
      codeFail: "验证码发送失败",
      codeError: "验证码错误",
      phoneError: "手机号错误",
      newPlaceholder: "请输入新密码",
      confirmNewPassword: "请再次输入新密码",
      tip: "必须是6-20个字符之间，至少包含英文字",
      phoneVerify: "手机号验证",
    },
    changePassword: {
      header: "修改密码",
      headerRight: "忘记密码",
      oldPassword: "原密码",
      oldPlaceholder: "请输入原密码",
      newPassword: "新密码",
      newPlaceholder: "请输入新密码",
      confirmNewPassword: "请再次输入新密码",
      tip: "必须是6-20个字符之间，包含大小写英文字母、数字、标点符号（除空格）",
      confirmBtn: "确认",
      editSuccess: "修改成功",
    },
    settingPassword: {
      header: "设置密码",
      newPlaceholder: "请输入密码",
      confirmNewPassword: "请再次输入密码",
      tip: "必须是6-20个字符之间，包含大小写英文字母、数字、标点符号（除空格）",
      confirmBtn: "确认",
      editSuccess: "修改成功",
    },
    phoneBinding: {
      isCheck: "现手机号验证",
      header: "新手机号绑定",
      inputPlaceholder: "请输入手机号",
      btnText: "获取验证码",
      codePlaceholder: "请输入验证码",
      codePlaceholderTime: "{{time}}秒后重发",
      codeBtnText: "确认",
      codeSuccess: "验证码发送成功",
      codeFail: "验证码发送失败",
      codeError: "验证码错误",
      phoneError: "手机号错误",
      phoneSuccess: "手机号绑定成功",
      phoneFail: "手机号绑定失败",
      code: "验证码",
      phone: "手机号",
      codeSend: "验证码已发送",
      codeSendAgain: "重新发送",
      codeSendFail: "验证码发送失败",
      codeSendSuccess: "验证码发送成功",
      phoneLabel: "+86",
      confirmBtn: "确定",
    },
    accountSafe: {
      header: "账号与安全",
      bindPhone: "绑定手机号",
      setPassWord: "修改密码",
      realName: "实名认证",
      realNameY: "已实名",
      realNameN: "未实名",
      bindWechat: "绑定微信",
      bindAlipay: "绑定支付宝",
      bindEmail: "绑定邮箱",
      bindQQ: "绑定QQ",
      bindWeibo: "绑定微博",
      bindDID: "绑定DID",
      clearUser: "注销账号",
      verified: "已认证",
      unverified: "未认证",
      binded: "已绑定",
      settingPassword: "设置密码",
      unbind: "未绑定",
      phoneBindingModal: {
        title: "更换绑定的手机号？",
        content: "当前绑定的手机号码为+86 {{phone}}， 是否继续？",
        cancel: "取消",
        confirm: "更换",
      },
    },
    nicknameEdit: {
      input: "输入您的昵称",
    },
    receiveDetail: {
      header: "收发详情",
      title: "详情",
      type: "类型",
      time: "时间",
      status: "状态",
      txid: "区块链交易ID",
      from: "发送地址",
      to: "接收地址",
      amount: "数量",
      source: "初始来源",
    },
    receiveRecord: {
      header: "收发记录",
      tabOne: "全部",
      tabTwo: "接收",
      tabThree: "发送",
      status: "状态:{{status}}",
    },
    orderBuy: {
      header: "消费记录",
      orderStatus: "订单状态",
      tabOne: "全部",
      tabTwo: "待付款",
      tabThree: "发送中",
      tabFour: "已完成",
      tabFive: "已取消",
      totalNumber: "共 {{total}件商品 合计：",
      oncePay: "立即支付 {{number}}",
      unit: "¥",
      randomDeparture: "随机开出{{number}}个时刻",
      paid: "已付款",
      beBeingSent: "发送中",
      sentSuccess: "发送成功",
      sendFailure: "发送失败",
    },
    walletAddress: {
      header: "我的钱包",
      copyAddress: "复制地址",
      copySuccess: "复制成功",
      copyFail: "复制失败",
      saveImg: "保存到相册",
      myAddress: "我的地址",
      rightBtn: "收发记录",
      wechat: "微信",
      wechatMoments: "朋友圈",
    },
    composeHisList: {
      header: "挑战记录",
      titleForShort: "MY CHALLENGE",
      completeState: "已完成",
      unCompleteState: "未完成",
      lockState: "已锁定",
      tip: "{{countdown}}后解锁",
    },
    getAwardFromCode: {
      header: "兑换中心",
      inputPlaceholder: "请输入兑换码",
      btnText: "立即兑换",
      infoTitle: "温馨提示",
      infoContent:
        "兑换码可通过多种官方运营渠道获取，可通过兑换中心兑換相关礼品。不同兑换码可兑換的礼品等级不同，请从正规渠道获取以防受骗。兑换码一经确认即视为核销不可二次使用，核销后会将兑换礼品下发至用户藏品背包中。",
      tip: "最终解释权归本平台所有",
    },
    closeAccount: {
      header: "账号注销",
      contentInfo:
        "很遗憾，CBA时刻无法继续为您提供服务，感谢您一直以来的陪伴。注销账号前，我们需要对以下信息进行审核，以保证您的账号安全。",
      btmText: "同意并注销",
      infoTitle: "CBA-聚光灯实验室平台用户注销规则",
      infoContentOne: "自愿放弃所有在聚光灯-CBA时刻下的虚拟资产和权益。",
      infoContentTwo: "账号内无未完成的订单和服务。",
      infoContentThree: "账户为有效账户，非冻结或封禁状态。",
      infoContentFour:
        "账户一旦被注销将不可恢复，建议您在操作之前先处理该账户内的数字藏品(如待付款、发放中、转赠中的数字藏品)。账户注销后60天内，该账户仍然可以登录，且一旦登录则终止注销流程。若超过60天未登录，则账户会彻底注销，不可恢复。",
      alertTitle: "申请注销即表示您自愿放弃账号内所有虚拟资产",
      alertClose: "暂不注销",
      alertConfirm: "申请注销",
      alertError: "注销失败",
      alertSuccess: "注销成功",
    },
    about: {
      version: "Version {{version}}",
      userAgreement: "用户协议",
      privacyPolicy: "隐私政策",
    },
    relationService: {
      header: "联系客服",
      infoOne: "联系咨询请关注官方微信公众号",
      infoTwo: "[聚光灯实验室]",
      btnText: "关注微信公众号",
    },
    loginFromMessage: {
      repeatLogin: "不能重复登录",
      protocolTip: "请阅读用户协议和隐私政策",
      userProtocol: "用户协议",
      privacyAgreement: "隐私协议",
      didTitle: "DID授权登录",
      didSettingText: "帮助与设置",
      didLoginTips: "登录前需先同意下方协议及政策",
      didUserProtocolAgree: "我已阅读并同意",
      didBtn: "DID登录",
      and: "和",
    },
    tabHome: {
      free: "免费兑换",
      title: "CBA时刻",
      timeDisplay: "时刻展示",
      allCardBag: "精选卡包",
      tidelandsList: "潮汇之地",
      prizeCenter: "兑奖中心",
      teachingVideo: "教学视频",
      invite: "邀请好友",
    },

    tabStore: {
      tabTitle: "我的时刻",
      headerTotal: "个时刻",
      codexTitle: "图鉴",
      seriesTitle: "系列",
      rarityTitle: "稀有度",
      cardBagTitle: "卡包",
      qualityTitle: "卡包",
      filtrate: "筛选",
      resetBtn: "重置",
      saveBtn: "保存",
      assetContent: {
        forShortName: "MY ASSET",
        fullName: "我的时刻",
      },
      illustratedHandbook: {
        forShortName: "COLLECTION",
        fullName: "时刻图鉴",
      },
      cardBoxStore: {
        forShortName: "MY CARD",
        fullName: "我的卡包",
      },
      cardInfo: {
        jerseyNumber: "球衣号码",
        cardRareType: "荣耀炫金",
        cardTaleType: "超级折射",
        cardEpicType: "史诗",
        playerStatistics: "球员数据",
        height: "身高",
        weight: "体重",
        age: "年龄",
        currentData: "本场数据",
        playingTime: "场上时间",
        contractAddress: "合约地址",
        bestow: "转赠",
        eventResult: "赛事成绩",
        blocks: "盖帽",
        steals: "抢断",
        assists: "助攻",
        rebound: "篮板",
        points: "得分",
        avgTime: "上场时间",
      },
    },
  },
  modal: {
    protocolTipModal: {
      oneTipTitle: "CBA时刻服务协议和隐私政策",
      twoTipTitle: "温馨提示",
      agreeBtn: "同意",
      exitApp: "不同意并退出",
      disagreeBtn: "不同意",
      continue: "同意并继续",
      twoInfo:
        "收集个人信息为我们向您提供服务所必须哦。我们仅会将您的个人信息用于为您提供服务，若不同意此协议，我们将无法为您提供服务并退出应用。",
      title: "在您使用我的各项服务之前，请务必审慎阅读、充分理解",
      userProtocol: "《CBA时刻用户协议》",
      privacyAgreement: "《CBA时刻隐私政策》",
      oneInfo:
        "同时您应特别注意前述协议中免除或者限制我们责任的条款、对您权利进行限制的条款。如您已详细阅读并同意CBA时刻用户协议、隐私政策，请点击[同意并继续]开始使用我们的服务。",
      and: "的条款。",
      oneViceInfo: "并且我们会申请获取您的设备信息，以向您提供安全风控服务。",
    },
    systemInfoModal: {
      versionNumber: "版本更新",
      info: "CBA时刻更新了新内容，其他小伙伴都已经更新了～～你也快来试试吧～",
    },
    optionalVideoPlayModal: {
      title: "恭喜获得",
      btnText: "确定",
      tip: "点击时刻查看详情",
    },
  },
  gToast: {
    showLoading: "加载中",
  },
  bottomNavigator: {
    oneTab: "首页",
    twoTab: "藏品",
    threeTab: "挑战",
    fourTab: "我的",
  },
  appNavigator: {
    openTimeList: "可获得时刻",
    showOrder: "订单详情",
    paymentSuccess: "支付成功",
    PaymentFailure: "支付失败",
    illustratedHandbook: "时刻图鉴",
    cardBoxStore: "我的卡包",
    assetContent: "我的资产",
    closeAccount: "账号注销",
    setting: "设置",
    relationService: "联系客服",
    chooseAvatar: "选择头像",
    about: "关于",
    getAwardFromCode: "兑换中心",
    composeHisList: "挑战记录",
    walletAddress: "钱包地址",
    orderBuy: "消费记录",
    receiveRecord: "收发记录",
    receiveDetail: "收发详情",
    nicknameEdit: "修改昵称",
    accountSecurity: "账号与安全",
    phoneBinding: "绑定手机号",
    changePassword: "修改密码",
    forgotPassword: "忘记密码",
    settingPassword: "设置密码",
    loginScreen: "登录",
    phoneLogin: "手机号登录",
    verificationCode: "请输入验证码",
    passwordLogin: "密码登录",
    settingNewPassword: "设置新密码",
    messageList: "系统公告",
    realName: "实名认证",
  },
  setting: {
    more: "更多",
    wallet: "钱包",
    tradeCenter: "交易中心",
    iBought: "我买到的",
    iSold: "我卖出的",
    selling: "在售中",
    myMall: "我的商城",
    personSetting: "个人设置",
    avator: "头像",
    nickName: "昵称",
    accountSafe: "账号与安全",
    realName: "实名认证",
    commonUse: "通用",
    feedbackAndHelp: "反馈与帮助",
    about: "关于",
    relationService: "联系客服",
    onlineService: "在线客服",
    exitApp: "退出登录",
    alreadyExit: "退出登录",
    sureExit: "是否退出?",
    personAvator: "个人头像",
    edit: "修改",
    savePic: "保存图片",
    editAvaor: "修改头像",
    getLimitAvator: "如何获取限量版头像？",
    getGold: "赚取金币",
    editNickName: "修改昵称",
    overLimitSize: "字符超出限制",
    passwordManage: "密码管理",
    bioSetting: "生物支付",
    payWay: "优先验证方式",
    autoSelect: "自动选择",
    keyboardPay: "键盘密码支付",
    editPassword: "修改密码",
    newPassword: "新密码",
    pleaseSetNewPass: "请设置新密码",
    confirmPass: "确认密码",
    inputAgain: "请再次输入密码",
    passwordRule: "密码由6位数字组成",
    bio1: "指纹支付",
    bio2: "人脸支付",
    settingHint: "系统会帮您优先选择便捷的验证方式",
    autoSelectBySystem: "自动选择优先方式",
    payWay1: "密码支付优先",
    payWay2: "指纹支付优先",
    payWay3: "人脸支付优先",
    messageSetting: "消息设置",
    languageSetting: "语言设置",
    jijinoOpen: "未打开时",
    receiveMessage: "接受消息通知",
    platformInfom: "平台通知提醒",
    tradeInfom: "交易订单信息",
    tradeMessage: "交易提醒",
    activityMessage: "平台公告、活动等通知",
    changeLangugage: "语言更改?",
    restart: "更改语言需要重启应用,是否确定?",
    dorestart: "立即重启",
    cancelSetting: "取消设置",
    submit: "提交",
    submitInfo:
      "请描述使用问题，例如某个功能无法使用，页面白屏/卡顿/闪退、或其它产品建议（5个字以上）",
    commonQuestion: "常见问题",
    aboutjiji: "关于",
    cancelAccount: "注销账号",
    quit: "退出",
    clear: "退出",
    save: "确定",
  },
  allSeriesModal: {
    title: "请选择查看编号",
    total: "共{{total}}个",
    tip: "默认按编号排列",
  },
}

export default zh
export type Translations = typeof zh
