import {
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
  useWindowDimensions,
} from "react-native"
import { colors, fitSize } from "../../theme"
import React, { useReducer, useState } from "react"
import { AppInfo } from "../../config/AppInfo"
import RenderHtml from "react-native-render-html"
import { RootStackNavProps } from "../../type/RouteType"
import { Screen, Sheader, Touchable, outlineStyle } from "../../components"
import { Text } from "../../components"
import TextModal from "../../components/TextModal"
import { translate } from "../../i18n"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { fontName } from "../../theme/fonts"
import { api } from "../../services/api"
import { GToast, goBack } from "../../navigators"
import { InAppPurchases } from "../../utils/CompatibleUtils"
import { isValidIdCard } from "../../utils/regexFunction"
import { showInfo } from "../../utils/showUtil"

interface RealNameProps extends RootStackNavProps<"RealName"> {}

type State = {
  name: string
  idCard: string
}

type Action = {
  type: "NAME" | "ID_CARD"
  value: string
}

const realNameReducer = (state: State, action: Action) => {
  const { type, value } = action
  switch (type) {
    case "NAME":
      return { ...state, name: value }
    case "ID_CARD":
      return { ...state, idCard: value }
    default:
      throw new Error()
  }
}

export const RealName: React.FC<RealNameProps> = ({ navigation }) => {
  const { accountStore } = useStores()
  const [realNameContent, setRealNameContent] = useReducer(realNameReducer, {
    name: "",
    idCard: "",
  })

  const fetchUserData = async () => {
    try {
      const res = await api.getInfo()
      if (res.kind === "ok") {
        accountStore.changeUser(res.data)
        goBack()
        showInfo("实名成功", "OK", 2000)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const getAgreementAPI = async () => {
    try {
      const res = await api.userReal(realNameContent.idCard, realNameContent.name)
      if (res.kind === "ok") {
        fetchUserData()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const disabledBtn = !realNameContent.name || !isValidIdCard(realNameContent.idCard)

  const disabledBtnStyle: ViewStyle = {
    backgroundColor: !disabledBtn ? colors.palette.neutral100 : colors.palette.neutral100 + "80",
  }

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={""}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <Text style={style.title}>{translate("screen.realName.title")}</Text>
        <View style={style.inputBox}>
          <Text style={style.nameInput}>{translate("screen.realName.nameInput")}</Text>
          <TextInput
            style={[style.input, outlineStyle]}
            onChangeText={(text) => setRealNameContent({ type: "NAME", value: text })}
            value={realNameContent.name}
            placeholder={translate("screen.realName.nameInputTip")}
            placeholderTextColor={colors.palette.neutral50}
            spellCheck={false}
            enablesReturnKeyAutomatically
            returnKeyType={"next"}
            autoCorrect={false}
            autoCapitalize={"none"}
            underlineColorAndroid={"transparent"}
            clearButtonMode="while-editing"
          />
        </View>
        <View style={style.inputBox}>
          <Text style={style.nameInput}>{translate("screen.realName.numberInput")}</Text>
          <TextInput
            style={[style.input, outlineStyle]}
            onChangeText={(text) => setRealNameContent({ type: "ID_CARD", value: text })}
            value={realNameContent.idCard}
            placeholder={translate("screen.realName.numberInputTip")}
            placeholderTextColor={colors.palette.neutral50}
            spellCheck={false}
            enablesReturnKeyAutomatically
            returnKeyType={"next"}
            autoCorrect={false}
            autoCapitalize={"none"}
            underlineColorAndroid={"transparent"}
            clearButtonMode="while-editing"
          />
        </View>
        <Touchable
          style={[style.btnBox, disabledBtnStyle]}
          disabled={disabledBtn}
          onPress={getAgreementAPI}
        >
          <Text style={style.btnTxt}>{translate("screen.realName.button")}</Text>
        </Touchable>
      </View>
    </Screen>
  )
}

const style = StyleSheet.create({
  btnTxt: {
    fontSize: fitSize(15),
    fontWeight: "500",
    color: colors.palette.neutral30,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  btnBox: {
    width: fitSize(180),
    height: fitSize(40),
    borderRadius: fitSize(25),
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: fitSize(60),
  },
  nameInput: {
    fontSize: fitSize(16),
    fontWeight: "400",
    color: "#E8E8E8",
    fontFamily: fontName.PingFang_SC,
    marginBottom: fitSize(8),
  },
  inputBox: {
    flexDirection: "column",
    width: "100%",
    marginBottom: fitSize(28),
  },
  input: {
    height: fitSize(50),
    width: "100%",
    borderRadius: fitSize(6),
    backgroundColor: colors.palette.neutral60,
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
    paddingLeft: fitSize(25),
  },
  title: {
    fontSize: fitSize(22),
    fontWeight: "500",
    color: "#E8E8E8",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginTop: fitSize(8),
    marginBottom: fitSize(60),
  },
  content: {
    backgroundColor: "transparent",
    flex: 1,
    paddingHorizontal: fitSize(32),
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  textStyle: {
    fontSize: fitSize(18),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
