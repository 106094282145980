import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native"

import Modal from "react-native-modal/dist/modal"
import React, { Fragment, useState } from "react"
import { fitSize, deviceHeight, deviceWidth } from "../../../theme/layout"
import { Images, colors } from "../../../theme"
import { Touchable } from "../../../components/Button"
import { translate } from "../../../i18n"
import TextModal from "../../../components/TextModal"
import RenderHTML from "react-native-render-html"
import { api } from "../../../services/api"
import { textModal } from "../../MineSetting/About"
import { GToast } from "../../../navigators"

interface ProtocolPromptModalProps {
  isShow: boolean
  onClose: () => void
  onSubmit: () => void
  isService?: boolean
}

export const ProtocolPromptModal: React.FC<ProtocolPromptModalProps> = ({
  isShow,
  onClose,
  onSubmit,
  isService = true,
}) => {
  const windowHeight = useWindowDimensions().height
  const windowWidth = useWindowDimensions().width

  const compatibleHeight = Platform.OS === "web" ? windowHeight : deviceHeight

  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>("")
  const [modalContent, setModalContent] = useState<string>("")

  const getAgreementAPI = async (type: 0 | 1 | 2) => {
    const res = await api.getPrivacyAgreement(type)
    if (res.kind === "ok") {
      if (res.data) {
        setModalContent(res?.data?.content)
      }
    } else {
      setModalContent("")
      GToast.current.handleErrorResponse(res)
    }
  }

  const openTextModal = (type: textModal): void => {
    setModalVisible(true)
    if (type === textModal.agreement) {
      getAgreementAPI(0)
      setModalTitle("用户协议")
    } else if (type === textModal.privacy) {
      getAgreementAPI(1)
      setModalTitle("隐私政策")
    } else if (type === textModal.service) {
      getAgreementAPI(2)
      setModalTitle("中国移动认证服务条款")
    }
  }

  return (
    <Modal
      useNativeDriverForBackdrop
      hasBackdrop={true}
      coverScreen={true}
      isVisible={isShow}
      animationIn="fadeInUp"
      animationOut="fadeOutDown"
      style={style.modal}
      onBackButtonPress={onClose}
      onBackdropPress={onClose}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      statusBarTranslucent
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
      backdropOpacity={0.6}
      swipeDirection={"down"}
    >
      <TextModal modalVisible={modalVisible} setModalVisible={setModalVisible} title={modalTitle}>
        <ScrollView
          style={style.modalScrollView}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {Platform.OS === "web" ? (
            <div style={style.modalText} dangerouslySetInnerHTML={{ __html: modalContent }} />
          ) : (
            <RenderHTML
              contentWidth={windowWidth}
              source={{
                html: modalContent,
              }}
            />
          )}
        </ScrollView>
      </TextModal>
      <View style={style.container}>
        <Touchable style={style.backBox} onPress={onClose}>
          <Image source={Images.components.back_fork} style={style.backIcon} />
        </Touchable>
        <Text style={style.title}>{translate("screen.loginScreen.modal.title")}</Text>
        <Text style={style.content}>
          {translate("screen.loginScreen.modal.content")}
          <Text style={style.protocolText} onPress={() => openTextModal(textModal.agreement)}>
            {translate("screen.loginScreen.userProtocol")}
          </Text>
          、
          <Text style={style.protocolText} onPress={() => openTextModal(textModal.privacy)}>
            {translate("screen.loginScreen.privacyAgreement")}
          </Text>
          {/* {isService ? (
            <Fragment>
              、
              <Text style={style.protocolText} onPress={() => openTextModal(textModal.service)}>
                {translate("screen.loginScreen.mobileService")}
              </Text>
            </Fragment>
          ) : null} */}
          <Text style={style.content}>{translate("screen.loginScreen.modal.tip")}</Text>
        </Text>
        <Touchable style={style.btn} onPress={onSubmit}>
          <Text style={style.btnText}>{translate("screen.loginScreen.modal.btn")}</Text>
        </Touchable>
      </View>
    </Modal>
  )
}

const style = StyleSheet.create({
  modalScrollView: {
    flex: 1,
    padding: fitSize(5),
  },
  modalText: {
    width: "100%",
    flexWrap: "wrap",
  },
  container: {
    height: fitSize(236),
    width: deviceWidth,
    borderTopLeftRadius: fitSize(10),
    borderTopRightRadius: fitSize(10),
    overflow: "hidden",
    backgroundColor: "#1D1B20",
    padding: fitSize(16),
    flexDirection: "column",
    alignItems: "center",
  },
  modal: {
    margin: 0,
    justifyContent: "flex-end",
  },
  backBox: {
    position: "absolute",
    right: 16,
    top: 16,
  },
  backIcon: {
    width: fitSize(14),
    height: fitSize(14),
  },
  title: {
    color: colors.palette.neutral100,
    fontSize: fitSize(16),
    fontWeight: "500",
    lineHeight: fitSize(22),
    marginBottom: fitSize(10),
    marginTop: fitSize(5),
  },
  content: {
    color: colors.palette.neutral50,
    fontSize: fitSize(13),
    fontWeight: "400",
    lineHeight: fitSize(23),
    marginBottom: fitSize(20),
  },
  protocolText: {
    color: colors.palette.neutral100,
    fontSize: fitSize(13),
    fontWeight: "400",
    lineHeight: fitSize(23),
    marginBottom: fitSize(20),
  },
  btn: {
    width: "100%",
    height: fitSize(44),
    backgroundColor: colors.palette.primary500,
    borderRadius: fitSize(6),
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    color: colors.palette.neutral30,
    fontSize: fitSize(14),
    fontWeight: "500",
    lineHeight: fitSize(20),
  },
})
