import { Instance, SnapshotOut, flow, getParent, types } from "mobx-state-tree"

// "nickName": "Charles Robinson",
// "id": 540000200804111300,
// "avater": "http://dummyimage.com/200x200",
// "registerTime": "2021-09-26 18:07:17",
// "boughtCount": 89,
// "sellCount": 41,
// "gender": 1,
// "browseCount": 836,
// "followCount": 797,
// "collectCount": 78,
// "walletAddress": "VpULeG",
// "phone": 13377765612

export const userInfoModel = types.model("userInfoStore").props({
  id: types.maybe(types.union(types.string, types.number)),
  phoneSecy: types.maybe(types.string),
  nickname: types.maybe(types.string),
  avatar: types.maybe(types.string),
  wallet: types.maybe(types.string),
  isReal: types.maybe(types.number),
  isLook: types.maybe(types.number),
  password: types.maybe(types.boolean),
  createdAt: types.maybe(types.string),
  assetCount: types.maybe(types.number),
  boxCount: types.maybe(types.number),
  dareCount: types.maybe(types.number),
  authorization: types.maybe(types.string),
})

type userInfoStoreType = Instance<typeof userInfoModel>
export interface userInfoStore extends userInfoStoreType {}
type userInfoStoreSnapshotType = SnapshotOut<typeof userInfoModel>
export interface userInfoStoreSnapshot extends userInfoStoreSnapshotType {}
export const createuserInfoStoreDefaultModel = () => types.optional(userInfoModel, {})
