import React, { DependencyList, useEffect, useRef, useState } from "react"
export function delay(time: number = 1000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

type itemList<T> = {
  fetchData: (lastId?: number, ...params: any) => Promise<T>
  pageLimit: number
  delaTime?: number
  isDestroy?: boolean
}
export type useListReturnType<T> = {
  data: T[]
  setData: React.Dispatch<React.SetStateAction<T[]>>
  bottomRefreshing: boolean
  topRefreshing: boolean
  onRefresh: (param?: any) => void
  onEndReached: () => void
  error: string
  isLoading: boolean
  noMoreData: boolean
  doFirstload: () => void
}
interface CustomType<T> {
  list: any[]
  lastId: number
  next: boolean
}

const initPage = 1
export function useAutoList<T extends CustomType<T>>(
  { fetchData, pageLimit, delaTime, isDestroy = false }: itemList<T>,
  deps?: DependencyList,
): [useListReturnType<T>] {
  const [data, setData] = useState<T[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [topRefreshing, setTopRefreshing] = useState(true)
  const [bottomRefreshing, setBottomRefreshing] = useState(false)
  const [noMoreData, setNoMoreData] = useState(false)
  const [error, setError] = useState("")
  const isMountedRef = useRef(false)
  const bottomRefreshRef = useRef(false)
  const topRefreshingRef = useRef(false)
  const [lastId, setLastId] = useState<undefined | number>()

  const judgeNoMoreData = (newData: T[]) => {
    if (newData) {
      return newData.length < pageLimit
    } else {
      return false
    }
  }

  const setDatas = (mydata: T[]) => {
    if (error) {
      setError("")
    }
    setNoMoreData(judgeNoMoreData(mydata))
    setData(mydata)
    setIsLoading(false)
    setTopRefreshing(false)
    topRefreshingRef.current = false
    setTimeout(() => {
      setBottomRefreshing(false)
      bottomRefreshRef.current = false
    }, 100)
  }

  const loadData = () => {
    if (error) {
      setIsLoading(true)
    }
    if (isMountedRef.current) {
      fetchData(lastId === 0 || isDestroy ? undefined : lastId, pageLimit)
        .then((item) => {
          setDatas(item.list ?? [])
          setLastId(item.lastId)
          isDestroy = false
          isMountedRef.current = item.next
        })
        .catch((e) => {
          setData([])
          setLastId(undefined)
          setIsLoading(false)
          setTopRefreshing(false)
          topRefreshingRef.current = false
          if (e?.message !== "Cannot read properties of null (reading 'list')") {
            setError(e)
          }
        })
    }
  }

  const initLoadData = async () => {
    if (error) {
      setIsLoading(true)
    }
    fetchData(undefined, pageLimit)
      .then((item) => {
        setDatas(item.list ?? [])
        setLastId(item.lastId)
        isMountedRef.current = item.next
        setTopRefreshing(false)
      })
      .catch((e) => {
        setData([])
        setLastId(undefined)
        setIsLoading(false)
        setTopRefreshing(false)
        topRefreshingRef.current = false
        if (e?.message !== "Cannot read properties of null (reading 'list')") {
          setError(e)
        }
      })
  }

  const onRefresh = (params) => {
    // pageRef.current = 1
    setNoMoreData(false)
    setTopRefreshing(true)
    topRefreshingRef.current = true
    initLoadData()
  }
  const onEndReached = () => {
    if (
      data?.length === 0 ||
      topRefreshingRef.current ||
      noMoreData ||
      bottomRefreshRef.current ||
      lastId === 0
    ) {
      return
    }
    setBottomRefreshing(true)
    bottomRefreshRef.current = true
    // pageRef.current = pageRef.current + 1
    // setLastId()
    // loadData();
    if (error) {
      setIsLoading(true)
    }
    if (isMountedRef.current) {
      fetchData(isDestroy ? undefined : lastId, pageLimit)
        .then((item) => {
          if (error) {
            setError("")
          }
          setIsLoading(false)
          setNoMoreData(judgeNoMoreData(item?.list))
          setData([...data, ...item?.list])
          setLastId(item.lastId)
          isMountedRef.current = item.next
          setTimeout(() => {
            setBottomRefreshing(false)
            bottomRefreshRef.current = false
          }, 100)
        })
        .catch((e) => {
          setIsLoading(false)
          setError(e)
        })
    }
  }
  const doFirstload = async () => {
    // setTopRefreshing(false)
    topRefreshingRef.current = false
    if (delaTime) {
      await delay(delaTime)
    }
    // pageRef.current = initPage
    // setLastId()
    loadData()
  }
  useEffect(() => {
    isMountedRef.current = true
    doFirstload()
    return () => {
      isMountedRef.current = false
    }
  }, deps || [])

  return [
    {
      data,
      setData,
      bottomRefreshing,
      topRefreshing,
      onRefresh,
      onEndReached,
      error,
      isLoading,
      noMoreData,
      doFirstload,
    },
  ]
}
