import { StyleSheet, Text, TextInput, View } from "react-native"
import { colors, fitSize } from "../../theme"
import React, { useState } from "react"
import { Screen, outlineStyle } from "../../components"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { observer } from "mobx-react-lite"
import { translate } from "../../i18n"
import { useStores } from "../../models"
import { fontName } from "../../theme/fonts"
import { Touchable } from "../../components/Button"
import { api } from "../../services/api"
import { GToast } from "../../navigators"

interface NicknameEditProps extends RootStackNavProps<"NicknameEdit"> {}

export const NicknameEdit: React.FC<NicknameEditProps> = observer(({ navigation, route }) => {
  const { accountStore } = useStores()
  const [text, setText] = useState(accountStore.user?.nickname || "")

  const save = async () => {
    try {
      const res = await api.editUserName(text)
      if (res.kind === "ok") {
        accountStore.getUserInfo()
        navigation.goBack()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const onChangeText = (text: string) => {
    setText(text)
  }

  const disabled = text.length < 2 || text.length > 7
  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("setting.nickName")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
        rightComp={
          <Touchable onPress={save} disabled={disabled}>
            <Text
              style={[
                style.saveBtn,
                { color: disabled ? colors.palette.neutral500 : colors.palette.neutral100 },
              ]}
            >
              {translate("setting.save")}
            </Text>
          </Touchable>
        }
      />
      <View style={style.content}>
        <TextInput
          style={[style.input, outlineStyle]}
          onChangeText={(text) => onChangeText(text)}
          value={text}
          placeholder={translate("screen.nicknameEdit.input")}
          placeholderTextColor={colors.palette.neutral100 + "66"}
          spellCheck={false}
          enablesReturnKeyAutomatically
          returnKeyType={"search"}
          autoCorrect={false}
          autoCapitalize={"none"}
          underlineColorAndroid={"transparent"}
          clearButtonMode="while-editing"
          // maxLength={7}
        />
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  input: {
    height: fitSize(50),
    width: fitSize(347),
    borderRadius: fitSize(6),
    backgroundColor: colors.palette.neutral60,
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
    paddingLeft: fitSize(25),
  },
  saveBtn: {
    fontSize: fitSize(14),

    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  content: {
    backgroundColor: colors.palette.neutral900,
    flex: 1,
    alignItems: "center",
    marginTop: fitSize(30),
  },
  boxText: {
    fontSize: fitSize(14),
    color: colors.palette.primary500,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  box: {
    position: "absolute",
    width: fitSize(310),
    height: fitSize(50),
    backgroundColor: colors.palette.neutral60,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: fitSize(6),
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
})
