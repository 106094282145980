import { Dimensions, Platform, StyleSheet ,ViewStyle} from "react-native"
import ExtraDimensions from "react-native-extra-dimensions-android"

const DEFAULT_DENSITY = 1
export const maxWebWidth = 600
export const deviceWidth =
  Dimensions.get("window").width > maxWebWidth ? maxWebWidth : Dimensions.get("window").width
export const deviceHeight =
  Platform.OS === "ios" ? Dimensions.get("window").height : ExtraDimensions.getRealWindowHeight()
export function fitSize(size: number | undefined) {
  if (size === undefined) return 0
  return (size / DEFAULT_DENSITY) * (deviceWidth / 375)
}

export const FULL: ViewStyle = {
  flex: 1,
  backgroundColor: "#000000",
}

export const gStyle = StyleSheet.create({
  horizontalView: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  verticalView: {
    justifyContent: "center",
    alignItems: "center",
  },
  verticalRightView: {
    justifyContent: "center",
    alignItems: "flex-end",
  },
  verticalLeftView: {
    justifyContent: "center",
    alignItems: "flex-start",
  },

  grayLine: {
    width: fitSize(322),
    height: fitSize(1),
    backgroundColor: "rgba(152, 157, 187,0.15)",
    alignSelf: "center",
    marginTop: fitSize(15),
  },
  absoluteCenter: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
  shadowStyle: {
    ...Platform.select({
      ios: {
        shadowOffset: { width: 3, height: 3 },
        shadowColor: "#000",
        shadowOpacity: 0.1,
        shadowRadius: 2,
      },
      android: {
        elevation: 4,
      },
    }),
  },
})
