import { RouteProp, useFocusEffect } from "@react-navigation/native"
import { RootStackParamList } from "../../../type"
import React, { FC, useEffect, useState } from "react"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { Image, Platform, Pressable, StyleSheet, Text, View } from "react-native"
import Clipboard from "@react-native-clipboard/clipboard"
import { GToast, goBack } from "../../../navigators"
import { translate } from "../../../i18n"
import { Images, colors, fitSize } from "../../../theme"
import { Touchable } from "../../../components"
import CopyToClipboard from "react-copy-to-clipboard"
import dayjs from "dayjs"
import { AssetData, AssetInfo } from "../../../services/api"
import { SizeImage } from "../../../components/SizeImage"
import { DashedLine } from "../StoreScreen"
import { RarityIcon } from "../../TabHome/components/RarityIcon"
import { showInfo } from "../../../utils/showUtil"

export const getRestTimeDays = (time: number) => {
  if (time <= 0) return "00:00:00"

  const allS = Math.floor(time / 1000)
  const days = Math.floor(allS / 60 / 60 / 24)

  const hours = Math.floor((allS / 60 / 60) % 24)
  const minutes = Math.floor((allS / 60) % 60)
  const seconds = Math.floor(allS % 60)

  const timeString =
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)

  if (days >= 1) {
    return `${days}天`
  }

  return timeString
}

interface CardDetailInfoProps {
  info: AssetInfo
  item: AssetData
  route: RouteProp<RootStackParamList, "AssetDetails">
  scrollViewState: "top" | "initial"
}

export const CardDetailInfo: FC<CardDetailInfoProps> = ({ item, info, route, scrollViewState }) => {
  const { accountStore } = useStores()
  const navigation = useTypeNavigation()
  const EVENT_RESULT_LABEL = ["VS", "第一节", "第二节", "第三节", "第四节"]

  const doCopy = (copy: string) => {
    Clipboard.setString(String(copy))
    GToast.current.showToast(translate("common.copy"), "TOAST", 2000)
  }

  const getAssetState = async () => {}

  const SendNft = (item) => {
    if (accountStore.user.isReal === 1) {
    } else {
    }
  }

  const degifting = async () => {
    if (accountStore.user.isReal === 1) {
      GToast.current.showGift(item.id, () => {
        // goBack()
      })
    } else {
      showInfo("未实名", "TOAST")
      navigation.navigate("RealName")
    }
  }

  const removeStringSpace = (str: string): string => {
    let value = ""
    if (str) {
      value = str.replace(/\s/g, "").slice(0, 6)
    }
    return value
  }

  const [showView, setShowView] = useState(false)
  const [isPress, setIsPress] = useState(false)

  useEffect(() => {
    if (isPress || scrollViewState === "top") {
      setShowView(true)
    }
  }, [isPress, scrollViewState])

  useEffect(() => {
    if (scrollViewState === "initial") {
      setShowView(false)
    }
  }, [scrollViewState])

  return (
    <Pressable
      onPressIn={() => {
        setIsPress((v) => !v)
      }}
    >
      <View style={[style.body, { paddingTop: fitSize(20) }]}>
        <View style={style.nameInfoContainer}>
          <View style={style.nameInfoContent}>
            <Text style={style.playerNameText}>{info?.playerInfo?.playerName}</Text>
          </View>
          <View
            style={[style.playNoContainer, { display: info?.playerInfo?.playNo ? "flex" : "none" }]}
          >
            <Text style={style.playNoLabel}>
              {translate("screen.tabStore.cardInfo.jerseyNumber")}:
            </Text>
            <Text style={style.playNoValue}>{info?.playerInfo?.playNo}</Text>
          </View>
        </View>
        <View style={[style.nameInfoContainer, { marginTop: fitSize(10) }]}>
          {!!info?.playerInfo?.club ? (
            <Text style={style.clubText}>{info?.playerInfo?.club}</Text>
          ) : null}
          <View style={style.rowEnd}>
            {info?.honorImgs?.map((i) => {
              return <SizeImage source={{ uri: i ?? "" }} style={style.honorImg} />
            })}
          </View>
        </View>
        <View style={[style.rarityContainer]}>
          <RarityIcon icon={item?.rarityIcon} />
          <Text style={style.cardShowIdText}>{item?.assetNumber}</Text>
          <Text style={[style.cardShowIdText, { color: colors.palette.neutral50 }]}>
            /{item?.circulation}
          </Text>
          {/* {!!info?.playerInfo?.club ? (
            <Text style={style.cardShowIdText}>{info?.playerInfo?.club}</Text>
          ) : null} */}
        </View>
        {/* {showView ? ( */}
        <React.Fragment>
          {item?.actionName ? (
            <View style={style.descriptionExContainer}>
              <View style={style.descriptionExContent}>
                <Text style={style.actionText}>{item?.actionName}</Text>
                <DashedLine color={colors.palette.neutral50} />
                <Text style={style.descriptionExTime}>
                  {dayjs(item?.actionAriseTime).format("YYYY.MM.DD")}
                </Text>
              </View>
              <View style={style.descriptionExContent}>
                <Text style={style.actionText}>{item?.cardBoxName}</Text>
                {/* <Text style={style.actionText}>{item?.seriesName}</Text> */}
                <DashedLine color={colors.palette.neutral50} />
                <Text style={style.descriptionExTime}>{item?.seriesName}</Text>
              </View>
              <View style={style.descriptionExContent}>
                <Text style={style.actionText}>{info?.nftdesc}</Text>
              </View>
            </View>
          ) : null}
          {info?.playerInfo?.height ? (
            <View
              style={[
                style.plyerInfoContainer,
                { marginBottom: fitSize(30), height: fitSize(119) },
              ]}
            >
              <View style={[style.plyerInfoTitleContainer, { marginBottom: fitSize(24) }]}>
                <Image source={Images.screen.mine.mine_official_nav} style={style.basketBallIcon} />
                <Text style={style.plyerInfoTitleText}>
                  {translate("screen.tabStore.cardInfo.playerStatistics")}
                </Text>
              </View>
              <View style={[style.plyerInfoContent, { height: fitSize(85) }]}>
                <View style={style.content}>
                  <View style={[style.plyerInfoBox]}>
                    <Text style={style.plyerInfoUnitText}>
                      {info?.playerInfo?.height}
                      {info?.playerInfo?.height && "cm"}
                    </Text>
                    <Text style={[style.plyerInfoUnitLabel, {}]}>
                      {translate("screen.tabStore.cardInfo.height")}
                    </Text>
                  </View>
                  <View style={[style.plyerInfoBox]}>
                    <Text style={style.plyerInfoUnitText}>
                      {info?.playerInfo?.weight}
                      {info?.playerInfo?.weight && "kg"}
                    </Text>
                    <Text style={style.plyerInfoUnitLabel}>
                      {translate("screen.tabStore.cardInfo.weight")}
                    </Text>
                  </View>
                  <View style={[style.plyerInfoBox]}>
                    <Text style={style.plyerInfoUnitText}>{info?.playerInfo?.age}</Text>
                    <Text style={style.plyerInfoUnitLabel}>
                      {translate("screen.tabStore.cardInfo.age")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          ) : null}
          {info?.playerInfo?.avgTime ? (
            <View
              style={[
                style.plyerInfoContainer,
                { marginBottom: fitSize(38), height: fitSize(164) },
              ]}
            >
              <View style={[style.plyerInfoTitleContainer, {}]}>
                <Text style={style.plyerInfoTitleText}>
                  {translate("screen.tabStore.cardInfo.currentData")}
                </Text>
              </View>
              <View style={[style.plyerInfoContent, { borderWidth: 0 }]}>
                <View style={style.plyerInfoUnitBox}>
                  <View style={style.plyerInfoBox}>
                    <Text style={style.plyerInfoUnitText}>{info?.playerInfo?.avgTime ?? "-"}</Text>
                    <Text style={style.plyerInfoUnitLabel}>
                      {translate("screen.tabStore.cardInfo.avgTime")}
                    </Text>
                  </View>
                  <View style={style.plyerInfoBox}>
                    <Text style={style.plyerInfoUnitText}>{info?.playerInfo?.points ?? 0}</Text>
                    <Text style={style.plyerInfoUnitLabel}>
                      {translate("screen.tabStore.cardInfo.points")}
                    </Text>
                  </View>
                  <View style={style.plyerInfoBox}>
                    <Text style={style.plyerInfoUnitText}>{info?.playerInfo?.rebound ?? 0}</Text>
                    <Text style={style.plyerInfoUnitLabel}>
                      {translate("screen.tabStore.cardInfo.rebound")}
                    </Text>
                  </View>
                </View>
                <View style={style.plyerInfoUnitBox}>
                  <View style={style.plyerInfoBox}>
                    <Text style={style.plyerInfoUnitText}>{info?.playerInfo?.assists ?? 0}</Text>
                    <Text style={style.plyerInfoUnitLabel}>
                      {translate("screen.tabStore.cardInfo.assists")}
                    </Text>
                  </View>
                  <View style={style.plyerInfoBox}>
                    <Text style={style.plyerInfoUnitText}>{info?.playerInfo?.steals ?? 0}</Text>
                    <Text style={style.plyerInfoUnitLabel}>
                      {translate("screen.tabStore.cardInfo.steals")}
                    </Text>
                  </View>
                  <View style={style.plyerInfoBox}>
                    <Text style={style.plyerInfoUnitText}>{info?.playerInfo?.blocks ?? 0}</Text>
                    <Text style={style.plyerInfoUnitLabel}>
                      {translate("screen.tabStore.cardInfo.blocks")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          ) : null}
          {info?.matchInfo.teamName1 && info?.matchInfo.teamName2 ? (
            <View style={[style.eventResult, { height: fitSize(147) }]}>
              <View style={style.plyerInfoTitleContainer}>
                <Text style={style.plyerInfoTitleText}>
                  {translate("screen.tabStore.cardInfo.eventResult")}
                </Text>
              </View>
              <View style={style.eventResultContent}>
                <View style={style.teamLogoBox}>
                  <SizeImage
                    source={{ uri: info?.matchInfo?.teamlogo1 }}
                    style={style.teamLogoIcon}
                  />
                  <Text style={style.teamNameText}>
                    {removeStringSpace(info?.matchInfo?.teamName1 ?? "")}
                  </Text>
                </View>
                <View style={[style.teamLogoBox, { width: fitSize(65) }]}>
                  <Text style={style.pointsNumber}>
                    {info?.matchInfo.points1.reduce((p, c) => Number(p) + Number(c), 0)}
                  </Text>
                  {info?.matchInfo?.points1.map((i, index) => {
                    return (
                      <Text key={index} style={style.pointsItem}>
                        {i}
                      </Text>
                    )
                  })}
                </View>
                <View style={[style.teamLogoBox, { width: fitSize(40) }]}>
                  {EVENT_RESULT_LABEL.slice(0, info?.matchInfo?.points2.length + 1).map(
                    (i, index) => {
                      return (
                        <Text
                          key={index}
                          style={{
                            fontSize: index === 0 ? fitSize(22) : fitSize(12),
                            color: colors.palette.neutral50,
                            fontWeight: index === 0 ? "500" : "400",
                            fontFamily: "PingFang_SC",
                            marginBottom: fitSize(8),
                          }}
                        >
                          {i}
                        </Text>
                      )
                    },
                  )}
                </View>
                <View style={[style.teamLogoBox, { width: fitSize(65) }]}>
                  <Text style={style.pointsNumber}>
                    {info?.matchInfo.points2.reduce((p, c) => Number(p) + Number(c), 0)}
                  </Text>
                  {info?.matchInfo?.points2.map((i, index) => {
                    return (
                      <Text key={index} style={style.pointsItem}>
                        {i}
                      </Text>
                    )
                  })}
                </View>
                <View style={style.teamLogoBox}>
                  <SizeImage
                    source={{ uri: info?.matchInfo?.teamlogo2 }}
                    style={style.teamLogoIcon}
                  />
                  <Text style={style.teamNameText}>
                    {removeStringSpace(info?.matchInfo?.teamName2 ?? "")}
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
          {item?.contract ? (
            <View style={[style.address]}>
              <View style={style.plyerInfoTitleContainer}>
                <Text style={style.plyerInfoTitleText}>
                  {translate("screen.tabStore.cardInfo.contractAddress")}
                </Text>
              </View>
              <View style={style.addressContent}>
                <Text style={style.nftAddressText}>{item?.contract}</Text>
                {Platform.OS !== "web" ? (
                  <Touchable onPress={() => doCopy(item?.contract)}>
                    <Image source={Images.common.copy} style={style.copyIcon} />
                  </Touchable>
                ) : (
                  <CopyToClipboard
                    text={String(item?.contract)}
                    onCopy={() => GToast.current.showToast(translate("common.copy"), "TOAST", 2000)}
                  >
                    <Image source={Images.common.copy} style={style.copyIcon} />
                  </CopyToClipboard>
                )}
              </View>
            </View>
          ) : null}
          <Touchable style={style.giftBtn} onPress={degifting}>
            <Image source={Images.screen.store.send_store} style={style.giftIcon} />
            <Text style={style.giftText}>{translate("screen.tabStore.cardInfo.bestow")}</Text>
          </Touchable>
        </React.Fragment>
        {/* ) : null} */}
      </View>
    </Pressable>
  )
}

const style = StyleSheet.create({
  content: {
    width: "100%",
    height: fitSize(45),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pointsNumber: {
    fontSize: fitSize(22),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
    marginBottom: fitSize(8),
  },
  pointsItem: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: "PingFang_SC_Semibold",
    marginBottom: fitSize(8),
  },
  rowEnd: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-end",
  },
  body: {
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: fitSize(14),
    backgroundColor: colors.palette.neutral900,
  },
  nameInfoContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameInfoContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  playerNameText: {
    fontSize: fitSize(24),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
    marginRight: fitSize(8),
  },
  honorImg: {
    width: fitSize(18),
    height: fitSize(18),
    marginLeft: fitSize(6),
  },
  playNoContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  playNoLabel: {
    fontSize: fitSize(13),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: "PingFang_SC",
  },
  playNoValue: {
    fontSize: fitSize(22),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
    marginLeft: fitSize(6),
  },
  clubText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
  },
  rarityContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: fitSize(22),
    marginTop: fitSize(10),
  },
  rarityText: {
    fontSize: fitSize(12),
    color: "#E6C656",
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
    marginRight: fitSize(4),
  },
  cardShowIdText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: "PingFang_SC",
  },
  descriptionExContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: fitSize(30),
  },
  descriptionExContent: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginBottom: fitSize(10),
  },
  actionText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
    paddingRight: fitSize(6),
  },
  dashedLine: {
    flexGrow: 1,
    height: 1,
    borderBottomColor: colors.palette.neutral50,
    borderStyle: Platform.select({ ios: "solid", android: "dashed", web: "dashed" }),
    borderBottomWidth: 1,
  },
  descriptionExTime: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
    paddingLeft: fitSize(6),
  },
  plyerInfoContainer: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  plyerInfoTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: fitSize(14),
  },
  basketBallIcon: {
    height: fitSize(18),
    width: fitSize(18),
    marginRight: fitSize(8),
  },
  plyerInfoTitleText: {
    fontSize: fitSize(14),
    color: colors.palette.neutral50,
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
  },
  plyerInfoContent: {
    borderRadius: fitSize(8),
    borderWidth: 1,
    borderColor: colors.palette.neutral30,
    width: "100%",
    height: fitSize(120),
    flexDirection: "column",
    justifyContent: "space-around",
    paddingHorizontal: fitSize(14),
    overflow: "hidden",
  },
  plyerInfoUnitBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  plyerInfoTimeBox: {
    width: "100%",
    height: fitSize(45),
    flexDirection: "row",
    alignItems: "center",
  },
  plyerInfoBox: {
    flexDirection: "column",
    alignItems: "center",
    minWidth: fitSize(66),
  },

  plyerInfoUnitText: {
    fontSize: fitSize(20),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
    height: fitSize(30),
  },
  plyerInfoUnitLabel: {
    fontSize: fitSize(11),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: "PingFang_SC",
    alignSelf: "center",
  },
  eventResult: {
    marginBottom: fitSize(40),
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  address: {
    marginBottom: fitSize(40),
    width: "100%",
    height: fitSize(73),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  eventResultContent: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  teamLogoBox: {
    flexDirection: "column",
    alignItems: "center",
    width: fitSize(85),
  },
  teamLogoIcon: {
    width: fitSize(40),
    height: fitSize(40),
    borderRadius: fitSize(40),
    overflow: "hidden",
  },
  teamNameText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral20,
    fontWeight: "400",
    fontFamily: "PingFang_SC_Semibold",
    marginTop: fitSize(6),
  },
  addressContent: {
    borderRadius: fitSize(8),
    borderWidth: 1,
    borderColor: colors.palette.neutral30,
    width: "100%",
    height: fitSize(39),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(10),
  },
  nftAddressText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: "PingFang_SC_Semibold",
  },
  copyIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(14),
    height: fitSize(14),
  },
  giftBtn: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: fitSize(196),
    height: fitSize(36),
    backgroundColor: colors.palette.primary500,
    borderRadius: fitSize(4),
    alignSelf: "center",
    marginBottom: fitSize(104),
  },
  giftIcon: {
    height: fitSize(16),
    width: fitSize(16),
    marginRight: fitSize(8),
  },
  giftText: {
    fontSize: fitSize(14),
    color: colors.palette.neutral30,
    fontWeight: "500",
    fontFamily: "PingFang_SC_Semibold",
  },
})
