import { View, Text, Alert, Image } from "react-native"
import { colors, fitSize } from "../../theme"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { observer } from "mobx-react-lite"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { Screen, Touchable } from "../../components"
import { GToast } from "../../navigators"
import { Images } from "../../theme"
import { api } from "../../services/api"
import { StyleSheet } from "react-native"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"

interface CloseAccountProps extends RootStackNavProps<"CloseAccount"> {}

export const CloseAccount: React.FC<CloseAccountProps> = observer(({ navigation, route }) => {
  const { bottom } = useSafeAreaInsets()
  const store = useStores()
  const { accountStore } = useStores()

  const closeAccount = () => {
    GToast.current.showAlert(
      translate("screen.closeAccount.alertTitle"),
      () => {},
      () => fetchSetAccountClose(),
      {
        closeText: translate("screen.closeAccount.alertClose"),
        sureText: translate("screen.closeAccount.alertConfirm"),
      },
    )
  }

  const fetchSetAccountClose = async () => {
    try {
      const res = await api.deregistration(Number(accountStore.user.id))
      if (res.kind === "ok") {
        exit()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const exit = () => {
    store.removeAll()
    //@ts-ignore
    navigation.replace("LoginScreen")
    GToast.current.showToast(translate("screen.closeAccount.alertSuccess"), "TOAST", 2000)
  }

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("screen.closeAccount.header")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <Image source={Images.screen.mine.close_at} style={style.icon} />
        <Text style={style.contentInfo}>{translate("screen.closeAccount.contentInfo")}</Text>
        <View style={style.backgroundImg}>
          <Text style={style.infoTitle}>{translate("screen.closeAccount.infoTitle")}</Text>
          <Text style={style.infoContentOne}>
            <View style={style.dotBox}>
              <View style={style.dot} />
            </View>
            {translate("screen.closeAccount.infoContentOne")}
          </Text>
          <Text style={style.infoContentTwo}>
            <View style={style.dotBox}>
              <View style={style.dot} />
            </View>
            {translate("screen.closeAccount.infoContentTwo")}
          </Text>
          <Text style={style.infoContentThree}>
            <View style={style.dotBox}>
              <View style={style.dot} />
            </View>
            {translate("screen.closeAccount.infoContentThree")}
          </Text>
          <Text style={style.infoContentFour}>
            <View style={style.dotBox}>
              <View style={style.dot} />
            </View>
            {translate("screen.closeAccount.infoContentFour")}
          </Text>
        </View>
        <Touchable onPress={closeAccount} style={[style.btn, { bottom: bottom + fitSize(20) }]}>
          <Text style={style.btnText}>{translate("screen.closeAccount.btmText")}</Text>
        </Touchable>
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  dotBox: {
    alignItems: "center",
    justifyContent: "center",
    height: fitSize(12),
    width: fitSize(6),
    marginRight: fitSize(5),
  },
  dot: {
    width: fitSize(6),
    height: fitSize(6),
    borderRadius: fitSize(6),
    backgroundColor: colors.palette.neutral100,
  },
  infoTitle: {
    fontSize: fitSize(14),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
    alignSelf: "center",
    marginBottom: fitSize(30),
  },
  infoContentOne: {
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral20,
    fontFamily: fontName.PingFang_SC,
    marginBottom: fitSize(20),
    lineHeight: fitSize(19),
  },
  infoContentTwo: {
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral20,
    fontFamily: fontName.PingFang_SC,
    alignSelf: "flex-start",
    marginBottom: fitSize(20),
    lineHeight: fitSize(19),
  },
  infoContentThree: {
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral20,
    fontFamily: fontName.PingFang_SC,
    alignSelf: "flex-start",
    marginBottom: fitSize(20),
    lineHeight: fitSize(19),
  },
  infoContentFour: {
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral20,
    fontFamily: fontName.PingFang_SC,
    alignSelf: "flex-start",
    marginBottom: fitSize(20),
    lineHeight: fitSize(19),
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
  content: {
    flex: 1,
    paddingHorizontal: fitSize(20),
    backgroundColor: colors.background,
  },
  icon: {
    width: fitSize(50),
    height: fitSize(50),
    alignContent: "center",
    alignSelf: "center",
    marginTop: fitSize(15),
    resizeMode: "contain",
  },
  contentInfo: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral10,
    marginTop: fitSize(20),
  },
  backgroundImg: {
    width: "100%",
    height: fitSize(327),
    marginTop: fitSize(29),
    backgroundColor: colors.palette.neutral100 + "1a",
    borderRadius: fitSize(5),
    flexDirection: "column",
    paddingTop: fitSize(20),
    paddingBottom: fitSize(24),
    paddingLeft: fitSize(22),
    paddingRight: fitSize(21),
  },
  btn: {
    position: "absolute",
    width: fitSize(310),
    height: fitSize(50),
    backgroundColor: colors.palette.secondary50,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: fitSize(5),
  },
  btnText: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
})
