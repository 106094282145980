import React, { Dispatch, useEffect, useState } from "react"
import {
  Platform,
  StyleSheet,
  View,
  useWindowDimensions,
  Text,
  TouchableOpacity,
  ScrollView,
} from "react-native"
import Modal from "react-native-modal/dist/modal"
import { Images, colors, deviceHeight, fitSize } from "../../../theme"
import { Touchable } from "../../../components"
import { SizeImage } from "../../../components/SizeImage"
import { fontName } from "../../../theme/fonts"
import { translate } from "../../../i18n"
import { CurrentSeriesAsset, api } from "../../../services/api"
import { GToast } from "../../../navigators"
interface AllSeriesModalProps {
  modalShow: boolean
  id: number
  onBack?: () => void
  assetTotal: number
  onSelect: (value: number) => void
}
const PL = 10
export const AllSeriesModal: React.FC<AllSeriesModalProps> = ({
  modalShow,
  onBack,
  id,
  assetTotal,
  onSelect,
}) => {
  const { height } = useWindowDimensions()
  const compatibleHeight = Platform.OS === "web" ? height : deviceHeight
  const [allAsset, setAllAsset] = useState([])

  const getAllAssetData = async () => {
    let allData: CurrentSeriesAsset[] = []
    let res = await api.getCurrentSeries(id, undefined, PL)
    if (res.kind === "ok") {
      allData.push(...res.data.list)
      if (res.data.next) {
        while (res.kind === "ok" && res.data.next) {
          res = await api.getCurrentSeries(id, res.data.lastId, PL)
          if (res.kind === "ok") {
            allData.push(...res.data.list)
          } else {
            GToast.current.handleErrorResponse(res)
          }
        }
      }
    }
    setAllAsset((asset) => [...asset, ...allData])
  }

  useEffect(() => {
    if (modalShow) {
      getAllAssetData()
    } else {
      setAllAsset([])
    }
  }, [modalShow])

  return (
    <Modal
      hasBackdrop={true}
      coverScreen={true}
      isVisible={modalShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={styles.modal}
      backdropOpacity={0.8}
      onBackButtonPress={() => {}}
      onBackdropPress={() => {}}
      swipeDirection={"down"}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
    >
      <View style={styles.container}>
        <Touchable onPress={onBack}>
          <SizeImage source={Images.components.modal_clear} style={styles.clearIcon} />
        </Touchable>
        <Text style={styles.title}>{translate("allSeriesModal.title")}</Text>
        <Text style={styles.total}>{translate("allSeriesModal.total", { total: assetTotal })}</Text>
        <Text style={styles.tip}>{translate("allSeriesModal.tip")}</Text>
        <ScrollView style={styles.list} removeClippedSubviews={true}>
          <TouchableOpacity activeOpacity={1} style={styles.warp}>
            {allAsset.map((item, index) => {
              return (
                <Touchable
                  onPress={() => {
                    onSelect(item.id)
                  }}
                  style={styles.item}
                  key={index}
                >
                  <Text style={styles.cardShowIdText}>#{item?.assetNumber}</Text>
                  <Text style={[styles.cardShowIdText, { color: colors.palette.neutral50 }]}>
                    /{item?.circulation}
                  </Text>
                </Touchable>
              )
            })}
          </TouchableOpacity>
        </ScrollView>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  warp: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  cardShowIdText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  item: {
    width: fitSize(94),
    height: fitSize(29),
    paddingVertical: fitSize(6),
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    overflow: "hidden",
    borderWidth: 1,
    borderRadius: fitSize(4),
    borderColor: colors.palette.neutral30,
    marginBottom: fitSize(14),
    marginLeft: fitSize(10),
  },
  list: {
    width: "100%",
    flexGrow: 1,
  },
  tip: {
    fontSize: fitSize(11),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(5),
    marginBottom: fitSize(10),
  },
  total: {
    fontSize: fitSize(11),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    alignSelf: "center",
    marginTop: fitSize(2),
  },
  title: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    alignSelf: "center",
    marginTop: fitSize(4),
  },
  content: {
    flexGrow: 1,
    width: "100%",
  },
  giftText: {
    fontSize: fitSize(14),
    color: colors.palette.neutral30,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  giftBtn: {
    width: fitSize(136),
    height: fitSize(35),
    backgroundColor: colors.palette.neutral100,
    marginTop: fitSize(30),
    borderRadius: fitSize(4),
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: fitSize(345),
    height: fitSize(249),
    flexDirection: "column",
    alignSelf: "center",
    backgroundColor: "#18181C",
    padding: fitSize(10),
    overflow: "hidden",
  },
  clearIcon: {
    width: fitSize(10),
    height: fitSize(10),
    tintColor: colors.palette.neutral20,
    alignSelf: "flex-end",
  },
  input: {
    width: fitSize(259),
    height: fitSize(40),
    backgroundColor: colors.palette.neutral100 + "33",
    marginTop: fitSize(50),
    paddingHorizontal: fitSize(10),
    color: colors.palette.neutral40,
  },
  modal: {
    margin: 0,
    zIndex: 9999,
    overflow: "hidden",
  },
  bgImg: {
    width: fitSize(307),
    height: fitSize(150),
    alignItems: "center",
    justifyContent: "center",
  },
})
