import { Images, colors, fitSize } from "../../theme"
import { Image, InteractionManager, Platform, StyleSheet, View } from "react-native"
import { Screen, Text, Touchable } from "../../components"
import { CopyToClipboard } from "react-copy-to-clipboard"
import QrSVG from "react-native-qrcode-svg"
import Clipboard from "@react-native-clipboard/clipboard"
import React, { useRef } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { translate } from "../../i18n"
import { useStores } from "../../models"
import { GToast } from "../../navigators"
import { fontName } from "../../theme/fonts"
import { CameraRoll, ViewShot, PermissionsAndroid,  } from "../../utils/CompatibleUtils"

export async function hasAndroidPermission() {
  const permission = PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE

  const hasPermission = await PermissionsAndroid.check(permission)
  if (hasPermission) {
    return true
  }

  const status = await PermissionsAndroid.request(permission)
  return status === "granted"
}

interface QRCodeProps extends RootStackNavProps<"QRCode"> {}

export const QRCode: React.FC<QRCodeProps> = ({ navigation }) => {
  const { accountStore } = useStores()
  const viewRef = useRef<ViewShot>()
  const user = accountStore.user
  const address = accountStore?.user?.wallet || ""

  const doCopy = () => {
    Clipboard.setString(address)
    GToast.current.showToast(translate("common.copy"), "TOAST", 2000)
  }
  const savelocal = () => {
    if (Platform.OS !== "web") {
      takePicture()
    }
  }

  const sharePicture = async () => {
    try {
      const res = await viewRef.current.capture()
      // await Share.open({
      //   url: res,
      //   title: "分享",
      //   type: "image/jpeg",
      // })
    } catch (err) {}
  }

  const takePicture = () => {
    viewRef.current.capture().then((uri) => {
      savePicture(uri)
    })
  }

  async function savePicture(tag: string) {
    if (Platform.OS === "android" && !(await hasAndroidPermission())) {
      return
    }
    CameraRoll.save(tag)
      .then(() => {
        InteractionManager.runAfterInteractions(() => {
          GToast.current.showToast(translate("common.saveSuccess"), "TOAST", 2000)
        })
      })
      .catch((err) => {
        GToast.current.showToast(translate("common.saveFail"), "TOAST", 2000)
      })
  }

  const onShareWeChat = async () => {
    // WeChat.isWXAppInstalled().then((isInstalled) => {
    //   if (isInstalled) {
    //     WeChat.shareToSession({
    //       type: "text",
    //       description: "desc",
    //       thumbImage: "", //自定义
    //       webpageUrl: "https://www.baidu.com", //自定义
    //     })
    //       .then((res) => {
    //         GToast.current.showToast("分享成功", "TOAST", 2000)
    //       })
    //       .catch((error) => {
    //         GToast.current.showToast("分享失败", "CANCEL", 2000)
    //       })
    //   } else {
    //     GToast.current.showToast("请安装微信", "TOAST", 2000)
    //   }
    // })
  }

  const onShareWeChatMoments = async () => {
    // WeChat.isWXAppInstalled().then((isInstalled) => {
    //   if (isInstalled) {
    //     WeChat.shareToTimeline({
    //       type: "text",
    //       description: "hello, wechat",
    //     })
    //       .then((res) => {
    //         GToast.current.showToast("分享成功", "TOAST", 2000)
    //       })
    //       .catch((error) => {
    //         GToast.current.showToast("分享失败", "CANCEL", 2000)
    //       })
    //   } else {
    //     GToast.current.showToast("请安装微信", "TOAST", 2000)
    //   }
    // })
  }

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("screen.walletAddress.header")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
        rightComp={
          <Touchable onPress={() => navigation.push("ReceiveRecord")}>
            <Text style={style.rightText}>{translate("screen.walletAddress.rightBtn")}</Text>
          </Touchable>
        }
        rightWrapStyle={style.rightWrap}
      />
      <View style={[style.centerWrap]}>
        {Platform.OS !== "web" ? (
          <ViewShot style={style.viewShot} ref={viewRef} options={{ format: "jpg", quality: 0.9 }}>
            <View style={style.centerModalWrap}>
              <View style={[style.topWrap]}>
                <View style={style.imgWrap}>
                  <Image
                    source={user?.avatar ? { uri: user?.avatar } : Images.test.m_owner1}
                    style={style.head}
                  />
                </View>
                <View style={style.midFullView}>
                  <Text numberOfLines={1} style={[style.phoneText]}>
                    {user?.nickname || user?.phoneSecy}
                  </Text>
                </View>
              </View>
              <View style={style.qrBG}>
                <QrSVG size={fitSize(190)} value={address} />
              </View>
            </View>
          </ViewShot>
        ) : (
          <View style={style.viewShot}>
            <View style={style.centerModalWrap}>
              <View style={[style.topWrap]}>
                <View style={style.imgWrap}>
                  <Image
                    source={user?.avatar ? { uri: user?.avatar } : Images.test.m_owner1}
                    style={style.head}
                  />
                </View>
                <View style={style.midFullView}>
                  <Text numberOfLines={1} style={[style.phoneText]}>
                    {user?.nickname || user?.phoneSecy}
                  </Text>
                </View>
              </View>
              <View style={style.qrBG}>
                <QrSVG size={fitSize(190)} value={address} />
              </View>
            </View>
          </View>
        )}
        <View style={style.infoBar}>
          <View style={style.row}>
            <Text numberOfLines={1} style={style.myAddressText}>
              {translate("screen.walletAddress.myAddress")}:
            </Text>
            <Text numberOfLines={2} style={style.addressText}>
              {address}
            </Text>
          </View>
          <View style={style.btnTextBar}>
            {Platform.OS !== "web" ? (
              <Touchable onPress={doCopy} style={style.copyWrap}>
                <Image source={Images.common.copy} style={style.copyImg} />
                <Text style={style.copyText}>{translate("screen.walletAddress.copyAddress")}</Text>
              </Touchable>
            ) : (
              <CopyToClipboard
                text={address}
                onCopy={() => GToast.current.showToast(translate("common.copy"), "TOAST", 2000)}
              >
                <View style={style.copyWrap}>
                  <Image source={Images.common.copy} style={style.copyImg} />
                  <Text style={style.copyText}>
                    {translate("screen.walletAddress.copyAddress")}
                  </Text>
                </View>
              </CopyToClipboard>
            )}
          </View>
        </View>
      </View>
      <View style={[style.btnBar, { display: Platform.OS !== "web" ? "flex" : "none" }]}>
        <Touchable onPress={onShareWeChat} style={style.center}>
          <Image source={Images.screen.mine.wechat} style={style.btnIcon} />
          <Text style={style.btnText}>{translate("screen.walletAddress.wechat")}</Text>
        </Touchable>
        <Touchable onPress={onShareWeChatMoments} style={style.center}>
          <Image source={Images.screen.mine.wechat_moments} style={style.btnIcon} />
          <Text style={style.btnText}>{translate("screen.walletAddress.wechatMoments")}</Text>
        </Touchable>
        <Touchable onPress={savelocal} style={style.center}>
          <Image source={Images.screen.mine.download_qrcode} style={style.btnIcon} />
          <Text style={style.btnText}>{translate("screen.walletAddress.saveImg")}</Text>
        </Touchable>
      </View>
    </Screen>
  )
}

const style = StyleSheet.create({
  infoBar: {
    width: "100%",
    backgroundColor: colors.palette.neutral100,
    minHeight: fitSize(100),
    borderBottomLeftRadius: fitSize(12),
    borderBottomRightRadius: fitSize(12),
  },
  viewShot: {
    width: "100%",
    height: fitSize(350),
    backgroundColor: colors.transparent,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  rightWrap: {
    width: fitSize(100),
  },
  rightText: {
    color: colors.palette.neutral100,
    fontSize: fitSize(14),
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
  },
  copyText: {
    fontSize: fitSize(13),
    color: colors.palette.neutral30,
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
  },
  btnTextBar: {
    alignItems: "center",
    marginTop: fitSize(20),
  },
  btnBar: {
    marginTop: fitSize(35),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
  btnText: {
    marginTop: 10,
    color: colors.palette.neutral100,
    fontSize: fitSize(14),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  btnIcon: {
    width: fitSize(30),
    height: fitSize(30),
  },
  center: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: fitSize(80),
  },
  row: {
    flexDirection: "row",
  },
  imgWrap: {
    width: fitSize(80),
    height: fitSize(80),
    backgroundColor: colors.palette.neutral100,
    borderRadius: fitSize(40),
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
  centerModalWrap: {
    width: "100%",
    height: fitSize(300),
    backgroundColor: colors.palette.neutral100,
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: fitSize(12),
    borderTopLeftRadius: fitSize(12),
  },
  centerWrap: {
    alignSelf: "center",
    width: fitSize(335),
  },
  qrBG: {
    width: fitSize(199),
    height: fitSize(199),
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: fitSize(7),
  },
  myAddressText: {
    fontSize: fitSize(14),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    color: colors.palette.neutral30,
    marginLeft: fitSize(30),
    marginTop: fitSize(9),
  },
  addressText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral900,
    marginTop: fitSize(10),
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
    width: "60%",
    marginLeft: fitSize(3),
  },
  copyWrap: {
    width: fitSize(115),
    height: fitSize(36),
    borderRadius: fitSize(4),
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: fitSize(30),
    backgroundColor: colors.palette.primary500,
  },
  copyImg: {
    width: fitSize(16),
    height: fitSize(16),
    marginRight: fitSize(6),
    tintColor: colors.palette.neutral30,
  },
  topWrap: {
    marginTop: fitSize(-25),
    width: fitSize(335),
    alignItems: "center",
    flexDirection: "column",
  },
  head: {
    width: fitSize(70),
    height: fitSize(70),
    borderRadius: fitSize(35),
    justifyContent: "center",
    alignItems: "center",
  },
  midFullView: {
    marginTop: fitSize(10),
    flexGrow: 1,
    marginLeft: fitSize(10),
  },
  phoneText: {
    fontSize: fitSize(16),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    color: colors.palette.neutral900,
  },
})
