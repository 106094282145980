import { colors, fitSize } from "../../theme"
import { StyleSheet, useWindowDimensions, Platform } from 'react-native';
import React, { useState } from "react"
import { SceneRendererProps, TabView } from "react-native-tab-view"
import { MyTabBar } from "../OrderCenter/components/MyTabBar"
import { ReceiveList } from "./Components/ReceiveList"
import { RootStackNavProps } from "../../type/RouteType"
import { Screen } from "../../components"
import { Sheader } from "../../components/Sheader"
import { routeProp } from "../OrderCenter/OrderBuy"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"

interface ReceiveRecordProps extends RootStackNavProps<"ReceiveRecord"> {}

const routes = [
  { key: "first", title: translate("screen.receiveRecord.tabOne") },
  { key: "second", title: translate("screen.receiveRecord.tabTwo") },
  { key: "third", title: translate("screen.receiveRecord.tabThree") },
]

export const ReceiveRecord: React.FC<ReceiveRecordProps> = ({ navigation, route }) => {
  const [navState, setNavState] = useState({
    index: 0,
    routes: routes,
  })

  const { width } = useWindowDimensions()
  const [change, setChange] = useState(0)

  const _renderScene = ({
    route,
  }: SceneRendererProps & {
    route: routeProp
  }) => {
    switch (route.key) {
      case "first":
        return <ReceiveList type={"all"} change={change} />
      case "second":
        return <ReceiveList type={1} change={change} />
      case "third":
        return <ReceiveList type={2} change={change} />
      default:
        return null
    }
  }

  const _handleIndexChange = (index: number) => {
    setNavState({ ...navState, index })
    setChange(index)
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("screen.receiveRecord.header")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <TabView
        initialLayout={{ width: width }}
        lazy={true}
        animationEnabled={false}
        navigationState={navState}
        renderScene={_renderScene}
        onIndexChange={(index) => {
          _handleIndexChange(index)
        }}
        renderTabBar={(props) => {
          return (
            <MyTabBar
              height={Platform.OS === "web" ? fitSize(37) : fitSize(30)}
              textColor={colors.palette.neutral50}
              textTintColor={colors.palette.neutral100}
              bgColor={colors.transparent}
              split={3}
              indicatorLeft={fitSize(21)}
              leftSpace={fitSize(2)}
              width={fitSize(60)}
              indicatorLeftWidth={fitSize(20)}
              noyTextFloating={true}
              {...props}
            />
          )
        }}
      />
    </Screen>
  )
}
const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
