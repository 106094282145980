import { useState, useEffect } from "react"

let timer
export const useCountdown = () => {
  const [showVerification, setShowVerification] = useState(false)
  const [time, setTime] = useState(60)

  const onTime = () => {
    timer = setInterval(() => setTime((pre) => pre - 1), 1000)
    setShowVerification(true)
  }

  useEffect(() => {
    if (time > 0 && time < 60) {
      return
    } else {
      clearInterval(timer)
      setShowVerification(false)
      setTime(60)
    }
  }, [time])

  useEffect(() => {
    clearInterval(timer)
    return () => clearInterval(timer)
  }, [])

  const onReset = () => {
    clearInterval(timer)
    setShowVerification(false)
    setTime(60)
  }

  return { showVerification, time, onTime, onReset }
}
