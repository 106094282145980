import { Animated, Image } from "react-native"
import { Images, fitSize } from "../../../theme"
import { FC, forwardRef, useImperativeHandle, useRef, useState } from "react"
import { Touchable } from "../../../components"

interface BackTopButtonProps {
  lisRef: React.MutableRefObject<any>
}

interface BackTopButtonRef {
  setFadeAnimate: (show: boolean) => void
}

export const BackTopButton = forwardRef<BackTopButtonRef, BackTopButtonProps>((props, ref) => {
  const fadeAnim = useRef(new Animated.Value(0)).current
  const [disabledBtn, setDisabledBtn] = useState(true)
  const { lisRef } = props

  useImperativeHandle(ref, () => ({
    setFadeAnimate,
  }))

  const setFadeAnimate = (show: boolean) => {
    if (show) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 100,
        useNativeDriver: false,
      }).start(() => {
        // 设置fadeAnim为0时，隐藏它
        setDisabledBtn(false)
      })
    } else {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 100,
        useNativeDriver: false,
      }).start(() => {
        // 设置fadeAnim为0时，隐藏它
        setDisabledBtn(true)
      })
    }
  }

  const $icon = {
    width: fitSize(46),
    height: fitSize(47),
  }

  return (
    <Animated.View
      style={{
        opacity: fadeAnim,
        position: "absolute",
        right: 30,
        bottom: 30,
        overflow: "hidden",
      }}
    >
      <Touchable
        onPress={() => {
          lisRef.current.scrollToIndex({ index: 0, animated: true })
        }}
        disabled={disabledBtn}
      >
        <Image source={Images.screen.store.list_up} style={$icon} />
      </Touchable>
    </Animated.View>
  )
})
