import { ActivityIndicator, Image, StyleSheet, View } from "react-native"

import { GestureResponderEvent } from "react-native"
import React from "react"
import { Images, fitSize } from "../theme"
import { Text } from "./Text"
import { Touchable } from "./Button"

interface NoInternetProps {
  onPress: ((event: GestureResponderEvent) => void) | undefined
  isLoading?: boolean
  top?: number
}

export const NoInternet: React.FC<NoInternetProps> = ({ onPress, isLoading, top = 180 }) => {
  return (
    <View style={{ alignSelf: "center", marginTop: fitSize(top), alignItems: "center" }}>
      <Image
        source={Images.not_data.no_internet}
        style={{ width: fitSize(188), height: fitSize(118) }}
      />
      <Text style={{ fontSize: fitSize(16), color: "#333", marginTop: fitSize(12) }}>
        无法连接网络
      </Text>
      <Text style={{ fontSize: fitSize(12), color: "#666", marginTop: fitSize(4) }}>
        请检查网络设置或稍后重试
      </Text>
      <Touchable
        onPress={onPress}
        style={{
          width: fitSize(72),
          height: fitSize(30),
          borderRadius: fitSize(6),
          backgroundColor: "#FAC033",
          marginTop: fitSize(16),
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: fitSize(16), color: "#333" }}>重试</Text>
      </Touchable>
      {isLoading && <ActivityIndicator size={"small"} color={"#999"} />}
    </View>
  )
}

const styles = StyleSheet.create({})
