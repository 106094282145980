import dayjs from "dayjs"
import { SynthesisItem } from "../../../services/api"
import { Touchable } from "../../../components"
import { SizeImage } from "../../../components/SizeImage"
import { Images, colors, fitSize } from "../../../theme"
import { StyleSheet, View, Text, Platform, Image } from "react-native"
import { translate } from "../../../i18n"
import { fontName } from "../../../theme/fonts"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { Fragment } from "react"
import { MultiPlatformVideoPlayer } from "../../../components/MultiPlatformVideoPlayer"

export const getActivityStartRestTimeDays = (time: number) => {
  if (time <= 0) return "00:00:00"

  const allS = Math.floor(time / 1000)
  const days = Math.floor(allS / 60 / 60 / 24)

  const hours = Math.floor((allS / 60 / 60) % 24)
  const minutes = Math.floor((allS / 60) % 60)
  const seconds = Math.floor(allS % 60)

  const timeString =
    (days < 10 ? "0" + days : days) +
    ":" +
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)

  return timeString
}

interface CompItemProps {
  item: SynthesisItem
  currentTime: number
}

export const CompItem: React.FC<CompItemProps> = ({ item, currentTime }) => {
  const navigation = useTypeNavigation()

  const onChallenge = () => {
    navigation.navigate("ChallengeDetails", { id: item.id })
  }

  return (
    <Touchable onPress={onChallenge} style={style.item}>
      <SizeImage source={Images.screen.comp.item} style={style.icon}>
        <View
          style={{
            minWidth: fitSize(90),
            height: fitSize(26),
            borderRadius: 5,
            backgroundColor: "#01010180",
            position: "absolute",
            right: fitSize(20),
            top: fitSize(20),
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: fitSize(5),
            zIndex: 2147483647,
          }}
        >
          <Image
            source={Images.components.card}
            style={{ width: fitSize(11), height: fitSize(10) }}
          />
          <Text
            style={{
              fontSize: fitSize(11),
              color: colors.palette.neutral100,
              fontWeight: "400",
              fontFamily: fontName.PingFang_SC,
              marginLeft: fitSize(3),
            }}
          >
            {translate("screen.compScreen.remainderTxt", { number: item?.remainder })}
          </Text>
        </View>
        {item?.revealUrl?.type === 0 ? (
          <SizeImage
            source={{ uri: item?.revealUrl?.url }}
            style={style.itemImage}
            resizeMode="cover"
          />
        ) : (
          <Fragment>
            {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
              <MultiPlatformVideoPlayer
                uri={item?.revealUrl?.url}
                style={{
                  width: fitSize(327),
                  height: fitSize(183),
                  overflow: "hidden",
                }}
                h={fitSize(183)}
                w={fitSize(327)}
                isPaused={true}
                poster={""}
                loop={false}
                index={0}
              />
            ) : null}
          </Fragment>
        )}
        <View style={style.itemBody}>
          {item.hot === 1 && (
            <View style={style.typeLabelBox}>
              <SizeImage source={Images.screen.comp.hot} style={style.itemTypeIcon} />
              <Text style={style.itemLabel}>{translate("screen.compScreen.hotText")}</Text>
            </View>
          )}
          <Text style={style.itemName}>{item?.name}</Text>
          <Text style={style.itemDescription} numberOfLines={3}>
            {item?.description}
          </Text>
        </View>
        <View style={style.itemFooter}>
          <View style={style.itemFooterInfo}>
            {item.status !== 3 ? (
              <Text style={style.closeCountdown}>
                {dayjs().isBefore(item.startTime)
                  ? translate("screen.compScreen.startCountdown")
                  : translate("screen.compScreen.closeCountdown")}
              </Text>
            ) : (
              <Text style={style.closeCountdown}>{translate("screen.compScreen.finish")}</Text>
            )}
            {item.status !== 3 && (
              <View style={style.countdownBox}>
                <Text style={style.countdown}>
                  {dayjs().isBefore(item.startTime)
                    ? getActivityStartRestTimeDays(dayjs(item?.startTime).diff(currentTime))
                    : getActivityStartRestTimeDays(dayjs(item?.endTime).diff(currentTime))}
                </Text>
                <View style={style.timeLabelBox}>
                  <Text style={style.timeLabel}>{translate("screen.compScreen.day")}</Text>
                  <Text style={style.timeLabel}>{translate("screen.compScreen.hour")}</Text>
                  <Text style={style.timeLabel}>{translate("screen.compScreen.minute")}</Text>
                  <Text style={style.timeLabel}>{translate("screen.compScreen.second")}</Text>
                </View>
              </View>
            )}
          </View>
          <View style={style.submit}>
            <Text style={style.submitText}>{item?.statusText}</Text>
          </View>
        </View>
      </SizeImage>
    </Touchable>
  )
}

const style = StyleSheet.create({
  icon: {
    width: "100%",
    height: fitSize(510),
    marginBottom: fitSize(30),
    overflow: "hidden",
    padding: fitSize(7),
  },
  submitText: {
    fontSize: fitSize(14),
    fontFamily: fontName.PingFang_SC_Semibold,
    color: colors.palette.neutral30,
    fontWeight: "500",
  },
  submit: {
    width: fitSize(136),
    height: fitSize(35),
    borderRadius: fitSize(4),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.palette.neutral100,
  },
  countdown: {
    fontSize: fitSize(18),
    fontWeight: "600",
    fontFamily: fontName.Poppins_ExtraBoldItalic_zitidi,
    color: colors.palette.neutral100,
    letterSpacing: fitSize(2),
    lineHeight: fitSize(27),
  },
  countdownBox: {
    width: fitSize(140),
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  timeLabelBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  timeLabel: {
    fontSize: fitSize(10),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    color: colors.palette.neutral50,
  },
  closeCountdown: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  itemFooterInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(50),
  },
  itemFooter: {
    width: "100%",
    height: fitSize(150),
    marginTop: fitSize(7),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: fitSize(14),
    paddingRight: fitSize(4),
    paddingBottom: fitSize(25),
  },
  itemDescription: {
    fontSize: fitSize(14),
    color: colors.palette.neutral20,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  itemName: {
    fontSize: fitSize(24),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
    marginBottom: fitSize(10),
    lineHeight: fitSize(33),
  },
  typeLabelBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: fitSize(10),
  },
  itemTypeIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  itemLabel: {
    fontSize: fitSize(11),
    color: colors.palette.neutral20,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
  },
  itemBody: {
    width: "100%",
    height: fitSize(150),
    paddingTop: fitSize(20),
    paddingHorizontal: fitSize(15),
    flexDirection: "column",
    alignItems: "flex-start",
  },
  itemImage: {
    width: fitSize(331),
    height: fitSize(186),
    alignSelf: "center",
  },
  itemContent: {
    width: "100%",
    height: "100%",
    padding: fitSize(9),
    overflow: "hidden",
  },
  item: {
    width: "100%",
    height: fitSize(510),
    marginBottom: fitSize(30),
    overflow: "hidden",
  },
})
