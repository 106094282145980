import { StyleSheet, View } from "react-native"
import React, { useRef } from "react"
import { ShouldStartLoadRequest } from "react-native-webview/lib/WebViewTypes"

import { InjectJS } from "./Components/JSContent"
import { NoInternet } from "../../components/NoInternet"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { WebView } from "react-native-webview"
import { fitSize } from "../../theme"
import { useState } from "react"

interface ActivityWebViewProps extends RootStackNavProps<"MassWebView"> {}
export const PAY_RES_URL = "http://jijipay.app"
export const LOGIN_URL = "http://jiji.app"
export const MassWebView: React.FC<ActivityWebViewProps> = ({ navigation, route }) => {
  const [title, setTitle] = useState(() => route.params?.title || "DID登录")
  const [error, setError] = useState("")
  const webviewRef = useRef<WebView>()

  const refresh = () => {
    webviewRef.current?.reload()
  }
  const openExternalLink = (req: ShouldStartLoadRequest) => {
    console.log(req.url)
    if (req.url.startsWith(LOGIN_URL)) {
      const s = new URL(req.url)
      const code = s.searchParams.get("code")
      console.log("code", code)
      navigation.navigate("LoginFromMessage", { code })
      return false
    } else if (req.url.startsWith(PAY_RES_URL)) {
      const s = new URL(req.url)
      const isPayback = s.searchParams.get("isPayback")
      const id = s.searchParams.get("id")
      console.log("id", id, "isPayback", isPayback)
      /**
       * TODO 暂时注释
       */
      // navigation.navigate("ShowOrder", {
      //   isPayback: isPayback === "true" ? true : false,
      //   id: route.params.ext,
      // })
      return false
    } else {
      return true
    }
  }

  return (
    <View style={[{ flexGrow: 1 }]}>
      <View style={{ flex: 1 }}>
        <Sheader
          textStyle={styles.headerTitle}
          rightImage={""}
          isNotSafe={false}
          title={title}
          leftImage={"black_back"}
          style={{ backgroundColor: "#fff" }}
        />
        <WebView
          ref={webviewRef}
          onError={(err) => setError(err.toString())}
          source={{ uri: route.params.uri }}
          bounces={false}
          onShouldStartLoadWithRequest={(e) => openExternalLink(e)}
          allowsInlineMediaPlayback
          injectedJavaScriptBeforeContentLoaded={InjectJS}
        />
        {!!error && (
          <View style={[StyleSheet.absoluteFill, { backgroundColor: "#fff" }]}>
            <NoInternet
              onPress={() => {
                refresh()
              }}
            />
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerTitle: {
    color: "#373737",
    fontWeight: "bold",
    fontSize: fitSize(18),
  },

  progressBarWrapper: {
    height: 3,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    position: "absolute",
    zIndex: 999999,
  },
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
    height: fitSize(266),
    zIndex: 1000,
  },
  bottom2Modal: {
    justifyContent: "flex-end",
    margin: 0,
    zIndex: 1000,
  },
})
