import { observer } from "mobx-react-lite"
import { StyleSheet, Text, View } from "react-native"
import { RootStackNavProps } from "../../type"
import { Screen, Sheader, Touchable } from "../../components"
import { Images, colors, fitSize } from "../../theme"
import { HookFlatList } from "../../components/list/HookFlatList"
import { useAutoList } from "../../hooks/useAutoList"
import { GToast } from "../../navigators"
import { NoticeListItem, api } from "../../services/api"
import dayjs from "dayjs"
import { translate } from "../../i18n"
import { fontName } from "../../theme/fonts"
import { SizeImage } from "../../components/SizeImage"
import { useFocusEffect } from "@react-navigation/native"
import React from "react"

interface MessageListProps extends RootStackNavProps<"MessageList"> {}

const PL = 10
export const MessageList: React.FC<MessageListProps> = observer(({ navigation, route }) => {
  const fetchData = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getNoticeList(lastId, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchList.onRefresh()
    }, [route]),
  )

  const [fetchList] = useAutoList(
    {
      fetchData: fetchData,
      pageLimit: PL,
    },
    [],
  )

  // const publishTimeDesc = (postTime) => {
  //   postTime = String(postTime).length >= 10 ? postTime * 1000 : postTime
  //   let curTime = new Date().getTime()
  //   let timeDiff = curTime - postTime

  //   let min = 60 * 1000
  //   let hour = min * 60
  //   let day = hour * 24
  //   let week = day * 7

  //   let exceedWeek = Math.floor(timeDiff / week)
  //   let exceedDay = Math.floor(timeDiff / day)
  //   let exceedHour = Math.floor(timeDiff / hour)
  //   let exceedMin = Math.floor(timeDiff / min)

  //   if (exceedWeek > 0) {
  //     return dayjs(postTime).format("YYYY-MM-DD HH:mm:ss")
  //   } else {
  //     if (exceedDay < 7 && exceedDay > 0) {
  //       return exceedDay + "天前"
  //     } else {
  //       if (exceedHour < 24 && exceedHour > 0) {
  //         return exceedHour + "小时前"
  //       } else {
  //         if (exceedMin < 4) {
  //           return "刚刚"
  //         } else {
  //           return exceedMin + "分种前"
  //         }
  //       }
  //     }
  //   }
  // }

  const readMessageItem = (id: number) => {
    navigation.navigate("MessageDetails", { id: id })
  }

  const Item = ({ item, index }) => {
    return (
      <Touchable
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: fitSize(24),
          height: fitSize(42),
          paddingHorizontal: fitSize(14),
        }}
        onPress={() => readMessageItem(item.id)}
      >
        <SizeImage
          source={Images.screen.home.notice_logo}
          style={{ width: fitSize(42), height: fitSize(42), marginRight: fitSize(12) }}
        />
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              height: fitSize(21),
            }}
          >
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={{
                fontWeight: "500",
                fontSize: fitSize(15),
                color: colors.palette.neutral100,
                fontFamily: fontName.PingFang_SC,
                width: fitSize(208),
              }}
            >
              CBA时刻官方
            </Text>
            <Text
              style={{
                fontWeight: "400",
                fontSize: fitSize(11),
                color: colors.palette.neutral50,
                fontFamily: fontName.PingFang_SC_Semibold,
              }}
            >
              {dayjs(item?.publishTime).format("YYYY.MM.DD HH:mm")}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={{
                fontWeight: "400",
                fontSize: fitSize(14),
                color: colors.palette.neutral50,
                fontFamily: fontName.PingFang_SC_Semibold,
                width: fitSize(266),
              }}
            >
              {item?.noticeTitle}
            </Text>
            {item?.isRead === 0 && (
              <View
                style={{
                  width: fitSize(7),
                  height: fitSize(7),
                  backgroundColor: "#FB6547",
                  borderRadius: fitSize(7),
                }}
              />
            )}
          </View>
        </View>
      </Touchable>
    )
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={styles.container}>
      <Sheader
        title={translate("screen.messageList.header")}
        textStyle={styles.textStyle}
        leftStyle={styles.leftIcon}
        style={styles.header}
        isNotSafe
      />
      <HookFlatList
        {...fetchList}
        isShow={fetchList.data.length > 0}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: fitSize(50),
          paddingTop: fitSize(14),
        }}
        refreshing={fetchList.topRefreshing}
        renderItem={Item}
      />
    </Screen>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
