import { Platform, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import React from "react"

import Modal from "react-native-modal"
import { fitSize, deviceHeight } from "../../../theme/layout"
import { colors } from "../../../theme/colors"
import { fontName } from "../../../theme/fonts"
import { Touchable } from "../../../components/Button"
import { translate } from "../../../i18n"

interface PhoneBindingModalProps {
  isShow: boolean
  closeModal: () => void
  onConfirm: () => void
  userPhone: string
}

export const PhoneBindingModal: React.FC<PhoneBindingModalProps> = ({
  closeModal,
  isShow,
  onConfirm,
  userPhone,
}) => {
  const windowHeight = useWindowDimensions().height

  const compatibleHeight = Platform.OS === "web" ? windowHeight : deviceHeight

  return (
    <Modal
      hasBackdrop={true}
      coverScreen={true}
      isVisible={isShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={style.modal}
      backdropOpacity={0.6}
      onBackButtonPress={closeModal}
      onBackdropPress={closeModal}
      swipeDirection={"down"}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
    >
      <View style={style.container}>
        <View style={style.content}>
          <Text style={style.title}>{translate("screen.accountSafe.phoneBindingModal.title")}</Text>
          <Text style={style.info}>
            {translate("screen.accountSafe.phoneBindingModal.content", { phone: userPhone })}
          </Text>
        </View>
        <View style={style.btnContent}>
          <Touchable style={style.btnBox} onPress={closeModal}>
            <Text style={style.closeBtn}>
              {translate("screen.accountSafe.phoneBindingModal.cancel")}
            </Text>
          </Touchable>
          <Touchable style={style.btnBox} onPress={onConfirm}>
            <Text style={style.confirmBtn}>
              {translate("screen.accountSafe.phoneBindingModal.confirm")}
            </Text>
          </Touchable>
        </View>
      </View>
    </Modal>
  )
}

const style = StyleSheet.create({
  confirmBtn: {
    fontSize: fitSize(15),
    color: colors.palette.primary500,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    textAlign: "center",
  },
  closeBtn: {
    fontSize: fitSize(15),
    color: colors.palette.neutral20,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    textAlign: "center",
  },
  btnBox: {
    width: fitSize(136),
    height: fitSize(48),
    alignItems: "center",
    justifyContent: "center",
  },
  btnContent: {
    width: "100%",
    height: fitSize(48),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  info: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    textAlign: "center",
  },
  title: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginBottom: fitSize(12),
  },
  content: {
    width: "100%",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: fitSize(15),
  },
  container: {
    width: fitSize(272),
    height: fitSize(153),
    alignSelf: "center",
    backgroundColor: colors.palette.neutral60,
    borderRadius: fitSize(10),
    overflow: "hidden",
    flexDirection: "column",
  },
  modal: {
    margin: 0,
    overflow: "hidden",
  },
})
