import { Image, StyleSheet, View } from "react-native"
import { Images, colors, fitSize } from "../../theme"
import React, { useState } from "react"
import { Screen, Text, Touchable } from "../../components"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { observer } from "mobx-react-lite"
import { translate } from "../../i18n"
import { useStores } from "../../models"
import { fontName } from "../../theme/fonts"
import { SizeImage } from "../../components/SizeImage"
import { GToast } from "../../navigators"
import { PhoneBindingModal } from "./components/PhoneBindingModal"

interface AccountSecurityProps extends RootStackNavProps<"AccountSecurity"> {}

export const AccountSecurity: React.FC<AccountSecurityProps> = observer(({ navigation, route }) => {
  const { accountStore } = useStores()
  const [showSettingPhone, setShowSettingPhone] = useState(false)

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("setting.accountSafe")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <View style={style.btnContent}>
          <Touchable style={style.btnLineBox} onPress={() => setShowSettingPhone(true)}>
            <Text style={style.btnLeftText}>{translate("screen.accountSafe.bindPhone")}</Text>
            <View style={style.rowCenter}>
              <Text style={style.nikeName}>{accountStore.user?.phoneSecy}</Text>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
          <Touchable
            style={style.btnLineBox}
            onPress={() => {
              if (accountStore.user.password) {
                navigation.push("ChangePassword")
              } else {
                navigation.push("SettingPassword")
              }
            }}
          >
            <Text style={style.btnLeftText}>
              {accountStore.user.password
                ? translate("screen.accountSafe.setPassWord")
                : translate("screen.accountSafe.settingPassword")}
            </Text>
            <View style={style.rowCenter}>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
          <Touchable
            style={style.btnBox}
            onPress={() => {
              navigation.push("RealName")
            }}
            disabled={accountStore.user?.isReal === 1}
          >
            <Text style={style.btnLeftText}>
              {accountStore.user.password
                ? translate("screen.accountSafe.realName")
                : translate("screen.accountSafe.realName")}
            </Text>
            <View style={style.rowCenter}>
              <Text style={style.nikeName}>
                {accountStore.user?.isReal === 1
                  ? translate("screen.accountSafe.realNameY")
                  : translate("screen.accountSafe.realNameN")}
              </Text>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
        </View>
        <View style={style.btnContent}>
          <Touchable
            style={style.btnLineBox}
            onPress={() => GToast.current.showToast("暂未开放", "TOAST", 2000)}
          >
            <Text style={style.btnLeftText}>{translate("screen.accountSafe.bindWechat")}</Text>
            <View style={style.rowCenter}>
              <Text style={style.unbind}>{translate("screen.accountSafe.unbind")}</Text>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
          <Touchable
            style={style.btnBox}
            onPress={() => GToast.current.showToast("暂未开放", "TOAST", 2000)}
          >
            <Text style={style.btnLeftText}>{translate("screen.accountSafe.bindQQ")}</Text>
            <View style={style.rowCenter}>
              <Text style={style.unbind}>{translate("screen.accountSafe.unbind")}</Text>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
        </View>
        <View style={style.btnContent}>
          <Touchable style={style.btnBox} onPress={() => navigation.push("CloseAccount")}>
            <Text style={style.btnLeftText}>{translate("screen.accountSafe.clearUser")}</Text>
            <View style={style.rowCenter}>
              <Image source={Images.screen.setting.back} style={style.backIcon} />
            </View>
          </Touchable>
        </View>
      </View>
      <PhoneBindingModal
        isShow={showSettingPhone}
        closeModal={() => setShowSettingPhone(false)}
        onConfirm={() => {
          setShowSettingPhone(false)
          navigation.push("PhoneBinding", { phone: accountStore.user?.phoneSecy })
        }}
        userPhone={accountStore.user?.phoneSecy}
      />
    </Screen>
  )
})

const style = StyleSheet.create({
  unbind: {
    fontSize: fitSize(13),
    color: colors.palette.primary500,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  nikeName: {
    fontSize: fitSize(13),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  btnBox: {
    width: "100%",
    height: fitSize(55),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  backIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginLeft: fitSize(8),
  },
  avatar: {
    width: fitSize(34),
    height: fitSize(34),
    borderRadius: fitSize(34),
    overflow: "hidden",
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  btnLeftText: {
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  btnLineBox: {
    width: "100%",
    minHeight: fitSize(55),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: colors.palette.neutral30,
  },
  btnContent: {
    flexDirection: "column",
    width: fitSize(347),
    minHeight: fitSize(55),
    borderRadius: fitSize(6),
    backgroundColor: colors.palette.neutral60,
    marginTop: fitSize(10),
    paddingHorizontal: fitSize(14),
    marginBottom: fitSize(14),
  },
  content: {
    backgroundColor: colors.palette.neutral900,
    flex: 1,
    alignItems: "center",
  },
  boxText: {
    fontSize: fitSize(14),
    color: colors.palette.primary500,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  box: {
    position: "absolute",
    width: fitSize(310),
    height: fitSize(50),
    backgroundColor: colors.palette.neutral60,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: fitSize(6),
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
})
