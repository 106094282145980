import React, { useCallback, useEffect, useRef, useState } from "react"
import { VideoBoxProps } from "./VideoBox"
import _ from "lodash"
import { Animated, Dimensions, Platform, View } from "react-native"
import videojs from "video.js"
import { videoStyle } from "../TimeDisplayList"
import { MultiPlatformVideoPlayer } from "../../../../components/MultiPlatformVideoPlayer"
import { SizeImage } from "../../../../components/SizeImage"
import { Easing } from "react-native-reanimated"
import { fitSize } from "../../../../theme"

export const VideoBox: React.FC<VideoBoxProps> = React.memo(({ data, isPaused, poster }) => {
  // const videoRef = useRef(null)
  // const playerRef = useRef(null)
  const [fadeAnim] = useState(new Animated.Value(1))
  const ref = useRef(null)
  const [successLoading, setSuccessLoading] = useState(false)

  useEffect(() => {
    if (successLoading) {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.ease,
      }).start()
    } else {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.ease,
      }).start()
    }
  }, [successLoading])

  // useEffect(() => {
  //   const loadVideo = async () => {
  //     try {
  //       const videoElement = videoRef.current
  //       const player = videojs(videoElement, {
  //         controls: false,
  //         poster: "",
  //         autoplay: false,
  //         muted: true,
  //         loop: true,
  //         playsinline: true,
  //         html5: {
  //           hls: {
  //             enableLowInitialPlaylist: true,
  //             smoothQualityChange: true,
  //           },
  //         },
  //         errorDisplay: false,
  //       })

  //       player.src(data.src)
  //       playerRef.current = player
  //       return player
  //     } catch (error) {
  //       // 执行自定义错误处理逻辑，例如记录日志或显示错误提示
  //       console.error("视频加载失败:", error)
  //       return null
  //     }
  //   }

  //   loadVideo()
  //   return () => {
  //     if (playerRef.current) {
  //       playerRef.current.dispose()
  //     }
  //   }
  // }, [data.src])

  // useEffect(() => {
  //   const player = playerRef.current
  //   if (player) {
  //     if (isPaused) {
  //       player.pause()
  //       player.currentTime(0)
  //     } else {
  //       player.play().catch((error) => {
  //         console.error("Error playing video:", error)
  //       })
  //     }
  //   }
  // }, [isPaused])

  return (
    <View style={videoStyle}>
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        {/* <video
            className="video-js vjs-default-skin"
            ref={videoRef}
            playsInline
            style={{ width: "100%", height: "100%", objectFit: "fill" }}
            poster
          ></video> */}
        <Animated.View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 999,
            opacity: fadeAnim,
          }}
        >
          <SizeImage
            source={{ uri: poster }}
            style={{ width: fitSize(95), height: fitSize(95) }}
            resizeMode="contain"
          />
        </Animated.View>
        {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
          <MultiPlatformVideoPlayer
            uri={data.src}
            isPaused={isPaused}
            muted={true}
            ref={ref}
            // poster={poster}
            h={"100%"}
            w={"100%"}
            loop={true}
            style={{
              width: "100%",
              height: "100%",
              opacity: successLoading ? 1 : 0,
            }}
            setSuccessLoading={setSuccessLoading}
          />
        ) : null}
      </div>
    </View>
  )
})
