import { Platform, StyleSheet, Text, TextStyle, View } from "react-native"
import React, { useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { colors, fitSize } from "../../theme"
import { Screen, Sheader } from "../../components"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"
import { Touchable } from "../../components/Button"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n/translate"
import { useFocusEffect } from "@react-navigation/native"
import { IllustratedHandbook } from "./IllustratedHandbook"
import { CardBoxStore } from "./CardBoxStore"

interface StoreContentProps extends RootStackNavProps<"StoreContent"> {}

export const StoreContent: React.FC<StoreContentProps> = observer(({ navigation, route }) => {
  const [type, setType] = useState<"IllustratedHandbook" | "CardBoxStore">(route.params.initRoute)

  useFocusEffect(
    React.useCallback(() => {
      if (Platform.OS === "web")
        window.document.title = translate(
          `appNavigator.${type === "IllustratedHandbook" ? "illustratedHandbook" : "cardBoxStore"}`,
        )
    }, [route, type]),
  )

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      {/* <View style={{ flex: 1, alignItems: "center" }}>
        <Sheader
          title={""}
          isNotSafe
          textStyle={style.textStyle}
          leftStyle={style.leftStyle}
          style={style.header}
        />
        <StoreTabBar currentRoute={type} isShowIndex={true} setType={setType} />
        {type === "IllustratedHandbook" ? <IllustratedHandbook /> : <CardBoxStore />}
      </View> */}
    </Screen>
  )
})

const style = StyleSheet.create({
  textStyle: {
    color: colors.palette.neutral100,
  },
  leftStyle: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  rightWrapStyle: {
    flexGrow: 1,
    height: "100%",
  },
  icon: {
    width: fitSize(15),
    height: fitSize(15),
  },
  ml: {
    marginLeft: fitSize(20),
  },
  rowEnd: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    height: fitSize(44),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  content: {
    paddingBottom: fitSize(120),
  },
  head: {
    flexDirection: "row",
    width: "100%",
    height: fitSize(64),
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingHorizontal: fitSize(14),
  },
  routeName: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
  },
  forShortName: {
    fontSize: fitSize(16),
    color: colors.palette.neutral20,
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  fullName: {
    fontSize: fitSize(25),
    color: colors.palette.neutral100,
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  currentRoute: {
    fontSize: fitSize(14),
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
  },
  index: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    height: "100%",
    paddingBottom: fitSize(7),
  },
})

interface StoreTabBarProps {
  isShowIndex: boolean
  setType: React.Dispatch<React.SetStateAction<"IllustratedHandbook" | "CardBoxStore">>
  currentRoute?: string
}

const StoreTabBar: React.FC<StoreTabBarProps> = ({ currentRoute, isShowIndex, setType }) => {
  const { navigate, goBack } = useTypeNavigation()

  const toTabRoute = (type: "IllustratedHandbook" | "CardBoxStore") => {
    // navigate(type, { back_route: "TabTwo" })
    setType(type)
  }

  const $collectionTimeText: TextStyle = {
    color:
      isShowIndex && currentRoute === "IllustratedHandbook"
        ? colors.palette.neutral100
        : colors.palette.neutral50,
  }

  const $collectionCardBagText: TextStyle = {
    color:
      isShowIndex && currentRoute === "CardBoxStore"
        ? colors.palette.neutral100
        : colors.palette.neutral50,
  }

  return (
    <View style={style.head}>
      <View style={style.routeName}>
        <Text style={style.forShortName}>
          {currentRoute === "CardBoxStore"
            ? translate("screen.tabStore.cardBoxStore.forShortName")
            : translate("screen.tabStore.illustratedHandbook.forShortName")}
        </Text>
        <Text style={style.fullName}>
          {currentRoute === "CardBoxStore"
            ? translate("screen.tabStore.cardBoxStore.fullName")
            : translate("screen.tabStore.illustratedHandbook.fullName")}
        </Text>
      </View>
      <View style={style.index}>
        <Touchable
          style={style.ml}
          onPress={() => {
            toTabRoute("IllustratedHandbook")
          }}
        >
          <Text style={[style.currentRoute, $collectionTimeText]}>
            {translate("screen.tabStore.codexTitle")}
          </Text>
        </Touchable>
        <Touchable
          style={style.ml}
          onPress={() => {
            toTabRoute("CardBoxStore")
          }}
        >
          <Text style={[style.currentRoute, $collectionCardBagText]}>
            {translate("screen.tabStore.cardBagTitle")}
          </Text>
        </Touchable>
      </View>
    </View>
  )
}
