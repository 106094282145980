import { Images, colors, fitSize } from "../../theme"
import { StyleSheet, Text, View, Image, Linking } from "react-native"
import React from "react"
import { Sheader } from "../../components/Sheader"
import { RootStackNavProps } from "../../type"
import { Screen, Touchable } from "../../components"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"

interface RelationServiceProps extends RootStackNavProps<"RelationService"> {}

export const RelationService: React.FC<RelationServiceProps> = ({ navigation }) => {
  const openWeChatPublicAccount = () => {
    const weChatPublicAccountID = "wx6023204a63d13d92"
    const url = `wechat://dl/officialaccounts?&uin=${weChatPublicAccountID}`

    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(url)
        } else {
          console.log(`无法打开微信公众号：${url}`)
        }
      })
      .catch((error) => console.log("打开微信公众号出错：", error))
  }

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("screen.relationService.header")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <Image source={Images.screen.mine.need_help} style={style.img} />
      <View style={style.infoBox}>
        <Text style={style.infoOne}>{translate("screen.relationService.infoOne")}</Text>
        <Text style={style.infoTwo}>{translate("screen.relationService.infoTwo")}</Text>
      </View>
      {/* <Touchable onPress={() => openWeChatPublicAccount()} style={style.button}>
        <Text style={style.btnText}>{translate("screen.relationService.btnText")}</Text>
      </Touchable> */}
    </Screen>
  )
}

const style = StyleSheet.create({
  btnText: {
    color: colors.palette.neutral30,
    fontSize: fitSize(16),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  infoBox: {
    flexDirection: "column",
    marginBottom: fitSize(40),
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  img: {
    width: fitSize(212),
    height: fitSize(184),
    marginTop: fitSize(135),
    marginBottom: fitSize(171),
    alignSelf: "center",
  },
  infoOne: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    alignSelf: "center",
    lineHeight: fitSize(22),
    fontFamily: fontName.PingFang_SC,
  },
  infoTwo: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    alignSelf: "center",
    lineHeight: fitSize(22),
    fontFamily: fontName.PingFang_SC,
  },
  button: {
    width: fitSize(310),
    height: fitSize(50),
    backgroundColor: colors.palette.primary500,
    justifyContent: "center",
    textAlign: "center",
    borderRadius: fitSize(5),
    alignItems: "center",
    alignSelf: "center",
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
