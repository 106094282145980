import {
  BackHandler,
  ImageBackground,
  ImageStyle,
  Platform,
  TextStyle,
  View,
  ViewStyle,
} from "react-native"
import { Images, colors, fitSize } from "../theme"
import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { Text } from "../components"
import { LoadCircleModal } from "./LoadCircleModal"
import { RootPublicStackList, RootStackParamList, promiseReturn } from "../type"
import { useStores } from "../models"
import { goBack, navigationRef } from "../navigators"
import { VideoModal } from "../modal/VideoModal"
import { PlatteWhiteAlert } from "../modal/PlatteWhiteAlert"
import { GiftModal } from "../modal/GiftModal"
import { GeneralApiProblem, api } from "../services/api"
import { ControlVideoModal } from "../modal/ControlVideoModal"
import { OptionalVideoPlayModal } from "../modal/OptionalVideoPlayModal"

interface GlobalModalProps {}
type msg = {
  msg: string
  type: string
  needCover: boolean
  cprogress?: number
  tprogress?: number
  image?: object
}
export type alertText = {
  closeText: string
  sureText: string
  info?: string
  isShowOne?: boolean
}

type stackType = keyof RootStackParamList

const DEFAULT_ALERT_TEXT = {
  closeText: "取消",
  sureText: "确认",
  info: "",
}

export type videoItem = {
  actionName: string
  name: string
  image: string
  video: string
  assetsNumber: number
  circulation: string
  rarityName: string
  rarityIcon: string
  cardBoxName: string
  seriesName: string
}

let AlertInfo = { msg: "", onClose: () => {}, onSure: () => {}, info: "" }

export interface GlobalModalHandle {
  showToast: (
    msg: string,
    type: "CANCEL" | "TOAST" | "OK" | "LOAD" | "IMG",
    duration: number,
    needCover?: boolean,
    cprogress?: number,
    tprogress?: number,
    image?: Object,
  ) => NodeJS.Timeout
  hideToast: (time?: NodeJS.Timeout) => void
  // showAlert: (
  //   title: string,
  //   onClose: () => void,
  //   onSure: () => void,
  //   info?: null,
  // ) => Promise<unknown>
  showLoading: (msg: string, time?: number) => void
  showProgress: (msg: string, cprogress?: number, tprogress?: number) => void
  showVideo: (
    isShow: boolean,
    data: {
      url: string
      poster: string
      seek: number
      info?: {
        id: number
        name: string
        cardBoxName: string
        seriesName: string
        rarityIcon: string
      }
    },
    style: ViewStyle,
    clearVideo?: () => void,
    isLoop?: boolean,
    onFinish?: () => void,
    isPercentageWH?: boolean,
    handlePaused?: () => void,
    isPaused?: boolean,
    isVertical?: boolean,
  ) => void
  showControlVideoModal: (
    isShow: boolean,
    data: {
      src: string
      poster: string
      seek: number
    },
  ) => void
  doLogin: (url?: keyof RootPublicStackList, params?: any) => void
  handleErrorResponse: (res: GeneralApiProblem) => void
  showAlert: (
    title: string,
    onClose: () => void,
    onSure: () => void,
    info?: alertText,
  ) => Promise<unknown>
  showGift: (id: number, callback?: () => void) => void
  showOptionalVideoPlay: (isShow: boolean, data: videoItem[], translateXValue?: number) => void
}

const durationDefault = 5 * 60 * 1000
const GlobalModalref: React.ForwardRefRenderFunction<GlobalModalHandle, GlobalModalProps> = (
  {},
  ref,
) => {
  const { accountStore } = useStores()
  const time = useRef<NodeJS.Timeout>()
  const [isShow, setIsShow] = useState(false)
  const [info, setInfo] = useState<msg>({
    msg: "",
    type: "",
    needCover: true,
  })
  const [videoState, setVideoState] = useState({
    style: {},
    url: "",
    isShow: false,
    isPercentageWH: false,
    info: {
      id: 0,
      name: "",
      cardBoxName: "",
      seriesName: "",
      rarityIcon: "",
    },
    poster: "",
    seek: 0,
    clearVideo: () => {},
    isLoop: false,
    onFinish: () => {},
    handlePaused: () => {},
    isPaused: false,
    isVertical: false,
  })
  const confirmRef = useRef<promiseReturn | null>(null)
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [alertInfo, setAlertInfo] = useState<alertText>(DEFAULT_ALERT_TEXT as alertText)
  const [gift, setGift] = useState({
    id: 0,
    callback: () => {},
  })
  const [showGiftModal, setShowGiftModal] = useState(false)
  const [controlVideo, setControlVideo] = useState({
    isShow: false,
    data: {
      src: "",
      poster: "",
      seek: 0,
    },
  })
  const [optionalVideoPlay, setOptionalVideoPlay] = useState({
    isShow: false,
    data: [],
    translateXValue: 0,
  })

  useImperativeHandle(ref, () => ({
    showToast,
    hideToast,
    showLoading,
    showProgress,
    showVideo,
    doLogin,
    showAlert,
    showGift,
    handleErrorResponse,
    showControlVideoModal,
    showOptionalVideoPlay,
  }))

  const showOptionalVideoPlay = (isShow: boolean, data: videoItem[], translateXValue?: number) => {
    setOptionalVideoPlay({ isShow, data, translateXValue })
  }

  const showProgress = (msg: string, cprogress = 1, tprogress = 1) => {
    setInfo({ msg, type: "Progress", cprogress, tprogress, needCover: true })
    setIsShow(true)
  }

  const showToast = (
    msg: string,
    type: string,
    duration: number,
    needCover = true,
    cprogress = 1,
    tprogress = 1,
    image: object = {},
  ) => {
    clearTimeout(time.current)
    setInfo({ msg, type, needCover, cprogress, tprogress, image })
    setIsShow(true)
    time.current = setTimeout(
      () => {
        hideToast()
      },
      duration ? duration : durationDefault,
    )
    return time.current
  }

  const showVideo = (
    isShow: boolean,
    data: {
      url: string
      poster: string
      seek: number
      info: {
        id: number
        name: string
        cardBoxName: string
        seriesName: string
        rarityIcon: string
      }
    },
    style: ViewStyle,
    clearVideo?: () => void,
    isLoop?: false,
    onFinish?: () => void,
    isPercentageWH?: boolean,
    handlePaused?: () => void,
    isPaused?: boolean,
    isVertical?: boolean,
  ) => {
    setVideoState({
      style: style,
      isPercentageWH: isPercentageWH,
      url: data.url,
      poster: data.poster,
      seek: data.seek,
      info: data.info,
      clearVideo,
      isLoop,
      onFinish,
      handlePaused,
      isPaused,
      isShow,
      isVertical,
    })
  }

  const showControlVideoModal = (
    isShow: boolean,
    data: {
      src: string
      poster: string
      seek: number
    },
  ) => {
    setControlVideo({
      isShow,
      data,
    })
  }

  /**
   * 需要记录登录前的路由名和参数，进行记忆化路由
   * @param url RootPublicStackList内声明的路由名称
   * @param params any的参数
   */
  const doLogin = (url?: keyof RootPublicStackList, params?: any) => {
    accountStore.setInviteReturnUrl(url, params)
    navigationRef.navigate("LoginScreen")
  }

  const doReal = () => {
    showToast("未实名", "CANCEL", 2000)
    navigationRef.navigate("RealName")
  }

  const handleErrorResponse = (res: GeneralApiProblem) => {
    if (res.kind === "unauthorized") {
      doLogin()
    } else if (res.kind === "forbidden") {
      doReal()
    } else if (res.kind === "not-sign") {
      showToast(res.msg, "CANCEL", 2000)
    } else if (res.kind === "captcha-traffic-limit") {
      showToast(res.msg, "CANCEL", 2000)
    } else {
      showToast(res.msg, "CANCEL", 2000)
    }
  }

  const showLoading = (msg: string, duration?: number) => {
    clearTimeout(time.current)
    setInfo({ msg, type: "LOAD", needCover: true })
    setIsShow(true)
    time.current = setTimeout(
      () => {
        hideToast()
      },
      duration ? duration : durationDefault,
    )

    return time.current
  }

  useEffect(() => {
    const backAction = () => {
      if (!isShow) {
        return false
      } else {
        return true
      }
    }
    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction)
    return () => backHandler?.remove()
  }, [isShow])

  const getComponent = () => {
    switch (info.type) {
      case "LOAD":
        return <LoadCircleModal msg={info.msg} />
      case "TOAST":
        return <Toast msg={info.msg} />
      case "CANCEL":
        return <CancelView msg={info.msg} />
      case "OK":
        return <OKView msg={info.msg} />
      default:
        return null
    }
  }

  const CancelView = ({ msg }) => {
    const $container: ViewStyle = {
      width: fitSize(162),
      minHeight: fitSize(146),
      borderRadius: fitSize(10),
      backgroundColor: colors.palette.neutral600,
      alignSelf: "center",
      alignItems: "center",
      paddingTop: fitSize(35),
      paddingBottom: fitSize(30),
    }

    const $msgText: TextStyle = {
      color: colors.palette.neutral100,
      fontSize: fitSize(18),
      marginTop: fitSize(20),
      width: fitSize(96),
      textAlign: "center",
    }

    const $circleImg: ImageStyle = {
      width: fitSize(36),
      height: fitSize(36),
    }

    return (
      <View style={$container}>
        <ImageBackground source={Images.components.alert_modal} style={$circleImg} />
        <Text style={$msgText}>{msg || "网络错误"}</Text>
      </View>
    )
  }

  const hideToast = (t?: NodeJS.Timeout) => {
    setIsShow(false)
    clearTimeout(t ? t : time.current)
  }
  const onBeforeSure = (func: () => void) => {
    if (confirmRef.current) confirmRef.current.resolve("success")
    func()
  }
  const onBeforeClose = (func: () => void) => {
    if (confirmRef.current) confirmRef.current.resolve({ message: "取消" })
    func()
  }

  const showAlert = (
    title: string,
    onClose: () => void,
    onSure: () => void,
    info: alertText = DEFAULT_ALERT_TEXT as alertText,
  ) => {
    if (confirmRef.current) confirmRef.current = null
    const confirmPromise = new Promise((resolve, reject) => {
      confirmRef.current = { resolve, reject }
    })
    AlertInfo = {
      msg: title,
      onClose: () => onBeforeClose(onClose),
      onSure: () => onBeforeSure(onSure),
      info: info.info,
    }
    setAlertInfo(info)
    setIsShowAlert(true)
    return confirmPromise
    // return new Promise((resolve, reject) => {})
  }

  const AlertComponent = () => {
    return (
      <PlatteWhiteAlert
        closeModal={() => {
          setIsShowAlert(false)
        }}
        isShow={isShowAlert}
        msg={AlertInfo.msg}
        onClose={() => AlertInfo?.onClose()}
        onConfirm={() => AlertInfo?.onSure()}
        alertInfo={alertInfo}
      />
    )
  }

  const showGift = (id: number, callback?: () => void) => {
    setShowGiftModal(true)
    setGift({
      id,
      callback,
    })
  }

  const preventDefault = (e) => {
    e.preventDefault()
  }

  function disableScroll() {
    document.body.addEventListener("touchmove", preventDefault, { passive: false })
  }

  function enableScroll() {
    document.body.removeEventListener("touchmove", preventDefault)
  }

  useEffect(() => {
    if (Platform.OS === "web") {
      if (showGiftModal) {
        disableScroll()
      } else {
        enableScroll()
      }

      return () => {
        enableScroll()
      }
    }
  }, [showGiftModal])

  const OKView = ({ msg }) => {
    const $centerWrap: ViewStyle = {
      width: fitSize(162),
      minHeight: fitSize(146),
      borderRadius: fitSize(10),
      backgroundColor: colors.palette.neutral600,
      alignSelf: "center",
      alignItems: "center",
      paddingTop: fitSize(35),
      paddingBottom: fitSize(30),
    }

    const $msgText: TextStyle = {
      color: colors.palette.neutral100,
      fontSize: fitSize(18),
      marginTop: fitSize(20),
      width: fitSize(96),
      textAlign: "center",
    }

    const $circleImg: ImageStyle = {
      width: fitSize(36),
      height: fitSize(36),
    }

    return (
      <View style={$centerWrap}>
        <ImageBackground source={Images.common.copy_success} style={$circleImg} />
        <Text style={$msgText}>{msg || "请求成功"}</Text>
      </View>
    )
  }

  return (
    <React.Fragment>
      {isShow ? (
        <View pointerEvents={info.needCover ? "auto" : "none"} style={$modalUniversality}>
          {getComponent()}
        </View>
      ) : null}
      <OptionalVideoPlayModal
        modalShow={optionalVideoPlay.isShow}
        contentData={optionalVideoPlay.data}
        onBack={() => {
          setOptionalVideoPlay({ ...optionalVideoPlay, isShow: false })
        }}
        translateXValue={optionalVideoPlay.translateXValue}
      />
      <ControlVideoModal
        isShow={controlVideo.isShow}
        closeModal={() => {
          setControlVideo({ ...controlVideo, isShow: false })
        }}
        data={controlVideo.data}
      />
      <VideoModal
        isShow={videoState.isShow}
        isPercentageWH={videoState.isPercentageWH}
        closeModal={() => {
          setVideoState({ ...videoState, isShow: false })
          videoState.clearVideo()
        }}
        onBack={() => {
          setVideoState({ ...videoState, isShow: false })
        }}
        data={{
          src: videoState.url,
          poster: videoState.poster,
          seek: videoState.seek,
          info: videoState.info,
        }}
        style={videoState.style}
        isLoop={videoState.isLoop}
        onFinish={videoState.onFinish}
        isPaused={videoState.isPaused}
        handlePaused={videoState.handlePaused}
        isVertical={videoState.isVertical}
      />
      {AlertComponent()}
      <GiftModal
        modalShow={showGiftModal}
        contentData={gift.id}
        onBack={() => {
          setShowGiftModal(false)
          setGift({
            ...gift,
            id: null,
          })
          gift.callback && gift.callback()
        }}
      />
    </React.Fragment>
  )
}

export const Toast = ({ msg }) => {
  const $container: ViewStyle = {
    borderRadius: fitSize(5),
    backgroundColor: colors.palette.neutral600,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: fitSize(20),
    paddingVertical: fitSize(15),
    alignSelf: "center",
  }

  const $text: TextStyle = {
    color: colors.palette.neutral100,
    fontSize: fitSize(18),
  }

  return (
    <View style={$container}>
      <Text style={$text}>{msg || ""}</Text>
    </View>
  )
}

const $modalUniversality: ViewStyle = {
  margin: 0,
  width: "100%",
  height: "100%",
  position: "absolute",
  zIndex: 10000,
  justifyContent: "center",
  alignItems: "center",
}

export const GlobalModal = React.forwardRef(GlobalModalref)
