import { Platform } from "react-native"
import { GToast, modalInfoType } from "../navigators"
import { showError, showInfo } from "../utils/showUtil"
import { useEffect, useRef, useState } from "react"

import { useStores } from "../models"
import { api } from "../services/api"
import * as UUID from "uuid"
import { DeviceInfo, InAppPurchases } from "../utils/CompatibleUtils"
import { loadString, saveString } from "../utils/storage"
import { AppInfo } from "../config/AppInfo"
import _ from "lodash"

export const isWebView = function () {
  if (Platform.OS !== "web") {
    return false
  }
  //判断是否是微信
  var ua = navigator.userAgent.toLowerCase()
  const apps = ["micromessenger", "weibo", "lark", "weixin"]
  for (let i = 0; i < apps.length; i++) {
    if (ua.includes(apps[i])) {
      return true
    }
  }
  return false
}
export function useInit() {
  const { accountStore } = useStores()
  const [isNetSettle, setisNetSettle] = useState(false)
  const token = accountStore.token
  const stateListener = useRef(null)
  const [isStop, setIsStop] = useState(Platform.OS === "android")
  const [modalInfo, setModalInfo] = useState<modalInfoType>()
  const [modalSystemShow, setModalSystemShow] = useState(false)
  const [modalType, setModalType] = useState<0 | 1>()
  const [loginStack, setLoginStack] = useState<"LoginScreen" | "PhoneLogin" | "Root" | undefined>()

  //token变化时运行的初始化
  useEffect(() => {
    if (isNetSettle) {
      if (token) {
        doTokenChangeInit()
      } else {
        setLoginStack("PhoneLogin")
        accountStore.changeOk(true)
      }
    }
    console.log("isNetSettle", isNetSettle)
  }, [token, isNetSettle])

  //只运行一次的初始化
  useEffect(() => {
    doRoutineInit()
    return () => {
      stateListener.current && stateListener.current?.remove()
    }
  }, [])

  const confirmAppleItem = async (purchase: InAppPurchases.InAppPurchase): Promise<boolean> => {
    const res = await api.createApple("", "")
    if (res.kind === "ok") {
      return res.data
    } else {
      showError(res)
      return false
    }
  }

  const applePayInit = () => {
    // 初始化
    InAppPurchases.connectAsync()
    console.log("connectAsync")
    // 设置购买监听器
    InAppPurchases.setPurchaseListener(({ responseCode, results, errorCode }) => {
      // 购买成功
      if (responseCode === InAppPurchases.IAPResponseCode.OK) {
        results.forEach(async (purchase) => {
          if (!purchase.acknowledged) {
            console.log(`Successfully purchased ${purchase}`, purchase)
            // 监听支付完成后执行操作
            // const res = await confirmAppleItem(purchase)
            // if (res) {
            showInfo("购买成功")
            accountStore.getUserInfo()
            InAppPurchases.finishTransactionAsync(purchase, true)

            // } else {
            //   showError("服务器无法确认此次内购支付")
            // }
          }
        })
      } else if (responseCode === InAppPurchases.IAPResponseCode.USER_CANCELED) {
        showInfo("内购支付取消了")
      } else if (responseCode === InAppPurchases.IAPResponseCode.DEFERRED) {
        showInfo("User does not have permissions to buy but requested parental approval")
      } else {
        showError("内购支付错误:" + errorCode)
      }
    })
  }

  const fetchTokenAuthentication = async () => {
    // if (token) {
    //   const res = await api.tokenAuthentication()
    //   if (res.kind === "ok") {
    //     setLoginStack("Root")
    //   } else {
    //     setLoginStack("LoginScreen")
    //   }
    // }
    if (accountStore.manualExit === true) {
      setLoginStack("LoginScreen")
    } else {
      setLoginStack("Root")
    }
    accountStore.changeOk(true)
  }

  const doRoutineInit = async () => {
    try {
      accountStore.changeOk(false)
      // accountStore.setSysCfg({ ...env[arg] })
      // await Promise.all([])
      setisNetSettle(true)
      getSysCfg()
      if (Platform.OS !== "web") fetchStopServiceMessage()
    } catch (err) {
      setisNetSettle(false)
      showError(err)
    }
  }

  const getSysCfg = async () => {
    const UID = await loadString("MC-UUID")
    if (!!UID) {
      api.newApisauce.setHeader("MC-UUID", UID)
    } else {
      if (Platform.OS === "web") {
        const uuid = UUID.v4()
        api.newApisauce.setHeader("MC-UUID", uuid)
        saveString("MC-UUID", uuid)
      } else {
        DeviceInfo.getUniqueId().then((uniqueId) => {
          api.newApisauce.setHeader("MC-UUID", uniqueId)
          saveString("MC-UUID", uniqueId)
        })
      }
    }
  }

  const fetchStopServiceMessage = async () => {
    try {
      const res = await api.monitoringVersion(`v${AppInfo.version}`, Platform.OS)
      if (res.kind === "ok") {
        if (_.isEmpty(res.data?.versions)) {
          setIsStop(false)
        } else {
          if (res.data.versions.forcibly === 1) {
            setIsStop(true)
            setModalSystemShow(true)
            setModalInfo({
              ...res.data.versions,
            })
            setModalType(1)
          } else {
            setIsStop(true)
            setModalSystemShow(true)
            setModalInfo({
              ...res.data.versions,
            })
            setModalType(0)
          }
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const doTokenChangeInit = async () => {
    accountStore.changeOk(false)
    try {
      api.newApisauce.setHeader("Authorization", token)
      await fetchTokenAuthentication()
      if (accountStore.token) {
        // Promise.all([])
        //   .then(async () => {

        //   })
        //   .catch((err) => {})
        accountStore.changeOk(true)
        if (Platform.OS === "ios") {
          applePayInit()
        }
      } else {
        GToast.current.hideToast()
      }
    } catch (err) {
      console.log("登录失败", err)
      showError(err)
      accountStore.clearUserInfo()
    }
  }

  return {
    isNetSettle,
    loginStack,
    stopControl: {
      isStop,
      setIsStop,
      modalSystemShow,
      modalInfo,
      modalType,
      setModalSystemShow,
    },
  }
}
