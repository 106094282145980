import { PhoneNumberUtil } from "google-libphonenumber"

export const phoneUtil = PhoneNumberUtil.getInstance()

/**
 * 辅助函数：验证电话号码是否正确 确保是一个全球性电话号码
 * @param phoneNumber string
 * @returns 返回 true 或 false
 */
export function isValidPhoneNumber(phoneNumber: string) {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, "CN")
    return phoneUtil.isValidNumber(number)
  } catch (error) {
    return false
  }
}

/**
 * 辅助函数：验证日期是否正确 确保日期存在并且格式为 DD/MM/YYYY
 * @param dateBirth string 辅助函数：验证日期是否正确
 * @returns 返回 true 或 false
 */
export function isValidDateBirth(dateBirth: string) {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/
  return dateRegex.test(dateBirth)
}

/**
 * 辅助函数：验证短信长度是不是 6 位
 * @param code string
 * @returns 返回 true 或 false
 * @description 用于验证短信验证码长度是否为 6 位
 */
export function isValidCode(code: string) {
  const codeRegex = /^\d{6}$/
  return codeRegex.test(code)
}

/**
 * 辅助函数：必须是6-20个字符之间，包含英文字母至少一个大写字母一个小写字母+数字+标点符号（除空格）
 * @param password string
 * @returns 返回 true 或 false
 * @description 用于验证密码长度是否为 6-20 位
 */
export function isValidPassword(password: string) {
  if (password.length < 6 || password.length > 20) return false
  let hasUppercase = false
  let hasLowercase = false
  let hasNumber = false
  let hasPunctuation = false
  for (let i = 0; i < password.length; i++) {
    const char = password[i]
    if (/[A-Z]/.test(char)) {
      hasUppercase = true
    } else if (/[a-z]/.test(char)) {
      hasLowercase = true
    } else if (/\d/.test(char)) {
      hasNumber = true
    } else if (/[^\w\s]/.test(char)) {
      hasPunctuation = true
    }
  }
  return hasUppercase && hasLowercase && hasNumber && hasPunctuation
}

/**
 * 辅助函数：验证身份证真实性
 * @param idCode string
 * @returns 返回 true 或 false
 */
export function isValidIdCard(idCode: string) {
  const weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  const check_code = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"]

  const code = idCode + ""
  const last = idCode[17]
  const seventeen = code.substring(0, 17)

  let num = 0
  for (let i = 0; i < seventeen.length; i++) {
    num += parseInt(seventeen[i]) * weight_factor[i]
  }

  const resisue = num % 11
  const last_no = check_code[resisue]

  const idCard_patter =
    /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/

  const format = idCard_patter.test(idCode)

  return last === last_no && format
}

interface ParsedUrl {
  route: string
  params: Record<string, string | string[]>
}
/**
 * banner参数解析
 * @param url /MessageDetails?id=16
 * @returns 返回路由名称和参数内容
 */
export function parseUrl(url: string): ParsedUrl | null {
  const regex = /^https?:\/\/[^\/]+(\/[^\?]+)\??(.*)$/
  const match = url.match(regex)

  if (!match) {
    console.error("Invalid URL format")
    return null
  }

  const route = match[1].replace(/^\//, "")

  const queryParamsString = match[2]

  const queryParams: Record<string, string | string[]> = {}
  queryParamsString.split("&").forEach((param) => {
    const [name, value] = param.split("=")
    queryParams[name] = decodeURIComponent(value)
  })

  return {
    route: route,
    params: queryParams,
  }
}

export function parseRouterURL(url) {
  if (url.startsWith("router://")) {
    // 去掉"router://"
    url = url.slice("router://".length)

    // 使用 '?' 分割路由和参数
    const [route, queryString] = url.split("?")

    // 提取路由名称
    const routeName = route

    // 创建一个对象来存储参数
    const params = {}

    // 如果有参数，则提取参数并将其存储在对象中
    if (queryString) {
      const pairs = queryString.split("&")
      pairs.forEach((pair) => {
        const [key, value] = pair.split("=")
        params[key] = value
      })
    }

    console.log("Route名称:", routeName)
    console.log("参数:", params)
    return {
      route: route,
      params: params,
    }
  } else {
    console.log("不是有效的router URL")
  }
}
