import React, { useImperativeHandle, forwardRef, useEffect, Fragment, useState } from "react"
import { StyleSheet, View } from "react-native"
import { fitSize, colors } from "../../../theme"
import Drawer from "react-modern-drawer"
import "react-modern-drawer/dist/index.css"
import { useStores } from "../../../models"
import { AssetListContent, typeState } from "./AssetListContent"

interface AssetDrawerProps {
  onCallback: (value: typeState) => void
  children?: any
  filtrationData?: number[]
}

interface AssetDrawerRef {
  toggleDrawer: (value: boolean) => void
  scopeLimitation: (
    resourceId: number,
    rarityId: number,
    seriesId: number,
    assetNumber: string,
    compositeFuelId: number,
  ) => void
  isOpen: boolean
}

export const AssetDrawer = forwardRef<AssetDrawerRef, AssetDrawerProps>((props, ref) => {
  const [open, setOpen] = useState(false)
  const { accountStore } = useStores()
  const { onCallback, children, filtrationData } = props
  const [scope, setScope] = useState({
    resourceId: 0,
    rarityId: 0,
    seriesId: 0,
    assetNumber: "",
    compositeFuelId: 0,
  })

  const toggleDrawer = () => {
    setOpen((prevState) => !prevState)
  }

  useImperativeHandle(ref, () => ({
    toggleDrawer: (value) => {
      setOpen(value)
    },
    scopeLimitation: (resourceId, rarityId, seriesId, assetNumber, compositeFuelId) => {
      setScope({ resourceId, rarityId, seriesId, assetNumber, compositeFuelId })
    },
    isOpen: open,
  }))

  useEffect(() => {
    if (open) {
      accountStore.setShowBottomBar(false)
    } else {
      accountStore.setShowBottomBar(true)
      setScope({
        resourceId: 0,
        rarityId: 0,
        seriesId: 0,
        assetNumber: "",
        compositeFuelId: 0,
      })
    }
    return () => {
      accountStore.setShowBottomBar(true)
    }
  }, [open])

  return (
    <Fragment>
      <Drawer
        open={open}
        onClose={toggleDrawer}
        direction="right"
        style={style.drawerStyle}
        size={fitSize(336)}
        overlayColor={colors.palette.neutral100 + "66"}
      >
        <AssetListContent
          clearModal={(value: typeState) => {
            setOpen(false)
            onCallback(value)
          }}
          scope={scope}
          isOpen={open}
          filtrationData={filtrationData}
        />
      </Drawer>
      <View
        style={{
          paddingHorizontal: fitSize(14),
          flex: 1,
          backgroundColor: colors.palette.neutral900,
        }}
      >
        {children}
      </View>
    </Fragment>
  )
})

const style = StyleSheet.create({
  drawerStyle: {
    backgroundColor: colors.palette.neutral900,
    flex: 1,
    width: fitSize(336),
  },
  overlayStyle: {
    backgroundColor: colors.palette.neutral100 + "66",
  },
})
