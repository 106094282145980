import { Instance, SnapshotOut, flow, getParent, types } from "mobx-state-tree"

export const settingModel = types.model("settingStore").props({
  language: "zh",
})

type settingStoreType = Instance<typeof settingModel>
export interface settingStore extends settingStoreType {}
type settingStoreSnapshotType = SnapshotOut<typeof settingModel>
export interface settingStoreSnapshot extends settingStoreSnapshotType {}
export const createsettingStoreDefaultModel = () => types.optional(settingModel, {})
