import { colors, fitSize } from "../../theme"
import { Screen, Sheader } from "../../components"
import { StyleSheet, View, useWindowDimensions, Platform, ScrollView } from "react-native"
import React, { useEffect, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import RenderHTML from "react-native-render-html"
import { fontName } from "../../theme/fonts"
import { GToast } from "../../navigators"
import { NoticeListItem, api } from "../../services/api"

interface MessageDetailsProps extends RootStackNavProps<"MessageDetails"> {}

export const MessageDetails: React.FC<MessageDetailsProps> = observer(({ navigation, route }) => {
  const { width, height } = useWindowDimensions()
  const [data, setData] = useState<NoticeListItem>()

  const fetchData = async () => {
    try {
      const res = await api.getNoticeInfo(route.params.id)
      if (res.kind === "ok") {
        setData(res.data)
        if (res.data.isRead === 0) {
          await api.getReadNotice(route.params.id)
        }
        if (Platform.OS === "web") {
          window.document.title = res.data?.noticeTitle ?? ""
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={styles.container}>
      <Sheader
        title={data?.noticeTitle}
        textStyle={styles.textStyle}
        leftStyle={styles.leftIcon}
        style={styles.header}
        isNotSafe
      />
      <ScrollView style={[styles.content, { display: data ? "flex" : "none" }]}>
        {/* <View style={styles.textBox}>
          <Text style={styles.titleText}>{data?.title ?? ""}</Text>
        </View> */}
        {/* <View style={styles.timeBox}>
          <Text style={styles.timeText}>
            发布时间：{dayjs(Number(data?.push_time) * 1000).format("YYYY-MM-DD HH:mm") ?? ""}
          </Text>
        </View> */}
        {Platform.OS === "web" ? (
          <div
            style={styles.modalText}
            dangerouslySetInnerHTML={{
              __html:
                `${data?.noticeContent}`.replace(
                  /<img/g,
                  '<img style="max-width: 100%;margin-top:20px;margin-bottom:20px"',
                ) ?? "",
            }}
          />
        ) : (
          <RenderHTML
            contentWidth={width}
            source={{
              html:
                data?.noticeContent.replace(
                  /<img/g,
                  '<img style="max-width: 100%;margin-top:20px;margin-bottom:20px"',
                ) ?? "",
            }}
            baseStyle={{
              color: "#999999",
              fontSize: fitSize(13),
              fontFamily: fontName.PingFang_SC,
              fontWeight: "400",
            }}
          />
        )}
      </ScrollView>
    </Screen>
  )
})

const styles = StyleSheet.create({
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  content: {
    paddingHorizontal: fitSize(14),
    marginTop: fitSize(10),
  },
  titleText: {
    fontSize: fitSize(20),
    fontWeight: "900",
    color: "#ffffff",
  },
  textBox: {
    marginTop: fitSize(10),
  },
  timeBox: {
    marginTop: fitSize(20),
    marginBottom: fitSize(20),
    alignSelf: "flex-end",
  },
  timeText: {
    fontSize: fitSize(15),
    color: "#9a9a9a",
  },
  modalText: {
    width: "100%",
    flexWrap: "wrap",
    color: "#999999",
    fontSize: fitSize(13),
    fontFamily: fontName.PingFang_SC,
    fontWeight: "400",
  },
})
