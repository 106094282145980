import React, { useEffect, useState } from "react"
import {
  Platform,
  StyleSheet,
  View,
  useWindowDimensions,
  Text,
  TextInput,
  BackHandler,
} from "react-native"
import Modal from "react-native-modal/dist/modal"
import { colors, deviceHeight, fitSize } from "../theme"
import { Touchable, outlineStyle } from "../components"
import { SizeImage } from "../components/SizeImage"
import { Images } from "../theme"
import { translate } from "../i18n"
import { fontName } from "../theme/fonts"
import { api } from "../services/api"
import { GToast } from "../navigators"
import { showInfo, showLoad } from "../utils/showUtil"

interface SystemInfoModalProps {
  modalShow: boolean
  contentData: number
  onBack?: () => void
}

export const GiftModal: React.FC<SystemInfoModalProps> = ({ modalShow, contentData, onBack }) => {
  const { height } = useWindowDimensions()
  const compatibleHeight = Platform.OS === "web" ? height : deviceHeight
  const [address, setAddress] = useState("")

  const onGift = async () => {
    onBack()
    showLoad()
    const res = await api.transferredAssets(contentData, address)
    if (res.kind === "ok") {
      onBack()
      showInfo("赠送成功", "OK")
    } else {
      GToast.current.handleErrorResponse(res)
    }
  }

  useEffect(() => {
    const backAction = () => {
      if (!modalShow) {
        return false
      } else {
        return true
      }
    }
    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction)
    return () => backHandler?.remove()
  }, [modalShow])

  return (
    <Modal
      hasBackdrop={true}
      coverScreen={true}
      isVisible={modalShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={styles.modal}
      backdropOpacity={0.8}
      onBackButtonPress={() => {}}
      onBackdropPress={() => {}}
      swipeDirection={"down"}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
    >
      <View style={[styles.container]}>
        <Touchable onPress={onBack}>
          <SizeImage source={Images.components.modal_clear} style={styles.clearIcon} />
        </Touchable>
        <SizeImage source={Images.components.gift_modal} style={styles.bgImg}>
          <TextInput
            underlineColorAndroid={colors.transparent}
            textAlignVertical="top"
            style={[styles.input, outlineStyle]}
            placeholder={translate("common.inputAddress")}
            placeholderTextColor={colors.palette.neutral40}
            value={address}
            onChangeText={(text) => {
              setAddress(text)
            }}
          />
        </SizeImage>
        <Touchable style={styles.giftBtn} onPress={onGift}>
          <Text style={styles.giftText}>赠送</Text>
        </Touchable>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  giftText: {
    fontSize: fitSize(14),
    color: colors.palette.neutral30,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  giftBtn: {
    width: fitSize(136),
    height: fitSize(35),
    backgroundColor: colors.palette.neutral100,
    marginTop: fitSize(30),
    borderRadius: fitSize(4),
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: fitSize(276),
    height: fitSize(322),
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
  },
  clearIcon: {
    width: fitSize(14),
    height: fitSize(14),
    tintColor: colors.palette.neutral20,
    marginLeft: fitSize(300),
  },
  input: {
    width: fitSize(259),
    height: fitSize(40),
    backgroundColor: colors.palette.neutral100 + "33",
    marginTop: fitSize(50),
    paddingHorizontal: fitSize(10),
    color: colors.palette.neutral40,
  },
  modal: {
    margin: 0,
    zIndex: 9999,
    overflow: "hidden",
  },
  bgImg: {
    width: fitSize(307),
    height: fitSize(150),
    alignItems: "center",
    justifyContent: "center",
  },
})
