import { View, StyleSheet, Text, Image, Platform, Dimensions } from "react-native"
import React, { Fragment, useEffect, useReducer, useRef, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { Images, colors, fitSize } from "../../theme"
import { Screen, Touchable, TouchableDeep } from "../../components"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { GivingCardItem, SynthesisDetailsInfo, api } from "../../services/api"
import { GToast } from "../../navigators"
import { useAutoList } from "../../hooks/useAutoList"
import { getActivityStartRestTimeDays } from "./components/CompItem"
import { SizeImage } from "../../components/SizeImage"
import { useSecond } from "../../hooks/useSecond"
import dayjs from "dayjs"
import { CardItem } from "./components/CardItem"
import { AssetDrawer, AssetDrawerRef } from "./components/AssetDrawer"
import { HorizontalFlatList } from "@idiosync/horizontal-flatlist"
import { typeState } from "./components/AssetListContent"
import { MultiPlatformVideoPlayer } from "../../components/MultiPlatformVideoPlayer"
import { useFocusEffect } from "@react-navigation/native"

interface ChallengeDetailsProps extends RootStackNavProps<"ChallengeDetails"> {}

const initialNumerical = {
  haveAsset: [],
  assetId: [],
  resourceId: [],
  assetNumber: [],
}

type numericalState = {
  haveAsset: boolean[]
  assetId: string[]
  resourceId: number[]
  assetNumber: string[]
}

type numericalAction = {
  type: "HAVE_ASSET" | "ASSET_ID" | "RESOURCE_ID" | "ASSET_NUMBER"
  value: boolean[] | string[] | number[]
}

const numericalReducer = (state: numericalState, action: numericalAction) => {
  const { type, value } = action
  switch (type) {
    case "HAVE_ASSET":
      return { ...state, haveAsset: value }
    case "ASSET_ID":
      return { ...state, assetId: value }
    case "RESOURCE_ID":
      return { ...state, resourceId: value }
    case "ASSET_NUMBER":
      return { ...state, assetNumber: value }
    default:
      throw new Error()
  }
}

const PL = 50
export const ChallengeDetails: React.FC<ChallengeDetailsProps> = observer(
  ({ navigation, route }) => {
    const { id } = route.params
    const { currentTime } = useSecond()
    const [info, setInfo] = useState<SynthesisDetailsInfo>()
    const [data, setData] = useState([])
    const [numerical, setNumerical] = useReducer(numericalReducer, initialNumerical)
    const drawerRef = useRef<AssetDrawerRef>(null)
    const videoRef = useRef(null)
    const [videoPlay, setVideoPlay] = useState(true)
    const openId = useRef()
    const [filtrData, setFiltrData] = useState([])

    const fetchDetails = async () => {
      try {
        const res = await api.getSynthesisDetails(id)
        if (res.kind === "ok") {
          setInfo(res.data)
          if (Platform.OS === "web") window.document.title = res.data.name
        } else {
          GToast.current.handleErrorResponse(res)
        }
      } catch (error) {
        console.log("error", error)
      }
    }

    useFocusEffect(
      React.useCallback(() => {
        fetchDetails()
      }, [route]),
    )

    const fetchGetGivingCardList = async (lastId?: number, size = PL) => {
      try {
        const res = await api.getGivingCardList(id, lastId, size)
        if (res.kind === "ok") {
          return res.data
        } else {
          GToast.current.handleErrorResponse(res)
        }
      } catch (error) {
        console.log("error", error)
      }
    }

    const [fetchList] = useAutoList(
      {
        fetchData: fetchGetGivingCardList,
        pageLimit: PL,
      },
      [],
    )

    const effectData = () => {
      let arr = []
      const dataList = fetchList?.data as unknown as GivingCardItem[] | undefined
      setNumerical({
        type: "HAVE_ASSET",
        value: dataList?.map((item) => item.collectCount >= item.count),
      })
      setNumerical({
        type: "ASSET_ID",
        value: dataList?.map((item) => item.id),
      })
      setNumerical({
        type: "RESOURCE_ID",
        value: dataList?.map((item) => item.resourceId),
      })
      setNumerical({
        type: "ASSET_NUMBER",
        value: dataList?.map((item) => item.assetNumber),
      })
      // arr = fetchList?.data.reduce((accumulator, item, index) => {
      //   const groupIndex = Math.floor(index / 2)
      //   if (!accumulator[groupIndex]) {
      //     accumulator[groupIndex] = []
      //   }
      //   accumulator[groupIndex].push(item)
      //   return accumulator
      // }, [])
      setData(fetchList?.data)
    }

    useEffect(() => {
      if (fetchList?.data.length > 0) {
        effectData()
      }
    }, [fetchList?.data])

    const initiateSynthesis = async () => {
      try {
        const compFuelAsset = data.map((item) => {
          return {
            compFuelId: item?.id,
            resourceId: item?.resourceId,
            assetNumber: item?.assetNumber,
          }
        })
        const res = await api.userSynthesis({ compositeId: id, compFuelAsset: compFuelAsset })
        if (res.kind === "ok") {
          navigation.replace("ComposeDetails", { id: res.data.id })
        } else {
          GToast.current.handleErrorResponse(res)
        }
      } catch (error) {
        console.log("error", error)
      }
    }

    const onCallback = (value: typeState) => {
      if (value) {
        const objAssetNumber = value?.assetNumber
        const objResourceId = value?.resourceId
        const objResourceName = value?.name
        const objResourceImage = value?.image
        const objResourceNumber = value?.id
        const updatedArr = data.map((item) => {
          if (item.id === openId.current) {
            return {
              ...item,
              assetNumber: objAssetNumber,
              resourceId: objResourceId,
              playerName: objResourceName,
              image: objResourceImage,
              id: objResourceNumber,
            }
          }
          return item
        })
        setData(updatedArr)
      }
    }

    return (
      <AssetDrawer
        ref={drawerRef}
        onCallback={onCallback}
        filtrationData={data.map((i, _) => {
          return i?.id
        })}
      >
        <Screen preset="scroll" safeAreaEdges={["top", "bottom"]}>
          <View style={style.header}>
            <Touchable onPress={() => navigation.goBack()}>
              <Image source={Images.components.header_back} style={style.leftIcon} />
            </Touchable>
          </View>
          <View style={style.topContainer}>
            {info?.revealUrl?.type === 0 ? (
              <SizeImage source={{ uri: info?.revealUrl.url }} style={style.image}>
                <View style={style.countdownBox}>
                  <Image source={Images.components.white_time} style={style.countdownIcon} />
                  <Text style={style.countdownText}>
                    {dayjs().isBefore(info?.startTime)
                      ? translate("screen.challengeDetails.countdownStart", {
                          time: getActivityStartRestTimeDays(
                            dayjs(info?.endTime).diff(currentTime),
                          ),
                        })
                      : translate("screen.challengeDetails.countdownEnd", {
                          time: getActivityStartRestTimeDays(
                            dayjs(info?.endTime).diff(currentTime),
                          ),
                        })}
                  </Text>
                </View>
              </SizeImage>
            ) : (
              <Fragment>
                {videoPlay ? (
                  <TouchableDeep
                    onPress={() => {
                      setVideoPlay(false)
                      videoRef.current.toggleVideo()
                    }}
                    style={[style.videoPlayBox]}
                  >
                    <Image
                      source={Images.screen.home.time_video_pause}
                      style={style.timeVideoPause}
                    />
                  </TouchableDeep>
                ) : null}
                {info?.revealUrl?.url && (
                  <TouchableDeep
                    onPress={() => {
                      setVideoPlay(true)
                      videoRef.current.toggleVideo()
                    }}
                    disabled={videoPlay}
                  >
                    {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
                      <MultiPlatformVideoPlayer
                        uri={info?.revealUrl?.url}
                        style={{
                          width: fitSize(327),
                          height: fitSize(183),
                          overflow: "hidden",
                        }}
                        h={fitSize(183)}
                        w={fitSize(327)}
                        isPaused={videoPlay}
                        poster={""}
                        loop={false}
                        index={0}
                        ref={videoRef}
                      />
                    ) : null}
                  </TouchableDeep>
                )}
              </Fragment>
            )}
            <View style={style.topContent}>
              <View style={style.nameInfo}>
                <Text style={style.nameText} numberOfLines={1}>
                  {info?.name}
                </Text>
                <View style={style.remainingBox}>
                  <Image source={Images.components.card} style={style.cardIcon} />
                  <Text style={style.remainingText}>
                    {translate("screen.challengeDetails.remaining", {
                      remaining: info?.remainder ? info?.remainder : 0,
                    })}
                  </Text>
                </View>
              </View>
              {info?.hot === 1 ? (
                <View style={style.typeLabelBox}>
                  <SizeImage source={Images.screen.comp.hot} style={style.itemTypeIcon} />
                  <Text style={style.itemLabel}>{translate("screen.compScreen.hotText")}</Text>
                </View>
              ) : null}
              <Text style={style.itemDescription} numberOfLines={3}>
                {info?.description}
              </Text>
            </View>
          </View>
          {data.length ? (
            <View style={style.cardList}>
              <Text style={style.cardTitle}>{translate("screen.challengeDetails.payOut")}</Text>
              <View
                style={[
                  style.cardListContent,
                  { height: data.length > 3 ? fitSize(368) : fitSize(178) },
                ]}
              >
                <HorizontalFlatList
                  {...fetchList}
                  bounces={false}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  data={data}
                  numRows={data.length > 3 ? 2 : 1}
                  refreshing={false}
                  keyExtractor={(item, index) => index + ""}
                  renderItem={({ item }) => {
                    return (
                      <CardItem
                        item={item}
                        openDrawer={(
                          resourceId,
                          rarityId,
                          seriesId,
                          assetNumber,
                          compositeFuelId,
                        ) => {
                          drawerRef.current.toggleDrawer(true)
                          drawerRef.current.scopeLimitation(
                            resourceId,
                            rarityId,
                            seriesId,
                            assetNumber,
                            compositeFuelId,
                          )
                          openId.current = item?.id
                        }}
                      />
                    )
                  }}
                />
              </View>
            </View>
          ) : null}
          <Touchable
            style={style.btn}
            disabled={numerical.haveAsset.some((value) => value === false)}
            onPress={initiateSynthesis}
          >
            <Text style={style.statusText}>{info?.statusText}</Text>
          </Touchable>
          <View style={style.ruleBox}>
            <Text style={style.ruleTitle}>{translate("screen.challengeDetails.ruleTitle")}</Text>
            <View style={style.titleWarpContent}>
              <Text style={style.titleWarpContentText}>{info?.rule}</Text>
            </View>
          </View>
          <View style={style.tipBox}>
            <Text style={style.ruleTitle}>{translate("screen.challengeDetails.tipTitle")}</Text>
            <View style={style.titleWarpContent}>
              <Image source={Images.components.triangular_warning} style={style.tip} />
              <Text style={style.titleWarpContentText}>{info?.note}</Text>
            </View>
          </View>
        </Screen>
      </AssetDrawer>
    )
  },
)

const style = StyleSheet.create({
  videoPlayBox: {
    zIndex: 2147483647,
    position: "absolute",
    left: fitSize(327) / 2 - fitSize(10),
    top: fitSize(80),
  },
  timeVideoPause: {
    width: fitSize(36),
    height: fitSize(36),
  },
  tip: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  tipBox: {
    width: "100%",
    flexDirection: "column",
    marginTop: fitSize(20),
    marginBottom: fitSize(100),
  },
  ruleTitle: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginBottom: fitSize(14),
  },
  titleWarpContentText: {
    color: colors.palette.neutral50,
    fontSize: fitSize(12),
    fontWeight: "400",
  },
  titleWarpContent: {
    width: "100%",
    padding: fitSize(10),
    borderRadius: fitSize(5),
    borderWidth: 1,
    borderColor: colors.palette.neutral30,
    minHeight: fitSize(39),
    flexDirection: "row",
    alignItems: "center",
  },
  ruleBox: {
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    width: fitSize(196),
    height: fitSize(35),
    alignSelf: "center",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.palette.neutral100,
    borderRadius: fitSize(4),
    marginTop: fitSize(40),
    marginBottom: fitSize(30),
  },
  statusText: {
    fontSize: fitSize(14),
    color: colors.palette.neutral30,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    textAlign: "center",
  },
  cardListContent: {
    width: "100%",
  },
  cardTitle: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginBottom: fitSize(20),
  },
  cardList: {
    width: "100%",
    marginTop: fitSize(40),
    flexDirection: "column",
  },
  itemDescription: {
    fontSize: fitSize(14),
    color: colors.palette.neutral20,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(10),
  },
  typeLabelBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginTop: fitSize(10),
  },
  itemTypeIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  itemLabel: {
    fontSize: fitSize(11),
    color: colors.palette.neutral20,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
  },
  remainingText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
  },
  cardIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  remainingBox: {
    minWidth: fitSize(90),
    height: fitSize(26),
    borderRadius: fitSize(5),
    backgroundColor: colors.palette.neutral30 + "80",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: fitSize(4),
    marginRight: fitSize(3),
  },
  nameText: {
    fontSize: fitSize(24),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
    maxWidth: fitSize(200),
  },
  nameInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  topContent: {
    flexGrow: 1,
    paddingTop: fitSize(24),
    flexDirection: "column",
  },
  countdownText: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
  },
  countdownIcon: {
    width: fitSize(15),
    height: fitSize(15),
    marginRight: fitSize(6),
  },
  countdownBox: {
    width: fitSize(130),
    height: fitSize(17),
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
    right: fitSize(0),
    top: fitSize(10),
  },
  image: {
    width: "100%",
    height: fitSize(183),
  },
  topContainer: {
    width: "100%",
    maxHeight: fitSize(358),
    flexDirection: "column",
    backgroundColor: "#222127",
    borderRadius: fitSize(10),
    marginTop: fitSize(10),
    padding: fitSize(10),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
    paddingHorizontal: fitSize(14),
    height: Dimensions.get("window").height,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    height: fitSize(44),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
})
