import { Keyboard, Platform, TouchableWithoutFeedback, View } from "react-native"

import React from "react"

const DismissKeyboardHOC = (Comp: React.ComponentType<any | string>) => {
  return ({ ...props }) => (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <Comp {...props}>{props.children}</Comp>
    </TouchableWithoutFeedback>
  )
}
export const DismissKeyboardView = Platform.OS === "web" ? View : DismissKeyboardHOC(View)
