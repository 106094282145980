const env = {
  prod: {
    BASE_URL: "https://api.cbatime.com",
    MASS_BASE_URL: "https://app.cbatime.com",
    DID_URL: "https://maas.tianhe32.cn",
    APPID: 1000013,
    pushy: {
      ios: {
        appId: 0,
        appKey: "",
      },
      android: {
        appId: 0,
        appKey: "",
      },
    },
  },
  test: {
    BASE_URL: "https://api-dev-app.cbatime.com",
    MASS_BASE_URL: "https://app.cba.ppe.cbatime.com",
    DID_URL: "http://maas-test.maas-test.tianhe32.cn",
    APPID: 1000013,
    pushy: {
      ios: {
        appId: 0,
        appKey: "",
      },
      android: {
        appId: 0,
        appKey: "",
      },
    },
  },
  ppe: {
    BASE_URL: "https://api-dev.cbatime.com",
    MASS_BASE_URL: "http://app.cba.ppe.cbatime.com",
    DID_URL: "http://maas-test.maas-test.tianhe32.cn",
    APPID: 1000013,
    pushy: {
      ios: {
        appId: 0,
        appKey: "",
      },
      android: {
        appId: 0,
        appKey: "",
      },
    },
  },
}

module.exports.env = env
