import { View, Animated, Platform, Dimensions, ViewStyle } from "react-native"
import { Images, fitSize } from "../theme"
import { useCallback, useEffect, useRef, useState } from "react"
import { AnimatedViewStyle } from "../type/ViewType"

const { width } = Dimensions.get("window")

export const WelcomeScreen = () => {
  const [translateX, setTranslateX] = useState(0)
  const [data, setData] = useState<any[]>([
    { icon: Images.common.card_epic_type },
    { icon: Images.common.card_rare_type },
    { icon: Images.common.card_sift_type },
    { icon: Images.common.card_tale_type },
  ])
  const refs = useRef(null)
  const [translation, setTranslation] = useState(new Animated.Value(translateX))

  const onScroll = useCallback((event) => {
    setTranslateX(event.nativeEvent.contentOffset.x)
  }, [])

  const $box: ViewStyle = {
    left: 0,
    position: "absolute",
    right: 0,
    top: fitSize(25),
    justifyContent: "center",
    alignItems: "center",
  }

  const $boxContainer: ViewStyle = {
    width,
    height: fitSize(257),
  }

  const $boxContent: ViewStyle = {
    width: fitSize(177),
    height: fitSize(207 - 25) * 1,
    backgroundColor: "#0f1",
  }

  useEffect(() => {
    Animated.timing(translation, {
      toValue: translateX,
      duration: 0.1,
      useNativeDriver: true,
    }).start()
  }, [translateX])

  return (
    <View>
      <Animated.ScrollView
        onScroll={onScroll}
        scrollEventThrottle={16}
        decelerationRate="fast"
        snapToInterval={width}
        horizontal
        showsHorizontalScrollIndicator={false}
        ref={(ref) => {
          refs.current = ref
        }}
      >
        {data?.map(({ item, index }) => {
          return (
            <View
              style={$boxContainer}
              key={index}
              onLayout={(event) => {
                const layout = event.nativeEvent.layout
                const arr = []
                arr[index] = layout.x
              }}
            />
          )
        })}
      </Animated.ScrollView>
      <View style={$box} pointerEvents="none">
        {data?.map((product, index) => {
          const $animatedBox: AnimatedViewStyle = {
            transform: [
              {
                translateX: translation.interpolate({
                  inputRange: [(index - 1) * width, index * width, (index + 1) * width],
                  outputRange: [width / 2, 0, -width / 2],
                }),
              },
              {
                scale: translation.interpolate({
                  inputRange: [(index - 1) * width, index * width, (index + 1) * width],
                  outputRange: [0.61, 1, 0.61],
                }),
              },
            ],
          }
          return (
            <Animated.View key={index} style={[$box, $animatedBox]}>
              <View style={$boxContent}></View>
            </Animated.View>
          )
        })}
      </View>
    </View>
  )
}
