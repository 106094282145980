import { Image, Linking, StyleSheet } from "react-native"
import React from "react"
import { Touchable } from "../../../components"
import { Images, fitSize } from "../../../theme"
import { isWebView } from "../../../hooks/useInit"

export const SaveAppBar = () => {
  return (
    <Touchable
      style={[
        style.container,
        { display: /Android/i.test(navigator.userAgent) && !isWebView() ? "flex" : "none" },
      ]}
      onPress={() => {
        Linking.openURL("https://www.cbatime.com/")
      }}
    >
      <Image source={Images.screen.home.save_app_bar} style={style.icon} />
    </Touchable>
  )
}

const style = StyleSheet.create({
  container: {
    position: "absolute",
    top: 350,
    right: 0,
    zIndex: 2147483647,
  },
  icon: { width: fitSize(114), height: fitSize(40) },
})
