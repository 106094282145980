import {
  View,
  Image,
  Platform,
  Text,
  TextInput,
  Animated,
  useWindowDimensions,
  ScrollView,
  Dimensions,
  StyleSheet,
} from "react-native"
import { colors, fitSize } from "../../theme"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models"
import { Screen, Touchable, iconRegistry, outlineStyle } from "../../components"
import { GToast } from "../../navigators"
import { Images } from "../../theme"
import { api } from "../../services/api"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { isValidPhoneNumber } from "../../utils/regexFunction"
import { textModal } from "../MineSetting/About"
import TextModal from "../../components/TextModal"
import RenderHTML from "react-native-render-html"
import { useTypeTabNavigation } from "../../navigators/useTypeNavigation"
import { MultiPlatformVideoPlayer } from "../../components/MultiPlatformVideoPlayer"
import { ProtocolPromptModal } from "./components/ProtocolPromptModal"
import { DashedLine } from "../TabStore/StoreScreen"
import { showInfo } from "../../utils/showUtil"
import { isChromeBrowser, isMIBrowser, isQQBrowser, isQuarkBrowser } from "../../utils/utils"
import { SizeImage } from "../../components/SizeImage"

interface PhoneLoginProps extends RootStackNavProps<"PhoneLogin"> {}

export const PhoneLogin: React.FC<PhoneLoginProps> = observer(({ route }) => {
  const { accountStore } = useStores()
  const [text, setText] = useState("")
  const [agree, setAgree] = useState(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>("")
  const [modalContent, setModalContent] = useState<string>("")
  const { width } = useWindowDimensions()
  const navigate = useTypeTabNavigation()
  const disabled = !isValidPhoneNumber(text)
  const submitAnimatedValue = useRef(new Animated.Value(disabled ? 0.5 : 1)).current
  const animatedValue = useRef(new Animated.Value(agree ? 1 : 0)).current
  const [isFocus, setIsFocus] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const input = useRef<TextInput>()

  const onChangeText = (text: string) => {
    setText(text)
  }

  const onSubmit = () => {
    fetchTokenAuthentication()
  }

  const switchLogin = () => {
    console.log("switchLogin")
    navigate.push("PasswordLogin")
  }

  const animatedIconTintColor = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [colors.palette.primary100, colors.palette.primary900],
  })

  const animatedCheckBtnBackground = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["transparent", colors.palette.primary500],
  })

  const animatedCheckBtnBorderWidth = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  })

  const animatedCheckBtnBorderColor = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [colors.palette.primary100, colors.palette.primary100],
  })

  const $iconTintColor = {
    tintColor: animatedIconTintColor,
  }

  const $checkBtn = {
    backgroundColor: animatedCheckBtnBackground,
    borderWidth: animatedCheckBtnBorderWidth,
    borderColor: animatedCheckBtnBorderColor,
  }

  const toggleAgree = () => {
    Animated.timing(animatedValue, {
      toValue: agree ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start()
  }

  const getAgreementAPI = async (type: 0 | 1 | 2) => {
    const res = await api.getPrivacyAgreement(type)
    if (res.kind === "ok") {
      if (res.data) {
        setModalContent(res?.data?.content)
      }
    } else {
      setModalContent("")
      showInfo(res.msg)
    }
  }

  const openTextModal = (type: textModal): void => {
    setModalVisible(true)
    if (type === textModal.agreement) {
      getAgreementAPI(0)
      setModalTitle("用户协议")
    } else if (type === textModal.privacy) {
      getAgreementAPI(1)
      setModalTitle("隐私政策")
    } else if (type === textModal.service) {
      getAgreementAPI(2)
      setModalTitle("中国移动认证服务条款")
    }
  }

  const fetchTokenAuthentication = async () => {
    const res = await api.verificationCode(text)
    if (res.kind === "ok") {
      navigate.push("VerificationCode", { phone: text })
    } else {
      showInfo(res.msg)
    }
  }

  const animatedDisabledStyle = {
    opacity: submitAnimatedValue,
  }

  const toggleDisabled = () => {
    Animated.timing(submitAnimatedValue, {
      toValue: disabled ? 0.5 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start()
  }

  useLayoutEffect(() => {
    toggleDisabled()
  }, [disabled])

  const [originalHeight, setOriginalHeight] = useState(window.innerHeight)

  if (Platform.OS === "web") {
    useEffect(() => {
      const handleResize = () => {
        const currentHeight = window.innerHeight

        if (currentHeight < originalHeight) {
          document.getElementById("footer").style.display = "none"
        } else {
          document.getElementById("footer").style.display = "flex"
        }
      }
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [originalHeight])
  }

  return (
    <Screen preset="scroll" contentContainerStyle={style.container}>
      <Sheader textStyle={style.textStyle} leftStyle={style.leftIcon} style={style.header} />
      <View style={style.content}>
        <Text style={style.title}>{translate("screen.phoneLogin.title")}</Text>
        <View style={style.body}>
          <Text style={style.phoneTip}>{translate("screen.phoneLogin.tip")}</Text>
          <View style={style.inputRow}>
            <View style={style.textCenter}>
              <Text style={style.phoneLabel}>{translate("screen.phoneBinding.phoneLabel")}</Text>
            </View>
            <TextInput
              style={[style.input, outlineStyle]}
              onChangeText={(text) => onChangeText(text)}
              value={text}
              placeholder={translate("screen.phoneBinding.inputPlaceholder")}
              placeholderTextColor={colors.palette.neutral50}
              maxLength={11}
              keyboardType={"number-pad"}
              ref={input}
            />
          </View>
          <Touchable
            onPress={() => {
              if (!agree) {
                setShowModal(true)
              } else {
                onSubmit()
              }
            }}
            disabled={disabled}
          >
            <Animated.View style={[style.submitButton, animatedDisabledStyle]}>
              <Text style={style.submitText}>{translate("screen.phoneLogin.btnText")}</Text>
            </Animated.View>
          </Touchable>
          <Touchable style={style.tipBtn} onPress={switchLogin}>
            <Text style={style.tipText}>{translate("screen.phoneLogin.passwordLogin")}</Text>
          </Touchable>
        </View>
        <View style={[style.endContainer]} id="footer">
          <View style={style.rowCenter}>
            <DashedLine color={colors.palette.neutral100} />
            <Text style={style.endText}>{translate("screen.loginScreen.thirdLogin")}</Text>
            <DashedLine color={colors.palette.neutral100} />
          </View>
          <View style={style.thirdLogin}>
            <Touchable
              style={style.rightIcon}
              onPress={() => GToast.current.showToast("暂未开放", "TOAST", 2000)}
            >
              <Image source={Images.components.wechat_white} style={style.wechatIcon} />
            </Touchable>
            <Touchable onPress={() => GToast.current.showToast("暂未开放", "TOAST", 2000)}>
              <Image source={Images.components.qq_white} style={style.qqIcon} />
            </Touchable>
          </View>
          <View style={style.protocolContainer}>
            <Touchable
              onPress={() => {
                setAgree((prev) => !prev)
                toggleAgree()
              }}
              style={{ alignSelf: "center" }}
            >
              <Animated.View style={[style.checkBox, $checkBtn]}>
                <Animated.Image
                  source={iconRegistry.check}
                  style={[style.checkboxDetail, $iconTintColor]}
                />
              </Animated.View>
            </Touchable>
            <Text style={style.tip}>{translate("screen.loginScreen.tip")}</Text>
            <Touchable onPress={() => openTextModal(textModal.agreement)}>
              <Text style={style.userProtocol}>
                {translate("screen.loginScreen.userProtocol")}和
              </Text>
            </Touchable>
            <Touchable onPress={() => openTextModal(textModal.privacy)}>
              <Text style={style.privacyAgreement}>
                {translate("screen.loginScreen.privacyAgreement")}
              </Text>
            </Touchable>
          </View>
          {/* <Touchable style={style.tipBtn}>
            <Text style={style.mobileService}>{translate("screen.loginScreen.mobileService")}</Text>
          </Touchable> */}
        </View>
      </View>
      <TextModal modalVisible={modalVisible} setModalVisible={setModalVisible} title={modalTitle}>
        <ScrollView
          style={style.modalScrollView}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {Platform.OS === "web" ? (
            <div style={style.modalText} dangerouslySetInnerHTML={{ __html: modalContent }} />
          ) : (
            <RenderHTML
              contentWidth={width}
              source={{
                html: modalContent,
              }}
            />
          )}
        </ScrollView>
      </TextModal>
      <ProtocolPromptModal
        isShow={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={() => {
          setAgree(true)
          toggleAgree()
          setShowModal(false)
          onSubmit()
        }}
      />
      <SizeImage
        isGif={true}
        source={{
          uri: "https://resource.cbatime.com/app/login.gif",
        }}
        style={{
          height: Dimensions.get("window").height,
          width: Dimensions.get("window").width,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          opacity: 0.8,
        }}
      />
      {/* {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
        <MultiPlatformVideoPlayer
          uri={"https://resource.cbatime.com/app/login.mp4"}
          isPaused={false}
          muted={true}
          h={Dimensions.get("window").height}
          w={Dimensions.get("window").width}
          loop={true}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            opacity: 0.8,
          }}
        />
      ) : null} */}
    </Screen>
  )
})

const style = StyleSheet.create({
  modalScrollView: {
    flex: 1,
    padding: fitSize(5),
  },
  modalText: {
    width: "100%",
    flexWrap: "wrap",
  },
  mobileService: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC,
    alignSelf: "center",
  },
  privacyAgreement: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
  },
  userProtocol: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
  },
  tip: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
    marginLeft: fitSize(6),
  },
  checkBox: {
    width: fitSize(12),
    height: fitSize(12),
    borderRadius: fitSize(12),
    justifyContent: "center",
    alignItems: "center",
  },
  checkboxDetail: {
    width: fitSize(10),
    height: fitSize(10),
  },
  protocolContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: fitSize(50),
    paddingLeft: fitSize(30),
  },
  rightIcon: {
    marginRight: fitSize(50),
  },
  qqIcon: {
    width: fitSize(40),
    height: fitSize(40),
  },
  wechatIcon: {
    width: fitSize(40),
    height: fitSize(40),
  },
  thirdLogin: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: fitSize(25),
  },
  endText: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
    paddingHorizontal: fitSize(14),
  },
  line: {
    height: 1,
    backgroundColor: colors.palette.neutral100,
    flexGrow: 1,
    borderBottomWidth: 1,
    borderColor: colors.palette.neutral100,
    borderStyle: Platform.select({ ios: "solid", android: "dashed", web: "dashed" }),
  },
  rowCenter: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  endContainer: {
    alignSelf: "flex-end",
    width: "100%",
    marginBottom: fitSize(47),
    flexDirection: "column",
  },
  tipText: {
    fontSize: fitSize(13),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
  },
  tipBtn: {
    alignSelf: "center",
  },
  submitText: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral30,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  submitButton: {
    width: "100%",
    height: fitSize(50),
    backgroundColor: colors.palette.primary500,
    borderRadius: fitSize(25),
    marginVertical: fitSize(14),
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  textCenter: {
    width: fitSize(64),
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  phoneLabel: {
    fontSize: fitSize(14),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  inputRow: {
    flexDirection: "row",
    width: fitSize(310),
    height: fitSize(50),
    backgroundColor: colors.palette.neutral80,
    borderRadius: fitSize(25),
    overflow: "hidden",
  },
  input: {
    height: "100%",
    flexGrow: 1,
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    padding: 0,
    backgroundColor: colors.palette.neutral80,
    borderRadius: fitSize(25),
  },
  body: {
    flex: 1,
    marginTop: fitSize(80),
  },
  title: {
    fontSize: fitSize(22),
    fontWeight: "500",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC_Semibold,
    marginTop: fitSize(8),
  },
  container: {
    flex: 1,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: "transparent",
    height: fitSize(74),
  },
  content: {
    flex: 1,
    paddingHorizontal: fitSize(32),
    zIndex: 2147483647,
    marginTop: fitSize(20),
  },
  phoneTip: {
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
    marginBottom: fitSize(10),
  },
})
