import { View, ViewStyle, Text, TextStyle, Platform } from "react-native"
import React, { FC, Fragment, forwardRef, useCallback, useEffect, useState } from "react"
import { fitSize } from "../../../theme"
import { Touchable } from "../../../components"
import { SizeImage } from "../../../components/SizeImage"
import { ImageStyle } from "react-native-fast-image"
import { Images } from "../../../theme"
import dayjs from "dayjs"
import { useAutoList } from "../../../hooks/useAutoList"
import { activityItem, api } from "../../../services/api"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { BlurView } from "expo-blur"
import { HookFlatList } from "../../../components/list/HookFlatList"
import { colors } from "../../../theme/colors"
import { useTokenAuthorization } from "../../../hooks/useTokenAuthorization"
import { GToast } from "../../../navigators"
import { DashedLine } from "../../TabStore/StoreScreen"
import { FlashList } from "@shopify/flash-list"

interface CardBagListProps {}

const PL = 50
export const CardBagList = forwardRef((props: CardBagListProps, ref) => {
  const { authorization } = useTokenAuthorization()

  React.useImperativeHandle(ref, () => ({
    onRefresh: () => {
      fetchList.onRefresh()
    },
  }))

  const fetchActiList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getActivityList(lastId, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchActiList,
      pageLimit: PL,
    },
    [],
  )

  return (
    <View style={$container}>
      <HookFlatList
        // isTransverseLoad={true}
        {...fetchList}
        horizontal={true}
        isShow={fetchList.data && fetchList.data.length > 0}
        data={fetchList?.data ?? []}
        showsHorizontalScrollIndicator={false}
        numColumns={1}
        bounces={false}
        showsVerticalScrollIndicator={false}
        estimatedItemSize={fitSize(325)}
        estimatedListSize={{ width: fitSize(325), height: fitSize(495) }}
        renderItem={({ item, index }) => {
          return <CollectionCard item={item as unknown as activityItem} key={index} />
        }}
      />
    </View>
  )
})

const $rowView: ViewStyle = {
  flexDirection: "row",
}

const $container: ViewStyle = {
  width: "100%",
  height: fitSize(495),
}

const $cardBox: ViewStyle = {
  width: fitSize(325),
  height: fitSize(495),
  marginRight: fitSize(14),
}

const $cardBag: ImageStyle = {
  width: "100%",
  height: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  overflow: "hidden",
  borderRadius: fitSize(10),
}

const $cardContent: ViewStyle = {
  height: fitSize(26),
  backgroundColor: "#01010180",
  borderRadius: fitSize(5),
  position: "absolute",
  top: 14,
  right: 12,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  paddingHorizontal: 8,
}

const $cardCountdownIcon: ImageStyle = {
  width: fitSize(11),
  height: fitSize(11),
  marginRight: fitSize(4),
}

const $cardCountdownTxt: TextStyle = {
  fontWeight: "400",
  fontSize: fitSize(11),
  color: colors.palette.neutral100,
  fontFamily: "PingFang_SC",
  flexGrow: 1,
  alignSelf: "center",
}

const $cardInfoContainer: ViewStyle = {
  width: fitSize(305),
  height: fitSize(170),
  marginBottom: fitSize(10),
  borderRadius: fitSize(10),
  flexDirection: "column",
  overflow: "hidden",
}

const $cardInfoContent: ImageStyle = {
  width: "100%",
  height: "100%",
  marginBottom: fitSize(12),
  borderRadius: fitSize(10),
  padding: fitSize(14),
  flexDirection: "column",
  overflow: "hidden",
}

const $cardInfoTopContent: ViewStyle = {
  width: "100%",
  flexDirection: "column",
}

const $cardSeriesText: TextStyle = {
  fontWeight: "400",
  fontSize: fitSize(11),
  color: "#aaaaaa",
  fontFamily: "PingFang_SC",
}

const $cardNameContainer: ViewStyle = {
  width: "100%",
  flexGrow: 1,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: fitSize(8),
}

const $cardNameText: TextStyle = {
  fontWeight: "500",
  fontSize: fitSize(20),
  color: colors.palette.neutral100,
  fontFamily: "PingFang_SC_Semibold",
}

const $cardPriceText: TextStyle = {
  fontWeight: "500",
  fontSize: fitSize(20),
  color: colors.palette.neutral100,
  fontFamily: "PingFang_SC_Semibold",
}

const $cardPriceIcon: TextStyle = {
  fontWeight: "600",
  fontSize: fitSize(10),
  color: colors.palette.neutral100,
  fontFamily: "PingFang_SC",
  alignSelf: "flex-end",
  paddingBottom: fitSize(4),
  paddingRight: fitSize(2),
}

const $cardInfoBottomContent: ViewStyle = {
  width: "100%",
  flexGrow: 1,
}

const $cardInfoText: TextStyle = {
  fontWeight: "400",
  fontSize: fitSize(11),
  color: "#aaaaaa",
  fontFamily: "PingFang_SC",
}

const $cardlimitText: TextStyle = {
  fontWeight: "400",
  fontSize: fitSize(11),
  color: colors.palette.neutral100,
  fontFamily: "PingFang_SC",
}

const $cardSubmitBtn: ViewStyle = {
  width: fitSize(273),
  height: fitSize(38),
  backgroundColor: colors.palette.neutral100,
  borderRadius: fitSize(4),
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  marginTop: fitSize(18),
}

const $cardSubmitText: TextStyle = {
  fontWeight: "500",
  fontSize: fitSize(14),
  color: colors.palette.neutral900,
  fontFamily: "PingFang_SC_Semibold",
}

const $emptyStype: ViewStyle = {
  position: "relative",
  bottom: 150,
  top: undefined,
  flex: undefined,
}

const $lineBox: ViewStyle = {
  width: "100%",
  borderBottomWidth: 1,
  borderColor: colors.palette.neutral50,
  borderStyle: Platform.select({ ios: "solid", android: "dashed", web: "dashed" }),
  height: 1,
  marginBottom: fitSize(8),
  marginTop: fitSize(9),
  borderRadius: 1,
}

interface CollectionCardProps {
  item: activityItem
}

const getRestTimeThreeDays = (time: number) => {
  if (time <= 0) return "00:00:00"
  const allS = Math.floor(time / 1000)
  const days = Math.floor(allS / 60 / 60 / 24)
  const hours = Math.floor((allS / 60 / 60) % 24)
  const minutes = Math.floor((allS / 60) % 60)
  const seconds = Math.floor(allS % 60)
  const timeString =
    (days > 0 ? days : days) +
    "天" +
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)

  if (days < 1) {
    return (
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds)
    )
  }

  return timeString
}

const CollectionCard: FC<CollectionCardProps> = ({ item }) => {
  const navigation = useTypeNavigation()
  const [currentTime, setCurrentTime] = useState(() => new Date().getTime())
  const {
    id,
    imageInfo,
    name,
    startTime,
    endTime,
    saleAmount,
    buyLimit,
    openNumber,
    specification,
    saleStatusName,
    canSaleIs,
    saleStatus,
  } = item

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime())
    }, 1000)
    return () => clearInterval(interval)
  }, [])
  return (
    <Touchable
      style={$cardBox}
      onPress={() => navigation.navigate("ActivityNew", { id, canSaleIs: canSaleIs })}
      disabled={saleStatus !== 1}
    >
      <SizeImage source={{ uri: imageInfo }} style={$cardBag}>
        <View
          style={[
            $cardContent,
            {
              minWidth: fitSize(70),
            },
          ]}
        >
          <SizeImage source={Images.screen.home.card_countdown} style={$cardCountdownIcon} />
          <Text style={$cardCountdownTxt} numberOfLines={1} ellipsizeMode="tail">
            {dayjs(startTime).unix() > currentTime
              ? `${getRestTimeThreeDays(dayjs(dayjs(startTime).unix()).diff(currentTime))}开始`
              : dayjs(endTime).unix() > currentTime && buyLimit !== 0
              ? `${getRestTimeThreeDays(dayjs(dayjs(endTime).unix()).diff(currentTime))}结束`
              : `${dayjs(endTime).format("YYYY.MM.DD")}`}
          </Text>
        </View>
        <BlurView
          style={[$cardInfoContainer, { position: "absolute", bottom: 0 }]}
          intensity={30}
        />
        <View style={[$cardInfoContainer, { zIndex: 2 }]}>
          <SizeImage source={Images.screen.home.card_info_box} style={$cardInfoContent}>
            <View style={$cardInfoTopContent}>
              <Text style={$cardSeriesText} numberOfLines={1}>
                {/* {specification} */}
              </Text>
              <View style={$cardNameContainer}>
                <Text style={$cardNameText} numberOfLines={1}>
                  {name}
                </Text>
                <Text style={[$cardPriceText, {}]} numberOfLines={1}>
                  <Text style={$cardPriceIcon}>¥</Text>
                  {saleAmount}
                </Text>
              </View>
            </View>
            <View style={{ marginVertical: fitSize(8) }}>
              <DashedLine color={colors.palette.neutral50} />
            </View>
            <View style={$cardInfoBottomContent}>
              <Text style={$cardInfoText} numberOfLines={1}>
                包含<Text style={$cardlimitText}>{openNumber}</Text>个时刻
              </Text>
              <View
                style={[
                  $cardSubmitBtn,
                  {
                    backgroundColor:
                      saleStatus === 1 ? colors.palette.neutral100 : colors.palette.neutral20,
                  },
                ]}
              >
                <Text style={$cardSubmitText}>{saleStatusName}</Text>
              </View>
            </View>
          </SizeImage>
        </View>
      </SizeImage>
    </Touchable>
  )
}
