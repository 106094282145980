import { colors, fitSize } from "../../theme"
import { StyleSheet, Text, View, TextInput, ViewStyle } from "react-native"
import React, { useReducer, useState } from "react"
import { Sheader } from "../../components/Sheader"
import { RootStackNavProps } from "../../type"
import { Screen, Touchable, outlineStyle } from "../../components"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { isValidCode, isValidPhoneNumber } from "../../utils/regexFunction"
import { api } from "../../services/api"
import { GToast } from "../../navigators"
import { TextStyle } from "react-native"
import { useCountdown } from "../../hooks/useCountdown"
import { useStores } from "../../models"

type phoneBindState = {
  phone: string
  authCode: string
  signature: string
}

type phoneBindAction = {
  type: "PHONE" | "AUTH_CODE" | "SIGNATURE"
  value: string
}

const phoneBindReducer = (state: phoneBindState, action: phoneBindAction) => {
  const { type, value } = action
  switch (type) {
    case "PHONE":
      return { ...state, phone: value }
    case "AUTH_CODE":
      return { ...state, authCode: value }
    case "SIGNATURE":
      return { ...state, signature: value }
    default:
      throw new Error()
  }
}

interface PhoneBindingProps extends RootStackNavProps<"PhoneBinding"> {}

export const PhoneBinding: React.FC<PhoneBindingProps> = ({ navigation, route }) => {
  const { showVerification, time, onTime, onReset } = useCountdown()
  const [phoneBind, setPhoneBind] = useReducer(phoneBindReducer, {
    phone: route.params.phone ?? "",
    authCode: "",
    signature: "",
  })
  const [isCheck, setIsCheck] = useState(false)
  const { accountStore } = useStores()

  const fetchVerifyCode = async () => {
    try {
      const res = await api.getVerifySMS(
        isCheck ? phoneBind.phone : undefined,
        isCheck ? phoneBind.signature : undefined,
      )
      if (res.kind === "ok") {
        GToast.current.showToast(translate("screen.phoneBinding.codeSuccess"), "OK", 1200)
        onTime()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const fetchNewVerifyCode = async () => {
    try {
      const res = await api.getNewVerifySMS(
        isCheck ? phoneBind.phone : undefined,
        isCheck ? phoneBind.signature : undefined,
      )
      if (res.kind === "ok") {
        GToast.current.showToast(translate("screen.phoneBinding.codeSuccess"), "OK", 1200)
        onTime()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const fetchData = async () => {
    try {
      const res = await api.getInfo()
      if (res.kind === "ok") {
        accountStore.changeUser(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const fetchConfirm = async () => {
    try {
      const res = await api.confirmPhone(phoneBind.phone, phoneBind.authCode, phoneBind.signature)
      if (res.kind === "ok") {
        GToast.current.showToast(translate("screen.phoneBinding.phoneSuccess"), "OK", 2000)
        fetchData()
        navigation.goBack()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const currentPhoneCheck = async () => {
    try {
      const res = await api.currentPhoneCheck(phoneBind.authCode)
      if (res.kind === "ok") {
        setIsCheck(true)
        setPhoneBind({ type: "SIGNATURE", value: res.data.sign })
        onReset()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  const isClickCode = isCheck && isValidPhoneNumber(phoneBind.phone) === false

  const isConfirm =
    (isCheck && isValidPhoneNumber(phoneBind.phone) === false) ||
    (isCheck && isValidCode(phoneBind.authCode) === false)

  const $confirmDisabledStyle: ViewStyle = {
    opacity: isConfirm ? 0.5 : 1,
  }

  const $clickCodeStyle: TextStyle = {
    color: isClickCode ? colors.palette.neutral20 : colors.palette.neutral100,
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <Text style={style.title}>
          {isCheck
            ? translate("screen.phoneBinding.header")
            : translate("screen.phoneBinding.isCheck")}
        </Text>
        <View style={style.inputRow}>
          <View style={style.textCenter}>
            <Text style={style.phoneLabel}>{translate("screen.phoneBinding.phoneLabel")}</Text>
          </View>
          {isCheck ? (
            <TextInput
              style={[style.phoneInput, outlineStyle]}
              onChangeText={(text) => setPhoneBind({ type: "PHONE", value: text })}
              value={phoneBind.phone}
              placeholder={translate("screen.phoneBinding.inputPlaceholder")}
              placeholderTextColor={colors.palette.neutral50}
              spellCheck={false}
              enablesReturnKeyAutomatically
              returnKeyType={"done"}
              autoCapitalize={"none"}
              underlineColorAndroid={"transparent"}
              clearButtonMode="while-editing"
              maxLength={11}
              keyboardType={"number-pad"}
            />
          ) : (
            <View style={[style.inputTextLineRow, outlineStyle]}>
              <Text style={style.text}>{route.params.phone}</Text>
            </View>
          )}
        </View>
        <View style={style.inputRow}>
          <TextInput
            style={[style.codeInput, outlineStyle]}
            onChangeText={(text) => setPhoneBind({ type: "AUTH_CODE", value: text })}
            value={phoneBind.authCode}
            placeholder={translate("screen.phoneBinding.codePlaceholder")}
            placeholderTextColor={colors.palette.neutral50}
            spellCheck={false}
            enablesReturnKeyAutomatically
            returnKeyType={"done"}
            autoCapitalize={"none"}
            underlineColorAndroid={"transparent"}
            clearButtonMode="while-editing"
            maxLength={6}
            keyboardType={"number-pad"}
          />
          {!showVerification ? (
            <Touchable
              style={style.codeBtn}
              disabled={isClickCode}
              onPress={!isCheck ? fetchVerifyCode : fetchNewVerifyCode}
            >
              <Text style={[style.codeLabel, $clickCodeStyle]}>
                {translate("screen.phoneBinding.btnText")}
              </Text>
            </Touchable>
          ) : (
            <View style={style.codeBtn}>
              <Text style={[style.codeLabel, $clickCodeStyle]}>
                {translate("screen.phoneBinding.codePlaceholderTime", { time: time })}
              </Text>
            </View>
          )}
        </View>
        <Touchable
          style={[style.confirmBtn, $confirmDisabledStyle]}
          disabled={isConfirm}
          onPress={() => {
            if (!isCheck) {
              setPhoneBind({ type: "PHONE", value: "" })
              setPhoneBind({ type: "AUTH_CODE", value: "" })
              currentPhoneCheck()
              onReset()
            } else {
              fetchConfirm()
            }
          }}
        >
          <Text style={style.confirmText}>{translate("screen.phoneBinding.confirmBtn")}</Text>
        </Touchable>
      </View>
    </Screen>
  )
}

const style = StyleSheet.create({
  inputTextLineRow: {
    flexGrow: 1,
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  text: {
    width: "100%",
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  confirmText: {
    fontSize: fitSize(15),
    fontWeight: "500",
    color: colors.palette.neutral30,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  confirmBtn: {
    width: fitSize(180),
    height: fitSize(40),
    backgroundColor: colors.palette.neutral100,
    borderRadius: fitSize(20),
    marginTop: fitSize(70),
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  codeBtn: {
    width: fitSize(84),
    textAlign: "center",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
  },
  codeLabel: {
    fontSize: fitSize(12),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  codeInput: {
    height: "100%",
    flexGrow: 1,
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
    paddingLeft: fitSize(21),
  },
  textCenter: {
    width: fitSize(64),
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  phoneLabel: {
    fontSize: fitSize(14),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  inputRow: {
    flexDirection: "row",
    width: fitSize(310),
    height: fitSize(50),
    backgroundColor: colors.palette.neutral60,
    borderRadius: fitSize(25),
    marginBottom: fitSize(15),
  },
  phoneInput: {
    height: "100%",
    flexGrow: 1,
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
  },
  title: {
    fontSize: fitSize(22),
    fontWeight: "500",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC_Semibold,
    marginBottom: fitSize(80),
    marginTop: fitSize(8),
  },
  content: {
    flex: 1,
    alignItems: "flex-start",
    paddingHorizontal: fitSize(36),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
