import { Animated, Dimensions, FlatList, StyleSheet, Text, TextInput, View } from "react-native"
import React, { FC, useEffect, useRef, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { colors, fitSize } from "../../theme"
import { Screen, Sheader } from "../../components"
import { Touchable } from "../../components/Button"
import { fontName } from "../../theme/fonts"
import { RarityCodex, api } from "../../services/api"
import { useAutoList } from "../../hooks/useAutoList"
import { GToast } from "../../navigators"
import { SizeImage } from "../../components/SizeImage"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"

interface TimeItemProps {
  item: RarityCodex
}

interface IllustratedHandbookProps extends RootStackNavProps<"IllustratedHandbook"> {}

const PL = 50
export const IllustratedHandbook: React.FC<IllustratedHandbookProps> = observer(() => {
  const searchInputRef = useRef<TextInput>(null)
  const searchWidthAnim = useRef(new Animated.Value(0)).current
  const [isSearchActive, setIsSearchActive] = useState(false)
  const navigation = useTypeNavigation()

  const Item: FC<TimeItemProps> = ({ item }) => {
    return (
      <Touchable
        style={style.card}
        onPress={() => {
          navigation.navigate("GuideDetails", {
            seriesId: item.seriesId,
            rarityId: item.rarityId,
            cardBoxId: item.cardBoxId,
            cardBoxRarityId: item.id,
          })
        }}
      >
        <SizeImage source={{ uri: item?.image }} style={style.cardImage} resizeMode="cover" />
        <View style={style.cardContent}>
          <Text style={style.cardName} numberOfLines={1} ellipsizeMode="tail">
            {item?.name}·{item?.rarityName}
          </Text>
          <Text style={style.cardInfo} numberOfLines={1} ellipsizeMode="tail">
            {item?.description}
          </Text>
          <Text style={style.cardTotal} numberOfLines={1} ellipsizeMode="tail">
            {item?.favoriteNumber}
            <Text style={style.favoriteNumber}>/{item?.resourceNumber}</Text>
          </Text>
        </View>
      </Touchable>
    )
  }

  const fetchTimeList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getRarityCodexList(lastId, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchTimeList,
      pageLimit: PL,
    },
    [],
  )

  useEffect(() => {
    if (isSearchActive) {
      Animated.timing(searchWidthAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
      }).start(() => searchInputRef?.current?.focus())
    } else {
      Animated.timing(searchWidthAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start(() => searchInputRef?.current?.blur())
    }
    return () => {
      if (searchInputRef.current) {
        searchInputRef.current.clear()
        searchInputRef.current.blur()
      }
    }
  }, [isSearchActive])

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <FlatList
        {...fetchList}
        data={fetchList?.data}
        numColumns={2}
        refreshing={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={style.content}
        showsVerticalScrollIndicator={false}
        renderItem={({ item }) => <Item item={item as unknown as RarityCodex} />}
      />
    </Screen>
  )
})

const style = StyleSheet.create({
  card: {
    width: fitSize(164),
    height: fitSize(259),
    borderWidth: 1,
    borderColor: colors.palette.neutral100 + "4d",
    marginHorizontal: fitSize(10),
    marginBottom: fitSize(19),
    flexDirection: "column",
    overflow: "hidden",
  },
  cardImage: {
    width: "100%",
    height: fitSize(173),
    backgroundColor: colors.palette.neutral100 + "1a",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContent: {
    flexDirection: "column",
    width: "100%",
    flexGrow: 1,
    padding: fitSize(8),
  },
  cardName: {
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontSize: fitSize(16),
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  cardInfo: {
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontSize: fitSize(12),
    marginTop: fitSize(5),
    height: fitSize(16),
  },
  cardTotal: {
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontSize: fitSize(12),
    alignSelf: "flex-end",
    marginTop: fitSize(5),
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  favoriteNumber: {
    fontWeight: "500",
    color: colors.palette.neutral50,
    fontSize: fitSize(12),
    alignSelf: "flex-end",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  body: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    alignItems: "center",
    justifyContentL: "center",
    marginTop: fitSize(16),
  },
  textStyle: {
    color: colors.palette.neutral100,
  },
  leftStyle: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  rightWrapStyle: {
    flexGrow: 1,
    height: "100%",
  },
  icon: {
    width: fitSize(15),
    height: fitSize(15),
  },
  ml: {
    marginLeft: fitSize(20),
  },
  rowEnd: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    height: fitSize(44),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  content: {
    paddingBottom: fitSize(120),
  },
})
