import { StyleSheet, View, Platform, Linking, ScrollView, Dimensions } from "react-native"
import { FULL, Images, fitSize } from "../../theme"
import { GToast, modalInfoType } from "../../navigators"
import React, { useLayoutEffect, useReducer, useRef, useState } from "react"
import { Screen, Text, Touchable } from "../../components"

import { observer } from "mobx-react-lite"
import { useFocusEffect } from "@react-navigation/native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { SizeImage } from "../../components/SizeImage"
import { NavItem } from "../TabMine/components/NavItem"
import { RootStackParamList, TabNavProps } from "../../type/RouteType"
import _ from "lodash"
import { AppInfo } from "../../config/AppInfo"
import { api } from "../../services/api"
import { useTokenAuthorization } from "../../hooks/useTokenAuthorization"
import { useTypeTabNavigation } from "../../navigators/useTypeNavigation"
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs"

interface TabMineProps extends TabNavProps<"TabThree"> {}
export const doThing = (deps: any, fn: any) => {
  if (deps) {
    fn()
  } else {
  }
}

const initialUserAssetTotal = {
  assetTotal: 0,
  cardBagTotal: 0,
  challengeTotal: 0,
}

type totalState = {
  assetTotal: number
  cardBagTotal: number
  challengeTotal: number
}

type totalAction = {
  type: "ASSET" | "CARD_BAG" | "CHALLENGE"
  value: number
}

const userAssetReducer = (state: totalState, action: totalAction) => {
  const { type, value } = action
  switch (type) {
    case "ASSET":
      return { ...state, assetTotal: value }
    case "CARD_BAG":
      return { ...state, cardBagTotal: value }
    case "CHALLENGE":
      return { ...state, challengeTotal: value }
    default:
      throw new Error()
  }
}

const COLOR_BLUR = [
  "#000000a6",
  "#000000a6",
  "#000000",
  "#000000",
  "#000000",
  "#000000a6",
  "#000000a6",
]

const NAV_ITEM_LIST = [
  {
    leftIcon: Images.screen.mine.mine_expense_calendar,
    leftText: "消费记录",
    type: "route",
    routeName: "OrderBuy",
    isShow: true,
  },
  {
    leftIcon: Images.screen.mine.mine_challenge_nav,
    leftText: "挑战记录",
    type: "route",
    routeName: "ComposeHisList",
    isShow: false,
  },
  {
    leftIcon: Images.screen.mine.mine_exchange_nav,
    leftText: "兑换中心",
    type: "route",
    routeName: "GetAwardFromCode",
    isShow: true,
  },
  {
    leftIcon: Images.screen.mine.mine_official_nav,
    leftText: "关注官方",
    type: "link",
    routeName: "https://www.cbatime.com/",
    isShow: true,
  },
  {
    leftIcon: Images.screen.mine.mine_setting_nav,
    leftText: "设置",
    type: "route",
    routeName: "Setting",
    isShow: true,
  },
  {
    leftIcon: Images.screen.mine.mine_version_nav,
    leftText: "当前版本",
    rightText: "",
    rightTextStyle: { color: "#666666" },
    type: "version",
    isShow: Platform.OS !== "web",
  },
]

export const MineScreen: React.FC<TabMineProps> = observer(({ navigation }) => {
  const { authorization } = useTokenAuthorization()
  const { accountStore } = useStores()
  const user = accountStore.user
  const [userAssetTotal, setUserAssetTotal] = useReducer(userAssetReducer, initialUserAssetTotal)
  const [modalInfo, setModalInfo] = useState<modalInfoType>()
  const tabNavigation = useTypeTabNavigation()
  const lisRef = useRef(null)
  const { top, bottom } = useSafeAreaInsets()
  const bottomHeight = useBottomTabBarHeight()

  const fetchData = async () => {
    try {
      const res = await api.getInfo()
      if (res.kind === "ok") {
        accountStore.changeUser(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const fetchStopServiceMessage = async (isNav: boolean) => {
    try {
      const res = await api.monitoringVersion(`v${AppInfo.version}`, Platform.OS)
      if (res.kind === "ok") {
        if (!_.isEmpty(res.data.versions)) {
          setModalInfo(res.data.versions)
        }
        if (isNav && _.isEmpty(res.data.versions)) {
          GToast.current.showToast("已是最新版本", "TOAST", 2000)
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchData()
      if (Platform.OS !== "web") {
        fetchStopServiceMessage(false)
      }
    }, []),
  )

  useLayoutEffect(() => {
    if (accountStore.tabScrollUp.tabFour && lisRef.current) {
      lisRef.current.scrollTo({ offset: 0, animated: true })
    }
  }, [accountStore.tabScrollUp.tabFour])

  return (
    <View testID="MineScreen" style={[FULL, { paddingTop: Platform.OS === "web" ? top : null }]}>
      <Screen
        preset="fixed"
        backgroundColor={"#000000"}
        style={{ paddingTop: Platform.OS !== "web" ? fitSize(39) : null }}
      >
        <ScrollView
          ref={lisRef}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          style={{
            width: Dimensions.get("window").width,
            height: Dimensions.get("window").height,
          }}
          onScroll={(event) => {
            const offsetY = event.nativeEvent.contentOffset.y
            const threshold = Dimensions.get("window").height
            if (offsetY < threshold && accountStore.tabScrollUp.tabFour !== false) {
              accountStore.setTabScrollUp("tabFour", false)
            }
          }}
        >
          <View style={styles.container}>
            <View style={styles.avatarContainer}>
              <SizeImage source={Images.screen.mine.avatar_halo} style={styles.avatarHalo}>
                <SizeImage source={{ uri: user?.avatar }} style={styles.avatarIcon} />
              </SizeImage>
              <Text style={styles.avatarNameText}>{user?.nickname}</Text>
            </View>
            <Touchable
              style={styles.tabBarContainer}
              // onPress={() => tabNavigation.navigate("TabTwo")}
              onPress={() => navigation.push("AssetContent")}
            >
              <View style={styles.tabContentBox}>
                <Text style={styles.tabTotalValue}>{user?.assetCount ?? 0}</Text>
                <Text style={styles.tabTotalLabel}>资产</Text>
              </View>
              <Touchable
                style={styles.tabContentBox}
                onPress={() => navigation.push("CardBoxStore")}
              >
                <Text style={styles.tabTotalValue}>{user?.boxCount ?? 0}</Text>
                <Text style={styles.tabTotalLabel}>卡包</Text>
              </Touchable>
              <Touchable
                style={styles.tabContentBox}
                onPress={() => navigation.push("ComposeHisList")}
              >
                <Text style={styles.tabTotalValue}>{user?.dareCount ?? 0}</Text>
                <Text style={styles.tabTotalLabel}>挑战</Text>
              </Touchable>
            </Touchable>
            <View style={styles.addressBtnContainer}>
              <Touchable
                style={styles.addressBtn}
                onPress={() => doThing(accountStore.token, () => navigation.push("QRCode"))}
              >
                <Text style={styles.addressBtnText}>我的区块链地址</Text>
              </Touchable>
            </View>
            <View style={styles.content}>
              {NAV_ITEM_LIST.map((i, index) => {
                return (
                  <NavItem
                    leftText={i.leftText}
                    isShow={i.isShow}
                    rightTextStyle={
                      i.type === "version"
                        ? _.isEmpty(modalInfo)
                          ? { color: "#666666" }
                          : { color: "#FB6547" }
                        : i?.rightTextStyle
                    }
                    rightText={
                      i.type === "version"
                        ? _.isEmpty(modalInfo)
                          ? "已是最新版本"
                          : "有更新版本"
                        : i?.rightText
                    }
                    leftIcon={i.leftIcon}
                    onNav={() => {
                      if (i.type === "route" && !!i?.routeName) {
                        navigation.push(i?.routeName as keyof RootStackParamList)
                      } else if (i.type === "link" && !!i?.routeName) {
                        // Linking.openURL(i.routeName)
                        navigation.navigate("ActivityWebView", { uri: i.routeName })
                      } else if (i.type === "version") {
                        if (!_.isEmpty(modalInfo)) {
                          Linking.openURL(modalInfo.link)
                        } else {
                          fetchStopServiceMessage(true)
                        }
                      } else {
                        //   Linking.openURL("")
                      }
                    }}
                    key={index}
                  />
                )
              })}
            </View>
          </View>
        </ScrollView>
      </Screen>
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    paddingVertical: fitSize(27),
    width: "100%",
    backgroundColor: "#000",
  },
  avatarContainer: {
    alignSelf: "center",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarHalo: {
    width: fitSize(130),
    height: fitSize(137),
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarIcon: {
    width: fitSize(86),
    height: fitSize(86),
    borderRadius: fitSize(50),
    overflow: "hidden",
    marginBottom: fitSize(6),
  },
  blurAvatar: {
    width: fitSize(72),
    height: fitSize(17),
    position: "absolute",
    bottom: 5,
    borderRadius: fitSize(50),
  },
  avatarNameText: {
    fontSize: fitSize(21),
    color: "#ffffff",
    fontFamily: "PingFang_SC",
    fontWeight: "500",
    height: fitSize(30),
    textAlign: "center",
    lineHeight: fitSize(30),
  },
  tabBarContainer: {
    width: "100%",
    height: fitSize(60),
    marginTop: fitSize(20),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabContentBox: {
    width: fitSize(125),
    height: fitSize(60),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  tabTotalValue: {
    fontSize: fitSize(18),
    color: "#ffffff",
    fontWeight: "600",
    fontFamily: "PingFang_SC",
  },
  tabTotalLabel: {
    fontSize: fitSize(12),
    color: "#666666",
    fontWeight: "400",
    fontFamily: "PingFang_SC",
  },
  addressBtnContainer: {
    alignSelf: "center",
    marginTop: fitSize(20),
  },
  addressBtn: {
    backgroundColor: "#6FEBE8",
    width: fitSize(347),
    height: fitSize(44),
    borderRadius: fitSize(25),
    alignItems: "center",
    justifyContent: "center",
  },
  addressBtnText: {
    fontSize: fitSize(14),
    color: "#333333",
    fontFamily: "PingFang_SC",
    fontWeight: "500",
  },
  content: {
    flexDirection: "column",
    flex: 1,
    marginTop: fitSize(20),
    paddingHorizontal: fitSize(22),
    marginBottom: fitSize(150),
  },
})
