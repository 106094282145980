import React, { FC } from "react"
import { ImageStyle } from "react-native-fast-image"
import { fitSize } from "../../../theme"
import { SizeImage } from "../../../components/SizeImage"

interface ImageFilterProps {
  source: any
  isHave?: boolean
}

export const ImageFilter: FC<ImageFilterProps> = ({ source, isHave }) => {
  return (
    <SizeImage
      source={source}
      resizeMode="contain"
      style={[$cardIcon, !isHave ? $imageFilter : {}]}
    />
  )
}

const $cardIcon: ImageStyle = {
  width: fitSize(64),
  height: fitSize(64),
}

const $imageFilter: any = {
  // filter: "grayscale(1)",
  opacity: 0.6,
  filter:
    "matrix(0.2126,0.7152,0.0722,0,0,0.2126,0.7152,0.0722,0,0,0.2126,0.7152,0.0722,0,0,0,0,0,1,0)",
}
