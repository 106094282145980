import { Image, Modal, StyleSheet, Text, View } from "react-native"
import { Images, fitSize } from "../theme"
import React, { ReactElement, ReactNode } from "react"

import { Touchable } from "../components"
interface props {
  modalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  children: ReactNode
  title?: string
}

const TextModal: React.FC<props> = ({
  modalVisible,
  setModalVisible,
  children,
  title = "",
}): ReactElement => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <View style={styles.centeredView}>
        <View
          style={[
            styles.modalView,
            {
              borderColor: "#ffffff",
              backgroundColor: "#000000",
              overflow: "hidden",
            },
          ]}
        >
          <View style={styles.titleContainer}>
            <Text style={[styles.title, { color: "#ffffff", alignSelf: "center" }]}>{title}</Text>
            <Touchable
              style={{ position: "absolute", top: fitSize(12), right: fitSize(12) }}
              onPress={() => setModalVisible(!modalVisible)}
            >
              <Image
                style={{
                  width: fitSize(16),
                  height: fitSize(16),
                  tintColor: "#ffffff",
                }}
                source={Images.components.modal_clear}
              />
            </Touchable>
          </View>
          {children}
        </View>
      </View>
    </Modal>
  )
}

export default TextModal

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00000055",
  },
  modalView: {
    backgroundColor: "white",
    alignItems: "flex-start",
    width: fitSize(315),
    height: fitSize(432),
    borderRadius: fitSize(12),
    borderWidth: fitSize(1),
  },
  title: {
    fontSize: fitSize(17),
    fontWeight: "600",
    marginTop: fitSize(20),
    marginBottom: fitSize(15),
  },
  clearIcon: {
    width: 25,
    height: 25,
  },
  titleContainer: {
    // flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-between",
    width: "100%",
    // height: fitSize(45),
    // borderBottomWidth: 1,
    // borderBottomColor: "#01010155",
  },
})
