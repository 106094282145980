/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import { DarkTheme, DefaultTheme, NavigationContainer } from "@react-navigation/native"
import { createNativeStackNavigator, NativeStackScreenProps } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import React, { Fragment, createRef, useEffect, useState } from "react"
import { Linking, Platform, Text, View, useColorScheme } from "react-native"
import * as Screens from "../screens"
import Config from "../config"
import { navigationRef, useBackButtonHandler } from "./navigationUtilities"
import { colors } from "../theme"
import { GlobalModal } from "../components/GlobalModal"
import { RootStackParamList } from "../type"
import { useStores } from "../models"
import { AppInfo } from "../config/AppInfo"
import { api } from "../services/api"
import _ from "lodash"
import RNExitApp from "react-native-exit-app"
import { SystemInfoModal } from "../modal/SystemInfoModal"
import { MainNavigator } from "./BottomTabNavigator"
import { translate } from "../i18n"
import { Host } from "react-native-portalize"
// import { WeChat } from "../utils/CompatibleUtils"
import { useInit } from "../hooks/useInit"
import { isBaiDu } from "../utils/utils"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 *   https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type AppStackParamList = {
  Welcome: undefined
  // 🔥 Your screens go here
  // IGNITE_GENERATOR_ANCHOR_APP_STACK_PARAM_LIST
}

/**
 * This is a list of all the route names that will exit the app if the back button
 * is pressed while in that screen. Only affects Android.
 */
const exitRoutes = Config.exitRoutes

export type AppStackScreenProps<T extends keyof RootStackParamList> = NativeStackScreenProps<
  RootStackParamList,
  T
>

export type modalInfoType = {
  link: string
  forcibly: number
  version: string
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createNativeStackNavigator<RootStackParamList>()

const AppStack = observer(function AppStack() {
  const { stopControl, loginStack } = useInit()
  const { accountStore } = useStores()

  // useEffect(() => {
  //   if (Platform.OS !== "web") {
  //     WeChat.registerApp("wxb48e8974cccc9339").then((r) => {
  //       console.log("wechat register result:", r)
  //     })
  //   }
  // }, [])

  useEffect(() => {
    if (Platform.OS === "web") {
      if (!accountStore.isOK) sessionStorage.clear()
    }
  }, [accountStore.isOK])

  useEffect(() => {
    if (Platform.OS === "web" && isBaiDu) {
      GToast.current.showAlert(
        translate("errorScreen.baiduTip"),
        () => {},
        () => {},
        {
          info: undefined,
          closeText: undefined,
          sureText: "确认",
          isShowOne: false,
        },
      )
    }
  }, [])

  return (
    <React.Fragment>
      {stopControl.isStop && Platform.OS !== "web" ? (
        <SystemInfoModal
          contentData={stopControl.modalInfo}
          isBack={true}
          modalShow={stopControl.modalSystemShow}
          onClick={() => {
            Linking.openURL(stopControl.modalInfo?.link)
          }}
          back={() => {
            if (stopControl.modalType === 1) {
              RNExitApp.exitApp()
            } else {
              stopControl.setIsStop(false)
              stopControl.setModalSystemShow(true)
            }
          }}
        />
      ) : (
        <Fragment>
          {accountStore.isOK && loginStack ? (
            <Host>
              <Stack.Navigator
                screenOptions={{
                  headerShown: false,
                  navigationBarColor: colors.background,
                  animation: Platform.OS === "android" ? "none" : undefined,
                }}
                initialRouteName={loginStack}
                // initialRouteName={"Welcome"}
              >
                <Stack.Screen name="Root" component={MainNavigator} />
                <Stack.Screen name="Welcome" component={Screens.WelcomeScreen} />
                <Stack.Screen name="ActivityNew" component={Screens.ActivityNew} />
                <Stack.Screen
                  name="ReceiveRecord"
                  component={Screens.ReceiveRecord}
                  options={{
                    title: translate("appNavigator.receiveRecord"),
                  }}
                />
                <Stack.Screen
                  name="ReceiveDetail"
                  component={Screens.ReceiveDetail}
                  options={{
                    title: translate("appNavigator.receiveDetail"),
                  }}
                />
                <Stack.Screen
                  name="OrderBuy"
                  component={Screens.OrderBuy}
                  options={{
                    title: translate("appNavigator.orderBuy"),
                  }}
                />
                <Stack.Screen
                  name="GetAwardFromCode"
                  component={Screens.GetAwardFromCode}
                  options={{
                    title: translate("appNavigator.getAwardFromCode"),
                  }}
                />
                <Stack.Screen
                  name="QRCode"
                  component={Screens.QRCode}
                  options={{
                    title: translate("appNavigator.walletAddress"),
                  }}
                />
                <Stack.Screen
                  name="ComposeHisList"
                  component={Screens.ComposeHisList}
                  options={{
                    title: translate("appNavigator.composeHisList"),
                  }}
                />
                <Stack.Screen
                  name="About"
                  component={Screens.About}
                  options={{
                    title: translate("appNavigator.about"),
                  }}
                />
                <Stack.Screen
                  name="ChooseAvatar"
                  component={Screens.ChooseAvatar}
                  options={{
                    title: translate("appNavigator.chooseAvatar"),
                  }}
                />
                <Stack.Screen
                  name="CloseAccount"
                  component={Screens.CloseAccount}
                  options={{
                    title: translate("appNavigator.closeAccount"),
                  }}
                />
                <Stack.Screen
                  name="RelationService"
                  component={Screens.RelationService}
                  options={{
                    title: translate("appNavigator.relationService"),
                  }}
                />
                <Stack.Screen
                  name="Setting"
                  component={Screens.Setting}
                  options={{
                    title: translate("appNavigator.setting"),
                  }}
                />
                <Stack.Screen
                  name="OpenTimeList"
                  component={Screens.OpenTimeList}
                  options={{
                    title: translate("appNavigator.openTimeList"),
                  }}
                />
                <Stack.Screen name="OpenCardBag" component={Screens.OpenCardBag} />
                <Stack.Screen
                  name="ShowOrder"
                  component={Screens.ShowOrder}
                  options={{
                    title: translate("appNavigator.showOrder"),
                  }}
                />
                <Stack.Screen
                  name="PaymentSuccess"
                  component={Screens.PaymentSuccess}
                  options={{
                    title: translate("appNavigator.paymentSuccess"),
                  }}
                />
                <Stack.Screen
                  name="PaymentFailure"
                  component={Screens.PaymentFailure}
                  options={{
                    title: translate("appNavigator.PaymentFailure"),
                  }}
                />
                <Stack.Screen
                  name="NicknameEdit"
                  component={Screens.NicknameEdit}
                  options={{
                    title: translate("appNavigator.nicknameEdit"),
                  }}
                />
                <Stack.Screen
                  name="AccountSecurity"
                  component={Screens.AccountSecurity}
                  options={{
                    title: translate("appNavigator.accountSecurity"),
                  }}
                />
                <Stack.Screen
                  name="PhoneBinding"
                  component={Screens.PhoneBinding}
                  options={{
                    title: translate("appNavigator.phoneBinding"),
                  }}
                />
                <Stack.Screen
                  name="ChangePassword"
                  component={Screens.ChangePassword}
                  options={{
                    title: translate("appNavigator.changePassword"),
                  }}
                />
                <Stack.Screen
                  name="ForgotPassword"
                  component={Screens.ForgotPassword}
                  options={{
                    title: translate("appNavigator.forgotPassword"),
                  }}
                />
                <Stack.Screen
                  name="SettingPassword"
                  component={Screens.SettingPassword}
                  options={{
                    title: translate("appNavigator.settingPassword"),
                  }}
                />
                <Stack.Screen
                  name="LoginScreen"
                  component={Screens.LoginScreen}
                  options={{
                    title: translate("appNavigator.loginScreen"),
                  }}
                />
                <Stack.Screen
                  name="PhoneLogin"
                  component={Screens.PhoneLogin}
                  options={{
                    title: translate("appNavigator.phoneLogin"),
                  }}
                />
                <Stack.Screen
                  name="VerificationCode"
                  component={Screens.VerificationCode}
                  options={{
                    title: translate("appNavigator.verificationCode"),
                  }}
                />
                <Stack.Screen
                  name="PasswordLogin"
                  component={Screens.PasswordLogin}
                  options={{
                    title: translate("appNavigator.passwordLogin"),
                  }}
                />
                <Stack.Screen
                  name="SettingNewPassword"
                  component={Screens.SettingNewPassword}
                  options={{
                    title: translate("appNavigator.settingNewPassword"),
                  }}
                />
                <Stack.Screen name="StoreContent" component={Screens.StoreContent} />
                <Stack.Screen name="GuideDetails" component={Screens.GuideDetails} />
                <Stack.Screen name="AssetDetails" component={Screens.AssetDetails} />
                <Stack.Screen name="MomentPreview" component={Screens.MomentPreview} />
                <Stack.Screen name="ChallengeDetails" component={Screens.ChallengeDetails} />
                <Stack.Screen name="ComposeDetails" component={Screens.ComposeDetails} />
                <Stack.Screen name="ActivityWebView" component={Screens.ActivityWebView} />
                <Stack.Screen
                  name="IllustratedHandbook"
                  component={Screens.IllustratedHandbook}
                  options={{
                    title: translate("appNavigator.illustratedHandbook"),
                  }}
                />
                <Stack.Screen
                  name="CardBoxStore"
                  component={Screens.CardBoxStore}
                  options={{
                    title: translate("appNavigator.cardBoxStore"),
                  }}
                />
                <Stack.Screen
                  name="AssetContent"
                  component={Screens.AssetContent}
                  options={{
                    title: translate("appNavigator.assetContent"),
                  }}
                />
                <Stack.Screen
                  name="MessageList"
                  component={Screens.MessageList}
                  options={{ title: translate("appNavigator.messageList") }}
                />

                <Stack.Screen
                  name="RealName"
                  component={Screens.RealName}
                  options={{ title: translate("appNavigator.realName") }}
                />

                <Stack.Screen name="MessageDetails" component={Screens.MessageDetails} />
                {/* <Stack.Screen name="LoginFromMessage" component={Screens.LoginFromMessage} /> */}
                {/* <Stack.Screen name="MassWebView" component={Screens.MassWebView} /> */}
                {/** 🔥 Your screens go here */}
                {/* IGNITE_GENERATOR_ANCHOR_APP_STACK_SCREENS */}
              </Stack.Navigator>
            </Host>
          ) : (
            <></>
          )}
        </Fragment>
      )}
    </React.Fragment>
  )
})

export const GToast = createRef<React.ElementRef<typeof GlobalModal>>()

export interface NavigationProps
  extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = observer(function AppNavigator(props: NavigationProps) {
  const colorScheme = useColorScheme()

  useBackButtonHandler((routeName) => exitRoutes.includes(routeName))

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      {...props}
    >
      <GlobalModal ref={GToast} />
      <AppStack />
    </NavigationContainer>
  )
})
