export const userProtocolTxt = `本次版本更新时间：2023年【1】月【6】日

【首部及导言】

《CBA时刻用户使用协议》（以下简称“本协议”）是用户与CBA时刻之间关于使用CBA时刻各项服务的法律协议。“CBA时刻”是指CBA时刻的运营公司上海壹齐欧网络科技有限公司及其关联公司。

本协议中与用户的权益（可能）存在重大关系的条款（包括免除CBA时刻责任的条款、限制用户权利的条款、争议解决条款及司法管辖条款等），CBA时刻已采用字体加粗的方式来特别提醒用户，请用户留意重点查阅。请用户确保用户在使用CBA时刻服务前已仔细阅读、充分理解并毫无保留地接受本协议所有条款。

《CBA时刻隐私协议》 、规则、声明、公告、政策等（以下统称“其他CBA时刻规则”）是本协议不可分割的组成部分，用户在使用CBA时刻服务时应同样遵守。用户接受并同意本协议的行为即视为用户同意并接受其他CBA时刻规则，用户接受并同意任一其他CBA时刻规则的行为亦视为用户接受并同意本协议。

用户确认用户具备完全民事权利能力和完全民事行为能力，有能力同意并遵守本协议，并对用户本协议项下的全部行为独立承担法律责任。若用户不具备前述与用户行为相适应的民事行为能力，则应获得法定监护人的知情同意。如用户尚未成年，请在法定监护人的陪同下阅读和判断是否同意本协议，请特别注意未成年人条款。未成年人行使和履行本协议项下的权利和义务视为已获得法定监护人的认可。

1.服务说明

1.1 CBA时刻向用户提供包括但不限于如下服务：

1.1.1 CBA时刻直接拥有或运营的包括但不限于PC、平板、手机等全部终端客户端产品；

1.1.2 CBA时刻用户个人中心、钱包；

1.1.3 CBA时刻直接拥有或运营的服务器、网络存储空间；

1.1.4 CBA时刻提供给用户的各类增值服务；

1.1.5 CBA时刻提供给用户的其他技术和/或服务，具体以CBA时刻实际提供的为准。

1.2 CBA时刻所提供的服务，均限于在上述1.1.1及1.1.2所述平台（以下简称“CBA时刻平台”）内使用，任何以恶意破解等非法手段将CBA时刻所提供的服务与CBA时刻平台分离的行为，皆不属于本协议约定的由CBA时刻提供的服务。由此引起的一切后果由行为人负责，CBA时刻将保留依法追究行为人法律责任的权利。CBA时刻所提供的服务仅供用户非商业性使用，用户在未经CBA时刻事先书面同意的情况下，不得以商业性目的使用CBA时刻所提供的服务。

1.3 CBA时刻官方所公布的方式为注册、登录、网页版官网（包括但不限于iOS、Android等智能平台）、使用CBA时刻服务的唯一合法方式，用户通过其他任何途径、任何渠道、任何方式获取的CBA时刻服务（包括但不限于账号、客户端下载等）均为非法所得，CBA时刻概不承认其效力，且一经发现，CBA时刻有权立即作出删除、清零、封号等处理，任何因此导致的一切不利后果均由用户自行承担。请用户妥善保管自己的账号和密码，加强密码安全性，谨防账号泄露或被盗。因用户账号被泄露或被盗而造成的任何损失，CBA时刻不承担补偿责任。

1.4 CBA时刻有权提前向用户公告（包括但不限于弹出公告、首页公告、系统消息）以修改、替换、升级与CBA时刻服务相关的任何软件。如果用户不同意或者不接受CBA时刻相关软件的修改、替代、升级，请直接拒绝、停止、取消使用行为，否则视为用户同意并接受CBA时刻相关软件的修改、替代、升级，同时该同意并接受的行为仍受本协议约束。

1.5 本协议所称“用户”，包括注册获得CBA时刻账号的用户（以下简称“注册用户”）及未注册获得CBA时刻账号的用户（以下简称“非注册用户”）。凡未注册CBA时刻的产品和/或服务的用户，自下载或安装CBA时刻的产品和/或使用CBA时刻的服务时即自动成为CBA时刻的“注册用户”。

1.6 特别提醒：由于CBA时刻的产品和服务较多，为用户提供的产品和服务内容也有所不同，本协议为CBA时刻统一适用的一般性用户服务条款。针对CBA时刻的某些特定产品/服务，CBA时刻还将制定特定用户服务协议，以便更具体地与用户约定CBA时刻的服务内容、服务规则等内容，用户应在充分阅读并同意特定用户服务协议的全部内容后再使用该特定产品/服务。

1.7 CBA时刻不向未满18周岁或超过70周岁的用户提供以上服务。

2.注册账号

2.1 部分CBA时刻服务仅向注册用户提供，如果用户需要使用主要功能，请先根据本协议及其他CBA时刻规则提示的规则、流程注册成为注册用户，并确保注册信息的真实性、正确性及完整性，如果上述注册信息发生变化，用户应及时更改。

2.2 用户应对注册获得的CBA时刻账号（以下简称“账号”）项下的一切行为承担全部责任，不得侵犯包括但不限于CBA时刻在内的任何主体的合法权益。

2.3 用户理解并同意，用户仅享有账号及账号项下由CBA时刻提供的虚拟产品及服务的使用权，账号及该等虚拟产品及服务的所有权归CBA时刻所有（法律法规另有规定的除外）。未经CBA时刻书面同意，用户不得以任何形式处置账号的使用权（包括但不限于赠与、出借、转让、销售、抵押、继承、许可他人使用）。如果CBA时刻发现或者有合理理由认为使用者并非账号初始注册人，CBA时刻有权在不通知用户的情况下，暂停或终止向该注册账号提供服务，并注销该账号。

2.4 用户应妥善保管账号信息、账号密码以及其他与账号相关的信息、资料。如因用户的原因，造成账号信息、资料、数据的变动、消失或财产损失等，用户应立即通知CBA时刻并自行承担相关法律后果。

2.5 用户同意并承诺做到：

2.5.1 当用户的账号或密码遭到未经授权的使用，或者发生任何安全问题时，用户会立即有效地通知到CBA时刻；

2.5.2 当用户每次登录CBA时刻或使用相关服务后，会将有关账号等安全退出；

2.5.3 用户同意接受CBA时刻通过客户端、网页或其他合法方式向用户发送通知信息和其他相关信息。

2.6 用户同意并承诺不从事以下行为：

2.6.1 冒用他人信息为自己注册CBA时刻账号；

2.6.2 未经他人合法授权以他人名义注册CBA时刻账号；

2.6.3 使用同一身份认证信息注册多个CBA时刻账号（包括经CBA时刻审核认定多个CBA时刻账号的实际控制人为同一人的情形）；

2.6.4 窃取、盗用他人的CBA时刻账号、会员标识等；

2.6.5 使用侮辱、诽谤、色情、政治等违反法律、道德及公序良俗的词语注册CBA时刻账号；

2.6.6 以非法占有CBA时刻相关服务资源为目的，通过正当或非正当手段恶意利用网站漏洞；

2.6.7 侵犯他人合法权益的其他内容。

2.7 用户理解并同意，CBA时刻有权对违反上述条款的用户作出禁止注册及/或封号的处理。

2.8 用户了解并知悉，用户可以使用账号登录CBA时刻提供的CBA时刻体系下全部产品。

2.9 用户理解并同意，CBA时刻有权在法律允许的最大范围内视情况决定收回账号使用权，无需另行通知用户亦无需征得用户同意。

3.CBA时刻上的内容

3.1 CBA时刻上的内容是指经由CBA时刻提供的服务，以上传、张贴或任何其他方式传送或传播的任何资讯、资料、文字、软件、音乐、音频、照片、图形、视频、信息、链接或其他资料，无论系公开还是私下传送（以下简称“内容”），内容提供者、上传者应对其提供、上传的内容承担全部责任，如果给CBA时刻造成损失的，还应向CBA时刻承担赔偿责任。对于第三方因用户上传的内容向CBA时刻主张权利的，CBA时刻有权在不事先通知内容提供者、上传者的情况下直接采取删除、屏蔽、断开链接等必要措施。

4.使用规则

4.1 用户在使用CBA时刻服务的过程中，应遵守以下法律法规：

4.1.1 《中华人民共和国保守国家秘密法》；

4.1.2 《中华人民共和国著作权法》；

4.1.3 《中华人民共和国计算机信息系统安全保护条例》；

4.1.4 《计算机软件保护条例》；

4.1.5 《网络信息内容生态治理规定》

4.1.6 《信息网络传播权保护条例》

4.1.7 《中华人民共和国网络安全法》；

4.1.8 其他法律、法规、规章、条例等具有法律效力的规范。

4.2 禁止用户从事以下行为：

4.2.1 制作、上传、复制、传送、传播包含任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容或其他令人反感的包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料；

4.2.2 以任何方式危害未成年人；

4.2.3 冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关；

4.2.4 伪造标题或以其他方式操控识别资料，使人误认为该内容为CBA时刻所传送；

4.2.5 将无权传送的内容（例如内部资料、机密资料）进行上载、张贴、发送电子邮件或以其他方式传送；

4.2.6 将侵犯任何人的专利、商标、著作权、商业秘密等知识产权的内容加以上载、张贴、发送电子邮件或以其他方式传送；

4.2.7 将广告函件、促销资料、"垃圾邮件"等，加以上载、张贴、发送电子邮件或以其他方式传送；

4.2.8 跟踪或以其他方式骚扰他人；

4.2.9 将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；

4.2.10 干扰或破坏CBA时刻服务或与CBA时刻服务相连的服务器和网络，或不遵守本协议之规定；

4.2.11 未经CBA时刻事先明确书面许可，以任何方式（包括但不限于机器人软件、蜘蛛软件、爬虫软件等任何自动程序、脚本、软件）和任何理由自行或委托他人、协助他人获取平台的服务、内容、数据；

4.2.12 如用户提供的上传内容含有以上违反政策法律法规的信息或者内容的，由用户直接承担以上导致的一切不利后果，如因此给CBA时刻造成不利后果的，用户应负责消除影响，并且赔偿因此导致的一切损失。

4.3 用户不得通过任何渠道或媒体（包括但不限于自媒体等）发出“与CBA时刻合作”、“与CBA时刻共同出品”等任何携带“CBA时刻”品牌的字样，如用户需宣传推广合作节目，用户只能在宣传中提及节目本身而不得提及与CBA时刻关系或者擅自以CBA时刻品牌进行推广，凡是用户的发稿带有“CBA时刻”的一切宣传稿件必须通过CBA时刻相应合作部门之书面同意，否则因此给CBA时刻造成的一切损失用户应予以赔偿。



5.第三方链接

CBA时刻服务可能会提供与其他国际互联网网站或资源进行链接。除非另有声明，CBA时刻无法对第三方网站之服务进行控制，用户因使用或依赖上述网站或资源所产生的损失或损害，CBA时刻不承担任何责任。我们建议用户在离开CBA时刻，访问其他网站或资源前仔细阅读其服务条款和隐私政策。

6.知识产权

6.1 受国际版权公约、中华人民共和国著作权法、专利法、及其他知识产权方面的法律法规的保护，CBA时刻服务及本服务所使用的软件、技术、商标、材料等的所有知识产权归CBA时刻所有和享有。“知识产权”包括在专利法、著作权法、商标法、反不正当竞争法等法律中规定的任何和所有权利、所有权和利益，以及其中的任何和所有应用、更新、扩展和恢复。

6.2 用户不得修改、改编、翻译CBA时刻服务所使用的软件、技术、材料等，或者创作与之相关的派生作品，不得通过反向工程、反编译、反汇编或其他类似行为获得其的源代码，否则由此引起的一切法律后果由用户负责，CBA时刻将依法追究违约方的法律责任。

6.3 用户不得恶意修改、复制、传播CBA时刻服务所使用的软件、技术、材料等。否则，用户自行承担因此而造成对其他人的损害，或者造成对CBA时刻公司形象损害，要承担相应的法律责任。

6.4 用户不得擅自删除、掩盖或更改CBA时刻的版权声明、商标或其它权利声明。CBA时刻平台所有设计图样以及其他图样、产品及服务名称，均为CBA时刻所享有的商标、标识。任何人不得使用、复制或用作其他用途。

6.5 CBA时刻对其自制内容和其他通过授权取得的独占内容享有完全知识产权，未经CBA时刻许可，任何单位和个人不得私自转载、传播和提供观看服务或者有其他侵犯CBA时刻知识产权的行为。否则，CBA时刻将追究侵权行为人的法律责任。

6.6 CBA时刻所有和享有的知识产权，不因用户的任何使用行为而发生权利转移。

7.免责声明

7.1 CBA时刻对于任何包含、经由或连接、下载或从任何与有关本网络服务所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经本服务上的广告、展示而购买、取得的任何产品、信息或资料，CBA时刻不负保证责任。用户自行负担使用本服务的风险。

7.2 除非另有明确的书面说明，CBA时刻提供给用户的全部产品和服务，均是在“按现状”和“按现有”的基础上提供的。

7.3 CBA时刻对如下事项不做担保（包括但不限于）：

7.3.1 CBA时刻提供的网站、客户端等软件虽然均已经过CBA时刻测试，但由于技术本身的局限性，CBA时刻不能保证其与其他软硬件、系统完全兼容。如果出现不兼容的情况，用户可将情况报告CBA时刻，以获得技术支持。如果无法解决问题，用户可以选择卸载、停止使用CBA时刻服务。

7.3.2 使用CBA时刻服务涉及到Internet服务，可能会受到各个环节不稳定因素的影响。因不可抗力、黑客攻击、系统不稳定、网络中断、用户关机、通信线路等原因，均可能造成CBA时刻服务中断或不能满足用户要求的情况。CBA时刻不保证CBA时刻服务适合用户的使用要求。

7.3.3 由于CBA时刻提供的客户端等软件可以通过网络途径下载、传播，因此对于从非CBA时刻指定官方站点下载、非CBA时刻指定途径获得的CBA时刻服务相关软件，CBA时刻无法保证其是否感染计算机病毒、是否隐藏有伪装的木马程序等黑客软件，也不承担用户由此遭受的一切直接或间接损害赔偿等法律责任。

7.3.4 CBA时刻不做任何与CBA时刻服务、产品的安全性、可靠性、及时性和性能有关的担保。

7.3.5 CBA时刻不保证其提供的任何产品、服务或其他材料符合用户的期望。

7.4 用户使用经由CBA时刻服务下载或取得的任何资料，其风险由用户自行负担，因该使用而导致用户电脑系统损坏或资料流失，用户应负完全责任。

7.5 基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，CBA时刻不承担任何直接、间接、附带、衍生或惩罚性的赔偿：

7.5.1 CBA时刻服务全部或部分无法使用；

7.5.2 经由CBA时刻服务购买或取得的任何产品、资料或服务；

7.5.3 用户资料遭到未授权的使用或修改；

7.5.4 其他与CBA时刻服务相关的事宜。

7.6 用户应妥善保管自己的账号和密码，加强密码安全性，谨防账号泄露或被盗。因用户账号被泄露或被盗而造成的任何损失，CBA时刻不承担补偿责任。用户因电信和网通部门的通讯线路故障、网络或电脑故障、系统不稳定、不可抗力（如服务器宕机）等非CBA时刻原因造成账号、账号内财产等丢失、减少的，CBA时刻不承担补偿等责任。

7.7 用户理解并同意自主选择免费下载和使用CBA时刻服务，风险自负，包括但不限于用户使用CBA时刻服务过程中的行为，以及因使用CBA时刻服务产生的一切后果。如因下载或使用CBA时刻服务而对计算机系统造成的损坏或数据的丢失等，用户须自行承担全部责任。

7.8 用户因认证信息不真实而导致账号、账号内财产等丢失、减少而无法找回的，CBA时刻不承担任何法律责任。

8.服务的变更、中断、终止

8.1 用户理解并同意，CBA时刻基于经营策略的调整，可能会对服务内容进行变更，也可能会中断、中止或终止服务。

8.2 用户理解并同意，如CBA时刻发生合并、分立、收购、资产转让时，CBA时刻可向第三方转让本服务下相关资产；CBA时刻亦可在单方通知用户后，将本协议下部分或全部服务及相应的权利义务转交由第三方运营或履行。

8.3 用户理解并同意，如用户在使用CBA时刻服务时存在违反法律法规、本协议及其他CBA时刻规则、社会公德、公序良俗和/或侵害他人合法权益等情形，CBA时刻有权不经通知而单方中断或终止向用户提供全部或部分服务。

8.4 用户理解并同意，如用户实施违反法律法规、社会公德、公序良俗和/或侵害他人合法权益等行为，并因此影响或可能影响CBA时刻和/或他人的名誉、声誉或其他合法权益，CBA时刻有权不经通知而单方中断或终止向用户提供全部或部分服务。

8.5 CBA时刻终止向用户提供服务后，有权根据适用法律的要求删除用户的个人信息，或使其匿名化处理，亦有权依照法律规定的期限和方式继续保存用户留存于我方平台的其他内容和信息。

9.注销

9.1 用户有权向CBA时刻提出账号注销申请，用户可以通过联系CBA时刻客服注销用户的账号（法律法规、本协议或其他CBA时刻规则另有规定的除外）。

9.2 特别提醒：注销CBA时刻账号后，用户将无法再以此账号登录和使用CBA时刻的所有产品与服务以及产品及服务中与第三方合作的服务内容，CBA时刻也将同时终止在该账号下为用户提供我们各项产品与服务，这同时也不可避免地会给用户的售后维权带来不便。且CBA时刻账号一旦注销完成，将无法恢复。请用户在注销前慎重考虑。

9.3 如用户确定需要注销CBA时刻账号的，用户已充分知晓并确认，账号注销后该UID仍然存在，但用户将不再拥有账号相关的权益，包括但不限于：

9.3.1 账号注销后，用户将无法再以该账号登录、使用CBA时刻旗下的全部产品和服务；

9.3.2 账号注销后，用户曾通过该账号登录、使用CBA时刻旗下的全部产品和服务的所有内容、信息、数据、记录将会被删除或匿名化处理，用户也无法再搜索、访问、获取、使用和找回，包括但不限于：账号信息（头像、昵称、签名等）、绑定信息；

9.3.3 账号注销后，该账号的交易记录将被清空且无法恢复；

9.3.4 账号注销后，CBA时刻有权不再为用户提供任何与账号有关的服务；

9.3.5 用户同意通过账号注销的方式放弃该账号在CBA时刻旗下的产品与服务使用期间已产生但未消耗完毕的权益及未来的预期利益。CBA时刻将对该账号下的全部权益做清除处理，包括但不限于：用户的数字卡牌、用户在CBA时刻各产品和/或服务中的各类身份权益、用户在CBA时刻各产品和/或服务中已经购买的未到期的在线服务内容、其他已经产生但未消耗完毕的权益或未来预期的利益。

9.4 在用户向我们申请注销CBA时刻账号之前，为了保护用户的账号安全和财产权益，用户需先行检查与确保用户申请注销的账号已经同时满足以下条件，包括但不限于：

9.4.1 账号系用户通过官方渠道注册，符合本协议及相关规定的账号；且为用户本人的会员账号；

10.权利
10.1 本协议的修改权，解释权归上海壹齐欧网络科技有限公司所有。

`

export const UPtitle = "CBA时刻用户使用协议"
