import React, { useEffect, useState } from "react"

import { api, bannerColumnItem, homeLayout } from "../../../services/api"
import { GToast } from "../../../navigators"

type layoutPromiseReturn = {
  kind: string
  data: any[]
}

export function useLayoutHook() {
  const [isLoading, setIsloading] = useState(true)
  const [error, setError] = useState("")
  const [layout, setLayout] = useState<homeLayout | null>(null)
  const [bannerList, setBannerList] = useState<bannerColumnItem[]>([])
  const [widgetList, setWidgetList] = useState<bannerColumnItem[]>([])
  const [inviteList, setInviteList] = useState<bannerColumnItem[]>([])
  const [teachingList, setTeachingList] = useState<bannerColumnItem[]>([])
  const [tidelandsList, setTidelandsList] = useState<bannerColumnItem[]>([])
  const [prizeList, setPrizeList] = useState<bannerColumnItem[]>([])
  const [isCompactModel, setIsCompactModel] = useState(false)

  const getAllColumnData = async () => {
    let allData = []
    let res = await api.getColumnTypeList("0,1,2,3,4")
    if (res.kind === "ok") {
      if (Object.prototype.toString.call(res.data.list) !== "[object Null]") {
        allData.push(...res.data.list)
      }
      while (res.kind === "ok" && res.data.next) {
        res = await api.getColumnTypeList("0,1,2,3,4", res.data.lastId)
        if (res.kind === "ok") {
          if (Object.prototype.toString.call(res.data.list) !== "[object Null]") {
            allData.push(...res.data.list)
          }
        } else {
          GToast.current.handleErrorResponse(res)
        }
      }
    } else {
      GToast.current.handleErrorResponse(res)
    }
    return { kind: "allData", data: allData }
  }

  const getColumnList = async () => {
    try {
      setIsloading(true)
      const res = await getAllColumnData()
      const columnData = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
      }
      console.log("res", !!res.data)
      !!res.data &&
        res.data?.forEach((i) => {
          if (columnData.hasOwnProperty(i.module)) {
            columnData[i.module].push(i)
          }
        })
      setBannerList(columnData[0])
      setInviteList(columnData[1])
      setTeachingList(columnData[2])
      setTidelandsList(columnData[3])
      setPrizeList(columnData[4])
      setIsloading(false)
      setError("")
    } catch (err) {
      console.log("load layout error", err)
    }
  }

  useEffect(() => {
    getColumnList()
  }, [])

  return {
    isLoading,
    layout,
    bannerList,
    widgetList,
    inviteList,
    teachingList,
    tidelandsList,
    prizeList,
    isCompactModel,
    error,
    fetchGetBannerList: getColumnList,
  }
}
