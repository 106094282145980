import React, { FC } from "react"
import { ImageStyle } from "react-native-fast-image"
import { fitSize } from "../../../theme"
import { SizeImage } from "../../../components/SizeImage"

interface ImageFilterProps {
  source: any
  isHave?: boolean
}

export const ImageFilter: FC<ImageFilterProps> = ({ source, isHave }) => {
  return (
    <SizeImage
      source={source}
      resizeMode="cover"
      style={[$cardIcon, !isHave ? $imageFilter : {}]}
    />
  )
}

const $cardIcon: ImageStyle = {
  width: fitSize(102),
  height: fitSize(102),
}

const $imageFilter: any = {
  filter: "grayscale(1)",
  opacity: 0.6,
}
