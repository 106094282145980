import { Dimensions, FlatList, Image, Platform, StyleSheet, Text, View } from "react-native"
import React, {
  FC,
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { HookFlatList } from "../../../components/list/HookFlatList"
import { UserAssetItem, api } from "../../../services/api"
import { GToast } from "../../../navigators"
import { useAutoList } from "../../../hooks/useAutoList"
import { Images, colors, fitSize } from "../../../theme"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../../models"
import { Touchable } from "../../../components"
import { SizeImage } from "../../../components/SizeImage"
import { DashedLine } from "../StoreScreen"
import dayjs from "dayjs"
import { fontName } from "../../../theme/fonts"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { observer } from "mobx-react-lite"
import { FlashList } from "@shopify/flash-list"
import { RarityIcon } from "../../TabHome/components/RarityIcon"
import { useFocusEffect, useIsFocused } from "@react-navigation/native"
import { showInfo } from "../../../utils/showUtil"

interface MainContentProps {
  seriesId: string
  rarityId: string
  cardBoxId: string
  handleScroll: (value: any) => void
  isOpen: boolean
  fetchUserAssetTotal: () => void
  fetchMomentPreview: () => void
}

interface AssetItemProps {
  item: UserAssetItem
}

const PL = 20
export const MainContent = observer(
  forwardRef((props: MainContentProps, ref) => {
    const {
      seriesId,
      rarityId,
      cardBoxId,
      handleScroll,
      isOpen,
      fetchUserAssetTotal,
      fetchMomentPreview,
    } = props
    const { bottom } = useSafeAreaInsets()
    const { accountStore } = useStores()
    const lisRef = useRef(null)
    const navigation = useTypeNavigation()
    const isFocused = useIsFocused()

    const fetchGetUserAssetList = async (lastId?: number, size = PL) => {
      try {
        const res = await api.getUserAssetList(
          lastId,
          size,
          seriesId,
          rarityId,
          undefined,
          cardBoxId,
        )
        if (res.kind === "ok") {
          return res.data
        } else {
          GToast.current.handleErrorResponse(res)
        }
      } catch (error) {
        console.log("error", error)
      }
    }

    useFocusEffect(
      React.useCallback(() => {
        fetchList.onRefresh()
      }, []),
    )

    const [fetchList] = useAutoList(
      {
        fetchData: fetchGetUserAssetList,
        pageLimit: PL,
        isDestroy: isOpen && (seriesId !== "0" || rarityId !== "0" || cardBoxId !== "0"),
        // isFetch: isFocused,
      },
      [seriesId, rarityId, cardBoxId],
    )

    useLayoutEffect(() => {
      if (accountStore.tabScrollUp.tabTwo && lisRef.current && fetchList.data.length > 0) {
        // lisRef.current.scrollToOffset({ offset: 0, animated: true })
        try {
          lisRef.current.scrollToIndex({ index: 0, animated: true })
        } catch (error) {
          console.log("error", error)
        }
      }
    }, [accountStore.tabScrollUp.tabTwo])

    useImperativeHandle(ref, () => ({
      onTop: () => {
        try {
          lisRef.current.scrollToOffset({ offset: 0, animated: true })
        } catch (error) {
          console.log("error", error)
        }
      },
    }))

    const AssetItem: FC<AssetItemProps> = useCallback(({ item }) => {
      return (
        <Fragment>
          {item.isRead === 0 && (
            <Image source={Images.components.new_tip} style={style.red} resizeMode="stretch" />
          )}
          <Touchable
            onPress={() => {
              navigation.navigate("AssetDetails", { id: item.assetsId, assetId: item.id })
            }}
          >
            <SizeImage
              source={{
                uri: item.frameHImage,
              }}
              resizeMode="contain"
              style={style.assetItem}
            >
              <View style={style.imgContainer}>
                <View
                  style={{
                    width: fitSize(102),
                    height: fitSize(96),
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SizeImage
                    source={{ uri: item?.image }}
                    style={style.image}
                    resizeMode="contain"
                  />
                </View>
                <View style={style.infoContainer}>
                  <View style={style.infoContent}>
                    <Text style={style.nameText} numberOfLines={1}>
                      {item?.name}
                    </Text>
                    <Text style={style.clubText} numberOfLines={1}>
                      {item?.club}
                    </Text>
                    <Touchable
                      style={style.sendBox}
                      onPress={() => {
                        if (accountStore.user?.isReal === 1) {
                          GToast.current.showGift(item.id, () => {
                            fetchList.onRefresh()
                            fetchUserAssetTotal()
                            fetchMomentPreview()
                          })
                        } else {
                          showInfo("未实名", "TOAST")
                          navigation.navigate("RealName")
                        }
                      }}
                    >
                      <SizeImage source={Images.screen.store.send_store} style={style.sendIcon} />
                    </Touchable>
                  </View>
                  <View style={style.actionContent}>
                    <Text style={style.actionNameText}>{item?.actionName}</Text>
                    {/* <View style={style.dashedLine} /> */}
                    <DashedLine />
                    <Text style={style.actionAriseTimeText}>
                      {dayjs(item?.actionAriseTime).format("YYYY.MM.DD")}
                    </Text>
                  </View>
                  <View style={style.rowCenter}>
                    <Text style={style.cardBoxNameText}>{item?.cardBoxName}</Text>
                    <DashedLine />
                    <Text style={style.seriesNameText}>{item?.seriesName}</Text>
                  </View>
                </View>
              </View>
              <View style={style.divideLine} />
              <View style={style.bottomContent}>
                <View style={style.row}>
                  <RarityIcon icon={item?.rarityIcon} />
                  <View style={style.rowCenter}>
                    <Text style={style.idText}>#{item?.assetNumber}</Text>
                    <Text style={style.circulationText}>/{item?.circulation}</Text>
                  </View>
                </View>
                <View style={style.rowEnd}>
                  {item?.honorImage?.map((item, index) => {
                    return <SizeImage source={{ uri: item }} style={style.honorIcon} key={index} />
                  })}
                </View>
              </View>
            </SizeImage>
          </Touchable>
        </Fragment>
      )
    }, [])

    return (
      <FlatList
        // isTransverseLoad={true}
        {...fetchList}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: fitSize(150),
        }}
        ref={lisRef}
        refreshing={fetchList.topRefreshing}
        onEndReachedThreshold={1}
        // estimatedItemSize={fitSize(159)}
        // estimatedListSize={{ height: fitSize(159), width: fitSize(347) }}
        renderItem={({ item }) => <AssetItem item={item as unknown as UserAssetItem} />}
        onScroll={(event) => {
          const offsetY = event.nativeEvent.contentOffset.y
          const threshold = Dimensions.get("window").height
          handleScroll(event)
          if (offsetY < threshold && accountStore.tabScrollUp.tabTwo !== false) {
            accountStore.setTabScrollUp("tabTwo", false)
          }
        }}
      />
    )
  }),
)

const style = StyleSheet.create({
  body: {
    flexGrow: 1,
    width: "100%",
    marginTop: fitSize(10),
    paddingBottom: fitSize(100),
  },
  list: {
    width: "100%",
    flexGrow: 1,
  },
  assetItem: {
    width: fitSize(347),
    height: fitSize(163),
    borderRadius: fitSize(8),
    marginBottom: fitSize(20),
    paddingTop: fitSize(15),
    paddingHorizontal: fitSize(8),
    overflow: "hidden",
    alignSelf: "center",
  },
  red: {
    width: fitSize(45),
    height: fitSize(54),
    position: "absolute",
    top: fitSize(-2),
    left: fitSize(10),
    zIndex: 100,
  },
  dashedLine: {
    flexGrow: 1,
    height: 1,
    borderBottomColor: colors.palette.neutral30,
    borderStyle: "dashed",
    borderBottomWidth: 1,
    borderRadius: 2,
    marginHorizontal: fitSize(6),
  },
  imgContainer: {
    flexDirection: "row",
    width: "100%",
    height: fitSize(96),
    overflow: "hidden",
    paddingRight: fitSize(6),
  },
  image: {
    width: fitSize(76),
    height: fitSize(71.53),
  },
  infoContainer: {
    marginLeft: fitSize(12),
    flexDirection: "column",
    flexGrow: 1,
  },
  infoContent: {
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-end",
    paddingTop: fitSize(10),
    marginBottom: fitSize(14),
  },
  nameText: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    flexGrow: 1,
    overflow: "hidden",
  },
  clubText: {
    fontSize: fitSize(11),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    overflow: "hidden",
    flexGrow: 1,
  },
  sendBox: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  sendIcon: {
    width: fitSize(22),
    height: fitSize(22),
  },
  actionContent: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginBottom: fitSize(10),
  },
  actionNameText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  actionAriseTimeText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  cardBoxNameText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  seriesNameText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  divideLine: {
    height: 1,
    width: "100%",
    backgroundColor: colors.palette.neutral30,
    marginTop: fitSize(10),
  },
  bottomContent: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: fitSize(5),
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  rarityIcon: {
    minWidth: fitSize(14),
    maxWidth: fitSize(24),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  idText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
  },
  circulationText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
    paddingTop: fitSize(1),
  },
  rowEnd: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  honorIcon: {
    width: fitSize(18),
    height: fitSize(18),
    marginLeft: fitSize(10),
  },
})
