import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  View,
  useWindowDimensions,
  Text,
} from "react-native"
import { GToast, goBack } from "../../navigators"
import { Images, colors, deviceWidth, fitSize } from "../../theme"
import React, { useEffect, useRef, useState } from "react"
import { Screen, Touchable } from "../../components"
import { showError, showInfo } from "../../utils/showUtil"

import { DismissKeyboardView } from "../../components/DismissKeyboardView"
import { LOGIN_URL } from "../WebView/MassWebView"
import RenderHtml from "react-native-render-html"
import { RootStackNavProps, RootStackParamList } from "../../type/RouteType"
import { SizeImage } from "../../components/SizeImage"
import TextModal from "../../components/TextModal"
import { getChannel } from "../../utils/getMetaInfo"
import { useFocusEffect } from "@react-navigation/native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { api } from "../../services/api"
import { generateEnv } from "../../../generateEnv"
import { translate } from "../../i18n"

interface LoginFromMessageProps extends RootStackNavProps<"LoginFromMessage"> {}

enum textModal {
  agreement = "agreement",
  privacy = "privacy",
}

export const isWebView = function () {
  if (Platform.OS !== "web") {
    return false
  }
  //判断是否是微信
  var ua = navigator.userAgent.toLowerCase()
  const apps = ["micromessenger", "weibo", "lark", "weixin"]
  for (let i = 0; i < apps.length; i++) {
    if (ua.includes(apps[i])) {
      return true
    }
  }
  return false
}

export const LoginFromMessage: React.FC<LoginFromMessageProps> = ({ navigation, route }) => {
  const { top } = useSafeAreaInsets()
  const { accountStore } = useStores()
  const [isCheckbox, setIsCheckbox] = useState<boolean>(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>("")
  const [modalContent, setModalContent] = useState<string>("")
  const { width } = useWindowDimensions()

  useEffect(() => {
    if (route.params?.code) {
      if (Platform.OS === "web" && route.params?.id && route.params?.id !== "undefined") {
        const { inviterId, inviteCode } = JSON.parse(window.atob(route.params?.id))
        doLogin(route.params?.code, inviteCode)
      } else {
        doLogin(route.params?.code)
      }
    }
    if (isWebView()) {
      document.getElementById("wxtip").style.display = "block"
    }
  }, [route])

  const doLogin = async (code: string, inviteCode?: string) => {
    GToast.current.showLoading(translate("gToast.showLoading"))
    const channel = await getChannel()
    const res = await api.normalRequest(
      "/user/login/loginOAuth",
      { code, channel: channel, inviteCode: Platform.OS === "web" ? inviteCode : "" },
      "post",
    )
    if (res.kind === "ok") {
      GToast.current.hideToast()
      //TODO:saveCode
      accountStore.doMessageLogin(res.data.accessToken)
      if (accountStore.beforeLogin.url) {
        navigation.navigate(
          accountStore.beforeLogin.url as any,
          accountStore.beforeLogin.params as any,
        )
      } else {
        // navigation.goBack()
        navigation.replace("Root", {
          screen: "TabOne",
        })
      }
      accountStore.setInviteReturnUrl()
    } else {
      GToast.current.handleErrorResponse(res)
    }
  }

  const goToMassOauth = () => {
    if (accountStore.token) {
      showInfo(translate("screen.loginFromMessage.repeatLogin"))
      // navigation.goBack()
      navigation.replace("Root", {
        screen: "TabOne",
      })
    } else {
      if (isCheckbox) {
        if (Platform.OS === "web") {
          const oldUrl = new URL(window.location.href)

          const redirectUrl = new URL(`${oldUrl.origin}/LoginFromMessage/${route?.params?.id}`)
          for (const [k, v] of oldUrl.searchParams.entries()) {
            redirectUrl.searchParams.append(k, v)
          }
          const massUrl = new URL(`${generateEnv.DID_URL}/authorize`)
          massUrl.searchParams.append("redirectUrl", encodeURIComponent(redirectUrl.toString()))
          massUrl.searchParams.append("appid", generateEnv.APPID.toString())
          window.location.href = massUrl.toString()
        } else {
          navigation.navigate("MassWebView", {
            uri: `${generateEnv.DID_URL}/authorize?redirectUrl=${LOGIN_URL}&appid=${generateEnv.APPID}`,
          })
        }
      } else {
        GToast.current.showToast(translate("screen.loginFromMessage.protocolTip"), "TOAST", 2000)
      }
    }
  }

  const textRef = useRef<TextInput>()
  useFocusEffect(
    React.useCallback(() => {
      return () => textRef?.current?.blur()
    }, []),
  )

  const onCheckbox = (): void => {
    setIsCheckbox(!isCheckbox)
  }

  const getAgreementAPI = async (type: 0 | 1) => {
    const res = await api.normalRequest(`user/home/agreement/query/${type}`, "get")
    if (res.kind === "ok") {
      if (res.data) {
        setModalContent(res?.data?.agreement)
      }
    } else {
      setModalContent("")
      GToast.current.handleErrorResponse(res)
    }
  }

  const openTextModal = (type: textModal): void => {
    setModalVisible(true)
    if (type === textModal.agreement) {
      getAgreementAPI(0)
      setModalTitle(translate("screen.loginFromMessage.userProtocol"))
    } else if (type === textModal.privacy) {
      getAgreementAPI(1)
      setModalTitle(translate("screen.loginFromMessage.privacyAgreement"))
    }
  }

  return (
    <DismissKeyboardView style={[styles.container, { paddingTop: top }]}>
      <Screen
        preset="fixed"
        backgroundColor={colors.palette.neutral900}
        style={{ width: deviceWidth }}
      >
        <TextModal modalVisible={modalVisible} setModalVisible={setModalVisible} title={modalTitle}>
          <ScrollView
            style={styles.modalScrollView}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            {Platform.OS === "web" ? (
              <div style={styles.modalText} dangerouslySetInnerHTML={{ __html: modalContent }} />
            ) : (
              <RenderHtml
                contentWidth={width}
                source={{
                  html: modalContent,
                }}
              />
            )}
          </ScrollView>
        </TextModal>
        <Touchable
          onPress={() => {
            goBack()
          }}
          style={{
            alignSelf: "flex-end",
            marginRight: fitSize(15),
            marginVertical: fitSize(10),
          }}
        >
          <Image
            source={Images.components.modal_clear}
            style={{ width: fitSize(13), height: fitSize(13) }}
          />
        </Touchable>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: "#fff",
              fontSize: fitSize(28),
              marginLeft: fitSize(20),
              fontWeight: "500",
            }}
          >
            {translate("screen.loginFromMessage.didTitle")}
          </Text>
          <Touchable
            onPress={() => {
              // TODO 暂时注释
              // navigation.navigate("HelpSetting")
            }}
            style={{
              alignSelf: "flex-end",
              marginRight: fitSize(20),
              marginBottom: fitSize(7),
            }}
          >
            <Text style={{ color: "#9a9a9a", fontSize: fitSize(13) }}>
              {translate("screen.loginFromMessage.didSettingText")}
            </Text>
          </Touchable>
        </View>
        <Text
          style={{
            fontSize: fitSize(14),
            color: "#fff",
            marginLeft: fitSize(20),
            marginTop: fitSize(5),
          }}
        >
          {translate("screen.loginFromMessage.didLoginTips")}
        </Text>
        <View style={[styles.userAgreement, { alignSelf: "center", marginTop: fitSize(80) }]}>
          <Touchable style={[styles.checkbox, { borderColor: "#FABF33" }]} onPress={onCheckbox}>
            {isCheckbox && (
              <SizeImage
                source={Images.components.correct}
                style={{
                  marginTop: fitSize(3.5),
                  marginLeft: fitSize(3),
                  width: fitSize(10),
                  height: fitSize(10),
                }}
              />
            )}
          </Touchable>
          <Text style={{ fontSize: fitSize(13), color: "#fff" }}>
            {translate("screen.loginFromMessage.didUserProtocolAgree")}
          </Text>
          <Touchable onPress={() => openTextModal(textModal.agreement)}>
            <Text style={[styles.userAgreementText, { color: "#FABF33" }]}>
              《{translate("screen.loginFromMessage.userProtocol")}》
            </Text>
          </Touchable>
          <Text style={{ fontSize: fitSize(13), color: "#fff" }}>
            {translate("screen.loginFromMessage.and")}
          </Text>
          <Touchable onPress={() => openTextModal(textModal.privacy)}>
            <Text style={[styles.userAgreementText, { color: "#FABF33" }]}>
              《{translate("screen.loginFromMessage.privacyAgreement")}》
            </Text>
          </Touchable>
        </View>
        <Touchable
          style={{
            width: fitSize(295),
            height: fitSize(44),
            borderRadius: fitSize(24),
            backgroundColor: "#FABF33",
            alignSelf: "center",
            marginTop: fitSize(14),
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={goToMassOauth}
        >
          <Text style={{ color: "#fff", fontSize: fitSize(18) }}>
            {translate("screen.loginFromMessage.didBtn")}
          </Text>
        </Touchable>
      </Screen>
    </DismissKeyboardView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  inputWrap: {
    width: fitSize(330),
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#E6E6E6",
    paddingVertical: fitSize(5),
    marginTop: fitSize(35),
    flexDirection: "row",
  },
  inputNumber: {
    flexGrow: 1,
    color: "#333",
    fontSize: fitSize(16),
    paddingVertical: fitSize(0),
  },
  cirle: {
    width: fitSize(14),
    height: fitSize(14),
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: fitSize(7),
    borderColor: "#2697FF",
    marginTop: fitSize(2),
    marginRight: fitSize(5),
  },
  content: {
    color: "#B3B3B3",
    fontSize: fitSize(12),
  },
  contentBlack: {
    color: "#333",
    fontSize: fitSize(12),
  },
  modalcontainer: {
    backgroundColor: "#fff",
    width: fitSize(286),
    minHeight: fitSize(145),
    alignSelf: "center",
    borderRadius: fitSize(6),
  },
  msg: {
    fontSize: fitSize(16),
    color: "#333",
    alignSelf: "center",
    marginTop: fitSize(20),
    marginBottom: fitSize(20),
    paddingHorizontal: fitSize(10),
  },
  bottomWrap: {
    width: fitSize(286),
    flexDirection: "row",
    height: fitSize(55),
    borderTopColor: "#B3B3B3",
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  leftBtn: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: "#B3B3B3",
  },
  modalContent: {
    color: "#333",
    fontSize: fitSize(18),
  },
  userAgreement: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  checkbox: {
    width: fitSize(20),
    height: fitSize(20),
    borderRadius: fitSize(10),
    marginRight: fitSize(4),
    borderWidth: fitSize(1.5),
  },
  userAgreementText: {
    fontSize: fitSize(13),
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  modalScrollView: {
    flex: 1,
    padding: fitSize(5),
  },
  modalText: {
    width: "100%",
    flexWrap: "wrap",
  },
})
