import { Animated, StyleSheet, ViewStyle } from "react-native"
import { NavigationState, Route, SceneRendererProps, TabBar } from "react-native-tab-view"
import { colors, fitSize } from "../../../theme"

import React from "react"
import { AnimatedTextStyle } from "../../../type/ViewType"

type MyTabBarProps<T extends Route> = SceneRendererProps & {
  navigationState: NavigationState<T>
  split?: number
  indicatorLeft?: number
  bgColor?: string
  textTintColor?: string
  textColor?: string
  leftSpace?: number
  width?: number
  indicatorLeftWidth?: number
  noyTextFloating?: boolean
  height?: number
}

export function MyTabBar<T extends Route>(props: MyTabBarProps<T>) {
  const $tabStateStyle: ViewStyle = {
    width: props.width ? props.width : fitSize(100),
  }

  const $contentContainerStyle: ViewStyle = {
    paddingLeft: props.leftSpace ? props.leftSpace : fitSize(414 - 100 * (props?.split || 3)) / 2,
  }

  const $indicatorStyle: ViewStyle = {
    backgroundColor: colors.palette.neutral100,
    height: fitSize(3),
    width: props?.indicatorLeftWidth || fitSize(32),
    left: props?.indicatorLeft || fitSize(92),
  }

  const $style: ViewStyle = {
    backgroundColor: props.bgColor || null,
    height: props.height + fitSize(10),
    shadowColor: colors.palette.neutral900,
    shadowOffset: { width: 1, height: 3 },
    shadowOpacity: 0.4,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 0,
    width: "100%",
    alignSelf: "center",
  }
  return (
    <TabBar
      {...props}
      activeColor={colors.palette.neutral30}
      inactiveColor={colors.palette.neutral20}
      tabStyle={[style.tabStyle, $tabStateStyle]}
      contentContainerStyle={$contentContainerStyle}
      indicatorStyle={$indicatorStyle}
      style={$style}
      scrollEnabled={false}
      renderLabel={({ route, focused }) => {
        const $view: AnimatedTextStyle = {
          color: focused ? props.textTintColor || colors.text : props.textColor || colors.line,
          fontSize: focused && !props.noyTextFloating ? fitSize(18) : fitSize(14),
          fontWeight: focused ? "bold" : "normal",
          width: props.width ? props.width : fitSize(100),
          height: fitSize(49),
          textAlign: "center",
          lineHeight: fitSize(40),
        }
        return <Animated.Text style={$view}>{route.title}</Animated.Text>
      }}
    />
  )
}

export const style = StyleSheet.create({
  tabStyle: {
    height: fitSize(49),
  },
})
