import { View, Text, TextInput, Platform, ViewStyle, TextStyle } from "react-native"
import { colors, fitSize } from "../../theme"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models"
import { Screen, outlineStyle } from "../../components"
import { GToast } from "../../navigators"
import { api } from "../../services/api"
import { StyleSheet } from "react-native"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { useEffect, useRef, useState } from "react"
import * as Clipboard from "expo-clipboard"

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field"

interface VerificationCodeProps extends RootStackNavProps<"VerificationCode"> {}

const CELL_COUNT = 6
const CELL_SIZE = 42
const CELL_BORDER_RADIUS = 4

// const CODE_LENGTH = 6
export const VerificationCode: React.FC<VerificationCodeProps> = observer(
  ({ navigation, route }) => {
    const { accountStore } = useStores()
    const codeInputs = Array.from({ length: CELL_COUNT })
    const [verificationCode, setVerificationCode] = useState(Array(CELL_COUNT).fill(""))
    const inputRefs = useRef([])
    // const [value, setValue] = useState("")
    // const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT })
    // const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    //   value,
    //   setValue,
    // })
    // const [verifySMS, setVerifySMS] = useState("")

    // const renderCell = ({ index, symbol, isFocused }) => {
    //   return (
    //     <View style={$cellBox}>
    //       <Text key={index} style={$cell} onLayout={getCellOnLayoutHandler(index)}>
    //         {symbol || (isFocused ? <Cursor /> : null)}
    //       </Text>
    //     </View>
    //   )
    // }

    // useEffect(() => {
    //   setVerifySMS(value)
    //   if (value.length === 6) fetchLogin()
    // }, [value])

    const handleVerificationCodeChange = (value, index) => {
      const newVerificationCode = [...verificationCode]
      newVerificationCode[index] = value
      setVerificationCode(newVerificationCode)
      if (value && index < CELL_COUNT - 1) {
        inputRefs.current[index + 1]?.focus()
      } else if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus()
      }
      if (!value && index < CELL_COUNT - 1) {
        const emptyInputs = Array(CELL_COUNT - index - 1).fill("")
        setVerificationCode((prevCode) => [...prevCode.slice(0, index + 1), ...emptyInputs])
      }
    }

    const handleKeyPress = (e, index) => {
      if (e.nativeEvent.key === "Backspace" && index > 0 && !verificationCode[index]) {
        inputRefs.current[index - 1].focus()
      } else if (
        e.nativeEvent.key !== "Backspace" &&
        verificationCode[index] !== "" &&
        index < CELL_COUNT - 1
      ) {
        inputRefs.current[index + 1]?.focus()
      }
    }

    const fetchLogin = async () => {
      if (
        /^\d+$/.test(verificationCode.join("")) &&
        verificationCode.join("").length === CELL_COUNT
      ) {
        const res = await api.login(0, verificationCode.join(""), route?.params?.phone)
        if (res.kind === "ok") {
          accountStore.setManualExit(false)
          accountStore.changeUser(res.data)
          accountStore.doMessageLogin(res.data.authorization)
          accountStore.setCurrentAccount(route?.params?.phone)
          // @ts-ignore
          navigation.replace("Root")
        } else {
          GToast.current.handleErrorResponse(res)
        }
      }
    }

    useEffect(() => {
      if (verificationCode.every((code) => code !== "")) {
        fetchLogin()
      }
    }, [verificationCode])

    const handlePaste = async () => {
      try {
        const pasteText = await Clipboard.getStringAsync()
        if (pasteText && pasteText.length === CELL_COUNT && /^\d+$/.test(pasteText)) {
          const newVerificationCode = pasteText.split("")
          setVerificationCode(newVerificationCode)
        }
      } catch (error) {
        console.log("无法获取剪贴板内容:", error)
      }
    }

    useEffect(() => {
      if (Platform.OS === "android" || Platform.OS === "ios") {
        // Clipboard.addListener(handlePaste);
      } else {
        document.addEventListener("paste", handlePaste)
        return () => {
          document.removeEventListener("paste", handlePaste)
        }
      }
    }, [])
    return (
      <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
        <Sheader
          textStyle={style.textStyle}
          leftStyle={style.leftIcon}
          style={style.header}
          isNotSafe
        />
        <View style={style.content}>
          <Text style={style.title}>{translate("screen.verificationCode.title")}</Text>
          <View style={style.body}>
            <Text style={style.phoneTip}>
              {translate("screen.verificationCode.tip", { phone: route.params.phone })}
            </Text>
            <View style={style.inputContainer}>
              {codeInputs.map((_, index) => {
                return (
                  <TextInput
                    style={[style.input, outlineStyle]}
                    key={index}
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    value={verificationCode[index]}
                    onChangeText={(value) => handleVerificationCodeChange(value, index)}
                    onKeyPress={(e) => handleKeyPress(e, index)}
                    keyboardType={"numeric"}
                    spellCheck={false}
                    autoFocus={index === 0}
                    enablesReturnKeyAutomatically
                    returnKeyType={"next"}
                    autoCorrect={false}
                    autoCapitalize={"none"}
                    underlineColorAndroid={"transparent"}
                    // secureTextEntry={true}
                    maxLength={1}
                  />
                )
              })}
              {/* <CodeField
                ref={ref}
                {...props}
                value={verifySMS}
                onChangeText={setValue}
                cellCount={CELL_COUNT}
                rootStyle={$codeFieldRoot}
                keyboardType="number-pad"
                textContentType="oneTimeCode"
                renderCell={renderCell}
              /> */}
            </View>
          </View>
        </View>
      </Screen>
    )
  },
)

const $codeFieldRoot: ViewStyle = {
  height: CELL_SIZE,
  justifyContent: "center",
}

const $cell: TextStyle = {
  marginRight: 12,
  height: CELL_SIZE,
  width: CELL_SIZE,
  lineHeight: CELL_SIZE,
  ...Platform.select({ web: { lineHeight: 45 } }),
  fontSize: fitSize(18),
  textAlign: "center",
  fontFamily: fontName.PingFang_SC_Semibold,
  borderRadius: CELL_BORDER_RADIUS,
  color: colors.palette.neutral100,
  fontWeight: "500",
  backgroundColor: colors.palette.neutral80,
}

const $cellBox: ViewStyle = {
  borderRadius: CELL_BORDER_RADIUS,
}

const style = StyleSheet.create({
  inputContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    width: fitSize(42),
    height: fitSize(42),
    borderRadius: fitSize(4),
    backgroundColor: colors.palette.neutral80,
    fontSize: fitSize(18),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
    textAlign: "center",
  },
  phoneTip: {
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral10,
    fontFamily: fontName.PingFang_SC,
    marginBottom: fitSize(14),
  },
  body: {
    flex: 1,
    marginTop: fitSize(75),
    backgroundColor: colors.background,
  },
  title: {
    fontSize: fitSize(22),
    fontWeight: "500",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC_Semibold,
    marginTop: fitSize(8),
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
  content: {
    flex: 1,
    paddingHorizontal: fitSize(32),
    backgroundColor: colors.background,
  },
})
