import React from "react"
import { fitSize } from "../../../theme"
import ResponsiveImageView from "react-native-responsive-image-view"
import { Image, Platform, View } from "react-native"

interface RarityIconProps {
  icon: string
  level?: number
}

const levelIconStyle = {
  1: {
    width: fitSize(16),
    height: fitSize(16),
  },
  2: {
    width: fitSize(16),
    height: fitSize(16),
  },
  3: {
    width: fitSize(14),
    height: fitSize(16),
  },
  4: {
    width: fitSize(14),
    height: fitSize(16),
  },
  5: {
    width: fitSize(14),
    height: fitSize(16),
  },
  6: {
    width: fitSize(21),
    height: fitSize(16),
  },
  7: {
    width: fitSize(24),
    height: fitSize(16),
  },
  8: {
    width: fitSize(24),
    height: fitSize(16),
  },
}

export const RarityIcon = (props: RarityIconProps) => {
  const { icon, level } = props
  if (Platform.OS !== "web") {
    return (
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginRight: fitSize(6),
        }}
      >
        <ResponsiveImageView source={{ uri: icon }}>
          {({ getViewProps, getImageProps }) => (
            <View {...getViewProps()}>
              <Image {...getImageProps()} style={{ height: fitSize(14) }} resizeMode="contain" />
            </View>
          )}
        </ResponsiveImageView>
      </View>
    )
  } else {
    return (
      <img
        src={icon}
        style={{
          maxWidth: fitSize(24),
          height: fitSize(14),
          right: 4,
          objectFit: "contain",
          marginRight: fitSize(6),
        }}
        alt=""
      />
    )
  }
}
