import { BottomTabScreenProps, createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { CompositeScreenProps } from "@react-navigation/native"
import React, { useEffect } from "react"
import { Platform, Text, TextStyle, ViewStyle } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Icon } from "../components"
import { translate } from "../i18n"
import { colors, fitSize, spacing, typography } from "../theme"
import { AppStackScreenProps, GToast } from "./AppNavigator"
import {
  BottomTabParamList,
  RootStackParamList,
  TabFourParamList,
  TabOneParamList,
  TabThreeParamList,
  TabTwoParamList,
} from "../type"
import { HomeScreen } from "../screens/TabHome/HomeScreen"
import { MineScreen } from "../screens/TabMine/MineScreen"
import { StoreScreen } from "../screens/TabStore/StoreScreen"
import { CompScreen } from "../screens/TabComp/CompScreen"
import { useTypeNavigation } from "./useTypeNavigation"
import { useStores } from "../models"
import { observer } from "mobx-react-lite"
import { createNativeStackNavigator } from "@react-navigation/native-stack"

/**
 * Helper for automatically generating navigation prop types for each route.
 *
 * More info: https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type MainTabScreenProps<T extends keyof BottomTabParamList> = CompositeScreenProps<
  BottomTabScreenProps<BottomTabParamList, T>,
  AppStackScreenProps<keyof RootStackParamList>
>

const Tab = createBottomTabNavigator<BottomTabParamList>()

export const MainNavigator = observer(() => {
  const { bottom } = useSafeAreaInsets()
  const { accountStore } = useStores()

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarHideOnKeyboard: true,
        tabBarStyle: [
          $tabBar,
          { height: bottom + 50, display: accountStore.showBottomBar ? "flex" : "none" },
        ],
        tabBarActiveTintColor: colors.text,
        tabBarInactiveTintColor: colors.text,
        tabBarLabelStyle: $tabBarLabel,
        tabBarItemStyle: $tabBarItem,
        title: "",
      }}
      initialRouteName="TabOne"
    >
      <Tab.Screen
        name="TabOne"
        component={TabOneNavigator}
        options={{
          title: translate("bottomNavigator.oneTab"),
          tabBarLabel: ({ color, focused }) => (
            <TabBarLabel
              title={translate("bottomNavigator.oneTab")}
              color={color}
              focused={focused}
            />
          ),
          tabBarIcon: ({ focused }) => (
            <Icon icon={focused ? "tabHomeActive" : "tabHomeInactive"} size={20} />
          ),
          // unmountOnBlur: true,
          // freezeOnBlur: true,
          lazy: true,
        }}
      />
      <Tab.Screen
        name="TabTwo"
        component={TabTwoNavigator}
        options={{
          title: translate("bottomNavigator.twoTab"),
          tabBarLabel: ({ color, focused }) => (
            <TabBarLabel
              title={translate("bottomNavigator.twoTab")}
              color={color}
              focused={focused}
            />
          ),
          tabBarIcon: ({ focused }) => (
            <Icon icon={focused ? "tabStoreActive" : "tabStoreInactive"} size={20} />
          ),
          // unmountOnBlur: true,
          // freezeOnBlur: true,
          lazy: true,
        }}
      />

      <Tab.Screen
        name="TabThree"
        component={TabThreeNavigator}
        options={{
          title: translate("bottomNavigator.threeTab"),
          tabBarLabel: ({ color, focused }) => (
            <TabBarLabel
              title={translate("bottomNavigator.threeTab")}
              color={color}
              focused={focused}
            />
          ),
          tabBarIcon: ({ focused }) => (
            <Icon icon={focused ? "tabCompActive" : "tabCompInactive"} size={20} />
          ),
          // unmountOnBlur: true,
          // freezeOnBlur: true,
          lazy: true,
        }}
      />

      <Tab.Screen
        name="TabFour"
        component={TabFourNavigator}
        options={{
          title: translate("bottomNavigator.fourTab"),
          tabBarLabel: ({ color, focused }) => (
            <TabBarLabel
              title={translate("bottomNavigator.fourTab")}
              color={color}
              focused={focused}
            />
          ),
          tabBarIcon: ({ focused }) => (
            <Icon icon={focused ? "tabMineActive" : "tabMineInactive"} size={20} />
          ),
          // unmountOnBlur: true,
          // freezeOnBlur: true,
          lazy: true,
        }}
      />
    </Tab.Navigator>
  )
})

const TabOneStack = createNativeStackNavigator<TabOneParamList>()

const TabOneNavigator = observer(({ navigation, route }: any) => {
  const { accountStore } = useStores()

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e) => {
      accountStore.setTabScrollUp("tabOne", true)
    })
    return unsubscribe
  }, [navigation, accountStore])

  return (
    <TabOneStack.Navigator
      screenOptions={{ headerShown: false, title: translate("bottomNavigator.oneTab") }}
    >
      <TabOneStack.Screen name="TabOneScreen" component={HomeScreen} />
    </TabOneStack.Navigator>
  )
})

const TabTwoStack = createNativeStackNavigator<TabTwoParamList>()

const TabTwoNavigator = observer(({ navigation, route }: any) => {
  const { accountStore } = useStores()

  React.useLayoutEffect(() => {
    navigation.setOptions({
      tabBarBadge: undefined,
      tabBarBadgeStyle: {
        minWidth: 7,
        maxHeight: 7,
        borderRadius: 7,
        fontSize: 10,
        lineHeight: 13,
        alignSelf: undefined,
        left: 7,
      },
    })
  }, [])
  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e) => {
      accountStore.setTabScrollUp("tabTwo", true)
    })
    return unsubscribe
  }, [navigation, accountStore])

  return (
    <TabTwoStack.Navigator
      screenOptions={{
        headerShown: false,
        title: translate("bottomNavigator.twoTab"),
      }}
    >
      <TabTwoStack.Screen name="TabTwoScreen" component={StoreScreen} />
    </TabTwoStack.Navigator>
  )
})

const TabThreeStack = createNativeStackNavigator<TabThreeParamList>()

const TabThreeNavigator = observer(({ navigation, route }: any) => {
  const { accountStore } = useStores()

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e) => {
      accountStore.setTabScrollUp("tabThree", true)
    })
    return unsubscribe
  }, [navigation, accountStore])

  return (
    <TabThreeStack.Navigator
      screenOptions={{ headerShown: false, title: translate("bottomNavigator.threeTab") }}
    >
      <TabThreeStack.Screen name="TabThreeScreen" component={CompScreen} />
    </TabThreeStack.Navigator>
  )
})

const TabFourStack = createNativeStackNavigator<TabFourParamList>()

const TabFourNavigator = observer(({ navigation, route }: any) => {
  const { accountStore } = useStores()

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e) => {
      accountStore.setTabScrollUp("tabFour", true)
    })
    return unsubscribe
  }, [navigation, accountStore])

  return (
    <TabFourStack.Navigator
      screenOptions={{ headerShown: false, title: translate("bottomNavigator.fourTab") }}
    >
      <TabFourStack.Screen name="TabFourScreen" component={MineScreen} />
    </TabFourStack.Navigator>
  )
})

function TabBarLabel(props: { title: string; color: string; focused: boolean }) {
  return (
    <Text
      style={{
        color: props.focused ? "#ffffff" : "#666666",
        fontSize: fitSize(10),
        fontWeight: props.focused ? "500" : "400",
        fontFamily: "PingFang_SC",
        marginBottom: fitSize(spacing.xxs),
      }}
    >
      {props.title}
    </Text>
  )
}

const $tabBar: ViewStyle = {
  backgroundColor: colors.palette.neutral900,
  borderTopColor: colors.palette.neutral900,
}

const $tabBarItem: ViewStyle = {
  //   paddingTop: spacing.sm,
}

const $tabBarLabel: TextStyle = {
  fontSize: 12,
  fontFamily: typography.primary.medium,
  lineHeight: 16,
  flex: 1,
}
