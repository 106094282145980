import { Text, View, ViewStyle, TextStyle, FlatList, Platform } from "react-native"
import React, { FC, useCallback } from "react"
import { colors, fitSize } from "../../../theme"
import { Touchable } from "../../../components"
import { SizeImage } from "../../../components/SizeImage"
import { ImageStyle } from "react-native-fast-image"
import dayjs from "dayjs"
import { VideoBox } from "./video/VideoBox"
import { FlashList } from "@shopify/flash-list"
import { RarityIcon } from "./RarityIcon"
import { MultiPlatformVideoPlayer } from "../../../components/MultiPlatformVideoPlayer"

export const videoStyle: ViewStyle = {
  width: fitSize(137),
  height: fitSize(248),
  borderRadius: fitSize(4),
}

type dataItem = {
  id: number
  name: string
  video: string
  rarityId: number
  rarityIcon: string
  actionAriseTime: string
  videoFrameImage: string
  isPaused?: boolean
  titleJumpTime: string
  frameVImage: string
  image: string
}
interface TimeDisplayListProps {
  timeData: dataItem[]
  onUpdateIsPaused: (id: number, isPaused: boolean) => void
}

export const TimeDisplayList: FC<TimeDisplayListProps> = ({ timeData, onUpdateIsPaused }) => {
  const onViewableItemsChanged = ({ viewableItems }) => {
    for (let i = 0; i < timeData.length; i++) {
      const isVisible = viewableItems.some((viewItem) => viewItem.item.id === timeData[i].id)
      onUpdateIsPaused(timeData[i].id, !isVisible)
    }
  }

  return (
    <FlashList
      data={timeData}
      horizontal={true}
      showsVerticalScrollIndicator={false}
      directionalLockEnabled={true}
      viewabilityConfig={{
        waitForInteraction: true,
        itemVisiblePercentThreshold: 50,
        minimumViewTime: 1500,
      }}
      onViewableItemsChanged={onViewableItemsChanged}
      showsHorizontalScrollIndicator={false}
      disableHorizontalListHeightMeasurement={true}
      refreshing={false}
      renderItem={({ item, index }) => {
        return (
          <Touchable style={$cardBox} key={index} disabled={true}>
            <SizeImage
              source={{
                uri: item?.frameVImage,
              }}
              style={$cardVideo}
              resizeMode="contain"
            >
              <VideoBox
                data={{
                  src: item?.video,
                  titleJumpTime: item?.titleJumpTime,
                }}
                isPaused={item.isPaused}
                onUpdateIsPaused={(isPaused) => onUpdateIsPaused(item.id, isPaused)}
                poster={item?.image}
              />
            </SizeImage>
            <View style={$cardInfo}>
              <Text style={$cardInfoNameTxt}>{item?.name}</Text>
              <View style={$cardInfoTimeBox}>
                {/* <SizeImage
                  source={{ uri: item?.rarityIcon }}
                  style={$cardInfoTypeIcon}
                  resizeMode="contain"
                /> */}
                <RarityIcon icon={item?.rarityIcon} />
                <Text style={$cardInfoTimeTxt}>
                  {dayjs(item?.actionAriseTime).format("YYYY.MM.DD")}
                </Text>
              </View>
            </View>
          </Touchable>
        )
      }}
      estimatedItemSize={fitSize(144)}
      estimatedListSize={{ height: fitSize(306), width: fitSize(144) }}
    />
  )
}

const $container: ViewStyle = {
  width: "100%",
  flexGrow: 1,
  marginTop: fitSize(14),
}

const $cardBox: ViewStyle = {
  width: fitSize(144),
  height: fitSize(306),
  marginRight: fitSize(14),
}

const $cardVideo: ImageStyle = {
  width: fitSize(144),
  height: fitSize(250),
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
}

const $cardInfo: ViewStyle = {
  flexGrow: 1,
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  marginTop: fitSize(12),
}

const $cardInfoNameTxt: TextStyle = {
  fontWeight: "500",
  fontSize: fitSize(14),
  color: colors.palette.neutral100,
  marginBottom: fitSize(6),
  fontFamily: "PingFang_SC_Semibold",
}

const $cardInfoTimeBox: ViewStyle = {
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
}

const $cardInfoTypeIcon: ImageStyle = {
  width: "auto",
  height: fitSize(16),
  marginRight: fitSize(4),
}

const $cardInfoTimeTxt: TextStyle = {
  fontWeight: "500",
  fontSize: fitSize(12),
  color: colors.palette.neutral50,
  fontFamily: "PingFang_SC_Semibold",
}
