import { colors, fitSize } from "../../theme"
import { Image, Platform, StyleSheet, Text, View } from "react-native"
import React, { useEffect, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { Screen } from "../../components"
import { Sheader } from "../../components/Sheader"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import dayjs from "dayjs"
import { CopyBox } from "../PayProcess/ShowOrder"
import { ReceiveDetailData, api } from "../../services/api"
import { GToast } from "../../navigators"
import { SizeImage } from "../../components/SizeImage"
import { DashedLine } from "../TabStore/StoreScreen"
import { RarityIcon } from "../TabHome/components/RarityIcon"

interface ReceiveDetailProps extends RootStackNavProps<"ReceiveDetail"> {}

const typeMap = {
  1: "接收",
  2: "发送",
}

export const ReceiveDetail: React.FC<ReceiveDetailProps> = ({ navigation, route }) => {
  const [detail, setDetail] = useState<ReceiveDetailData>()

  const fetchDetailData = async () => {
    try {
      const res = await api.getSendReceiveDetail(route.params.id)
      if (res.kind === "ok") {
        setDetail(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    fetchDetailData()
  }, [])

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("screen.receiveDetail.header")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <View style={style.headerInfoContent}>
          <View style={style.imgContainer}>
            <View
              style={{
                width: fitSize(102),
                height: fitSize(96),
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SizeImage source={{ uri: detail?.image }} style={style.image} resizeMode="contain" />
            </View>
            <View style={style.infoContainer}>
              <View style={style.infoContent}>
                <Text style={style.nameText} numberOfLines={1}>
                  {detail?.name}
                </Text>
                <Text style={style.clubText} numberOfLines={1}>
                  {detail?.club}
                </Text>
              </View>
              <View style={style.actionContent}>
                <Text style={style.actionNameText}>{detail?.actionName}</Text>
                <DashedLine color={colors.palette.neutral30} />
                <Text style={style.actionAriseTimeText}>
                  {dayjs(detail?.createdAt).format("YYYY.MM.DD")}
                </Text>
              </View>
              <View style={style.rowCenter}>
                <Text style={style.cardBoxNameText}>{detail?.cardBoxName}</Text>
                <DashedLine color={colors.palette.neutral30} />
                <Text style={style.seriesNameText}>{detail?.seriesName}</Text>
              </View>
            </View>
          </View>
          <View style={style.divideLine} />
          <View style={style.bottomContent}>
            <View style={style.row}>
              <RarityIcon icon={detail?.rarityIcon} />
              <View style={style.rowCenter}>
                <Text style={style.idText}>#{detail?.assetsNumber}</Text>
                <Text style={style.circulationText}>/{detail?.circulation}</Text>
              </View>
            </View>
            <View style={style.rowEnd}>
              {detail?.honorImages?.map((item, index) => {
                return <SizeImage source={{ uri: item }} style={style.honorIcon} key={index} />
              })}
            </View>
          </View>
        </View>
        <Text style={style.title}>{translate("screen.receiveDetail.title")}</Text>
        <View style={style.titleWarpContent}>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>{translate("screen.receiveDetail.type")}</Text>
            <Text style={style.titleWarpContentText}>{typeMap[detail?.operating] ?? "-"}</Text>
          </View>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>
              {translate("screen.receiveDetail.status")}
            </Text>
            <Text style={style.titleWarpContentText}>{detail?.status ?? "-"}</Text>
          </View>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>{translate("screen.receiveDetail.from")}</Text>
            <View style={style.rowCenterOne}>
              <Text style={[style.titleWarpContentText, { width: fitSize(200) }]} numberOfLines={1}>
                {detail?.fromAddress ?? "-"}
              </Text>
              <CopyBox text={detail?.fromAddress} />
            </View>
          </View>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>{translate("screen.receiveDetail.to")}</Text>
            <View style={style.rowCenterOne}>
              <Text style={[style.titleWarpContentText, { width: fitSize(200) }]} numberOfLines={1}>
                {detail?.toAddress ?? "-"}
              </Text>
              <CopyBox text={detail?.toAddress} />
            </View>
          </View>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>{translate("screen.receiveDetail.txid")}</Text>
            <View style={style.rowCenterOne}>
              <Text style={[style.titleWarpContentText, { width: fitSize(200) }]} numberOfLines={1}>
                {detail?.hashBlock ?? "-"}
              </Text>
              <CopyBox text={detail?.hashBlock} />
            </View>
          </View>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>{translate("screen.receiveDetail.time")}</Text>
            <Text style={style.titleWarpContentText}>
              {dayjs(detail?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </Text>
          </View>
          <View style={[style.titleWarpBox, { marginBottom: 0 }]}>
            <Text style={style.titleWarpContentText}>
              {translate("screen.receiveDetail.source")}
            </Text>
            <Text style={style.titleWarpContentText}>{detail?.source ?? "-"}</Text>
          </View>
        </View>
      </View>
    </Screen>
  )
}
const style = StyleSheet.create({
  rowCenterOne: {
    flexDirection: "row",
    alignItems: "center",
  },
  dashedLine: {
    flexGrow: 1,
    height: 1,
    borderBottomColor: colors.palette.neutral50,
    borderBottomWidth: 1,
    marginHorizontal: fitSize(6),
    borderWidth: 1,
    borderColor: colors.palette.neutral30,
    borderStyle: Platform.select({ ios: "solid", android: "dashed", web: "dashed" }),
  },
  rowEnd: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  honorIcon: {
    width: fitSize(18),
    height: fitSize(18),
    marginLeft: fitSize(10),
  },
  imgContainer: {
    flexDirection: "row",
    width: "100%",
    height: fitSize(96),
    overflow: "hidden",
    paddingRight: fitSize(6),
  },
  image: {
    width: fitSize(76),
    height: fitSize(71.53),
  },
  infoContainer: {
    marginLeft: fitSize(12),
    flexDirection: "column",
    flexGrow: 1,
  },
  infoContent: {
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-end",
    paddingTop: fitSize(10),
    marginBottom: fitSize(14),
  },
  nameText: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    width: fitSize(70),
    overflow: "hidden",
  },
  clubText: {
    fontSize: fitSize(11),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    width: fitSize(70),
    overflow: "hidden",
  },
  sendBox: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  sendIcon: {
    width: fitSize(22),
    height: fitSize(22),
  },
  actionContent: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginBottom: fitSize(10),
  },
  actionNameText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  actionAriseTimeText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  cardBoxNameText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  seriesNameText: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
  },
  divideLine: {
    height: 1,
    width: "100%",
    backgroundColor: colors.palette.neutral50,
    marginTop: fitSize(10),
  },
  bottomContent: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: fitSize(7),
    paddingRight: fitSize(5),
  },
  row: {
    flexDirection: "row",
  },
  rarityIcon: {
    minWidth: fitSize(14),
    maxWidth: fitSize(24),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  idText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
  },
  circulationText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
    paddingTop: fitSize(1),
  },
  headerInfoContent: {
    width: fitSize(347),
    height: fitSize(159),
    borderWidth: 1,
    borderColor: colors.palette.neutral30,
    borderRadius: fitSize(8),
    paddingTop: fitSize(15),
    paddingHorizontal: fitSize(8),
    overflow: "hidden",
  },
  title: {
    color: colors.palette.neutral100,
    fontSize: fitSize(14),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginTop: fitSize(20),
    marginBottom: fitSize(14),
  },
  titleWarpBox: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: fitSize(14),
  },
  titleWarpContent: {
    width: "100%",
    paddingVertical: fitSize(14),
    paddingHorizontal: fitSize(10),
    borderRadius: fitSize(8),
    borderWidth: 1,
    borderColor: colors.palette.neutral30,
    minHeight: fitSize(39),
  },
  titleWarpContentText: {
    color: colors.palette.neutral50,
    fontSize: fitSize(12),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  content: {
    flex: 1,
    backgroundColor: colors.background,
    paddingHorizontal: fitSize(14),
    marginTop: fitSize(20),
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
