import { colors, fitSize } from "../../theme"
import { Platform, StyleSheet, TextInput, View } from "react-native"
import React, { useEffect, useRef, useState } from "react"
import { Screen, Text, Touchable, outlineStyle } from "../../components"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import _ from "lodash"
import { useStores } from "../../models"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { hideLoad, showInfo, showLoad } from "../../utils/showUtil"
import { api } from "../../services/api"
import { GToast, goBack } from "../../navigators"

interface GetAwardFromCodeProps extends RootStackNavProps<"GetAwardFromCode"> {}

export const GetAwardFromCode: React.FC<GetAwardFromCodeProps> = ({ navigation }) => {
  const [code, setCode] = useState("")
  const { accountStore } = useStores()
  const input = useRef<TextInput>()
  const [originalHeight, setOriginalHeight] = useState(window.innerHeight)

  if (Platform.OS === "web") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const handleResize = () => {
        const currentHeight = window.innerHeight

        if (currentHeight < originalHeight) {
          document.getElementById("footerCode").style.display = "none"
        } else {
          document.getElementById("footerCode").style.display = "flex"
        }
      }
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [originalHeight])
  }

  const checkCode = async () => {
    try {
      showLoad("上链中")
      const res = await api.getAwardFromCode(code)
      if (res.kind === "ok") {
        // setInfo(res.data)
        // goBack()
        showInfo("兑换成功")
        setCode("")
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
      hideLoad()
    }
  }

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("screen.getAwardFromCode.header")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <View style={style.box}>
          <TextInput
            placeholder={translate("screen.getAwardFromCode.inputPlaceholder")}
            value={code}
            onChangeText={setCode}
            placeholderTextColor={colors.palette.neutral400}
            style={[style.input, outlineStyle]}
            ref={input}
          />
        </View>
        <Touchable
          onPress={() => {
            if (accountStore.user?.isReal === 1) {
              checkCode()
            } else {
              showInfo("未实名", "TOAST")
              navigation.navigate("RealName")
            }
          }}
          disabled={!code}
          style={style.btn}
        >
          <Text style={style.btnText}>{translate("screen.getAwardFromCode.btnText")}</Text>
        </Touchable>
      </View>
      <View style={style.bottomBar}>
        <Text style={style.infoTitle}>{translate("screen.getAwardFromCode.infoTitle")}</Text>
        <Text style={style.infoContent}>{translate("screen.getAwardFromCode.infoContent")}</Text>
        <Text style={[style.tip]} id="footerCode">
          {translate("screen.getAwardFromCode.tip")}
        </Text>
      </View>
    </Screen>
  )
}

const style = StyleSheet.create({
  tip: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
    alignSelf: "center",
    position: "absolute",
    bottom: 50,
  },
  infoContent: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(20),
    width: "100%",
  },
  infoTitle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  bottomBar: {
    flexGrow: 1,
    width: "100%",
    paddingHorizontal: fitSize(14),
    marginTop: fitSize(40),
  },
  infoBox: {
    width: fitSize(279),
    alignSelf: "center",
    paddingTop: fitSize(30),
  },
  btnText: {
    color: colors.palette.neutral900,
    fontSize: fitSize(15),
    fontWeight: "bold",
  },
  btn: {
    width: fitSize(279),
    height: fitSize(50),
    backgroundColor: "#6FEBE8",
    borderRadius: fitSize(25),
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    marginTop: fitSize(19),
  },
  input: {
    textAlign: "center",
    width: fitSize(279),
    height: fitSize(50),
    padding: 0,
    borderRadius: fitSize(25),
    paddingHorizontal: fitSize(10),
    color: colors.palette.neutral900,
    backgroundColor: colors.palette.neutral100,
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
  content: {
    width: fitSize(347),
    height: fitSize(188),
    alignSelf: "center",
    marginTop: fitSize(30),
    borderRadius: fitSize(5),
    backgroundColor: colors.palette.neutral30,
  },
  box: {
    paddingTop: fitSize(25),
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
  },
})
