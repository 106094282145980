import { Images, colors, fitSize } from "../../theme"
import { Image, StyleSheet, Text, View } from "react-native"
import React from "react"
import { Screen, Touchable } from "../../components"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import _ from "lodash"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { FlashList } from "@shopify/flash-list"
import { CompositeRecordItem, api } from "../../services/api"
import { GToast } from "../../navigators"
import { useAutoList } from "../../hooks/useAutoList"
import { ComposeHisItem } from "./components/ComposeHisItem"
import { HookFlatList } from "../../components/list/HookFlatList"

interface ComposeHisListProps extends RootStackNavProps<"ComposeHisList"> {}

const PL = 20
export const ComposeHisList: React.FC<ComposeHisListProps> = ({ navigation }) => {
  const fetchGetSynthesisList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getCompositeRecord(lastId, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchGetSynthesisList,
      pageLimit: PL,
    },
    [],
  )

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <View style={style.header}>
        <Touchable onPress={() => navigation.goBack()}>
          <Image source={Images.components.header_back} style={style.leftIcon} />
        </Touchable>
      </View>
      <View style={style.headerTitleContainer}>
        <Text style={style.forShort}>{translate("screen.composeHisList.titleForShort")}</Text>
        <Text style={style.headerTitle}>{translate("screen.composeHisList.header")}</Text>
      </View>
      <View style={style.content}>
        <HookFlatList
          {...fetchList}
          isTransverseLoad={true}
          data={fetchList.data}
          isShow={fetchList.data && fetchList.data.length > 0}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <ComposeHisItem item={item as unknown as CompositeRecordItem} />
          )}
          contentContainerStyle={style.contentContainerStyle}
          estimatedItemSize={fitSize(130)}
          // estimatedListSize={{ height: fitSize(130), width: fitSize(347) }}
        />
      </View>
    </Screen>
  )
}

const style = StyleSheet.create({
  contentContainerStyle: {
    paddingBottom: fitSize(20),
  },
  content: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  headerTitle: {
    fontSize: fitSize(25),
    color: colors.palette.neutral100,
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  forShort: {
    fontSize: fitSize(16),
    color: colors.palette.neutral20,
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  headerTitleContainer: {
    width: "100%",
    flexDirection: "column",
    height: fitSize(50),
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: fitSize(23),
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingHorizontal: fitSize(14),
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    height: fitSize(44),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
})
