import { Image, StyleSheet, View } from "react-native"
import React from "react"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Text } from "./Text"
import { deviceWidth, fitSize, gStyle } from '../theme/layout';
import { Touchable } from "./Button";

interface shareModalProps {
  cancel: () => void
}

export const ShareModal: React.FC<shareModalProps> = ({ cancel }) => {
  const { bottom } = useSafeAreaInsets()
  return (
    <View style={[styles.container, { minHeight: fitSize(266) + bottom }]}>
      <Text style={styles.shareText}>分享到</Text>
      <View
        style={[
          gStyle.horizontalView,
          {
            marginTop: fitSize(24),
          },
        ]}
      >
        <View style={{ alignItems: "center" }}>
          <Touchable style={styles.button}>
            {/* <Image
              source={Images.activity.wechat}
              style={{
                width: fitSize(64),
                height: fitSize(64),
                resizeMode: "contain",
              }}
            /> */}
          </Touchable>
          <Text style={styles.btnText}>微信好友</Text>
        </View>
        <View style={{ alignItems: "center", marginLeft: fitSize(30) }}>
          <Touchable style={styles.button}>
            {/* <Image
              source={Images.activity.zone}
              style={{
                width: fitSize(64),
                height: fitSize(64),
                resizeMode: "contain",
              }}
            /> */}
          </Touchable>
          <Text style={styles.btnText}>朋友圈</Text>
        </View>
        <View style={{ alignItems: "center", marginLeft: fitSize(30) }}>
          <Touchable style={styles.button}>
            {/* <Image
              source={Images.activity.sina}
              style={{
                width: fitSize(64),
                height: fitSize(64),
                resizeMode: "contain",
              }}
            /> */}
          </Touchable>
          <Text style={styles.btnText}>微博</Text>
        </View>
      </View>
      <Touchable onPress={cancel} style={styles.bottomWrap}>
        <Text style={{ fontSize: fitSize(18), color: "#373737" }}>取消</Text>
      </Touchable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: fitSize(10),
    borderTopRightRadius: fitSize(10),
    backgroundColor: "#fff",
    alignItems: "flex-start",
    paddingTop: fitSize(35),
    paddingLeft: fitSize(32),
  },
  button: {
    width: fitSize(60),
    height: fitSize(60),
    backgroundColor: "#F1F1F1",
    borderRadius: fitSize(10),
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    color: "#929292",
    fontSize: fitSize(12),
    marginTop: fitSize(5),
  },
  bottomWrap: {
    width: deviceWidth,
    height: fitSize(61),
    justifyContent: "center",
    alignItems: "center",
    marginTop: fitSize(38),
    borderTopColor: "#F1F1F1",
    borderTopWidth: fitSize(1),
    marginLeft: fitSize(-32),
  },
  shareText: {
    fontSize: fitSize(16),
  },
})
