import { View, StyleSheet, Text, Dimensions } from "react-native"
import React, { useLayoutEffect, useRef } from "react"
import { TabNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { colors, fitSize } from "../../theme"
import { Screen, Touchable } from "../../components"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { FlashList } from "@shopify/flash-list"
import { SynthesisItem, api } from "../../services/api"
import { GToast } from "../../navigators"
import { useAutoList } from "../../hooks/useAutoList"
import { CompItem } from "./components/CompItem"
import { useSecond } from "../../hooks/useSecond"
import { useStores } from "../../models"
import { HookFlatList } from "../../components/list/HookFlatList"
import { useFocusEffect } from "@react-navigation/native"
import { da } from "date-fns/locale"

interface CompScreenProps extends TabNavProps<"TabThree"> {}

const PL = 20
export const CompScreen: React.FC<CompScreenProps> = observer(({ navigation, route }) => {
  const { currentTime } = useSecond()
  const { accountStore } = useStores()
  const flatListRef = useRef(null)

  useFocusEffect(
    React.useCallback(() => {
      fetchList.onRefresh()
    }, [route]),
  )

  const fetchGetSynthesisList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getSynthesisList(lastId, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchGetSynthesisList,
      pageLimit: PL,
    },
    [],
  )

  useLayoutEffect(() => {
    if (accountStore.tabScrollUp.tabThree && flatListRef.current && fetchList.data.length > 0) {
      // flatListRef.current.scrollToOffset({ offset: 0, animated: true })
      flatListRef.current.scrollToIndex({ index: 0, animated: true })
    }
  }, [accountStore.tabScrollUp.tabThree])

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <View style={style.header}>
        <Text style={style.headerTitle}>{translate("screen.compScreen.header")}</Text>
        <Touchable onPress={() => navigation.navigate("ComposeHisList")}>
          <Text style={style.headerRight}>{translate("screen.compScreen.headerRight")}</Text>
        </Touchable>
      </View>
      <View style={style.content}>
        <HookFlatList
          isTransverseLoad={true}
          {...fetchList}
          ref={flatListRef}
          isShow={fetchList.data.length > 0}
          showsVerticalScrollIndicator={false}
          onScroll={(event) => {
            const offsetY = event.nativeEvent.contentOffset.y
            const threshold = Dimensions.get("window").height
            if (offsetY < threshold && accountStore.tabScrollUp.tabThree !== false) {
              accountStore.setTabScrollUp("tabThree", false)
            }
          }}
          renderItem={({ item }) => (
            <CompItem item={item as unknown as SynthesisItem} currentTime={currentTime} />
          )}
          estimatedItemSize={fitSize(200)}
          extraData={currentTime}
          // estimatedListSize={{ height: fitSize(510), width: fitSize(347) }}
        />
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  countdown: {
    fontSize: fitSize(18),
    fontWeight: "600",
    fontFamily: fontName.Poppins_ExtraBoldItalic_zitidi,
    color: colors.palette.neutral100,
    letterSpacing: fitSize(2),
    lineHeight: fitSize(27),
  },
  countdownBox: {
    width: fitSize(120),
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  timeLabelBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  timeLabel: {
    fontSize: fitSize(10),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    color: colors.palette.neutral50,
  },
  closeCountdown: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  itemFooterInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(50),
  },
  itemFooter: {
    width: "100%",
    height: fitSize(150),
    marginTop: fitSize(7),
    flexDirection: "column",
    paddingHorizontal: fitSize(14),
  },
  itemDescription: {
    fontSize: fitSize(14),
    color: colors.palette.neutral20,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  itemName: {
    fontSize: fitSize(24),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
    marginBottom: fitSize(10),
    lineHeight: fitSize(33),
  },
  typeLabelBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: fitSize(10),
  },
  itemTypeIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  itemLabel: {
    fontSize: fitSize(11),
    color: colors.palette.neutral20,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
  },
  itemBody: {
    width: "100%",
    height: fitSize(150),
    paddingTop: fitSize(20),
    paddingHorizontal: fitSize(15),
    flexDirection: "column",
    alignItems: "flex-start",
  },
  itemImage: {
    width: "100%",
    height: fitSize(186),
  },
  itemContent: {
    width: "100%",
    height: "100%",
    padding: fitSize(9),
    overflow: "hidden",
  },
  item: {
    width: "100%",
    height: fitSize(510),
    marginBottom: fitSize(30),
    overflow: "hidden",
  },
  headerRight: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  headerTitle: {
    fontSize: fitSize(30),
    color: colors.palette.neutral100,
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  header: {
    flexDirection: "row",
    width: "100%",
    height: fitSize(40),
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: fitSize(16),
    marginBottom: fitSize(10),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
    paddingHorizontal: fitSize(14),
  },
  content: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
})
