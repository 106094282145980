import {
  Platform,
  View,
  Text,
  ViewStyle,
  TextStyle,
  StatusBar,
  Dimensions,
  Animated,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
} from "react-native"
import React, { useEffect, useLayoutEffect, useReducer, useRef, useState } from "react"
import { fitSize, colors, Images } from "../../theme"
import SplashScreen from "react-native-splash-screen"
import { TabNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { useFocusEffect, useIsFocused } from "@react-navigation/native"
import { ImageStyle } from "react-native-fast-image"
import { useLayoutHook } from "../TabHome/hooks/useLayoutHook"
import { Swiper } from "./components/Swiper"
import { TimeDisplayList } from "./components/TimeDisplayList"
import { CardBagList } from "./components/CardBagList"
import { TipCard } from "./components/TipCard"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { TeachingVideo } from "./components/TeachingVideo"
import { api } from "../../services/api"
import { useStores } from "../../models/helpers/useStores"
import { SaveAppBar } from "./components/SaveAppBar.web"
import { PrizeCenter } from "./components/PrizeCenter"
import { TidelandsList } from "./components/TidelandsList"
import { translate } from "../../i18n/translate"
import { GToast } from "../../navigators"
import { isWebView } from "../../hooks/useInit"
import { BlurView } from "expo-blur"
import { LinearGradient } from "expo-linear-gradient"
import { RefreshControl } from "react-native-web-refresh-control"
import { SizeImage } from "../../components/SizeImage"
import { Touchable } from "../../components"

type showContainerState = {
  header: boolean
  banner: boolean
  card: boolean
  time: boolean
  tide: boolean
  prize: boolean
  teaching: boolean
  invite: boolean
}

type TypeName = "BANNER" | "CARD" | "TIME" | "TIDE" | "TEACHING" | "INVITE" | "PRIZE" | "HEADER"

type showContainerAction = {
  type: TypeName
  value: boolean
}

const showContainerReducer = (state: showContainerState, action: showContainerAction) => {
  const { type, value } = action
  switch (type) {
    case "HEADER":
      return { ...state, header: value }
    case "BANNER":
      return { ...state, banner: value }
    case "CARD":
      return { ...state, card: value }
    case "TIME":
      return { ...state, time: value }
    case "TIDE":
      return { ...state, tide: value }
    case "PRIZE":
      return { ...state, prize: value }
    case "TEACHING":
      return { ...state, teaching: value }
    case "INVITE":
      return { ...state, invite: value }
    default:
      throw new Error()
  }
}

interface HomeScreenProps extends TabNavProps<"TabOne"> {}

export const HomeScreen: React.FC<HomeScreenProps> = observer(({ navigation, route }) => {
  const ly = useLayoutHook()
  const { top } = useSafeAreaInsets()
  const [timeData, setTimeData] = useState([])
  const { accountStore } = useStores()
  const [isShow, setIsShow] = useState(false)
  const fadeAnim = useRef(new Animated.Value(0)).current
  const FADE_HEIGHT = Platform.OS === "web" ? fitSize(230) : fitSize(200)
  const lisRef = useRef(null)
  const [rollingDistance, setRollingDistance] = useState(0)
  const [showContainer, setShowContainer] = useReducer(showContainerReducer, {
    header: true,
    banner: true,
    card: true,
    time: false,
    tide: false,
    prize: false,
    teaching: false,
    invite: false,
  })
  const [bgColor, setBgColors] = useState<string[]>([])
  const [isAnimating, setIsAnimating] = useState(false)
  const isFocus = useIsFocused()
  const [topRefreshing, setTopRefreshing] = useState(false)
  const cardBagRef = useRef(null)
  const [isNewNotice, setIsNewNotice] = useState(false)

  useFocusEffect(
    React.useCallback(() => {
      if (isWebView()) {
        document.getElementById("wxtip").style.display = "none"
      }
      onRefresh()
      setIsShow(true)
      return () => {
        setIsShow(false)
      }
    }, [route]),
  )

  const fetchGetShowTimeList = async () => {
    try {
      const res = await api.getShowTimeList()
      if (res.kind === "ok") {
        const arr = res.data.list.map((i, index) => {
          return { ...i, isPaused: index < 2 ? false : true }
        })
        setTimeData(arr)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    const doit = async () => {
      if (Platform.OS === "ios" || Platform.OS === "android") {
        SplashScreen.hide()
      }
    }
    doit()
  }, [])

  const onRankingList = () => {
    console.info("排行榜")
  }

  const onTipList = () => {
    navigation.push("MessageList")
  }

  const backgroundColor = fadeAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [colors.transparent, colors.palette.neutral900],
  })

  useLayoutEffect(() => {
    if (accountStore.tabScrollUp.tabOne && lisRef.current) {
      // lisRef.current.scrollToIndex({ index: 0, animated: true })
      lisRef.current.scrollTo({ x: 0, y: 0, animated: true })
    }
  }, [accountStore.tabScrollUp.tabOne])

  const handleUpdateIsPaused = (itemId: number, isPaused: boolean) => {
    setTimeData((prevTimeData) =>
      prevTimeData.map((item) => (item.id === itemId ? { ...item, isPaused } : item)),
    )
  }

  // const memoizedSwiper = useMemo(
  //   () => (
  //     <View style={$bannerContainer}>
  //       <Swiper
  //         data={ly?.bannerList.map((i) => {
  //           return { ...i, isPaused: true }
  //         })}
  //         w={fitSize(347)}
  //         backdropColor={setBgColors}
  //       />
  //     </View>
  //   ),
  //   [ly.bannerList],
  // )

  // const memoizedCardBagList = useMemo(
  //   () => (
  //     <View
  //       style={[
  //         $cardBagListContainer,
  //         { marginTop: timeData.length === 0 ? fitSize(11) : fitSize(30) },
  //       ]}
  //     >
  //       <Text style={$listTitle}>{translate("screen.tabHome.allCardBag")}</Text>
  //       <CardBagList />
  //     </View>
  //   ),
  //   [],
  // )

  // const memoizedTimeDisplayList = useMemo(
  //   () => (

  //   ),
  //   [timeData],
  // )

  // const memoizedTidelandsList = useMemo(
  //   () => (
  //     <View style={$tidelandsListContainer}>
  //       <Text style={$listTitle}>{translate("screen.tabHome.tidelandsList")}</Text>
  //       <TidelandsList
  //         data={ly.tidelandsList.map((i) => {
  //           return { ...i, isPaused: true, poster: i.link }
  //         })}
  //       />
  //     </View>
  //   ),
  //   [ly.tidelandsList],
  // )

  // const memoizedPrizeCenter = useMemo(
  //   () => (
  //     <View style={$prizeCenterContainer}>
  //       <Text style={$listTitle}>{translate("screen.tabHome.prizeCenter")}</Text>
  //       <PrizeCenter
  //         data={ly.prizeList.map((i) => {
  //           return { ...i, isPaused: true, poster: i.link }
  //         })}
  //       />
  //     </View>
  //   ),
  //   [ly.prizeList],
  // )

  // const memoizedTeachingVideo = useMemo(
  //   () => (
  //     <View style={$teachingVideoContainer}>
  //       <Text style={$listTitle}>{translate("screen.tabHome.teachingVideo")}</Text>
  //       <TeachingVideo
  //         data={ly.teachingList.map((i) => {
  //           return { ...i, isPaused: true, poster: i.link }
  //         })}
  //       />
  //     </View>
  //   ),
  //   [ly.teachingList],
  // )

  // const memoizedTipCard = useMemo(
  //   () => (
  //     <View style={$inviteFriendsContainer}>
  //       <Text style={$listTitle}>{translate("screen.tabHome.invite")}</Text>
  //       <TipCard
  //         data={ly.inviteList.map((i) => {
  //           return { ...i, isPaused: true }
  //         })}
  //       />
  //     </View>
  //   ),
  //   [ly.inviteList],
  // )

  const $headerContainerStateStyle: ViewStyle = {
    height: fitSize(40) + top,
  }

  // const memoizedHeader = useMemo(
  //   () => (
  //     <BlurView intensity={30} style={[$headerContainer, $headerContainerStateStyle]}>
  //       <Animated.View style={[$barStyle, { backgroundColor }]}>
  //         <Text style={$titleTxt}>{translate("screen.tabHome.title")}</Text>
  //         <View style={$titleIconContent}></View>
  //       </Animated.View>
  //     </BlurView>
  //   ),
  //   [bgColor],
  // )
  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const offsetY = event.nativeEvent.contentOffset.y
    const threshold = Dimensions.get("window").height
    setRollingDistance(offsetY)
    if (offsetY < threshold && accountStore.tabScrollUp.tabOne !== false) {
      accountStore.setTabScrollUp("tabOne", false)
    }
    setIsAnimating(offsetY > FADE_HEIGHT)
  }

  const onViewableItemsChanged = ({ viewableItems }) => {
    // const visibleItemKeys = viewableItems.map((item) => item.item.key)
    // for (const key in showContainer) {
    //   const value = visibleItemKeys.includes(key)
    //   setShowContainer({ type: key.toUpperCase() as TypeName, value })
    // }
  }

  const onRefresh = async () => {
    try {
      setTopRefreshing(true)
      await Promise.all([
        fetchGetShowTimeList(),
        ly.fetchGetBannerList(),
        cardBagRef.current && cardBagRef.current?.onRefresh(),
        fetchNewNotice(),
      ])
        .then(() => {
          setTopRefreshing(false)
        })
        .catch(() => {
          setTopRefreshing(false)
        })
    } catch (error) {
      console.log("error", error)
    }
  }

  const fetchNewNotice = async () => {
    try {
      const res = await api.getNewNotice()
      if (res.kind === "ok") {
        setIsNewNotice(res.data.isNew)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const $blurView: ViewStyle = {
    justifyContent: "flex-end",
    height: fitSize(230) + top,
    position: "absolute",
    zIndex: -2,
  }

  const $titleBar: ViewStyle = {
    overflow: "hidden",
    height: fitSize(44) + top,
    zIndex: 999,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: isAnimating ? colors.palette.neutral900 : colors.transparent,
  }

  const readNotice = () => {
    navigation.navigate("MessageList")
  }

  return (
    <View testID="TabHome" style={$screen}>
      <StatusBar backgroundColor={"transparent"} translucent={true} />
      <SaveAppBar />
      {/* <FlashList
        style={[$screen]}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        stickyHeaderIndices={[0]}
        scrollEventThrottle={16}
        ref={lisRef}
        onScroll={onScroll}
        data={[
          { key: "header", data: [] },
          { key: "banner", data: ly?.bannerList },
          { key: "card", data: [] },
          { key: "time", data: timeData },
          { key: "tide", data: ly?.tidelandsList },
          { key: "prize", data: ly?.prizeList },
          { key: "teaching", data: ly?.teachingList },
          { key: "invite", data: ly?.inviteList },
        ]}
        renderItem={({ item }) => {
          switch (item.key) {
            case "header":
              return memoizedHeader
            case "banner":
              return item.data.length > 0 ? memoizedSwiper : null
            case "card":
              return memoizedCardBagList
            case "time":
              return (
                <View style={[$timeDisplayListContainer]}>
                  <Text style={$listTimeTitle}>{translate("screen.tabHome.timeDisplay")}</Text>
                  <TimeDisplayList timeData={timeData} onUpdateIsPaused={handleUpdateIsPaused} />
                </View>
              )
            case "tide":
              return item.data.length > 0 ? memoizedTidelandsList : null
            case "prize":
              return item.data.length > 0 ? memoizedPrizeCenter : null
            case "teaching":
              return item.data.length > 0 ? memoizedTeachingVideo : null
            case "invite":
              return item.data.length > 0 ? memoizedTipCard : null
            default:
              return null
          }
        }}
        keyExtractor={(item, index) => item.key + index}
      /> */}
      <ScrollView
        style={[$screen]}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        stickyHeaderIndices={[0]}
        scrollEventThrottle={16}
        ref={lisRef}
        onScroll={onScroll}
        refreshControl={
          <RefreshControl
            onRefresh={onRefresh}
            refreshing={topRefreshing}
            enabled={true}
            tintColor={colors.palette.neutral100}
          />
        }
      >
        <Animated.View style={$titleBar}>
          <Text style={[$titleTxt, { top: Platform.OS !== "web" ? top - fitSize(7) : 0 }]}>
            {translate("screen.tabHome.title")}
          </Text>
          <Touchable style={$noticeBox} onPress={readNotice}>
            {isNewNotice && <View style={$readStyle} />}
            <SizeImage source={Images.screen.home.notice_icon} style={$noticeIcon} />
          </Touchable>
        </Animated.View>
        <BlurView intensity={0} style={$blurView}>
          <LinearGradient
            colors={
              !isAnimating
                ? [...bgColor, colors.palette.neutral900, colors.palette.neutral900]
                : [colors.palette.neutral900, colors.transparent, colors.transparent]
            }
            style={$title}
          >
            <View style={$titleIconContent}></View>
          </LinearGradient>
        </BlurView>
        {ly?.bannerList.length > 0 && (
          <View style={[$bannerContainer, {}]}>
            <Swiper
              data={ly?.bannerList.map((i) => {
                return { ...i, isPaused: true }
              })}
              w={fitSize(347)}
              backdropColor={setBgColors}
            />
          </View>
        )}
        <View style={[$cardBagListContainer]}>
          <Text style={$listTitle}>{translate("screen.tabHome.allCardBag")}</Text>
          <CardBagList ref={cardBagRef} />
        </View>
        {timeData.length > 0 && (
          <View style={[$timeDisplayListContainer]}>
            <Text style={$listTimeTitle}>{translate("screen.tabHome.timeDisplay")}</Text>
            {isFocus && (
              <TimeDisplayList timeData={timeData} onUpdateIsPaused={handleUpdateIsPaused} />
            )}
          </View>
        )}
        {ly.tidelandsList.length > 0 && (
          <View style={$tidelandsListContainer}>
            <Text style={$listTitle}>{translate("screen.tabHome.tidelandsList")}</Text>
            <TidelandsList
              data={ly.tidelandsList.map((i) => {
                return { ...i, isPaused: true, poster: i.link }
              })}
            />
          </View>
        )}
        {ly.prizeList.length > 0 && (
          <View style={$prizeCenterContainer}>
            <Text style={$listTitle}>{translate("screen.tabHome.prizeCenter")}</Text>
            <PrizeCenter
              data={ly.prizeList.map((i) => {
                return { ...i, isPaused: true, poster: i.link }
              })}
            />
          </View>
        )}
        {ly.teachingList.length > 0 && (
          <View style={$teachingVideoContainer}>
            <Text style={$listTitle}>{translate("screen.tabHome.teachingVideo")}</Text>
            <TeachingVideo
              data={ly.teachingList.map((i) => {
                return { ...i, isPaused: true, poster: i.link, muted: true }
              })}
            />
          </View>
        )}
        {ly.inviteList.length > 0 && (
          <View style={$inviteFriendsContainer}>
            <Text style={$listTitle}>{translate("screen.tabHome.invite")}</Text>
            <TipCard
              data={ly.inviteList.map((i) => {
                return { ...i, isPaused: true }
              })}
            />
          </View>
        )}
      </ScrollView>
    </View>
  )
})

const $noticeIcon: ImageStyle = {
  width: fitSize(20),
  height: fitSize(20),
}

const $noticeBox: ViewStyle = {
  flexDirection: "column",
  alignItems: "center",
  marginRight: fitSize(14),
  alignSelf: Platform.OS === "web" ? undefined : "flex-end",
}

const $title: TextStyle = {
  width: Dimensions.get("window").width,
  flexDirection: "row",
  justifyContent: "space-between",
  height: "100%",
}

const $readStyle: ViewStyle = {
  width: fitSize(7),
  height: fitSize(7),
  borderRadius: fitSize(7),
  position: "absolute",
  top: -3,
  right: fitSize(2),
  zIndex:100,
  backgroundColor: "#FB6547",
}

const $barStyle: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
}

const $screen: ViewStyle = {
  flex: 1,
  backgroundColor: colors.palette.neutral900,
}

const $headerContainer: ViewStyle = {
  justifyContent: "flex-end",
}

const $titleTxt: TextStyle = {
  fontSize: fitSize(25),
  color: colors.palette.neutral100,
  fontFamily: "YouSheBiaoTiHei_2",
  paddingLeft: fitSize(14),
}

const $titleIconContent: ViewStyle = {
  paddingBottom: fitSize(5),
  paddingRight: fitSize(14),
}

const $headerIcon: ImageStyle = {
  width: fitSize(20),
  height: fitSize(20),
  marginLeft: fitSize(20),
}

const $bannerContainer: ViewStyle = {
  width: "100%",
  height: fitSize(190),
  paddingLeft: fitSize(14),
  marginBottom: fitSize(30),
}

const $timeDisplayListContainer: ViewStyle = {
  width: "100%",
  flexDirection: "column",
  paddingLeft: fitSize(14),
  height: fitSize(330),
  marginBottom: fitSize(30),
}

const $cardBagListContainer: ViewStyle = {
  width: "100%",
  height: fitSize(535),
  flexDirection: "column",
  overflow: "hidden",
  paddingLeft: fitSize(14),
  marginBottom: fitSize(30),
}

const $inviteFriendsContainer: ViewStyle = {
  width: "100%",
  height: fitSize(170),
  flexDirection: "column",
  marginTop: fitSize(30),
  paddingLeft: fitSize(14),
}

const $teachingVideoContainer: ViewStyle = {
  width: "100%",
  height: fitSize(222),
  flexDirection: "column",
  borderRadius: fitSize(8),
  marginTop: fitSize(30),
  paddingLeft: fitSize(14),
}

const $listTitle: TextStyle = {
  fontWeight: "500",
  fontSize: fitSize(16),
  marginBottom: fitSize(14),
  color: colors.palette.neutral100,
  fontFamily: "PingFang_SC_Semibold",
}

const $listTimeTitle: TextStyle = {
  fontWeight: "500",
  fontSize: fitSize(16),
  color: colors.palette.neutral100,
  fontFamily: "PingFang_SC_Semibold",
  marginBottom: fitSize(14),
}

const $tidelandsListContainer: ViewStyle = {
  width: "100%",
  height: fitSize(222),
  flexDirection: "column",
  borderRadius: fitSize(8),
  marginTop: fitSize(30),
  paddingLeft: fitSize(14),
}

const $prizeCenterContainer: ViewStyle = {
  width: "100%",
  height: fitSize(222),
  flexDirection: "column",
  borderRadius: fitSize(8),
  marginTop: fitSize(30),
  paddingLeft: fitSize(14),
}
