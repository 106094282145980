import { observer } from "mobx-react-lite"
import { RootStackNavProps } from "../../type"
import { Screen, Touchable } from "../../components"
import { Image, Platform, StyleSheet, View, ViewStyle } from "react-native"
import { Images, colors, fitSize } from "../../theme"
import { useTokenAuthorization } from "../../hooks/useTokenAuthorization"
import { AssetData, AssetInfo, api } from "../../services/api"
import { useEffect, useRef, useState } from "react"
import { MultiPlatformVideoPlayer } from "../../components/MultiPlatformVideoPlayer"
import { CardDetailModal } from "./components/CardDetailModal"
import { CardDetailInfo } from "./components/CardDetailInfo"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Dimensions } from "react-native"
import { GToast } from "../../navigators"
import { WebView as WWebView } from "react-native-web-webview"
import { WebView } from "react-native-webview"
import { AllSeriesModal } from "./components/AllSeriesModal"

const MyWebView = Platform.OS === "web" ? WWebView : WebView

interface AssetDetailsProps extends RootStackNavProps<"AssetDetails"> {}

export const AssetDetails: React.FC<AssetDetailsProps> = observer(({ navigation, route }) => {
  const { authorization } = useTokenAuthorization()
  const [activityData, setActivityData] = useState<AssetData>()
  const [activityInfo, setActivityInfo] = useState<AssetInfo>()
  const [scrollViewState, setScrollViewState] = useState<"top" | "initial">("initial")
  const { top } = useSafeAreaInsets()
  const HEIGHT = Dimensions.get("window").height - fitSize(135) - (Platform.OS !== "web" ? top : 0)
  const WIDTH = Dimensions.get("window").width - fitSize(14)
  const [isPaused, setIsPaused] = useState(true)
  const [muted, setMuted] = useState(true)

  const fetchGetPlayerInfo = async (id: number) => {
    try {
      const res = await api.getPlayerInfo(id)
      if (res.kind === "ok") {
        setActivityInfo(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const fetchGetAssetDetail = async () => {
    try {
      const res = await api.getAssetDetail(route.params.id, route.params.assetId)
      if (res.kind === "ok") {
        if (Platform.OS === "web") {
          window.document.title = res.data.name
        }
        await fetchGetPlayerInfo(res.data.id)
        setActivityData(res.data)
        setIsPaused(false)
        if (res.data.isRead === 0) await read(res.data.id)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const read = async (id: number) => {
    try {
      const res = await api.readCurrentAsset([route.params.assetId || id])
      if (res.kind === "ok") {
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    fetchGetAssetDetail()
  }, [])

  const $content: ViewStyle = {
    width: WIDTH,
    height: HEIGHT,
    alignItems: "center",
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Touchable style={style.backBox} onPress={() => navigation.goBack()}>
        <Image source={Images.components.header_back} style={style.backIcon} />
      </Touchable>
      <Touchable
        style={[style.btnBox, { bottom: fitSize(135) + fitSize(20) }]}
        onPress={() => {
          setMuted(!muted)
        }}
      >
        <Image
          source={Images.screen.store[muted ? "default_mute" : "not_mute"]}
          style={style.bestView}
        />
      </Touchable>
      <View style={style.body}>
        {activityData?.video && (
          <View style={$content}>
            {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
              <MultiPlatformVideoPlayer
                uri={activityData?.video}
                isPaused={Platform.OS !== "web" ? false : isPaused}
                muted={muted}
                h={HEIGHT}
                w={WIDTH}
                isLook={false}
                loop={true}
              />
            ) : null}
          </View>
        )}
        <CardDetailModal setScrollViewState={setScrollViewState}>
          <CardDetailInfo
            item={activityData}
            info={activityInfo}
            route={route}
            scrollViewState={scrollViewState}
          />
        </CardDetailModal>
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  body: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: fitSize(10),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  backBox: {
    width: fitSize(20),
    height: fitSize(20),
    position: "absolute",
    top: 30,
    left: 10,
    zIndex: 2147483647,
  },
  backIcon: {
    width: fitSize(20),
    height: fitSize(20),
  },
  btnBox: {
    width: fitSize(44),
    height: fitSize(44),
    position: "absolute",
    right: 18,
    zIndex: 2147483647,
  },
  bestView: {
    width: "100%",
    height: "100%",
  },
})
