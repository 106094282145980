// import * as InAppPurchases from "expo-in-app-purchases"
import { Text, Touchable } from "../../../components"
import { StyleSheet, View, TextStyle } from "react-native"
import React, { FC, memo, useEffect, useMemo, useState } from "react"
import { HookFlatList } from "../../../components/list/HookFlatList"
import { SizeImage } from "../../../components/SizeImage"
import dayjs from "dayjs"
import { UserBuyList, api } from "../../../services/api"
import { colors, fitSize } from "../../../theme"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useAutoList } from "../../../hooks/useAutoList"
import { fontName } from "../../../theme/fonts"
import { translate } from "../../../i18n/translate"
import { GToast } from "../../../navigators"
import { FlashList } from "@shopify/flash-list"

interface BoughtViewProps {
  type: number
  change: number
}

interface ItemProps {
  item: UserBuyList
}

const statusMap = {
  1: translate("screen.orderBuy.paid"),
  2: translate("screen.orderBuy.beBeingSent"),
  3: translate("screen.orderBuy.sentSuccess"),
  4: translate("screen.orderBuy.sendFailure"),
}

export const PL = 20
let interval
export const BoughtView: React.FC<BoughtViewProps> = ({ type, change }) => {
  const navigation = useTypeNavigation()

  const Item: FC<ItemProps> = memo(
    ({ item }) => {
      const [countdown, setCountdown] = useState("")

      useEffect(() => {
        if (item?.status === 1 && interval) {
          const targetDate = dayjs(item?.expiredTime)
          interval = setInterval(() => {
            const now = dayjs()
            if (now.isBefore(targetDate)) {
              const remainingTime = targetDate.diff(now)
              const countdown = dayjs.duration(remainingTime).format("mm:ss")
              setCountdown(countdown)
            } else {
              console.log("当前时间已超过目标时间，执行相应操作")
              clearInterval(interval)
            }
          }, 1000)
        }
        return () => {
          clearInterval(interval)
        }
      }, [item?.expiredTime])

      const $state: TextStyle = {
        color: item.status === 1 ? colors.palette.primary500 : colors.palette.neutral100,
        fontSize: fitSize(12),
        fontFamily: fontName.PingFang_SC_Semibold,
        fontWeight: "500",
      }

      return (
        <Touchable
          onPress={() => {
            navigation.push("ShowOrder", { id: item.outTradeNo })
          }}
          style={[style.item]}
        >
          <View style={style.itemContent}>
            <Text style={style.createdAt}>
              {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </Text>
            <Text style={$state}>{item.statusText}</Text>
          </View>
          <View style={style.line}>
            <View style={style.lineBox} />
          </View>
          <View style={style.itemInfo}>
            <View style={style.topBox}>
              <View style={style.rowOne}>
                <SizeImage
                  source={{ uri: item?.goodsList ? item?.goodsList[0]?.imageMain : "" }}
                  style={style.icon}
                  resizeMode={"cover"}
                  isPreview
                />
                <View style={style.itemInfoContent}>
                  <Text numberOfLines={2} style={style.infoName}>
                    {item?.goodsList ? item?.goodsList[0]?.goodsName : ""}
                  </Text>
                  <Text style={style.series}>
                    {translate("screen.orderBuy.randomDeparture", {
                      number: item.goodsList ? item.goodsList[0].openNumber : 0,
                    })}
                  </Text>
                </View>
              </View>
              <View style={style.unitContent}>
                <Text style={style.unitIcon}>{translate("screen.orderBuy.unit")}</Text>
                <Text style={style.amount}>
                  {item?.goodsList ? item?.goodsList[0]?.amount : ""}
                </Text>
                <Text style={style.count}>x {item?.goodsList ? item?.goodsList[0]?.count : 0}</Text>
              </View>
            </View>
          </View>
          <View style={style.total}>
            <Text style={style.length}>
              {translate("screen.orderBuy.totalNumber", { total: item?.goodsList?.length })}
            </Text>
            <Text style={style.unitTwo}>{translate("screen.orderBuy.unit")} </Text>
            <Text style={style.payAmount}>{item?.payAmount}</Text>
          </View>
          {item?.status === 1 ? (
            <Touchable
              onPress={() => {
                navigation.push("ShowOrder", { id: String(item.outTradeNo) })
              }}
              style={[
                style.payButton,
                countdown ? { backgroundColor: colors.palette.primary500 } : { display: "none" },
              ]}
            >
              <Text style={style.payText}>
                {translate("screen.orderBuy.oncePay", {
                  number: countdown,
                })}
              </Text>
            </Touchable>
          ) : null}
        </Touchable>
      )
    },
    (prev, next) => prev.item.outTradeNo === next.item.outTradeNo,
  )

  const fetchBoughtList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getUserBuyList(lastId, type !== 0 ? type : undefined, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchBoughtList,
      pageLimit: PL,
    },
    [],
  )

  useEffect(() => {
    fetchList.onRefresh()
  }, [change])

  return (
    <View key={type} style={style.content}>
      <HookFlatList
        isShow={fetchList.data.length > 0}
        isTransverseLoad={true}
        {...fetchList}
        contentContainerStyle={style.contentContainerStyle}
        renderItem={({ item }) => <Item item={item as unknown as UserBuyList} />}
        estimatedItemSize={fitSize(181)}
        estimatedListSize={{ width: fitSize(347), height: fitSize(181) }}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      />
    </View>
  )
}

const style = StyleSheet.create({
  rowOne: {
    flexDirection: "row",
  },
  length: {
    color: colors.palette.neutral50,
    fontSize: fitSize(12),
  },
  total: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingHorizontal: fitSize(10),
    marginVertical: fitSize(20),
  },
  topBox: {
    flexDirection: "row",
    height: fitSize(60),
    width: "100%",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(10),
  },
  payButton: {
    width: fitSize(122),
    height: fitSize(32),
    borderRadius: fitSize(4),
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
    marginRight: fitSize(10),
    marginBottom: fitSize(18),
  },
  payText: {
    color: colors.palette.neutral30,
    fontSize: fitSize(13),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  payAmount: {
    color: colors.palette.primary500,
    fontSize: fitSize(12),
    fontWeight: "600",
    fontFamily: fontName.PingFang_SC,
  },
  unitTwo: {
    color: colors.palette.primary500,
    fontSize: fitSize(8),
    fontWeight: "600",
    fontFamily: fontName.PingFang_SC,
  },
  count: {
    color: colors.palette.neutral100,
    fontSize: fitSize(12),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    marginLeft: fitSize(10),
  },
  amount: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
    fontWeight: "400",
  },
  unitIcon: {
    fontSize: fitSize(10),
    fontWeight: "bold",
    color: colors.palette.neutral100,
  },
  unitContent: {
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  series: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  infoName: {
    fontSize: fitSize(15),
    color: colors.palette.neutral100,
    fontWeight: "500",
    width: "100%",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  itemInfoContent: {
    paddingLeft: fitSize(12),
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: fitSize(10),
    flexGrow: 1,
  },
  icon: {
    width: fitSize(60),
    height: fitSize(60),
  },
  itemInfo: {
    flexDirection: "row",
    flexGrow: 1,
    paddingTop: fitSize(16),
  },
  lineBox: {
    width: fitSize(327),
    height: 1,
    backgroundColor: colors.palette.neutral30,
  },
  line: {
    justifyContent: "center",
    alignItems: "center",
  },
  createdAt: {
    color: colors.palette.neutral50,
    fontSize: fitSize(12),
    width: fitSize(240),
    height: fitSize(25),
    textAlign: "left",
    lineHeight: fitSize(28),
  },
  itemContent: {
    height: fitSize(48),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(10),
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.palette.neutral30,
  },
  content: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingTop: fitSize(5),
  },
  item: {
    width: fitSize(347),
    alignSelf: "center",
    backgroundColor: colors.palette.neutral900,
    borderRadius: fitSize(8),
    marginTop: fitSize(24),
    borderWidth: 1,
    borderColor: colors.palette.neutral30,
    minHeight: fitSize(181),
    flexDirection: "column",
  },
})
