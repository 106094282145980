import { Images, colors, fitSize } from "../../theme"
import { Dimensions, Image, StyleSheet, View } from "react-native"
import { GToast } from "../../navigators"
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react"
import { Screen, Text, Touchable, TouchableDeep } from "../../components"
import { FlexView } from "../../components/FlexView"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { translate } from "../../i18n"
import { useStores } from "../../models"
import { api } from "../../services/api"
import { fontName } from "../../theme/fonts"
import { AvatarItem } from "../../services/api/api.types"
import { useAutoList } from "../../hooks/useAutoList"
import { HookFlatList } from "../../components/list/HookFlatList"
import { SizeImage } from "../../components/SizeImage"
import { FlashList } from "@shopify/flash-list"

interface ChooseAvatarProps extends RootStackNavProps<"ChooseAvatar"> {}
const PL = 20
export const ChooseAvatar: React.FC<ChooseAvatarProps> = ({ navigation }) => {
  const { accountStore } = useStores()
  const [chooseIndex, setChooseIndex] = useState(() => accountStore.user.avatar)
  const [img, setImg] = useState("")
  const getAllAvatars = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getAvatarList(lastId, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: getAllAvatars,
      pageLimit: PL,
    },
    [],
  )

  useEffect(() => {
    getAllAvatars()
  }, [])

  const postSetAvatar = async () => {
    GToast.current.showLoading(translate("common.load"))
    try {
      const res = await api.setAvatar(chooseIndex)
      GToast.current.hideToast()
      if (res.kind === "ok") {
        GToast.current.showToast(translate("common.editSuccess"), "OK", 2000)
        accountStore.changeUserImage(chooseIndex, img)
        navigation.goBack()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      GToast.current.hideToast()
      console.log("error", error)
    }
  }

  interface ItemProps {
    item: AvatarItem
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("setting.editAvaor")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
        rightComp={
          <TouchableDeep
            onPress={() => postSetAvatar()}
            disabled={chooseIndex === accountStore.user.avatar}
            style={[
              style.rightWrap,
              chooseIndex === accountStore.user.avatar && {
                backgroundColor: colors.palette.neutral100,
              },
            ]}
          >
            <Text style={style.settingEdit} tx={"setting.edit"}></Text>
          </TouchableDeep>
        }
        rightWrapStyle={style.rightWrapStyle}
      />
      <View style={style.container}>
        <HookFlatList
          {...fetchList}
          isShow={fetchList.data.length > 0}
          showsVerticalScrollIndicator={false}
          numColumns={3}
          renderItem={({ item }: { item: any }) => {
            return (
              <View style={style.item}>
                <Touchable
                  onPress={() => {
                    setImg(item?.image)
                    setChooseIndex(item?.image)
                  }}
                  style={[style.itemWrap, chooseIndex === item?.image ? style.borderYellow : null]}
                >
                  <SizeImage source={{ uri: item.image }} style={style.image} />
                  {accountStore.user.avatar === item?.image && (
                    <Image
                      source={Images.screen.setting.confirm_avatar}
                      style={style.imageAvatar}
                    />
                  )}
                </Touchable>
                <Text
                  style={[
                    style.name,
                    {
                      color:
                        chooseIndex === item?.image
                          ? colors.palette.accent500
                          : colors.palette.neutral100,
                    },
                  ]}
                >
                  {item.name}
                </Text>
              </View>
            )
          }}
          estimatedItemSize={fitSize(104)}
          extraData={chooseIndex}
          estimatedListSize={{ height: fitSize(104), width: fitSize(104) }}
        />
      </View>
    </Screen>
  )
}

const style = StyleSheet.create({
  itemWrap: {
    justifyContent: "center",
    alignItems: "center",
    width: fitSize(104),
    height: fitSize(104),
    backgroundColor: colors.palette.neutral100,
    borderRadius: fitSize(6),
  },
  borderYellow: {
    borderWidth: fitSize(5),
    borderColor: colors.palette.accent500,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
  rightWrap: {
    width: fitSize(62),
    height: fitSize(32),
    backgroundColor: colors.palette.accent500,
    borderRadius: fitSize(6),
    marginRight: fitSize(6),
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
  },
  settingEdit: {
    color: colors.palette.neutral30,
    fontWeight: "bold",
    fontSize: fitSize(14),
  },
  container: {
    flex: 1,
    paddingTop: fitSize(16),
    height: Dimensions.get("window").height,
    backgroundColor: colors.palette.neutral900,
  },
  rightWrapStyle: {
    width: fitSize(90),
  },
  item: {
    margin: fitSize(8),
    justifyContent: "center",
    alignItems: "center",
  },
  columnWrapperStyle: {},
  image: {
    width: fitSize(96),
    height: fitSize(96),
    borderRadius: fitSize(48),
  },
  imageAvatar: {
    width: fitSize(14),
    height: fitSize(12),
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  name: {
    fontWeight: "bold",
    fontSize: fitSize(16),
    marginTop: fitSize(10),
  },
})
