import dayjs from "dayjs"
import { CompositeRecordItem } from "../../../services/api"
import { Touchable } from "../../../components"
import { SizeImage } from "../../../components/SizeImage"
import { Images, colors, fitSize } from "../../../theme"
import { StyleSheet, View, Text, Image } from "react-native"
import { translate } from "../../../i18n"
import { fontName } from "../../../theme/fonts"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"

export const getActivityStartRestTimeDays = (time: number) => {
  if (time <= 0) return "00:00:00"

  const allS = Math.floor(time / 1000)
  const days = Math.floor(allS / 60 / 60 / 24)

  const hours = Math.floor((allS / 60 / 60) % 24)
  const minutes = Math.floor((allS / 60) % 60)
  const seconds = Math.floor(allS % 60)

  const timeString =
    (days > 0 ? days : "") +
    (days > 0 ? "天" : "") +
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)

  return timeString
}

export const STATUS = {
  0: Images.components.hourglass,
  1: Images.components.red_destroy,
  2: Images.components.blue_transmit,
  3: Images.components.blue_ok,
  4: Images.components.red_close,
}

interface ComposeHisItemProps {
  item: CompositeRecordItem
}

export const ComposeHisItem: React.FC<ComposeHisItemProps> = ({ item }) => {
  const navigation = useTypeNavigation()

  const onLook = () => {
    navigation.navigate("ComposeDetails", { id: item.id })
  }

  return (
    <Touchable style={style.item} onPress={onLook}>
      <SizeImage source={{ uri: item?.revealUrl?.url }} style={style.icon} resizeMode="cover" />
      <View style={style.itemInfo}>
        <Text style={style.itemName} numberOfLines={1}>
          {item?.name}
        </Text>
        <View style={style.countdownContainer}>
          <Image source={STATUS[item?.compStatus]} style={style.stateIcon} />
          <Text style={style.stateText}>{item?.compStatusText}</Text>
        </View>
        <Text style={style.tip}>{dayjs(item?.createdAt).format("YYYY.MM.DD HH:mm")}</Text>
      </View>
    </Touchable>
  )
}

const style = StyleSheet.create({
  tip: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  stateText: {
    fontSize: fitSize(12),
    color: colors.palette.primary500,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  stateIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(6),
  },
  countdownContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: fitSize(4),
    marginBottom: fitSize(40),
  },
  itemSketch: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    marginBottom: fitSize(20),
  },
  score: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  player: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  itemName: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
  },
  itemInfo: {
    flexGrow: 1,
    paddingLeft: fitSize(10),
    flexDirection: "column",
  },
  icon: {
    width: fitSize(168),
    height: "100%",
  },
  item: {
    width: "100%",
    height: fitSize(130),
    flexDirection: "row",
    borderWidth: 1,
    borderRadius: fitSize(8),
    borderColor: colors.palette.neutral30,
    marginBottom: fitSize(20),
    padding: fitSize(14),
  },
})
