import {
  ActivityIndicator,
  FlatList,
  FlatListProps,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  useWindowDimensions,
} from "react-native"
import React, { forwardRef, useMemo } from "react"
import { colors, fitSize } from "../../theme"

import { EmptyItem } from "./EmptyItem"
import { NoInternet } from "../NoInternet"
import { RefreshControl } from "react-native-web-refresh-control"
import { Touchable } from "../Button"
import { FlashList, FlashListProps } from "@shopify/flash-list"

interface HookFlatListProps<T> extends FlashListProps<T> {
  error?: string
  isLoading?: boolean
  bottomRefreshing?: boolean
  topRefreshing?: boolean
  tintColor?: string
  notShowEmpty?: boolean
  emptyStype?: StyleProp<ViewStyle>
  emptyItem?: () => React.ReactElement | null
  isTopRefreshing?: boolean
  isTransverseLoad?: boolean
  showNoInternet?: boolean
  isShow?: boolean
}

export const HookFlatList = forwardRef(function HookFlatList<T>(props: HookFlatListProps<T>, refs) {
  const {
    error,
    isLoading,
    bottomRefreshing,
    topRefreshing,
    data,
    tintColor,
    notShowEmpty = true,
    emptyStype,
    emptyItem,
    isTopRefreshing,
    showNoInternet = true,
    isShow = true,
  } = props

  const ListFooterComponent = useMemo(() => {
    if (data?.length === 0) {
      return null
    }
    return (
      <View style={styles.loadMoreView}>{bottomRefreshing ? <ActivityIndicator /> : null}</View>
    )
  }, [data, bottomRefreshing])

  const windowHeight = useWindowDimensions().height

  if (error) {
    return (
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "transparent",
          flex: 1,
          bottom: 130,
          // display: showNoInternet ? "flex" : "none",
          display: "none",
        }}
      >
        <NoInternet
          onPress={() => {
            props.onRefresh()
          }}
          isLoading={isLoading}
        />
      </View>
    )
  }
  if (isLoading) {
    return (
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: fitSize(20),
          backgroundColor: "#transparent",
          flex: 1,
        }}
      >
        <ActivityIndicator color={"#999"} />
      </View>
    )
  }
  const { onRefresh, refreshing, isTransverseLoad, ...rest } = props

  return (
    <View
      style={{
        flexGrow: 1,
        height: Platform.OS === "web" ? windowHeight - fitSize(65) : undefined,
      }}
    >
      {isShow ? (
        <FlashList<T>
          showsVerticalScrollIndicator={false}
          refreshControl={
            isTransverseLoad ? (
              <RefreshControl
                onRefresh={onRefresh}
                refreshing={topRefreshing}
                enabled={isTopRefreshing}
                tintColor={colors.palette.neutral100}
              />
            ) : null
          }
          keyExtractor={(item, index) => index + ""}
          ListFooterComponent={ListFooterComponent}
          onEndReachedThreshold={1}
          ref={refs}
          {...rest}
        />
      ) : null}
      {data?.length === 0 &&
        notShowEmpty &&
        (emptyItem ? (
          emptyItem()
        ) : (
          <View
            style={[
              {
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                alignSelf: "center",
                top: 150,
              },
              emptyStype,
            ]}
          >
            <Touchable
              onPress={() => {
                props.onRefresh()
              }}
            >
              <EmptyItem tintColor={tintColor || "#CBCEDD"} />
            </Touchable>
          </View>
        ))}
    </View>
  )
})

const styles = StyleSheet.create({
  loadMoreView: {
    height: 40,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
})
