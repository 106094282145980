import { Platform } from "react-native"

const config = {
  production: {
    aliOnePressIOS:
      "EmarO8U4JTm46EjG+I4LhZHxofnCxzrseizOCDltgDHQUB1h4vxceb1f4UnmOHeugFgxoQ4SDf+hbq6UZs+nDYH9Jmj+St4GD/8pxm7YwrznQ+/GENWFLhhuk3GRkQXhLATAtaxlWVW3RKsoxm6DUMuOkOKzXrEejawSY7Posev0h7UzyfUzxko/cuFZtdT2ObkxYmIsThSMEhQ1VvA9uKTbRHZyAuQJxV3as+siX3VRMyikIK5xbn/RhdOOofPNBMX6FJQKVos=",
    aliOnePressAndroid:
      "5YBzJlYgPs6HbWFehV9SXdgvlDDrJksk43nxhALcuOjmr6i7FZuwoKpghGtyvnmIW6zc1fg6+lcXw4Q5aaf5xJR3KuE4q40Xr2SjjLS4GEXBwpod8xZrgG/MfqoiubiwSlFRnvns1izBv3Mq8fo9KV1IG5lXOhFPbB3zNDebBL1+iG0CUGfS+ZEH4qsPh+PAz1QUh4IxFkPd/9e8HfUK9Gx+1hop6vixDJvlJiU7ZsgkA2aPqSZWUYPN0cTrwznCWy9qCGgx3mPUH/IalMyK9sB5jskAucaGcJTUzCeSh1M=",
  },
  dev: {
    aliOnePressIOS:
      "EmarO8U4JTm46EjG+I4LhZHxofnCxzrseizOCDltgDHQUB1h4vxceb1f4UnmOHeugFgxoQ4SDf+hbq6UZs+nDYH9Jmj+St4GD/8pxm7YwrznQ+/GENWFLhhuk3GRkQXhLATAtaxlWVW3RKsoxm6DUMuOkOKzXrEejawSY7Posev0h7UzyfUzxko/cuFZtdT2ObkxYmIsThSMEhQ1VvA9uKTbRHZyAuQJxV3as+siX3VRMyikIK5xbn/RhdOOofPNBMX6FJQKVos=",
    aliOnePressAndroid:
      "5YBzJlYgPs6HbWFehV9SXdgvlDDrJksk43nxhALcuOjmr6i7FZuwoKpghGtyvnmIW6zc1fg6+lcXw4Q5aaf5xJR3KuE4q40Xr2SjjLS4GEXBwpod8xZrgG/MfqoiubiwSlFRnvns1izBv3Mq8fo9KV1IG5lXOhFPbB3zNDebBL1+iG0CUGfS+ZEH4qsPh+PAz1QUh4IxFkPd/9e8HfUK9Gx+1hop6vixDJvlJiU7ZsgkA2aPqSZWUYPN0cTrwznCWy9qCGgx3mPUH/IalMyK9sB5jskAucaGcJTUzCeSh1M=",
  },
  common: {
    version: "2.0.0",
    name: "CBA时刻",
    packageName: "com.cbatime",
    perfix: ["qweqewq://", "https://app.qweqewq.com", "http://app.qweqewq.com"],
    platform: Platform.OS,
  },
}

export const AppInfo = {
  ...config.production,
  ...config.common,
}
