import { Image, ImageBackground, Platform, StyleSheet, Text, View } from "react-native"
import { Images, colors, fitSize } from "../../../theme"
import { GivingCardItem } from "../../../services/api"
import { SizeImage } from "../../../components/SizeImage"
import { Fragment, ReactNode, useCallback, useMemo } from "react"
import { fontName } from "../../../theme/fonts"
import { translate } from "../../../i18n"
import { ImageFilter } from "./ImageFilter"
import { Touchable } from "../../../components"

interface CardItemProps {
  item: GivingCardItem
  openDrawer: (
    resourceId: number,
    rarityId: number,
    seriesId: number,
    assetNumber: string,
    compositeFuelId: number,
  ) => void
}

export const CardItem = (props: CardItemProps, ref) => {
  const { item, openDrawer } = props

  const isHave = useMemo(() => {
    return item?.collectCount >= item?.count
  }, [item?.collectCount, item?.count])

  return (
    <View style={style.item}>
      {isHave ? (
        <SizeImage
          source={item?.assetNumber ? Images.screen.comp.hot_card : {}}
          style={style.urlImage}
        >
          <Touchable
            style={style.switchBox}
            onPress={() => {
              console.log("item", item)
              openDrawer(
                item?.resourceId,
                item?.rarityId,
                item.seriesId,
                item.assetNumber,
                item.compositeFuelId,
              )
            }}
          >
            <Text style={style.switch}>
              {item?.resourceId > 0
                ? translate("screen.challengeDetails.switch")
                : translate("screen.challengeDetails.select")}
            </Text>
          </Touchable>
          <View
            style={{
              width: fitSize(102),
              height: fitSize(102),
              alignItems: "center",
              justifyContent: "center",
              zIndex: -2,
            }}
          >
            <SizeImage
              source={{ uri: encodeURI(item?.image) }}
              style={style.url}
              resizeMode="contain"
            />
          </View>
        </SizeImage>
      ) : (
        <Fragment>
          <View style={style.urlImage}>
            <ImageFilter
              isHave={false}
              source={
                Platform.OS === "web" ? { uri: encodeURI(item?.image) } : encodeURI(item?.image)
              }
            />
          </View>
        </Fragment>
      )}
      <Text
        style={[
          style.name,
          { color: isHave ? colors.palette.neutral100 : colors.palette.neutral20 },
        ]}
        numberOfLines={1}
      >
        {item?.resourceId > 0 ? item?.playerName : item?.seriesName}
      </Text>
      <View style={style.stateContainer}>
        {isHave ? (
          <Fragment>
            {item?.resourceId > 0 ? (
              <Text style={[style.assetNumber]}>
                #{item?.assetNumber}
                <Text style={style.circulation}>/{item?.circulation}</Text>
              </Text>
            ) : item?.rarityId > 0 && item?.rarityId >= item?.seriesId ? (
              <Text style={style.circulation}>
                {translate("screen.challengeDetails.restrict", { name: item?.rarityName })}
              </Text>
            ) : (
              <Text style={style.circulation}>{translate("screen.challengeDetails.atWill")}</Text>
            )}
          </Fragment>
        ) : (
          <Text style={[style.state]}>{translate("screen.challengeDetails.nowGet")}</Text>
        )}
      </View>
      <View style={style.tip}>
        <Image
          source={isHave ? Images.components.have_tip : Images.components.not_tip}
          style={style.tipIcon}
        />
        <Text
          style={[
            style.closeTip,
            { color: isHave ? colors.palette.secondary50 : colors.palette.neutral50 },
          ]}
        >
          {item?.assetNumber
            ? translate("screen.challengeDetails.closeTip")
            : translate("screen.challengeDetails.notSelectTip")}
        </Text>
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  switchBox: {
    position: "absolute",
    top: fitSize(5),
    right: fitSize(2),
  },
  switch: {
    fontSize: fitSize(10),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    color: colors.palette.neutral100,
  },
  closeTip: {
    fontSize: fitSize(11),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  tipIcon: {
    width: fitSize(11),
    height: fitSize(11),
    marginRight: fitSize(4),
  },
  tip: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: fitSize(6),
  },
  assetNumber: {
    fontSize: fitSize(12),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    color: colors.palette.neutral100,
  },
  circulation: {
    fontSize: fitSize(12),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    color: colors.palette.neutral20,
  },
  stateContainer: {
    flexDirection: "row",
    marginTop: fitSize(3),
  },
  state: {
    fontSize: fitSize(11),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    color: colors.palette.neutral100,
  },
  name: {
    fontSize: fitSize(14),
    marginTop: fitSize(8),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  url: {
    width: fitSize(102),
    height: fitSize(102),
  },
  urlImage: {
    width: fitSize(102),
    height: fitSize(102),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: fitSize(4),
    borderColor: colors.palette.neutral30,
    borderWidth: 1,
  },
  item: {
    width: fitSize(102),
    height: fitSize(170),
    flexDirection: "column",
    alignItems: "center",
    marginRight: fitSize(8),
    marginBottom: fitSize(26),
  },
})
