import { Image, ImageSourcePropType, StyleSheet, Text, View } from "react-native"
import React, { FC } from "react"
import { fitSize } from "../../../theme/"
import { Images } from "../../../theme"
import { Touchable } from "../../../components/Button"

interface NavItemProps {
  leftIcon?: ImageSourcePropType
  leftText?: string
  rightText?: string
  rightTextStyle?: StyleSheet.NamedStyles<{}>
  style?: StyleSheet.NamedStyles<{}>
  onNav?: () => void
  isShow?: boolean
}

export const NavItem: FC<NavItemProps> = ({
  leftIcon,
  leftText,
  rightText,
  rightTextStyle,
  style,
  onNav,
  isShow,
}) => {
  return (
    <Touchable
      style={[styles.defContainer, style, { display: isShow ? "flex" : "none" }]}
      onPress={onNav}
    >
      <View style={styles.leftBox}>
        <Image source={leftIcon} style={styles.leftIcon} />
        <Text style={styles.leftText}>{leftText}</Text>
      </View>
      {!rightText ? (
        <Image source={Images.screen.mine.mine_past} style={styles.past_icon} />
      ) : (
        <Text style={[styles.rightText, rightTextStyle]}>{rightText}</Text>
      )}
    </Touchable>
  )
}

const styles = StyleSheet.create({
  defContainer: {
    width: "100%",
    flexDirection: "row",
    height: fitSize(70),
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomColor: "#33333399",
    borderBottomWidth: 1,
    marginBottom: fitSize(5),
  },
  leftBox: {
    flexDirection: "row",
    alignItems: "center",
  },
  leftIcon: {
    width: fitSize(19),
    height: fitSize(19),
  },
  past_icon: {
    width: fitSize(14),
    height: fitSize(14),
  },
  leftText: {
    fontSize: fitSize(15),
    color: "#ffffff",
    fontFamily: "PingFang_SC",
    fontWeight: "400",
    marginLeft: fitSize(10),
  },
  rightText: {
    fontSize: fitSize(12),
    color: "#ffffff",
    fontFamily: "PingFang_SC",
    fontWeight: "400",
  },
})
