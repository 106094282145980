import { Image, StyleSheet, View, ViewStyle } from "react-native"
import { Images, fitSize } from "../../theme"

import React from "react"
import { Text } from "../Text"

interface EmptyItemProps {
  tintColor?: string
  style?: ViewStyle
}

export const EmptyItem: React.FC<EmptyItemProps> = ({ tintColor, style }) => {
  return (
    <View style={{ ...style, ...styles.container }}>
      <Image
        source={Images.not_data.new_empty}
        style={{
          width: fitSize(196),
          height: fitSize(114),
        }}
      />
      <Text
        style={{
          color: "#999",
          fontSize: fitSize(16),
          marginTop: fitSize(15),
        }}
      >
        空空如也~
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
})
