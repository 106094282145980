import { View, ViewStyle } from "react-native"
import React, { FC } from "react"
import { fitSize } from "../../../theme"
import { bannerColumnItem } from "../../../services/api"
import { Swiper } from "./Swiper"

interface TipCardProps {
  data: bannerColumnItem[]
}

export const TipCard: FC<TipCardProps> = ({ data }) => {
  return (
    <View style={$cardBox}>
      <Swiper
        data={data}
        h={fitSize(90)}
        isAutoplay={false}
        isShowPagination={false}
        isAutoplayLoop={false}
        w={fitSize(347)}
      />
    </View>
  )
}

const $cardBox: ViewStyle = {
  width: "100%",
  height: fitSize(90),
  borderRadius: fitSize(10),
  overflow: "hidden",
}
