import { View, Text, StyleSheet, Dimensions, ViewStyle, Animated, ScrollView } from "react-native"
import React, { useLayoutEffect, useMemo, useRef, useState } from "react"
import { RootStackNavProps, TabNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { Images, colors } from "../../theme"
import { Screen, Sheader, Touchable } from "../../components"
import { fitSize } from "../../theme/layout"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { api } from "../../services/api"
import { Image } from "react-native"
import { FuzzySearchDrawer, FuzzySearchDrawerRef } from "./components/FuzzySearchDrawer"
import { useStores } from "../../models"
import { typeState } from "./components/FuzzySearchContent"
import { GToast, goBack } from "../../navigators"
import { useFocusEffect } from "@react-navigation/native"
import { MainContent } from "./components/MainContent"
import { MomentPreview } from "./MomentPreview"
import { SizeImage } from "../../components/SizeImage"

export const DashedLine = ({
  color,
  width,
  notTop = false,
}: {
  color?: string
  width?: number
  notTop?: boolean
}) => {
  return (
    <View
      style={[
        style.lineBox,
        {
          flexGrow: !!width ? undefined : 1,
          width,
        },
      ]}
    >
      <View
        style={[
          style.line,
          {
            borderColor: !!color ? color : colors.palette.neutral30,
            flexGrow: !!width ? undefined : 1,
            width,
            top: !!width || notTop ? undefined : fitSize(-3),
          },
        ]}
      >
        <View style={style.lineContent} />
      </View>
    </View>
  )
}

interface AssetContentProps extends RootStackNavProps<"AssetContent"> {}

export const AssetContent: React.FC<AssetContentProps> = observer(({ navigation, route }) => {
  const drawerRef = useRef<FuzzySearchDrawerRef>(null)
  const { accountStore } = useStores()
  const [seriesId, setSeriesId] = useState("0")
  const [rarityId, setRarityId] = useState("0")
  const [cardBoxId, setCardBoxId] = useState("0")
  const listRef = useRef(null)
  const topRef = useRef(null)
  const [total, setTotal] = useState(0)
  const [index, setIndex] = useState(0)
  const [showAsset, setShowAsset] = useState(false)
  const [showTime, setShowTime] = useState(false)

  const marginTopRef = useRef(new Animated.Value(75)).current

  const handleScroll = (event) => {
    const scrollPosition = event.nativeEvent.contentOffset.y
    const newMarginTop = Math.max(75 - scrollPosition, 22)
    Animated.timing(marginTopRef, {
      toValue: newMarginTop,
      duration: 0,
      useNativeDriver: true,
    }).start()
  }

  const fetchUserAssetTotal = async () => {
    try {
      const res = await api.getUserAssetTotal()
      if (res.kind === "ok") {
        setTotal(res.data.total)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchUserAssetTotal()
    }, []),
  )

  const onFilter = (value: typeState) => {
    setSeriesId(String(value.series))
    setRarityId(String(value.rarity))
    setCardBoxId(String(value.cardBox))
  }

  const orientation: ViewStyle = {
    position: "absolute",
    zIndex: 999,
    backgroundColor: "transparent",
  }

  const translateXValue = useRef(new Animated.Value(0)).current

  const Header = useMemo(
    () => (
      <View style={[style.header, orientation]}>
        <View style={style.selectBar}>
          <View style={style.selectContent}>
            <Animated.View
              style={{
                width: fitSize(29),
                height: fitSize(29),
                borderRadius: fitSize(16),
                backgroundColor: colors.palette.neutral100,
                position: "absolute",
                transform: [{ translateX: translateXValue }],
              }}
            />
            <Touchable
              style={[style.leftBox]}
              onPress={() => {
                setIndex(0)
                listRef.current?.scrollTo({ x: 0, y: 0, animated: true })
              }}
            >
              <Image
                source={
                  index === 0
                    ? Images.screen.store.select_left
                    : Images.screen.store.not_select_left
                }
                style={{ width: fitSize(17), height: fitSize(17) }}
              />
            </Touchable>
            <Touchable
              style={[style.rightBox]}
              onPress={() => {
                setIndex(1)
                listRef.current?.scrollToEnd({ animated: true })
              }}
            >
              <Image
                source={
                  index === 1
                    ? Images.screen.store.select_right
                    : Images.screen.store.not_select_right
                }
                style={{ width: fitSize(17), height: fitSize(17) }}
              />
            </Touchable>
          </View>
          <View
            style={{
              backgroundColor: "#282B2E",
              borderRadius: fitSize(16),
              height: fitSize(21),
              alignItems: "center",
              justifyContent: "center",
              paddingHorizontal: fitSize(10),
            }}
          >
            <Text style={style.headerBar}>
              <Text style={style.infoTotalText}>
                {total}
                {translate("screen.tabStore.headerTotal")}
              </Text>
            </Text>
          </View>
        </View>
      </View>
    ),
    [total, index],
  )

  useLayoutEffect(() => {
    const translateXTo = index === 0 ? 0 : fitSize(29)
    Animated.timing(translateXValue, {
      toValue: translateXTo,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      if (index === 0) {
        setShowAsset(true)
        setShowTime(false)
      } else {
        setShowAsset(false)
        setShowTime(true)
      }
    })
  }, [index, translateXValue])

  const headerStyle: ViewStyle = {
    overflow: "hidden",
    position: "absolute",
    zIndex: 999,
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      {accountStore.showBottomBar ? (
        <View
          style={[
            {
              flexDirection: "column",
              width: "100%",
              // top: 15,
            },
            index === 1 ? headerStyle : undefined,
          ]}
        >
          <View
            style={{
              height: fitSize(44),
              width: "100%",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Touchable
              onPress={goBack}
              style={{ padding: fitSize(10), width: fitSize(60), flexShrink: 1 }}
            >
              <Image
                source={Images.components["header_back"]}
                style={{
                  width: fitSize(20),
                  height: fitSize(20),
                  resizeMode: "contain",
                }}
              />
            </Touchable>
          </View>
          <View style={style.headerContainer}>
            <Text style={style.forShortName}>
              {translate("screen.tabStore.assetContent.forShortName")}
            </Text>
          </View>
          <View style={style.headerContent}>
            <Text style={style.titleTxt}>{translate("screen.tabStore.assetContent.fullName")}</Text>
            {/* <View style={style.setInfoBar}>
              <Touchable
                onPress={() =>
                  navigation.navigate("StoreContent", { initRoute: "IllustratedHandbook" })
                }
                style={{
                  marginLeft: fitSize(14),
                }}
              >
                <Text style={style.codexTitle}>{translate("screen.tabStore.codexTitle")}</Text>
              </Touchable>
              <Touchable
                onPress={() => navigation.navigate("StoreContent", { initRoute: "CardBoxStore" })}
                style={{
                  marginLeft: fitSize(14),
                }}
              >
                <Text style={style.cardBagTitle}>{translate("screen.tabStore.cardBagTitle")}</Text>
              </Touchable>
              <Touchable
                onPress={() => {
                  drawerRef.current.toggleDrawer(true)
                  topRef.current?.onTop()
                }}
              >
                <Image source={Images.screen.store.drawer_open} style={style.drawerOpenIcon} />
              </Touchable>
            </View> */}
          </View>
        </View>
      ) : null}
      {accountStore.showBottomBar ? Header : null}
      <FuzzySearchDrawer ref={drawerRef} onFilter={onFilter}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          horizontal
          scrollEnabled={false}
          ref={listRef}
          style={{
            width: Dimensions.get("window").width,
            height: "100%",
          }}
        >
          <Animated.View
            style={{
              width: Dimensions.get("window").width,
              height: Dimensions.get("window").height,
              transform: [{ translateY: marginTopRef }],
            }}
          >
            {showAsset && index === 0 && (
              <MainContent
                seriesId={seriesId}
                rarityId={rarityId}
                cardBoxId={cardBoxId}
                ref={topRef}
                handleScroll={handleScroll}
                isOpen={drawerRef.current?.isOpen}
                fetchUserAssetTotal={fetchUserAssetTotal}
                fetchMomentPreview={topRef.current?.onReset}
              />
            )}
          </Animated.View>
          <View
            style={{
              width: Dimensions.get("window").width,
              height: Dimensions.get("window").height,
            }}
          >
            {/* {showTime && index === 1 && ( */}
            <MomentPreview
              seriesId={seriesId}
              rarityId={rarityId}
              cardBoxId={cardBoxId}
              currentIndex={index}
              ref={topRef}
              isOpen={drawerRef.current?.isOpen}
            />
            {/* )} */}
          </View>
        </ScrollView>
      </FuzzySearchDrawer>
    </Screen>
  )
})

const style = StyleSheet.create({
  bestView: {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  },
  line: {
    borderStyle: "dashed",
    borderWidth: 1,
    margin: -2,
    marginTop: 1,
  },
  lineBox: {
    overflow: "hidden",
  },
  lineContent: {
    height: 1,
    width: "100%",
    backgroundColor: colors.palette.neutral30,
  },
  rightBox: {
    width: fitSize(29),
    height: fitSize(29),
    borderRadius: fitSize(16),
    alignItems: "center",
    justifyContent: "center",
  },
  leftBox: {
    width: fitSize(29),
    height: fitSize(29),

    borderRadius: fitSize(16),
    alignItems: "center",
    justifyContent: "center",
  },
  selectContent: {
    flexDirection: "row",
    width: fitSize(60.97),
    height: fitSize(31),
    backgroundColor: "#282B2E",
    borderRadius: fitSize(18),
  },
  selectBar: {
    width: "100%",
    height: fitSize(31),
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: fitSize(14),
    marginTop: fitSize(15),
    marginBottom: fitSize(7),
  },
  header: {
    width: "100%",
    top: 70 + fitSize(34),
  },
  forShortName: {
    fontSize: fitSize(16),
    color: colors.palette.neutral20,
    fontWeight: "400",
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  headerContent: {
    height: fitSize(30),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(14),
  },
  headerBar: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  infoTotalText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  setInfoBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  codexTitle: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  cardBagTitle: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  drawerOpenIcon: {
    width: fitSize(20),
    height: fitSize(20),
    marginLeft: fitSize(14),
  },
  list: {
    width: "100%",
    flexGrow: 1,
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.palette.neutral100,
  },
  headerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // backgroundColor: colors.palette.neutral900,
    paddingHorizontal: fitSize(14),
  },
  titleTxt: {
    fontSize: fitSize(25),
    color: colors.palette.neutral100,
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  body: {
    flexGrow: 1,
    width: "100%",
    alignItems: "center",
    marginTop: fitSize(16),
  },
  textStyle: {
    color: colors.palette.neutral100,
  },
  leftStyle: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
})
