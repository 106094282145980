import { useEffect, useState } from "react"
import { useStores } from "../models"
import { api } from "../services/api"
import { GToast } from "../navigators"
import { RootPublicStackList } from "../type"

export const useTokenAuthorization = (route?: keyof RootPublicStackList, params?: any) => {
  const {
    accountStore: { token },
  } = useStores()
  const [authorization, setAuthorization] = useState(false)

  useEffect(() => {
    try {
      if (!!token) {
        api.apisauce.setHeader("Authorization", token)
        api.newApisauce.setHeader("Authorization", token)
        setAuthorization(true)
      }
    } catch (error) {
      setAuthorization(false)
      GToast.current.doLogin(route, params)
      console.log("error", error)
    }
  }, [])

  return { authorization }
}
