// import * as InAppPurchases from "expo-in-app-purchases"
import { Text, Touchable } from "../../../components"
import { StyleSheet, View, Image, Platform } from "react-native"
import React, { FC } from "react"
import { HookFlatList } from "../../../components/list/HookFlatList"
import { SizeImage } from "../../../components/SizeImage"
import dayjs from "dayjs"
import { ReceiveItem, api } from "../../../services/api"
import { Images, colors, fitSize } from "../../../theme"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useAutoList } from "../../../hooks/useAutoList"
import { fontName } from "../../../theme/fonts"
import { translate } from "../../../i18n/translate"
import { GToast } from "../../../navigators"
import { DashedLine } from "../../TabStore/StoreScreen"
import { FlashList } from "@shopify/flash-list"
import { RarityIcon } from "../../TabHome/components/RarityIcon"

interface ReceiveListProps {
  type: "all" | 1 | 2
  change: number
}

interface ItemProps {
  item: ReceiveItem
}

export const PL = 20
export const ReceiveList: React.FC<ReceiveListProps> = ({ type, change }) => {
  const navigation = useTypeNavigation()

  const Item: FC<ItemProps> = ({ item }) => {
    return (
      <Touchable
        onPress={() => {
          navigation.push("ReceiveDetail", { id: item.id })
        }}
        style={[style.item]}
      >
        <View
          style={{
            width: fitSize(102),
            height: fitSize(102),
            alignItems: "center",
            justifyContent: "center",
            marginRight: fitSize(10),
          }}
        >
          <SizeImage source={{ uri: item?.image }} style={style.img} resizeMode="contain" />
        </View>
        <View style={style.itemInfo}>
          <View style={style.nameInfo}>
            <Text style={style.nameTxt}>{item?.name}</Text>
            <Image source={Images.screen.receiveRecord.gift} style={style.icon} />
          </View>
          <View style={style.numberInfo}>
            <View style={{ flexDirection: "row", alignItems: "center", paddingRight: fitSize(6) }}>
              <RarityIcon icon={item?.rarityIcon} />
              <Text style={style.number}>
                #{item?.assetsNumber}
                <Text style={style.totalNumber}>/{item?.circulation}</Text>
              </Text>
            </View>
            <DashedLine color={colors.palette.neutral50} width={fitSize(40)} />
            <Text style={style.series}>{item?.seriesName}</Text>
          </View>
          <View style={style.timeInfo}>
            <Text style={style.statusTxt}>
              {translate("screen.receiveRecord.status", {
                status: item?.operating === 1 ? "接收" : "发送",
              })}
            </Text>
            <Text style={style.time}>{dayjs(item?.createdAt).format("YYYY.MM.DD HH:mm:ss")}</Text>
          </View>
        </View>
      </Touchable>
    )
  }

  const fetchReceiveList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getReceiveList(
        lastId,
        size,
        undefined,
        type === "all" ? undefined : type,
      )
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchReceiveList,
      pageLimit: PL,
    },
    [],
  )

  return (
    <View key={type} style={style.content}>
      <HookFlatList
        isTransverseLoad={true}
        {...fetchList}
        contentContainerStyle={style.contentContainerStyle}
        renderItem={({ item }) => <Item item={item as unknown as ReceiveItem} />}
        estimatedItemSize={fitSize(123)}
        // estimatedListSize={{ height: fitSize(123), width: fitSize(347) }}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      />
    </View>
  )
}

const style = StyleSheet.create({
  giftBox: {
    alignSelf: "flex-start",
  },
  time: {
    fontSize: fitSize(11),
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
    fontWeight: "400",
  },
  statusTxt: {
    fontSize: fitSize(11),
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
    fontWeight: "400",
  },
  timeInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: fitSize(14),
  },
  series: {
    fontSize: fitSize(11),
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
    fontWeight: "500",
    paddingLeft: fitSize(5),
  },
  line: {
    height: 1,
    borderBottomColor: colors.palette.neutral50,
    borderStyle: Platform.select({ ios: "solid", android: "dashed", web: "dashed" }),
    borderBottomWidth: 1,
    marginHorizontal: fitSize(6),
    width: fitSize(30),
  },
  totalNumber: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
  },
  number: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
  },
  statusIcon: {
    minWidth: fitSize(14),
    maxWidth: fitSize(24),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  numberInfo: {
    // flexDirection: "row",
    // alignItems: "center",
    // marginBottom: fitSize(14),
    // flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginBottom: fitSize(14),
  },
  icon: {
    width: fitSize(20),
    height: fitSize(20),
    alignSelf: "flex-start",
  },
  nameTxt: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
    fontWeight: "500",
  },
  nameInfo: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(29),
    marginBottom: fitSize(10),
  },
  itemInfo: {
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    paddingBottom: fitSize(12),
  },
  img: {
    width: fitSize(68),
    height: fitSize(68),
  },
  content: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingTop: fitSize(5),
  },
  item: {
    width: fitSize(347),
    alignSelf: "center",
    backgroundColor: colors.transparent,
    borderRadius: fitSize(8),
    marginTop: fitSize(20),
    borderWidth: 1,
    borderColor: colors.palette.neutral30,
    height: fitSize(123),
    flexDirection: "row",
    paddingTop: fitSize(9),
    paddingBottom: fitSize(12),
    paddingLeft: fitSize(9),
    paddingRight: fitSize(15),
    overflow: "hidden",
  },
})
