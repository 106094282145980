import { View, StyleSheet, Text, Image, Platform, Dimensions } from "react-native"
import React, { Fragment, useEffect, useReducer, useRef, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { Images, colors, fitSize } from "../../theme"
import { Screen, Touchable, TouchableDeep } from "../../components"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import {
  GetComposeDetailsInfo,
  GivingCardItem,
  SynthesisDetailsInfo,
  api,
} from "../../services/api"
import { GToast } from "../../navigators"
import { useAutoList } from "../../hooks/useAutoList"
import { SizeImage } from "../../components/SizeImage"
import { useSecond } from "../../hooks/useSecond"
import dayjs from "dayjs"
import { HorizontalFlatList } from "@idiosync/horizontal-flatlist"
import { MultiPlatformVideoPlayer } from "../../components/MultiPlatformVideoPlayer"
import { STATUS } from "./components/ComposeHisItem"

interface ComposeDetailsProps extends RootStackNavProps<"ComposeDetails"> {}

export const ComposeDetails: React.FC<ComposeDetailsProps> = observer(({ navigation, route }) => {
  const { id } = route.params
  const [info, setInfo] = useState<GetComposeDetailsInfo>()
  const videoRef = useRef(null)
  const [videoPlay, setVideoPlay] = useState(true)

  const fetchDetails = async () => {
    try {
      const res = await api.getComposeDetails(id)
      if (res.kind === "ok") {
        setInfo(res.data)
        if (Platform.OS === "web") window.document.title = res.data.name
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  return (
    <Screen preset="scroll" safeAreaEdges={["top", "bottom"]} style={style.container}>
      <View style={style.header}>
        <Touchable onPress={() => navigation.goBack()}>
          <Image source={Images.components.header_back} style={style.leftIcon} />
        </Touchable>
      </View>
      <View style={style.topContainer}>
        {videoPlay && (
          <TouchableDeep
            onPress={() => {
              setVideoPlay(false)
              videoRef.current.toggleVideo()
            }}
            style={[style.videoPlayBox]}
          >
            <Image source={Images.screen.home.time_video_pause} style={style.timeVideoPause} />
          </TouchableDeep>
        )}
        {info?.awardList[0]?.video && (
          <TouchableDeep
            onPress={() => {
              setVideoPlay(true)
              videoRef.current.toggleVideo()
            }}
            disabled={videoPlay}
          >
            {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
              <MultiPlatformVideoPlayer
                uri={info?.awardList[0]?.video}
                style={{
                  width: fitSize(327),
                  height: fitSize(436),
                  overflow: "hidden",
                }}
                h={fitSize(436)}
                w={fitSize(327)}
                isPaused={videoPlay}
                poster={info?.awardList[0]?.image}
                loop={false}
                index={0}
                ref={videoRef}
              />
            ) : null}
          </TouchableDeep>
        )}
        <View style={style.topContent}>
          <View style={style.nameInfo}>
            <Text style={style.nameText} numberOfLines={1}>
              {info?.name}
            </Text>
          </View>
          <Text style={style.itemDescription} numberOfLines={3}>
            {info?.description}
          </Text>
        </View>
        <View style={style.stateBox}>
          <Image source={STATUS[info?.status]} style={style.stateIcon} />
          <Text style={style.statusText}>{info?.statusText}</Text>
        </View>
      </View>
      <View style={style.cardList}>
        <Text style={style.cardTitle}>{translate("screen.challengeDetails.havePaid")}</Text>
        <View
          style={[
            style.cardListContent,
            { height: info?.fuelList.length > 3 ? fitSize(368) : fitSize(178) },
          ]}
        >
          <HorizontalFlatList
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            data={info?.fuelList}
            bounces={false}
            numRows={info?.fuelList.length > 3 ? 2 : 1}
            refreshing={false}
            keyExtractor={(item, index) => index + ""}
            renderItem={({ item }) => {
              return (
                <View style={style.item}>
                  <View style={style.itemIcon}>
                    <Image source={Images.components.destroy_card} style={style.destroy} />
                    <SizeImage
                      source={{ uri: encodeURI(item?.image) }}
                      style={style.img}
                      resizeMode="cover"
                    />
                  </View>
                  <Text style={style.itemName}>{item?.name}</Text>
                  <Text style={[style.assetNumber]}>
                    #{item?.assetNumber}
                    <Text style={style.circulation}>/{item?.circulation}</Text>
                  </Text>
                </View>
              )
            }}
          />
        </View>
      </View>
      <View style={style.ruleBox}>
        <Text style={style.ruleTitle}>{translate("screen.challengeDetails.ruleTitle")}</Text>
        <View style={style.titleWarpContent}>
          <Text style={style.titleWarpContentText}>{info?.description}</Text>
        </View>
      </View>
      <View style={style.tipBox}>
        <Text style={style.ruleTitle}>{translate("screen.challengeDetails.tipTitle")}</Text>
        <View style={style.titleWarpContent}>
          <Image source={Images.components.triangular_warning} style={style.tip} />
          <Text style={style.titleWarpContentText}>{info?.note}</Text>
        </View>
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  itemName: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginTop: fitSize(8),
  },
  img: {
    width: "100%",
    height: "100%",
  },
  destroy: {
    width: fitSize(40),
    height: fitSize(40),
    position: "absolute",
    top: fitSize(31),
    left: fitSize(31),
    zIndex: 10,
  },
  itemIcon: {
    width: "100%",
    height: fitSize(102),
    borderRadius: fitSize(4),
    borderWidth: 1,
    borderColor: colors.palette.secondary50,
    alignItems: "center",
    justifyContent: "center",
  },
  item: {
    width: fitSize(102),
    height: fitSize(149),
    flexDirection: "column",
    alignItems: "center",
    marginRight: fitSize(8),
    marginBottom: fitSize(25),
  },
  assetNumber: {
    fontSize: fitSize(12),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    color: colors.palette.neutral100,
    marginTop: fitSize(2),
  },
  circulation: {
    fontSize: fitSize(12),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    color: colors.palette.neutral20,
  },
  cardListContent: {
    width: "100%",
  },
  cardTitle: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginBottom: fitSize(20),
  },
  cardList: {
    width: "100%",
    marginTop: fitSize(40),
    flexDirection: "column",
  },
  typeLabelBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginTop: fitSize(10),
  },
  itemTypeIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  itemLabel: {
    fontSize: fitSize(11),
    color: colors.palette.neutral20,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC,
  },
  statusText: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginLeft: fitSize(6),
  },
  stateIcon: {
    width: fitSize(34),
    height: fitSize(34),
  },
  stateBox: {
    width: "100%",
    height: fitSize(34),
    marginTop: fitSize(30),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: fitSize(12),
  },
  videoPlayBox: {
    zIndex: 2147483647,
    position: "absolute",
    left: 160,
    top: fitSize(200),
  },
  timeVideoPause: {
    width: fitSize(36),
    height: fitSize(36),
  },
  tip: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  tipBox: {
    width: "100%",
    flexDirection: "column",
    marginTop: fitSize(20),
    marginBottom: fitSize(100),
  },
  ruleTitle: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginBottom: fitSize(14),
  },
  titleWarpContentText: {
    color: colors.palette.neutral50,
    fontSize: fitSize(12),
    fontWeight: "400",
  },
  titleWarpContent: {
    width: "100%",
    padding: fitSize(10),
    borderRadius: fitSize(5),
    borderWidth: 1,
    borderColor: colors.palette.neutral30,
    minHeight: fitSize(39),
    flexDirection: "row",
    alignItems: "center",
  },
  ruleBox: {
    width: "100%",
    flexDirection: "column",
  },
  itemDescription: {
    fontSize: fitSize(14),
    color: colors.palette.neutral20,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(10),
  },
  nameText: {
    fontSize: fitSize(24),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
    maxWidth: fitSize(200),
  },
  nameInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  topContent: {
    flexGrow: 1,
    paddingTop: fitSize(23),
    flexDirection: "column",
  },
  countdownText: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
  },
  countdownIcon: {
    width: fitSize(15),
    height: fitSize(15),
    marginRight: fitSize(6),
  },
  countdownBox: {
    width: fitSize(130),
    height: fitSize(17),
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
    right: fitSize(0),
    top: fitSize(10),
  },
  image: {
    width: "100%",
    height: fitSize(183),
  },
  topContainer: {
    width: "100%",
    maxHeight: fitSize(642),
    flexDirection: "column",
    backgroundColor: "#222127",
    borderRadius: fitSize(10),
    marginTop: fitSize(10),
    padding: fitSize(10),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
    paddingHorizontal: fitSize(14),
    height: Dimensions.get("window").height,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    height: fitSize(44),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
})
