import { Images, colors, fitSize } from "../../theme"
import { StyleSheet, Text, View, Image, ViewStyle, TextInput } from "react-native"
import React, { useReducer, useState } from "react"
import { Sheader } from "../../components/Sheader"
import { RootStackNavProps } from "../../type"
import { Screen, Touchable } from "../../components"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { isValidPassword } from "../../utils/regexFunction"
import { api } from "../../services/api"
import { GToast } from "../../navigators"
import { useStores } from "../../models"
import { outlineStyle } from "../../components/TextField"

type State = {
  newPassword: string
  confirmPassword: string
}

type Action = {
  type: "NEW" | "CONFIRM"
  value: string
}

const passwordEditReducer = (state: State, action: Action) => {
  const { type, value } = action
  switch (type) {
    case "NEW":
      return { ...state, newPassword: value }
    case "CONFIRM":
      return { ...state, confirmPassword: value }
    default:
      throw new Error()
  }
}

interface SettingPasswordProps extends RootStackNavProps<"SettingPassword"> {}

export const SettingPassword: React.FC<SettingPasswordProps> = ({ navigation }) => {
  const [passwordEdit, setPasswordEdit] = useReducer(passwordEditReducer, {
    newPassword: "",
    confirmPassword: "",
  })
  const [showNewPassWord, setShowNewPassWord] = useState(true)
  const [showConfirmPassWord, setShowConfirmPassWord] = useState(true)
  const store = useStores()

  const fetchLogout = async () => {
    try {
      const res = await api.logout()
      if (res.kind === "ok") {
        exit()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const exit = () => {
    store.removeAll()
    navigation.replace("LoginScreen")
    store.accountStore.setManualExit(true)
  }

  const onConfirm = async () => {
    try {
      const res = await api.settingPassword(passwordEdit.newPassword, passwordEdit.confirmPassword)
      if (res.kind === "ok") {
        GToast.current.showToast(translate("screen.settingPassword.editSuccess"), "OK", 2000)
        fetchLogout()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const isConfirm = !(
    isValidPassword(passwordEdit.newPassword) &&
    isValidPassword(passwordEdit.confirmPassword) &&
    passwordEdit.newPassword === passwordEdit.confirmPassword
  )

  const $confirmDisabledStyle: ViewStyle = {
    opacity: isConfirm ? 0.5 : 1,
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("screen.settingPassword.header")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <View style={style.inputLineRow}>
          <TextInput
            style={[style.newInput, outlineStyle]}
            onChangeText={(text) => setPasswordEdit({ type: "NEW", value: text })}
            value={passwordEdit.newPassword}
            placeholder={translate("screen.settingPassword.newPlaceholder")}
            placeholderTextColor={colors.palette.neutral100 + "66"}
            spellCheck={false}
            enablesReturnKeyAutomatically
            returnKeyType={"done"}
            autoCorrect={false}
            autoCapitalize={"none"}
            underlineColorAndroid={"transparent"}
            clearButtonMode="while-editing"
            textContentType="password"
            autoComplete="password"
            secureTextEntry={showNewPassWord}
            maxLength={20}
          />
          <Touchable style={style.eyeBtn} onPress={() => setShowNewPassWord((prev) => !prev)}>
            {showNewPassWord ? (
              <Image source={Images.common.hid_eye} style={style.eye} />
            ) : (
              <Image source={Images.common.show_eye} style={style.eye} />
            )}
          </Touchable>
        </View>
        <View style={style.inputRow}>
          <TextInput
            style={[style.confirmInput, outlineStyle]}
            onChangeText={(text) => setPasswordEdit({ type: "CONFIRM", value: text })}
            value={passwordEdit.confirmPassword}
            placeholder={translate("screen.settingPassword.confirmNewPassword")}
            placeholderTextColor={colors.palette.neutral100 + "66"}
            spellCheck={false}
            enablesReturnKeyAutomatically
            returnKeyType={"done"}
            autoCorrect={false}
            autoCapitalize={"none"}
            underlineColorAndroid={"transparent"}
            clearButtonMode="while-editing"
            textContentType="password"
            autoComplete="password"
            secureTextEntry={showConfirmPassWord}
            maxLength={20}
          />
          <Touchable style={style.eyeBtn} onPress={() => setShowConfirmPassWord((prev) => !prev)}>
            {showConfirmPassWord ? (
              <Image source={Images.common.hid_eye} style={style.eye} />
            ) : (
              <Image source={Images.common.show_eye} style={style.eye} />
            )}
          </Touchable>
        </View>
        <Text style={style.tip}>{translate("screen.settingPassword.tip")}</Text>
        <Touchable
          style={[style.btn, $confirmDisabledStyle]}
          onPress={onConfirm}
          disabled={isConfirm}
        >
          <Text style={style.btnText}>{translate("screen.settingPassword.confirmBtn")}</Text>
        </Touchable>
      </View>
    </Screen>
  )
}

const style = StyleSheet.create({
  eyeBtn: {
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  eye: {
    width: fitSize(20),
    height: fitSize(20),
  },
  inputLineRow: {
    height: fitSize(50),
    width: fitSize(347),
    borderTopRadius: fitSize(6),
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: colors.palette.neutral30,
    backgroundColor: colors.palette.neutral60,
  },
  inputRow: {
    height: fitSize(50),
    width: fitSize(347),
    borderTopRadius: fitSize(6),
    flexDirection: "row",
    backgroundColor: colors.palette.neutral60,
  },
  btnText: {
    fontSize: fitSize(14),
    fontWeight: "500",
    color: colors.palette.neutral30,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  btn: {
    width: fitSize(310),
    height: fitSize(50),
    borderRadius: fitSize(6),
    backgroundColor: colors.palette.neutral100,
    justifyContent: "center",
    alignItems: "center",
    marginTop: fitSize(50),
    alignSelf: "center",
  },
  tip: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral20,
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(14),
  },
  confirmInput: {
    height: "100%",
    width: fitSize(283),
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
    paddingLeft: fitSize(25),
  },
  newInput: {
    height: "100%",
    width: fitSize(283),
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
    paddingLeft: fitSize(25),
  },
  oldInput: {
    marginBottom: fitSize(10),
    height: "100%",
    width: fitSize(283),
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
    paddingLeft: fitSize(25),
  },
  label: {
    fontSize: fitSize(15),
    fontWeight: "400",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC,
    marginBottom: fitSize(10),
    marginTop: fitSize(20),
  },
  rightStyle: {
    width: fitSize(80),
  },
  headerRight: {
    fontSize: fitSize(14),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  content: {
    flex: 1,
    alignItems: "flex-start",
    paddingHorizontal: fitSize(14),
    paddingTop: fitSize(20),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
