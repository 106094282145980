import { colors } from "../theme"
import React, {
  Dispatch,
  Fragment,
  ReactNode,
  RefObject,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { Platform, View, ViewStyle } from "react-native"
import { Video } from "../utils/CompatibleUtils"
import _ from "lodash"
import videojs from "video.js"
import { isBaiDu, isIos, isQuarkBrowser, isUCBrowser } from "../utils/utils"
interface BaseVideoProps {
  uri: any
  isPaused?: boolean
  muted?: boolean
  loop?: boolean
  poster?: string
  style?: ViewStyle
  index?: number
  w: number | string
  h: number | string
  children?: ReactNode
  isReset?: boolean
  resizeMode?: "cover" | "contain" | "stretch"
  isLook?: boolean
  isPreload?: boolean
  onFinish?: () => void
  setSuccessLoading?: Dispatch<React.SetStateAction<boolean>>
}

interface MultiPlatformVideoPlayerRef {
  toggleVideo?: () => void
  sonVideoPalyState?: boolean
}

export const MobileVideo = forwardRef(
  (props: BaseVideoProps, ref: RefObject<MultiPlatformVideoPlayerRef>) => {
    const {
      uri,
      isPaused,
      muted = true,
      loop,
      poster,
      w,
      h,
      children,
      resizeMode = "cover",
      isLook = true,
      isPreload = false,
      isReset = true,
      onFinish,
      setSuccessLoading,
    } = props

    const videoRef = useRef<any>()

    useImperativeHandle(ref, () => ({
      toggleVideo: async () => {
        if (isPaused) {
          videoRef.current?.setNativeProps({ paused: false })
          videoRef.current?.seek(0)
        } else {
          videoRef.current?.setNativeProps({ paused: true })
          videoRef.current?.seek(0)
        }
      },
      sonVideoPalyState: isPaused,
    }))

    useEffect(() => {
      if (isPaused && isReset) {
        videoRef.current?.seek(0)
      }
    }, [isPaused, isReset])

    return (
      <Fragment>
        <Video
          source={isPaused && !isLook && !isPreload && isReset ? {} : { uri: encodeURI(uri) }}
          poster={poster}
          style={{ width: w, height: h }}
          muted={muted}
          resizeMode={resizeMode}
          ref={videoRef}
          ignoreSilentSwitch="ignore"
          mixWithOthers="mix"
          hideShutterView={true}
          disableFocus={true}
          onEnd={() => {
            if (onFinish) onFinish()
            if (loop) {
              videoRef.current?.setNativeProps({ paused: false })
              videoRef.current?.seek(0)
            }
          }}
          onBuffer={(data) => {
            if (!isPaused) {
              videoRef.current?.setNativeProps({ paused: false })
            }
            setSuccessLoading && setSuccessLoading(data)
          }}
          useTextureView
          paused={isPaused}
          onError={(err) => {
            console.log("uri_error:", err)
          }}
          preventsDisplaySleepDuringVideoPlayback={false}
        />
        {children}
      </Fragment>
    )
  },
)

export const MultiPlatformVideoPlayer = forwardRef(
  (props: BaseVideoProps, ref: RefObject<MultiPlatformVideoPlayerRef>) => {
    const { style } = props
    const videoRef = useRef(null)

    useImperativeHandle(ref, () => ({
      toggleVideo: () => {
        videoRef.current?.toggleVideo()
      },
      sonVideoPalyState: videoRef.current?.sonVideoPalyState ?? true,
    }))

    return (
      <View style={[$container, style]}>
        {Platform.OS === "web" ? (
          <WebVideo {...props} ref={videoRef} isPaused={props.isPaused} />
        ) : (
          <MobileVideo {...props} ref={videoRef} isPaused={props.isPaused} />
        )}
      </View>
    )
  },
)

const $container: ViewStyle = {
  width: "100%",
  height: "100%",
  backgroundColor: colors.palette.neutral900,
  alignItems: "center",
  justifyContent: "center",
}

export const WebVideo = React.forwardRef(
  (props: BaseVideoProps, ref: React.Ref<MultiPlatformVideoPlayerRef>) => {
    const {
      uri,
      isPaused = true,
      muted = true,
      loop = true,
      style,
      poster,
      children,
      isReset = true,
      resizeMode = "cover",
      w,
      h,
      onFinish,
      setSuccessLoading,
    } = props

    const videoRef = useRef(null)
    const playerRef = useRef(null)
    const [isPlaying, setIsPlaying] = useState(!isPaused)
    const firstPlay = useRef(false)

    const loadVideo = async (src) => {
      try {
        const videoElement = videoRef.current
        const player = videojs(videoElement, {
          controls: false,
          poster: poster ?? "",
          autoplay: false,
          muted: muted,
          loop,
          playsinline: true,
          preload: "auto",
          controlsList: "nodownload,nofullscreen,noremoteplayback ",
          html5: {
            hls: {
              enableLowInitialPlaylist: true,
              smoothQualityChange: true,
            },
          },
          plugins: {
            // 可根据需要添加其他插件配置
          },
          errorDisplay: false,
        })
        player.src({ type: "video/mp4", src: src })
        player.on("ended", () => {
          if (onFinish) onFinish()
        })
        playerRef.current = player
        setSuccessLoading && setSuccessLoading(true)
        if (!firstPlay.current) {
          videoState()
        }
      } catch (error) {
        // 执行自定义错误处理逻辑，例如记录日志或显示错误提示
        console.error("视频加载失败:", error)
        return null
      }
    }

    const getCachedVideoData = (key: string) => {
      return sessionStorage.getItem(key)
    }

    const setCachedVideoData = (data: string, key: string) => {
      sessionStorage.setItem(key, data)
    }

    const fetchWebVideoData = async () => {
      try {
        if (getCachedVideoData(uri)) {
          loadVideo(getCachedVideoData(uri))
          setSuccessLoading && setSuccessLoading(true)
          console.log("Video loaded from cache.")
          return
        }
        const headers = new Headers()
        headers.append("Range", "bytes=0-")

        const response = await fetch(uri, { headers })

        if (!response.ok) {
          throw new Error("Network response was not ok")
        }

        const arrayBuffer = await response.arrayBuffer()
        const blob = new Blob([arrayBuffer], { type: "application/octet-stream" })

        const videoData = URL.createObjectURL(blob)

        // cachedVideoData.current = videoData
        setCachedVideoData(videoData, uri)
        loadVideo(videoData)
        setSuccessLoading && setSuccessLoading(true)

        console.log("Video loaded successfully.")
      } catch (error) {
        setSuccessLoading && setSuccessLoading(false)
        console.error("Error fetching web video data:", error)
        // throw error
      }
    }

    useEffect(() => {
      if (!!uri) {
        if (isIos || isQuarkBrowser || isUCBrowser) {
          loadVideo(uri)
        } else {
          fetchWebVideoData()
        }
      }
    }, [uri])

    const videoState = () => {
      const player = playerRef.current
      if (player) {
        if (isPaused) {
          player.pause()
          try {
            if (isReset) player?.currentTime(0)
          } catch (error) {
            console.error("Error playing video:", error)
          }
        } else {
          player.play().catch((error) => {
            console.error("Error playing video:", error)
          })
        }
      }
    }

    useEffect(() => {
      if (!!uri) {
        videoState()
      }
    }, [isPaused])

    React.useImperativeHandle(ref, () => ({
      toggleVideo: () => {
        if (playerRef.current) {
          if (playerRef.current.paused) {
            playerRef.current.play()
            setIsPlaying(true)
          } else {
            playerRef.current.pause()
            setIsPlaying(false)
          }
        }
      },
      sonVideoPalyState: isPlaying,
    }))

    useEffect(() => {
      const player = playerRef.current
      if (player) {
        player.muted(muted)
      }
    }, [muted])

    if (isBaiDu) return

    return (
      <View style={[$container, style, { width: w, height: h }]}>
        <video
          className="video-js"
          width={"100%"}
          controls={false}
          x5-video-player-type="h5-page"
          height={"100%"}
          muted={muted}
          poster={poster}
          style={{
            objectFit: resizeMode as any,
            width: "100%",
            height: "100%",
          }}
          ref={videoRef}
          onPause={() => {
            setIsPlaying(false)
          }}
          id="capsule"
        ></video>
        {children}
      </View>
    )
  },
)
