import { StyleSheet, View } from "react-native"
import { Text } from "../components"

import Modal from "react-native-modal"
import React from "react"
import { alertText } from "../components"
import { TouchableDeep } from "../components/Button"
import { fitSize, deviceHeight } from "../theme/layout"
import { colors } from "../theme"
import { fontName } from "../theme/fonts"

interface PlatteWhiteAlertProps {
  msg: string
  isShow: boolean
  closeModal: () => void
  onConfirm: () => void
  onClose?: () => void
  alertInfo: alertText
}

export const PlatteWhiteAlert: React.FC<PlatteWhiteAlertProps> = ({
  msg,
  closeModal,
  isShow,
  onConfirm,
  onClose,
  alertInfo: { closeText, sureText, info, isShowOne = true },
}) => {
  return (
    <Modal
      useNativeDriverForBackdrop
      hasBackdrop={true}
      coverScreen={true}
      isVisible={isShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={{ margin: 0, backgroundColor: colors.transparent }}
      onBackButtonPress={() => {}}
      onBackdropPress={() => {}}
      swipeDirection={"down"}
      backdropTransitionOutTiming={0}
      useNativeDriver
      backdropOpacity={0}
      deviceHeight={deviceHeight}
      hideModalContentWhileAnimating={true}
    >
      <View style={styles.container}>
        <Text style={styles.msg}>{msg}</Text>
        {!!info && (
          <Text
            style={{
              color: "#333",
              fontSize: fitSize(16),
              marginVertical: fitSize(13),
              paddingHorizontal: fitSize(40),
            }}
          >
            {info}
          </Text>
        )}
        <View style={{ flex: 1 }} />
        <View style={styles.bottomWrap}>
          {isShowOne ? (
            <TouchableDeep
              onPress={() => {
                closeModal()
                if (onClose) onClose()
              }}
              style={styles.leftBtn}
            >
              <Text style={styles.content}>{closeText}</Text>
            </TouchableDeep>
          ) : null}
          <TouchableDeep
            onPress={() => {
              closeModal()
              onConfirm()
            }}
            style={styles.rightBtn}
          >
            <Text style={[styles.content]}>{sureText}</Text>
          </TouchableDeep>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#333333",
    width: fitSize(254),
    minHeight: fitSize(132),
    alignSelf: "center",
    borderRadius: fitSize(10),
  },
  msg: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    alignSelf: "center",
    marginVertical: fitSize(18),
    // fontWeight: "bold",
    paddingHorizontal: fitSize(10),
    fontFamily: fontName.PingFang_SC,
  },
  bottomWrap: {
    // position: "absolute",
    // bottom: 0,
    width: fitSize(254),
    flexDirection: "row",
    height: fitSize(48),
    borderTopColor: "#B3B3B3",
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  leftBtn: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: "#B3B3B3",
  },
  rightBtn: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  content: {
    color: colors.palette.neutral100,
    fontSize: fitSize(13),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
})
