import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ViewStyle,
  Animated,
  ScrollView,
  FlatList,
} from "react-native"
import React, { useLayoutEffect, useMemo, useRef, useState } from "react"
import { TabNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { Images, colors } from "../../theme"
import { Screen, Touchable } from "../../components"
import { fitSize } from "../../theme/layout"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { RarityCodex, api } from "../../services/api"
import { useStores } from "../../models"
import { typeState } from "./components/FuzzySearchContent"
import { GToast } from "../../navigators"
import { useFocusEffect } from "@react-navigation/native"
import { SizeImage } from "../../components/SizeImage"
import { useAutoList } from "../../hooks/useAutoList"
import { HookFlatList } from "../../components/list/HookFlatList"

const PL = 20
export const DashedLine = ({
  color,
  width,
  notTop = false,
}: {
  color?: string
  width?: number
  notTop?: boolean
}) => {
  return (
    <View
      style={[
        style.lineBox,
        {
          flexGrow: !!width ? undefined : 1,
          width,
        },
      ]}
    >
      <View
        style={[
          style.line,
          {
            borderColor: !!color ? color : colors.palette.neutral30,
            flexGrow: !!width ? undefined : 1,
            width,
            top: !!width || notTop ? undefined : fitSize(-3),
          },
        ]}
      >
        <View style={style.lineContent} />
      </View>
    </View>
  )
}

interface StoreScreenProps extends TabNavProps<"TabTwo"> {}

export const StoreScreen: React.FC<StoreScreenProps> = observer(({ navigation, route }) => {
  const { accountStore } = useStores()
  const [total, setTotal] = useState(0)
  const [index, setIndex] = useState(0)

  // const fetchUserAssetTotal = async () => {
  //   try {
  //     const res = await api.getUserAssetTotal()
  //     if (res.kind === "ok") {
  //       setTotal(res.data.total)
  //     } else {
  //       GToast.current.handleErrorResponse(res)
  //     }
  //   } catch (error) {
  //     console.log("error", error)
  //   }
  // }

  // useFocusEffect(
  //   React.useCallback(() => {
  //     fetchUserAssetTotal()
  //   }, []),
  // )

  useFocusEffect(
    React.useCallback(() => {
      fetchList.onRefresh()
    }, [route]),
  )

  const fetchTimeList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getRarityCodexList(lastId, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchTimeList,
      pageLimit: PL,
    },
    [],
  )

  const onClickCard = (item: RarityCodex) => {
    navigation.navigate("GuideDetails", {
      seriesId: item.seriesId,
      rarityId: item.rarityId,
      cardBoxId: item.cardBoxId,
      cardBoxRarityId: item.id,
    })
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <View
        style={[
          {
            flexDirection: "column",
            width: "100%",
            top: 15,
            zIndex: 2,
            backgroundColor: colors.palette.neutral900,
          },
        ]}
      >
        <View style={style.headerContainer}>
          <Text style={style.forShortName}>
            {translate("screen.tabStore.illustratedHandbook.forShortName")}
          </Text>
        </View>
        <View style={style.headerContent}>
          <Text style={style.titleTxt}>
            {translate("screen.tabStore.illustratedHandbook.fullName")}
          </Text>
        </View>
      </View>
      <FlatList
        {...fetchList}
        refreshing={false}
        data={fetchList?.data}
        numColumns={2}
        contentContainerStyle={style.contentContainerStyle}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        renderItem={({ item }) => {
          const card = item as unknown as RarityCodex
          return (
            <Touchable style={style.card} onPress={() => onClickCard(card)}>
              <SizeImage source={{ uri: card?.image }} style={style.cardImage} resizeMode="cover" />
              <View style={style.cardContent}>
                <Text style={style.cardName} numberOfLines={1} ellipsizeMode="tail">
                  {card?.name}
                </Text>
                <Text style={style.cardInfo} numberOfLines={1} ellipsizeMode="tail">
                  {card?.description}
                </Text>
                <Text style={style.cardTotal} numberOfLines={1} ellipsizeMode="tail">
                  {card?.haveNum ?? 0}
                  <Text
                    style={[style.cardTotal, { color: "#666666" }]}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    /{card?.totalAssetsNum ?? 0}
                  </Text>
                </Text>
              </View>
            </Touchable>
          )
        }}
        // estimatedItemSize={fitSize(259)}
      />
    </Screen>
  )
})

const style = StyleSheet.create({
  contentContainerStyle: {
    paddingTop: fitSize(20),
    paddingLeft: fitSize(10),
  },
  cardName: {
    fontWeight: "700",
    color: "#ffffff",
    fontSize: fitSize(18),
  },
  cardContent: {
    flexDirection: "column",
    width: "100%",
    flexGrow: 1,
    padding: fitSize(8),
  },
  cardImage: {
    width: "100%",
    height: fitSize(172),
    backgroundColor: "#ffffff1a",
    alignItems: "center",
    justifyContent: "center",
  },
  cardInfo: {
    fontWeight: "400",
    color: "#666666",
    fontSize: fitSize(12),
    marginTop: fitSize(1),
    height: fitSize(16),
  },
  cardTotal: {
    fontWeight: "700",
    color: "#ffffff",
    fontSize: fitSize(12),
    alignSelf: "flex-end",
    marginTop: fitSize(10),
  },
  card: {
    width: fitSize(164),
    height: fitSize(259),
    borderWidth: 1,
    borderColor: "#ffffff4D",
    marginVertical: fitSize(10),
    flexDirection: "column",
    overflow: "hidden",
    marginLeft: fitSize(10),
  },
  listCenter: {
    flex: 1,
    width: "100%",
  },
  listContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  bestView: {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  },
  line: {
    borderStyle: "dashed",
    borderWidth: 1,
    margin: -2,
    marginTop: 1,
  },
  lineBox: {
    overflow: "hidden",
  },
  lineContent: {
    height: 1,
    width: "100%",
    backgroundColor: colors.palette.neutral30,
  },
  rightBox: {
    width: fitSize(29),
    height: fitSize(29),
    borderRadius: fitSize(16),
    alignItems: "center",
    justifyContent: "center",
  },
  leftBox: {
    width: fitSize(29),
    height: fitSize(29),

    borderRadius: fitSize(16),
    alignItems: "center",
    justifyContent: "center",
  },
  selectContent: {
    flexDirection: "row",
    width: fitSize(60.97),
    height: fitSize(31),
    backgroundColor: "#282B2E",
    borderRadius: fitSize(18),
  },
  selectBar: {
    width: "100%",
    height: fitSize(31),
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: fitSize(14),
    marginTop: fitSize(15),
    marginBottom: fitSize(7),
  },
  header: {
    width: "100%",
    top: 70,
  },
  forShortName: {
    fontSize: fitSize(16),
    color: colors.palette.neutral20,
    fontWeight: "400",
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  headerContent: {
    height: fitSize(30),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(14),
  },
  headerBar: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  infoTotalText: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  setInfoBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  codexTitle: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  cardBagTitle: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  drawerOpenIcon: {
    width: fitSize(20),
    height: fitSize(20),
    marginLeft: fitSize(14),
  },
  list: {
    width: "100%",
    flexGrow: 1,
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.palette.neutral100,
  },
  headerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // backgroundColor: colors.palette.neutral900,
    paddingHorizontal: fitSize(14),
  },
  titleTxt: {
    fontSize: fitSize(25),
    color: colors.palette.neutral100,
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  body: {
    flexGrow: 1,
    width: "100%",
    alignItems: "center",
    marginTop: fitSize(16),
  },
})
