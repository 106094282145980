import { Platform, StyleSheet, View } from "react-native"
import React, { useCallback, useRef } from "react"
import { WebViewMessageEvent, WebViewProgressEvent } from "react-native-webview/lib/WebViewTypes"
import { promiseReturn, webviewResponse } from "../../type/RequestType"

import { FlexView } from "../../components/FlexView"
import Modal from "react-native-modal"
import { NoInternet } from "../../components/NoInternet"
import { RootStackNavProps } from "../../type/RouteType"
import { ShareModal } from "../../components/ShareModal"
import { Sheader } from "../../components/Sheader"
import { WebView as WWebView } from "react-native-web-webview"
import { WebView } from "react-native-webview"
import { colors, deviceWidth, fitSize } from "../../theme"
import { useFocusEffect } from "@react-navigation/native"
import { useState } from "react"
import { Screen } from "../../components"
import { fontName } from "../../theme/fonts"

const MyWebView = Platform.OS === "web" ? WWebView : WebView

interface ActivityWebViewProps extends RootStackNavProps<"ActivityWebView"> {}

export const ActivityWebView: React.FC<ActivityWebViewProps> = ({ navigation, route }) => {
  const [showShareModal, setShowShareModal] = useState(false)
  const [showCardModal, setShowCardModal] = useState(false)
  const [progress, setProgress] = useState(0)
  const webviewRef = useRef<WebView>()
  const qrScanRequest = useRef<promiseReturn | null>(null)
  //摄像机调用返回
  useFocusEffect(
    useCallback(() => {
      if (qrScanRequest.current) {
        const qrcode = route.params?.qrcode
        console.log("qrcode", qrcode)
        if (qrcode) {
          qrScanRequest.current.resolve(qrcode)
        } else {
          qrScanRequest.current.reject("")
        }
      }
    }, [route]),
  )

  const closeShareModal = () => {
    setShowShareModal(false)
  }
  const openShareModal = () => {
    setShowShareModal(true)
  }

  const renderBottomSheet = () => {
    return (
      <Modal
        useNativeDriver
        useNativeDriverForBackdrop
        hasBackdrop={true}
        coverScreen={false}
        isVisible={showShareModal}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={styles.bottomModal}
        animationInTiming={300}
        animationOutTiming={300}
        onBackButtonPress={closeShareModal}
        onBackdropPress={closeShareModal}
        swipeDirection={"down"}
      >
        <ShareModal cancel={closeShareModal} />
      </Modal>
    )
  }

  const closeCardModal = () => {
    setShowCardModal(false)
  }

  const renderCardDetailModal = () => {
    return (
      <Modal
        useNativeDriverForBackdrop
        hasBackdrop={true}
        coverScreen={true}
        isVisible={showCardModal}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={styles.bottom2Modal}
        animationInTiming={300}
        animationOutTiming={300}
        onBackButtonPress={closeCardModal}
        onBackdropPress={closeCardModal}
        backdropTransitionOutTiming={0}
        useNativeDriver
        hideModalContentWhileAnimating={true}
        propagateSwipe={true}
      >
        <></>
        {/* <CardsInModal closeCardModal={closeCardModal} /> */}
      </Modal>
    )
  }
  const renderProgressBar = () => (
    <View style={styles.progressBarWrapper}>{/* <ProgressView progress={progress} /> */}</View>
  )
  const onLoadProgress = (event: WebViewProgressEvent) => {
    const progress = event.nativeEvent.progress
    setProgress(progress)
  }
  const sendToBrage = (message: any) => {
    const data = JSON.stringify(message)
    const res = `ReactNativeWebView.onMessage(\`${data}\`)`
    console.log("toWebviewMessage:", res)
    webviewRef.current?.injectJavaScript(res)
  }
  const rejectMessage = (jsonData: webviewResponse) => {
    sendToBrage({
      type: "api-response",
      isAllowed: false,
      permission: jsonData.permission,
      messageId: jsonData.messageId,
    })
  }
  const onMessage = async (event: WebViewMessageEvent) => {
    const data = event.nativeEvent.data
    console.log("================WEBVIEW MESSAGE==============", data)
    if (data) {
      const jsonData = JSON.parse(data) as webviewResponse
      console.log("jsonData", jsonData)
      if (jsonData.type === "api-request") {
        switch (jsonData.permission) {
          case "qr-code":
            try {
              if (qrScanRequest.current) qrScanRequest.current = null
              navigation.setParams({
                qrcode: "",
              })
              /**
               * TODO 暂时注释
               */
              // navigation.push("CameraScan", { lastRoute: route.name, isGetCase: true })
              const approved = await new Promise((resolve, reject) => {
                qrScanRequest.current = { resolve, reject }
              })
              sendToBrage({
                type: "api-response",
                isAllowed: true,
                permission: jsonData.permission,
                messageId: jsonData.messageId,
                data: approved,
              })
            } catch (err) {
              console.log("error", err)
              rejectMessage(jsonData)
            }
            break
          default:
            rejectMessage(jsonData)
            break
        }
      }
    }
  }
  const refresh = () => {
    webviewRef.current?.reload()
  }
  const [error, setError] = useState("")

  return (
    <FlexView>
      <View style={{ flex: 1 }}>
        <Sheader
          textStyle={styles.textStyle}
          leftStyle={styles.leftIcon}
          style={styles.header}
          isNotSafe
        />
        <MyWebView
          ref={webviewRef}
          onError={(err) => setError(err.toString())}
          onLoadEnd={(e) => {
            if (progress == 1) {
              setError("")
            }
          }}
          source={{ uri: route.params.uri }}
          onLoadProgress={onLoadProgress}
          bounces={false}
          mixedContentMode={"always"}
        />
        {!!error && (
          <View style={[StyleSheet.absoluteFill, { backgroundColor: "#fff" }]}>
            <NoInternet
              onPress={() => {
                refresh()
              }}
            />
          </View>
        )}
      </View>
      {renderBottomSheet()}
      {renderCardDetailModal()}
    </FlexView>
  )
}

const styles = StyleSheet.create({
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: "transparent",
    height: fitSize(44),
    position: "absolute",
  },
  container: {
    flex: 1,
  },
  headerTitle: {
    color: "#373737",
    fontWeight: "bold",
    fontSize: fitSize(18),
  },

  progressBarWrapper: {
    height: 3,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    position: "absolute",
    zIndex: 999999,
  },
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
    height: fitSize(266),
    zIndex: 1000,
  },
  bottom2Modal: {
    justifyContent: "flex-end",
    margin: 0,
    zIndex: 1000,
  },
})
