export const Images = {
  test: {
    m_owner1: require("../../assets/images/header_test.png"),
    login: require("../../assets/images/login.png"),
    // invite_modal:require('../../assets/images/test_invite_modal.jpeg')
  },
  not_data: {
    no_internet: require("../../assets/images/no_internet.png"),
    new_empty: require("../../assets/images/no_data.png"),
  },
  components: {
    circle_layer1: require("../../assets/images/loading_layer1.png"),
    circle_layer2: require("../../assets/images/loading_layer2.png"),
    header_back: require("../../assets/images/header_more.png"),
    modal_clear: require("../../assets/images/modal_clear.png"),
    correct: require("../../assets/images/correct_login.png"),
    blind_cancel: require("../../assets/images/blind_cancel.png"),
    a_minus: require("../../assets/images/minus_acti.png"),
    a_add: require("../../assets/images/add_minus.png"),
    warning: require("../../assets/images/warning.png"),
    card_bag: require("../../assets/images/card_bag.png"),
    order_warning: require("../../assets/images/order_warning.png"),
    gift_modal: require("../../assets/images/gift_modal.png"),
    tip_down: require("../../assets/images/tip_down.png"),
    alert_modal: require("../../assets/images/alert_icon.png"),
    back_fork: require("../../assets/images/back_fork.png"),
    wechat_login: require("../../assets/images/wechat_login.png"),
    qq_login: require("../../assets/images/qq_login.png"),
    wechat_white: require("../../assets/images/wechat_white.png"),
    qq_white: require("../../assets/images/qq_white.png"),
    countdown: require("../../assets/images/countdown.png"),
    white_time: require("../../assets/images/white_time.png"),
    card: require("../../assets/images/card.png"),
    triangular_warning: require("../../assets/images/triangular_warning.png"),
    not_tip: require("../../assets/images/not_tip.png"),
    have_tip: require("../../assets/images/have_tip.png"),
    hourglass: require("../../assets/images/hourglass.png"),
    red_close: require("../../assets/images/red_close.png"),
    blue_ok: require("../../assets/images/blue_ok.png"),
    red_destroy: require("../../assets/images/red_destroy.png"),
    blue_transmit: require("../../assets/images/blue_transmit.png"),
    destroy_card: require("../../assets/images/destroy_card.png"),
    vertical_video: require("../../assets/images/vertical_video.png"),
    horizontal_video: require("../../assets/images/horizontal_video.png"),
    red_tip: require("../../assets/images/red_tip.png"),
    new_tip: require("../../assets/images/new_tip.png"),
    video_pause: require("../../assets/images/video_pause.png"),
    video_play: require("../../assets/images/time_video_pause.png"),
    default_not_circle_muted: require("../../assets/images/default_not_circle_muted.png"),
    not_circle_muted: require("../../assets/images/not_circle_muted.png"),
  },
  common: {
    copy: require("../../assets/images/cpoy_address.png"),
    card_rare_type: require("../../assets/images/card_rare_type.png"),
    card_tale_type: require("../../assets/images/card_tale_type.png"),
    card_epic_type: require("../../assets/images/card_epic_type.png"),
    card_sift_type: require("../../assets/images/card_sift_type.png"),
    copy_success: require("../../assets/images/copy_success.png"),
    show_eye: require("../../assets/images/show_password.png"),
    hid_eye: require("../../assets/images/hid_password.png"),
  },
  route: {
    launch_screen: require("../../android/app/src/main/res/drawable-xhdpi/launch_screen.png"),
    version_modal: require("../../assets/images/version_modal.png"),
    version_modal_btn: require("../../assets/images/version_modal_btn.png"),
  },
  card: {
    mini_default_background: require("../../assets/images/default_mini_background.png"),
  },
  pay: {
    copy: require("../../assets/images/reieive_copy.png"),
    choose: require("../../assets/images/pay_choose.png"),
    not_choose: require("../../assets/images/pay_not_choose.png"),
  },
  screen: {
    comp: {
      item: require("../../assets/images/comp_item.png"),
      hot: require("../../assets/images/hot.png"),
      comp_complete: require("../../assets/images/comp_complete.png"),
      hot_card: require("../../assets/images/hot_card.png"),
    },
    login: {
      back_fork: require("../../assets/images/back_fork.png"),
    },
    home: {
      clock: require("../../assets/images/clock.png"),
      card_info_box: require("../../assets/images/card_info_box.png"),
      card_countdown: require("../../assets/images/card_countdown.png"),
      home_tips: require("../../assets/images/home_tips.png"),
      time_video_pause: require("../../assets/images/time_video_pause.png"),
      fill_screen: require("../../assets/images/fill_screen.png"),
      save_app_bar: require("../../assets/images/save_app_bar.png"),
      notice_icon: require("../../assets/images/notice_icon.png"),
      notice_logo: require("../../assets/images/notice_logo.png"),
    },
    activity: {
      open_right: require("../../assets/images/open_right.png"),
      video_play: require("../../assets/images/video_play.png"),
      payment_success: require("../../assets/images/payment_success.png"),
      payment_failure: require("../../assets/images/payment_failure.png"),
      more_text: require("../../assets/images/more_text.png"),
    },
    mine: {
      avatar_halo: require("../../assets/images/avatar_halo.png"),
      mine_past: require("../../assets/images/mine_past.png"),
      mine_expense_calendar: require("../../assets/images/mine_expense_calendar.png"),
      mine_version_nav: require("../../assets/images/mine_version_nav.png"),
      mine_setting_nav: require("../../assets/images/mine_setting_nav.png"),
      mine_official_nav: require("../../assets/images/mine_official_nav.png"),
      mine_exchange_nav: require("../../assets/images/mine_exchange_nav.png"),
      mine_challenge_nav: require("../../assets/images/mine_challenge_nav.png"),
      need_help: require("../../assets/images/needhelp.png"),
      close_at: require("../../assets/images/close_at.png"),
      download_qrcode: require("../../assets/images/download_qrcode.png"),
      wechat: require("../../assets/images/wechat.png"),
      wechat_moments: require("../../assets/images/wechat_moments.png"),
    },
    setting: {
      confirm_avatar: require("../../assets/images/confirm_avatar.png"),
      back: require("../../assets/images/setting_right_back.png"),
    },
    store: {
      drawer_open: require("../../assets/images/drawer_open.png"),
      send_store: require("../../assets/images/send_store.png"),
      search_store: require("../../assets/images/search_store.png"),
      list_up: require("../../assets/images/list_up.png"),
      select_left: require("../../assets/images/select_left.png"),
      not_select_left: require("../../assets/images/not_select_left.png"),
      select_right: require("../../assets/images/select_right.png"),
      not_select_right: require("../../assets/images/not_select_right.png"),
      video_bar: require("../../assets/images/video_bar.png"),
      video_info: require("../../assets/images/video_info.png"),
      vide_share: require("../../assets/images/video_share.png"),
      default_mute: require("../../assets/images/default_mute.png"),
      not_mute: require("../../assets/images/not_mute.png"),
    },
    receiveRecord: {
      receive: require("../../assets/images/receive.png"),
      gift: require("../../assets/images/gift.png"),
    },
    openCardBox: {
      obtained_modal: require("../../assets/images/obtained_modal.png"),
    },
  },
}

export const rootIcon = {}

export const mockIcon = {}
