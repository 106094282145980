import {
  View,
  Image,
  Platform,
  Text,
  TextInput,
  Animated,
  useWindowDimensions,
  ScrollView,
  Dimensions,
  Keyboard,
} from "react-native"
import { colors, fitSize } from "../../theme"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/Sheader"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models"
import { Screen, Touchable, iconRegistry, outlineStyle } from "../../components"
import { GToast } from "../../navigators"
import { Images } from "../../theme"
import { api } from "../../services/api"
import { StyleSheet } from "react-native"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { useEffect, useLayoutEffect, useReducer, useRef, useState } from "react"
import { isValidPhoneNumber, isValidPassword } from "../../utils/regexFunction"
import { textModal } from "../MineSetting/About"
import TextModal from "../../components/TextModal"
import RenderHTML from "react-native-render-html"
import { useTypeTabNavigation } from "../../navigators/useTypeNavigation"
import { ProtocolPromptModal } from "./components/ProtocolPromptModal"
import { DashedLine } from "../TabStore/StoreScreen"
import { showInfo } from "../../utils/showUtil"

type State = {
  account: string
  password: string
}

type Action = {
  type: "ACCOUNT" | "PASSWORD"
  value: string
}

const accountReducer = (state: State, action: Action) => {
  const { type, value } = action
  switch (type) {
    case "ACCOUNT":
      return { ...state, account: value }
    case "PASSWORD":
      return { ...state, password: value }
    default:
      throw new Error()
  }
}

interface PasswordLoginProps extends RootStackNavProps<"PasswordLogin"> {}

export const PasswordLogin: React.FC<PasswordLoginProps> = observer(({ navigation, route }) => {
  const { accountStore } = useStores()
  const [agree, setAgree] = useState(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>("")
  const [modalContent, setModalContent] = useState<string>("")
  const { width } = useWindowDimensions()
  const navigate = useTypeTabNavigation()
  const animatedValue = useRef(new Animated.Value(agree ? 1 : 0)).current
  const [isFocus, setIsFocus] = useState(false)
  const [showPassWord, setShowPassWord] = useState(true)
  const [accountLogin, setAccountLogin] = useReducer(accountReducer, {
    account: "",
    password: "",
  })
  const disabled = !isValidPhoneNumber(accountLogin.account) || accountLogin.password.length < 1
  const submitAnimatedValue = useRef(new Animated.Value(disabled ? 0.5 : 1)).current
  const [showModal, setShowModal] = useState(false)
  const input = useRef<TextInput>()
  const inputPwd = useRef<TextInput>()

  const [originalHeight, setOriginalHeight] = useState(window.innerHeight)

  if (Platform.OS === "web") {
    useEffect(() => {
      const handleResize = () => {
        const currentHeight = window.innerHeight

        if (currentHeight < originalHeight) {
          document.getElementById("footerPassword").style.display = "none"
        } else {
          document.getElementById("footerPassword").style.display = "flex"
        }
      }
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [originalHeight])
  }

  const onSubmit = () => {
    fetchLogin()
  }

  const switchLogin = () => {
    console.log("switchLogin")
  }

  const animatedIconTintColor = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [colors.palette.primary100, colors.palette.primary900],
  })

  const animatedCheckBtnBackground = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["transparent", colors.palette.primary500],
  })

  const animatedCheckBtnBorderWidth = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  })

  const animatedCheckBtnBorderColor = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [colors.palette.primary100, colors.palette.primary100],
  })

  const $iconTintColor = {
    tintColor: animatedIconTintColor,
  }

  const $checkBtn = {
    backgroundColor: animatedCheckBtnBackground,
    borderWidth: animatedCheckBtnBorderWidth,
    borderColor: animatedCheckBtnBorderColor,
  }

  const toggleAgree = () => {
    Animated.timing(animatedValue, {
      toValue: agree ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start()
  }

  const getAgreementAPI = async (type: 0 | 1 | 2) => {
    const res = await api.getPrivacyAgreement(type)
    if (res.kind === "ok") {
      if (res.data) {
        setModalContent(res?.data?.content)
      }
    } else {
      setModalContent("")
      GToast.current.handleErrorResponse(res)
    }
  }

  const openTextModal = (type: textModal): void => {
    setModalVisible(true)
    if (type === textModal.agreement) {
      getAgreementAPI(0)
      setModalTitle("用户协议")
    } else if (type === textModal.privacy) {
      getAgreementAPI(1)
      setModalTitle("隐私政策")
    } else if (type === textModal.service) {
      getAgreementAPI(2)
      setModalTitle("中国移动认证服务条款")
    }
  }

  const animatedDisabledStyle = {
    opacity: submitAnimatedValue,
  }

  const toggleDisabled = () => {
    Animated.timing(submitAnimatedValue, {
      toValue: disabled ? 0.5 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start()
  }

  useLayoutEffect(() => {
    toggleDisabled()
  }, [disabled])

  const fetchLogin = async () => {
    const res = await api.login(1, undefined, accountLogin.account, accountLogin.password)
    if (res.kind === "ok") {
      accountStore.setManualExit(false)
      accountStore.changeUser(res.data)
      accountStore.doMessageLogin(res.data.authorization)
      accountStore.setCurrentAccount(accountLogin.account)
      // @ts-ignore
      navigation.replace("Root")
    } else {
      GToast.current.handleErrorResponse(res)
    }
  }

  const forgotPassword = () => {
    navigation.push("SettingNewPassword")
  }

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <View style={style.titleContainer}>
          <Text style={style.title}>{translate("screen.passwordLogin.title")}</Text>
          <Touchable onPress={forgotPassword}>
            <Text style={style.forgetPassword}>
              {translate("screen.passwordLogin.forgetPassword")}
            </Text>
          </Touchable>
        </View>
        <View style={style.body}>
          <View style={style.inputRow}>
            <TextInput
              style={[style.input, outlineStyle]}
              onChangeText={(text) => setAccountLogin({ type: "ACCOUNT", value: text })}
              value={accountLogin.account}
              placeholder={translate("screen.passwordLogin.inputPlaceholder")}
              placeholderTextColor={colors.palette.neutral50}
              spellCheck={false}
              enablesReturnKeyAutomatically
              returnKeyType={"done"}
              autoCapitalize={"none"}
              underlineColorAndroid={"transparent"}
              clearButtonMode="while-editing"
              maxLength={11}
              keyboardType={"number-pad"}
              ref={input}
            />
          </View>
          <View style={style.inputRow}>
            <TextInput
              style={[style.input, outlineStyle]}
              onChangeText={(text) => setAccountLogin({ type: "PASSWORD", value: text })}
              value={accountLogin.password}
              placeholder={translate("screen.passwordLogin.passwordPlaceholder")}
              placeholderTextColor={colors.palette.neutral50}
              spellCheck={false}
              enablesReturnKeyAutomatically
              returnKeyType={"done"}
              autoCorrect={false}
              autoCapitalize={"none"}
              underlineColorAndroid={"transparent"}
              clearButtonMode="while-editing"
              textContentType="password"
              autoComplete="password"
              secureTextEntry={showPassWord}
              maxLength={20}
              ref={inputPwd}
            />
            <Touchable style={style.eyeBtn} onPress={() => setShowPassWord((prev) => !prev)}>
              {showPassWord ? (
                <Image source={Images.common.hid_eye} style={style.eye} />
              ) : (
                <Image source={Images.common.show_eye} style={style.eye} />
              )}
            </Touchable>
          </View>
          <View style={style.protocolContainer}>
            <Touchable
              onPress={() => {
                setAgree((prev) => !prev)
                toggleAgree()
              }}
              style={{ alignSelf: "center" }}
            >
              <Animated.View style={[style.checkBox, $checkBtn]}>
                <Animated.Image
                  source={iconRegistry.check}
                  style={[style.checkboxDetail, $iconTintColor]}
                />
              </Animated.View>
            </Touchable>
            <Text style={style.tip}>{translate("screen.loginScreen.tip")}</Text>
            <Touchable onPress={() => openTextModal(textModal.agreement)}>
              <Text style={style.userProtocol}>
                {translate("screen.loginScreen.userProtocol")}、
              </Text>
            </Touchable>
            <Touchable onPress={() => openTextModal(textModal.privacy)}>
              <Text style={style.privacyAgreement}>
                {translate("screen.loginScreen.privacyAgreement")}
              </Text>
            </Touchable>
          </View>
          <Touchable
            onPress={() => {
              if (
                !isValidPhoneNumber(accountLogin.account) ||
                !isValidPassword(accountLogin.password)
              ) {
                showInfo("密码格式错误", "CANCEL")
                return
              }
              if (!agree) {
                setShowModal(true)
              } else {
                onSubmit()
              }
            }}
            // disabled={disabled}
            style={style.center}
          >
            <Animated.View style={[style.submitButton, animatedDisabledStyle]}>
              <Text style={style.submitText}>{translate("screen.passwordLogin.btnText")}</Text>
            </Animated.View>
          </Touchable>
        </View>
        <View style={[style.endContainer]} id="footerPassword">
          <View style={style.rowCenter}>
            <DashedLine color={colors.palette.neutral50} />
            <Text style={style.endText}>{translate("screen.loginScreen.thirdLogin")}</Text>
            <DashedLine color={colors.palette.neutral50} />
          </View>
          <View style={style.thirdLogin}>
            <Touchable
              style={style.rightIcon}
              onPress={() => GToast.current.showToast("暂未开放", "TOAST", 2000)}
            >
              <Image source={Images.components.wechat_login} style={style.wechatIcon} />
            </Touchable>
            <Touchable onPress={() => GToast.current.showToast("暂未开放", "TOAST", 2000)}>
              <Image source={Images.components.qq_login} style={style.qqIcon} />
            </Touchable>
          </View>
        </View>
      </View>
      <ProtocolPromptModal
        isShow={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={() => {
          setAgree(true)
          toggleAgree()
          setShowModal(false)
          onSubmit()
        }}
        isService={false}
      />
      <TextModal modalVisible={modalVisible} setModalVisible={setModalVisible} title={modalTitle}>
        <ScrollView
          style={style.modalScrollView}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {Platform.OS === "web" ? (
            <div style={style.modalText} dangerouslySetInnerHTML={{ __html: modalContent }} />
          ) : (
            <RenderHTML
              contentWidth={width}
              source={{
                html: modalContent,
              }}
            />
          )}
        </ScrollView>
      </TextModal>
    </Screen>
  )
})

const style = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  forgetPassword: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral10,
    fontFamily: fontName.PingFang_SC,
  },
  eye: {
    width: fitSize(20),
    height: fitSize(20),
  },
  eyeBtn: {
    width: fitSize(20),
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  modalScrollView: {
    flex: 1,
    padding: fitSize(5),
  },
  modalText: {
    width: "100%",
    flexWrap: "wrap",
  },
  mobileService: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC,
    alignSelf: "center",
  },
  privacyAgreement: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC,
  },
  userProtocol: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC,
  },
  tip: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
    marginLeft: fitSize(6),
  },
  checkBox: {
    width: fitSize(12),
    height: fitSize(12),
    borderRadius: fitSize(12),
    justifyContent: "center",
    alignItems: "center",
  },
  checkboxDetail: {
    width: fitSize(10),
    height: fitSize(10),
  },
  protocolContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: fitSize(34),
    paddingLeft: fitSize(30),
    marginBottom: fitSize(50),
  },
  rightIcon: {
    marginRight: fitSize(50),
  },
  qqIcon: {
    width: fitSize(40),
    height: fitSize(40),
  },
  wechatIcon: {
    width: fitSize(40),
    height: fitSize(40),
  },
  thirdLogin: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: fitSize(25),
  },
  endText: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral50,
    fontFamily: fontName.PingFang_SC,
    paddingHorizontal: fitSize(14),
  },
  line: {
    height: 1,
    backgroundColor: colors.palette.neutral50,
    flexGrow: 1,
    borderBottomWidth: 1,
    borderColor: colors.palette.neutral50,
    borderStyle: Platform.select({ ios: "solid", android: "dashed", web: "dashed" }),
  },
  rowCenter: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  endContainer: {
    alignSelf: "flex-end",
    width: "100%",
    marginBottom: fitSize(47),
    flexDirection: "column",
    marginTop: fitSize(100),
  },
  tipText: {
    fontSize: fitSize(13),
    fontWeight: "500",
    color: colors.palette.neutral10,
    fontFamily: fontName.PingFang_SC,
  },
  tipBtn: {
    alignSelf: "center",
  },
  submitText: {
    fontSize: fitSize(15),
    fontWeight: "500",
    color: colors.palette.neutral30,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  submitButton: {
    width: fitSize(180),
    height: fitSize(40),
    backgroundColor: colors.palette.primary500,
    borderRadius: fitSize(20),
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  textCenter: {
    width: fitSize(64),
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  phoneLabel: {
    fontSize: fitSize(14),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  inputRow: {
    flexDirection: "row",
    width: fitSize(310),
    height: fitSize(50),
    backgroundColor: colors.palette.neutral80,
    borderRadius: fitSize(25),
    overflow: "hidden",
    paddingHorizontal: fitSize(24),
    marginTop: fitSize(14),
  },
  input: {
    height: "100%",
    flexGrow: 1,
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    padding: 0,
    borderRadius: fitSize(25),
  },
  body: {
    flex: 1,
    marginTop: fitSize(80),
  },
  title: {
    fontSize: fitSize(22),
    fontWeight: "500",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC_Semibold,
    marginTop: fitSize(8),
  },
  container: {
    flex: 1,
    height: Dimensions.get("window").height,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: "transparent",
    height: fitSize(44),
  },
  content: {
    flex: 1,
    paddingHorizontal: fitSize(32),
    zIndex: 2147483647,
  },
  phoneTip: {
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral10,
    fontFamily: fontName.PingFang_SC,
    marginBottom: fitSize(10),
  },
  center: {
    alignSelf: "center",
  },
})
