import { Images, colors, fitSize } from "../../theme"
import { StyleSheet, Text, View, Image, TextInput, ViewStyle } from "react-native"
import React, { useReducer, useState } from "react"
import { Sheader } from "../../components/Sheader"
import { RootStackNavProps } from "../../type"
import { Screen, Touchable, outlineStyle } from "../../components"
import { fontName } from "../../theme/fonts"
import { translate } from "../../i18n"
import { isValidPassword, isValidPhoneNumber } from "../../utils/regexFunction"
import { api } from "../../services/api"
import { GToast } from "../../navigators"
import { TextStyle } from "react-native"
import { useStores } from "../../models"
import { useCountdown } from "../../hooks/useCountdown"
import { ca } from "date-fns/locale"

type State = {
  code: string
  phone: string
  password: string
  cfmPassword: string
}

type Action = {
  type: "CODE" | "PHONE" | "PASSWORD" | "CFM_PASSWORD"
  value: string
}

const passwordEditReducer = (state: State, action: Action) => {
  const { type, value } = action
  switch (type) {
    case "CODE":
      return { ...state, code: value }
    case "PHONE":
      return { ...state, phone: value }
    case "PASSWORD":
      return { ...state, password: value }
    case "CFM_PASSWORD":
      return { ...state, cfmPassword: value }
    default:
      throw new Error()
  }
}

interface ForgotPasswordProps extends RootStackNavProps<"ForgotPassword"> {}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ navigation }) => {
  const [passwordEdit, setPasswordEdit] = useReducer(passwordEditReducer, {
    code: "",
    phone: "",
    password: "",
    cfmPassword: "",
  })
  const [showNewPassWord, setShowNewPassWord] = useState(true)
  const [showConfirmPassWord, setShowConfirmPassWord] = useState(true)
  const store = useStores()
  const { accountStore } = useStores()
  const { showVerification, time, onTime, onReset } = useCountdown()

  const exit = () => {
    store.removeAll()
    //@ts-ignore
    navigation.navigate("LoginScreen")
  }

  const fetchVerifyCode = async () => {
    try {
      const res = await api.getVerifyCode(accountStore.user.phoneSecy)
      if (res.kind === "ok") {
        onTime()
        GToast.current.showToast(translate("screen.phoneBinding.codeSuccess"), "OK", 1200)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const onConfirm = async () => {
    try {
      const res = await api.forgetPassword(
        passwordEdit.code,
        passwordEdit.phone,
        passwordEdit.password,
        passwordEdit.cfmPassword,
      )
      if (res.kind === "ok") {
        GToast.current.showToast(translate("screen.changePassword.editSuccess"), "OK", 2000)
        exit()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const isConfirm = !(
    isValidPassword(passwordEdit.password) &&
    isValidPassword(passwordEdit.cfmPassword) &&
    passwordEdit.password === passwordEdit.cfmPassword
  )

  const $confirmDisabledStyle: ViewStyle = {
    opacity: isConfirm ? 0.5 : 1,
  }

  const isClickCode = !isValidPhoneNumber(accountStore.user?.phoneSecy)

  const $clickCodeStyle: TextStyle = {
    color: colors.palette.neutral100,
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("screen.forgotPassword.header")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <Text style={style.label}>{translate("screen.forgotPassword.phoneVerify")}</Text>
        <View style={style.inputTextLineRow}>
          <Text style={style.text}>{accountStore.user?.phoneSecy}</Text>
        </View>
        <View style={style.inputRow}>
          <TextInput
            style={[style.confirmInput, outlineStyle]}
            onChangeText={(text) => setPasswordEdit({ type: "CODE", value: text })}
            value={passwordEdit.code}
            placeholder={translate("screen.forgotPassword.codePlaceholder")}
            placeholderTextColor={colors.palette.neutral100 + "66"}
            spellCheck={false}
            enablesReturnKeyAutomatically
            returnKeyType={"done"}
            autoCapitalize={"none"}
            underlineColorAndroid={"transparent"}
            clearButtonMode="while-editing"
            maxLength={6}
            keyboardType={"number-pad"}
          />
          {!showVerification ? (
            <Touchable style={style.codeBtn} onPress={fetchVerifyCode}>
              <Text style={[style.codeLabel, $clickCodeStyle]}>
                {translate("screen.phoneBinding.btnText")}
              </Text>
            </Touchable>
          ) : (
            <View style={style.codeBtn}>
              <Text style={[style.codeLabel, $clickCodeStyle]}>
                {translate("screen.phoneBinding.codePlaceholderTime", { time: time })}
              </Text>
            </View>
          )}
        </View>
        <Text style={style.label}>{translate("screen.changePassword.newPassword")}</Text>
        <View style={style.inputLineRow}>
          <TextInput
            style={[style.newInput, outlineStyle]}
            onChangeText={(text) => setPasswordEdit({ type: "PASSWORD", value: text })}
            value={passwordEdit.password}
            placeholder={translate("screen.changePassword.newPlaceholder")}
            placeholderTextColor={colors.palette.neutral100 + "66"}
            spellCheck={false}
            enablesReturnKeyAutomatically
            returnKeyType={"done"}
            autoCorrect={false}
            autoCapitalize={"none"}
            underlineColorAndroid={"transparent"}
            clearButtonMode="while-editing"
            textContentType="password"
            autoComplete="password"
            secureTextEntry={showNewPassWord}
            maxLength={20}
          />
          <Touchable style={style.eyeBtn} onPress={() => setShowNewPassWord((prev) => !prev)}>
            {showNewPassWord ? (
              <Image source={Images.common.hid_eye} style={style.eye} />
            ) : (
              <Image source={Images.common.show_eye} style={style.eye} />
            )}
          </Touchable>
        </View>
        <View style={style.inputRow}>
          <TextInput
            style={[style.confirmInput, outlineStyle]}
            onChangeText={(text) => setPasswordEdit({ type: "CFM_PASSWORD", value: text })}
            value={passwordEdit.cfmPassword}
            placeholder={translate("screen.changePassword.confirmNewPassword")}
            placeholderTextColor={colors.palette.neutral100 + "66"}
            spellCheck={false}
            enablesReturnKeyAutomatically
            returnKeyType={"done"}
            autoCorrect={false}
            autoCapitalize={"none"}
            underlineColorAndroid={"transparent"}
            clearButtonMode="while-editing"
            textContentType="password"
            autoComplete="password"
            secureTextEntry={showConfirmPassWord}
            maxLength={20}
          />
          <Touchable style={style.eyeBtn} onPress={() => setShowConfirmPassWord((prev) => !prev)}>
            {showConfirmPassWord ? (
              <Image source={Images.common.hid_eye} style={style.eye} />
            ) : (
              <Image source={Images.common.show_eye} style={style.eye} />
            )}
          </Touchable>
        </View>
        <Text style={style.tip}>{translate("screen.changePassword.tip")}</Text>
        <Touchable
          style={[style.btn, $confirmDisabledStyle]}
          onPress={onConfirm}
          disabled={isConfirm}
        >
          <Text style={style.btnText}>{translate("screen.changePassword.confirmBtn")}</Text>
        </Touchable>
      </View>
    </Screen>
  )
}

const style = StyleSheet.create({
  codeBtn: {
    width: fitSize(84),
    textAlign: "center",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
  },
  codeLabel: {
    fontSize: fitSize(12),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  eyeBtn: {
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  eye: {
    width: fitSize(20),
    height: fitSize(20),
  },
  inputTextLineRow: {
    height: fitSize(50),
    width: fitSize(347),
    borderTopRadius: fitSize(6),
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: colors.palette.neutral30,
    backgroundColor: colors.palette.neutral60,
    alignItems: "center",
  },
  inputLineRow: {
    height: fitSize(50),
    width: fitSize(347),
    borderTopRadius: fitSize(6),
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: colors.palette.neutral30,
    backgroundColor: colors.palette.neutral60,
  },
  inputRow: {
    height: fitSize(50),
    width: fitSize(347),
    borderTopRadius: fitSize(6),
    flexDirection: "row",
    backgroundColor: colors.palette.neutral60,
  },
  btnText: {
    fontSize: fitSize(14),
    fontWeight: "500",
    color: colors.palette.neutral30,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  btn: {
    width: fitSize(310),
    height: fitSize(50),
    borderRadius: fitSize(6),
    backgroundColor: colors.palette.neutral100,
    justifyContent: "center",
    alignItems: "center",
    marginTop: fitSize(50),
    alignSelf: "center",
  },
  tip: {
    fontSize: fitSize(12),
    fontWeight: "400",
    color: colors.palette.neutral20,
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(14),
  },
  text: {
    width: "100%",
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
    paddingLeft: fitSize(25),
  },
  confirmInput: {
    height: "100%",
    width: fitSize(283),
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
    paddingLeft: fitSize(25),
  },
  newInput: {
    height: "100%",
    width: fitSize(283),
    fontSize: fitSize(13),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    padding: 0,
    paddingLeft: fitSize(25),
  },
  label: {
    fontSize: fitSize(15),
    fontWeight: "400",
    color: colors.palette.neutral70,
    fontFamily: fontName.PingFang_SC,
    marginBottom: fitSize(10),
    marginTop: fitSize(20),
  },
  rightStyle: {
    width: fitSize(80),
  },
  headerRight: {
    fontSize: fitSize(14),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  content: {
    flex: 1,
    alignItems: "flex-start",
    paddingHorizontal: fitSize(14),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  textStyle: {
    fontSize: fitSize(16),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
