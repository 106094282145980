import { BottomTabParamList, MainScreenNavigationProp, RootStackParamList } from "../type/RouteType"
import { RouteProp, useRoute } from "@react-navigation/native"

import { useNavigation } from "@react-navigation/core"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"

export function useTypeNavigation<T extends keyof RootStackParamList>() {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList, T>>()
  return navigation
}
export function useTypeTabNavigation<T extends keyof BottomTabParamList>() {
  const navigation = useNavigation<MainScreenNavigationProp<T>>()
  return navigation
}

export function useTypeRoute<T extends keyof RootStackParamList>() {
  const route = useRoute<RouteProp<RootStackParamList, T>>()
  return route
}
