import * as Animatable from "react-native-animatable"

import { ImageBackground, ImageStyle, TextStyle, View, ViewStyle } from "react-native"
import React from "react"
import { Text } from "./Text"
import { Images, fitSize } from "../theme"

export const LoadCircleModal = ({ msg }) => {
  return (
    <View style={$centerWrap}>
      <ImageBackground source={Images.components.circle_layer2} style={$circleImg}>
        <Animatable.Image
          animation={"rotate"}
          easing={"linear"}
          iterationCount={Infinity}
          useNativeDriver={true}
          source={Images.components.circle_layer1}
          style={$circleImg}
        />
      </ImageBackground>
      <Text style={$msgText}>{msg || "Loading"}</Text>
    </View>
  )
}

const $centerWrap: ViewStyle = {
  width: fitSize(162),
  minHeight: fitSize(146),
  borderRadius: fitSize(10),
  backgroundColor: "#4D4D4D",
  alignSelf: "center",
  alignItems: "center",
  paddingTop: fitSize(35),
  paddingBottom: fitSize(30),
}

const $circleImg: ImageStyle = {
  width: fitSize(36),
  height: fitSize(36),
}

const $msgText: TextStyle = {
  color: "#fff",
  fontSize: fitSize(18),
  marginTop: fitSize(20),
  width: fitSize(96),
  textAlign: "center",
}
