import FastImage, { FastImageProps } from "react-native-fast-image"
import { ImageBackground, PixelRatio, Platform } from "react-native"
import React, { useEffect, useRef, useState } from "react"

interface SizeImageProps extends FastImageProps {
  isPreview?: boolean
  isGif?: boolean
  source: any
}
const normalImgSuffix = {
  1: "?x-oss-process=style/x1",
  2: "?x-oss-process=style/x2",
  3: "?x-oss-process=style/x3",
}
const previewImgSuffix = {
  1: "?x-oss-process=style/thumb_x1",
  2: "?x-oss-process=style/thumb_x2",
  3: "?x-oss-process=style/thumb_x3",
}
const pixRatio = PixelRatio.get()
const getFinalSuffix = (isPreview: boolean, uri: string, isGif: boolean) => {
  if (isGif) {
    return uri
  }
  const suffixObject = isPreview ? previewImgSuffix : normalImgSuffix
  if (pixRatio >= 3) {
    return uri + suffixObject[3]
  } else if (pixRatio >= 2) {
    return uri + suffixObject[2]
  } else {
    return uri + suffixObject[1]
  }
}

// 增加图片重试
const maxRetries = 3
export const SizeImage: React.FC<SizeImageProps> = ({
  isPreview = false,
  isGif = false,
  source,
  ...rest
}) => {
  const [errorCount, setErrorCount] = useState(0)
  const [visible, setVisible] = useState(true)
  const isMount = useRef(false)
  useEffect(() => {
    isMount.current = true
    return () => {
      isMount.current = false
    }
  }, [])

  const data = source?.uri
    ? { ...source, uri: getFinalSuffix(isPreview, source.uri, isGif) }
    : source

  if (Platform.OS === "web") {
    // @ts-ignore
    return <ImageBackground source={data} {...rest}></ImageBackground>
  }

  return (
    <FastImage
      {...rest}
      key={`${visible}`}
      source={data}
      onError={() => {
        if (isMount.current && errorCount < maxRetries) {
          setErrorCount(errorCount + 1)
          setVisible(false)
          setTimeout(() => {
            if (isMount.current) {
              setVisible(true)
            }
          }, 500)
        }
      }}
    />
  )
}
