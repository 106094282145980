import {
  Image,
  Platform,
  ScrollView,
  Dimensions,
  StyleSheet,
  Text,
  View,
  ImageStyle,
  AppState,
} from "react-native"
import React, { FC, Fragment, useEffect, useLayoutEffect, useRef, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { Images, colors, fitSize } from "../../theme"
import { Screen, Sheader, Touchable, TouchableDeep } from "../../components"
import { hideLoad, showError, showLoad } from "../../utils/showUtil"
import { OrderDetail, OrderDetailItem, PaymentTypeItem, api } from "../../services/api"
import { useTokenAuthorization } from "../../hooks/useTokenAuthorization"
import { fontName } from "../../theme/fonts"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import { PayModal } from "../../modal/PayModal"
import { GToast, goBack } from "../../navigators"
import { CmbPay } from "../../utils/CmbPay"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { translate } from "../../i18n"
import Clipboard from "@react-native-clipboard/clipboard"
import { SizeImage } from "../../components/SizeImage"
import { openLinkInBrowser } from "../../utils/openLinkInBrowser"
import { useSecond } from "../../hooks/useSecond"
import { loadString, saveString } from "../../utils/storage"
import { InAppPurchases } from "../../utils/CompatibleUtils"
import { useFocusEffect } from "@react-navigation/native"

const getActivityStartRestTimeDays = (time: number) => {
  if (time <= 0) return "00:00"

  const allS = Math.floor(time / 1000)
  const days = Math.floor(allS / 60 / 60 / 24)

  const hours = Math.floor((allS / 60 / 60) % 24)
  const minutes = Math.floor((allS / 60) % 60)
  const seconds = Math.floor(allS % 60)

  const timeString =
    (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds)

  return timeString
}

dayjs.extend(duration)
dayjs.extend(relativeTime)

interface AssetItemProps {
  item: OrderDetailItem
}

export enum OrderStatus {
  nonPayment = 0,
  Payed = 1,
  Paying = 2,
  // Failure = 3,
  Cancel = 4,
  SendSuccess = 3,
}

const AssetItem: FC<AssetItemProps> = ({ item }) => {
  return (
    <View style={style.assetItemContainer}>
      <SizeImage source={{ uri: item?.imageMain }} style={style.assetItemImage} />
      <View style={style.assetItemContent}>
        <View style={style.assetItemInfo}>
          <Text style={style.goodsName}>{item?.goodsName}</Text>
          <Text style={style.amount}>
            <Text style={style.unitIcon}>¥</Text>
            {item?.amount}
          </Text>
        </View>
        <View style={style.assetItemNumberBox}>
          <Text style={style.openNumber}>随机开出{item?.openNumber}个时刻</Text>
          <Text style={style.countText}>
            <Text style={style.unitIcon}>x</Text>
            {item?.count}
          </Text>
        </View>
      </View>
    </View>
  )
}

interface CopyBoxProps {
  text: string
}

export const CopyBox: FC<CopyBoxProps> = ({ text }) => {
  const doCopy = (copy: any) => {
    Clipboard.setString(String(copy))
    GToast.current.showToast(translate("common.copy"), "TOAST", 2000)
  }

  const $copyIcon: ImageStyle = {
    tintColor: colors.palette.neutral100,
    width: fitSize(12),
    height: fitSize(12),
    marginLeft: fitSize(8),
  }

  return (
    <Fragment>
      {Platform.OS !== "web" ? (
        <Touchable onPress={() => doCopy(text)}>
          <Image source={Images.common.copy} style={$copyIcon} />
        </Touchable>
      ) : (
        <CopyToClipboard
          text={text}
          onCopy={() => GToast.current.showToast(translate("common.copy"), "TOAST", 2000)}
        >
          <Image source={Images.common.copy} style={$copyIcon} />
        </CopyToClipboard>
      )}
    </Fragment>
  )
}

interface ShowOrderProps extends RootStackNavProps<"ShowOrder"> {}

let interval
export const ShowOrder: React.FC<ShowOrderProps> = observer(({ navigation, route }) => {
  const [orderData, setOrderData] = useState<OrderDetail | null>(null)
  const [payTypeList, setPayTypeList] = useState<PaymentTypeItem[] | null>(null)
  const { authorization } = useTokenAuthorization()
  const { bottom } = useSafeAreaInsets()
  const [showPayModal, setShowPayModal] = useState(false)
  const { currentTime } = useSecond()
  const [payTriggered, setPayTriggered] = useState(false)
  const payTriggeredRef = useRef(payTriggered)

  useEffect(() => {
    payTriggeredRef.current = payTriggered
  }, [payTriggered])

  useEffect(() => {
    const handleAppStateChange = async (nextAppState) => {
      if (nextAppState === "active" && payTriggeredRef.current) {
        setPayTriggered(false)
        await GToast.current.showAlert(
          "是否完成支付?",
          () => {
            checkOrder()
          },
          () => {
            checkOrder()
          },
        )
      }
    }
    const subscription = AppState.addEventListener("change", handleAppStateChange)
    return () => {
      subscription.remove()
    }
  }, [])

  useEffect(() => {
    if (orderData?.status === OrderStatus.nonPayment || orderData?.status === OrderStatus.Payed) {
      const targetDate = dayjs(orderData?.expiredTime)
      interval = setInterval(() => {
        const now = dayjs()
        if (!now.isBefore(targetDate)) {
          console.log("当前时间已超过目标时间，执行相应操作")
          clearInterval(interval)
          init()
        }
      }, 1000)
    }
  }, [orderData?.expiredTime])

  const init = async () => {
    try {
      const res = await api.getOrderDetail(route.params.id)
      if (res.kind === "ok") {
        setOrderData(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const getPaymentType = async () => {
    try {
      const res = await api.getPaymentTypeList()
      if (res.kind === "ok") {
        setPayTypeList(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const doAliPay = async () => {
    try {
      setPayTriggered(true)
      const res = await api.alipayPayment(orderData?.outTradeNo)
      if (res.kind === "ok") {
        if (Platform.OS === "android" || Platform.OS === "ios") {
          await CmbPay.justOpenSimpleUrl(res.data.url)
        } else {
          window.location.href = res.data.url
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const doWeChatPay = async () => {
    try {
      const res = await api.weChatPayPayment(orderData?.outTradeNo)
      if (res.kind === "ok") {
        GToast.current.showAlert(
          "是否完成支付?",
          () => {
            checkOrder()
          },
          () => {
            checkOrder()
          },
        )
        if (Platform.OS === "android") {
          await CmbPay.justOpenSimpleUrl(res.data.urlLink)
        } else if (Platform.OS === "ios") {
          openLinkInBrowser(res.data.urlLink)
        } else {
          window.location.href = res.data.urlLink
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const checkOrder = async () => {
    try {
      const res = await api.getOrderDetail(route.params.id)
      if (res.kind === "ok") {
        setOrderData(res.data)
        switch (res.data.status) {
          // case 0:
          //   GToast.current.showToast("未支付", "CANCEL", 1000)
          //   break
          case 1:
            GToast.current.showToast("支付中", "CANCEL", 1000)
            break
          case 2:
            GToast.current.showToast("支付成功", "OK", 1000)
            // navigation.replace("PaymentSuccess", { ...route.params })
            break
          case 3:
            // GToast.current.showToast("支付失败", "CANCEL", 1000)
            // navigation.replace("PaymentFailure", { ...route.params })
            GToast.current.showToast("订单完成", "CANCEL", 1000)
            // navigation.replace("PaymentSuccess", { ...route.params })
            break
          case 4:
            GToast.current.showToast("订单取消", "CANCEL", 1000)

            break
          // case 5:
          //   GToast.current.showToast("订单完成", "CANCEL", 1000)
          //   navigation.replace("PaymentSuccess", { ...route.params })
          //   break
          default:
            break
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const applePay = async (payInfo) => {
    await saveString("appleOrderUncheck", payInfo?.outTradeNo)
    const data = await loadString("appleOrderUncheck")
    console.log("appleOrderUncheck", data)
    try {
      GToast.current.showLoading("请稍等...")
      await InAppPurchases.getProductsAsync([String(payInfo?.applePayId)])
      const payres = await InAppPurchases.purchaseItemAsync(String(payInfo?.applePayId))
      console.log("payInfo?.data?.outTradeNo", payInfo?.outTradeNo)
      console.log("payres", payres)
      GToast.current.hideToast()
      GToast.current.showAlert(
        "是否完成支付?",
        () => {
          checkOrder()
        },
        () => {
          checkOrder()
        },
      )
    } catch (err) {
      GToast.current.hideToast()
      GToast.current.showToast(err?.message, "CANCEL", 2000)
      console.log("error========", err?.message)
    }
  }

  const paymentCallBack = async (payWay: number) => {
    const currentTime = dayjs() // 获取当前时间
    const targetTime = dayjs(orderData?.expiredTime) // 将目标时间转换为 dayjs 对象

    // 计算目标时间与当前时间的时间差（单位：毫秒）
    const timeDifference = targetTime.diff(currentTime)

    // 判断时间差是否小于30秒
    if (timeDifference < 30000) {
      // 时间差小于30秒，执行不允许购买的操作
      console.log("不允许购买")
      GToast.current.showToast("支付时间不足,请重新下单", "CANCEL", 2000)
    } else {
      setShowPayModal(false)
      if (payWay === 5) {
        await doAliPay()
      } else if (payWay === 6) {
        await doWeChatPay()
      } else if (payWay === 3) {
        applePay(orderData)
      }
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      init()
      getPaymentType()
    }, [route]),
  )

  const clearPayWay = async () => {
    try {
      const res = await api.clearPayWay(orderData?.outTradeNo)
      if (res.kind === "ok") {
        goBack()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={"订单详情"}
        isNotSafe
        textStyle={{ color: colors.palette.neutral100 }}
        leftStyle={style.headerLeftIcon}
        style={style.header}
        leftAction={() => navigation.navigate("OrderBuy")}
      />
      <ScrollView
        style={style.valueContent}
        showsVerticalScrollIndicator
        showsHorizontalScrollIndicator
      >
        <View style={style.goodsListContainer}>
          {orderData?.goodsList?.map((i, index) => {
            return <AssetItem item={i} key={index} />
          })}
        </View>
        <View style={style.titleWarpContent}>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>商品总价</Text>
            <Text style={style.amount}>
              <Text style={style.unitIcon}>¥</Text>
              {orderData?.amount}
            </Text>
          </View>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>优惠卷</Text>
            <Text style={style.rollText}>暂无可用</Text>
          </View>
          <View style={style.playAmountBox}>
            <Text style={style.playLabel}>实付款</Text>
            <Text style={style.playAmountText}>
              <Text style={style.unitIcon}>¥</Text>
              {orderData?.payAmount}
            </Text>
          </View>
        </View>
        <View style={style.titleWarp}>
          <Text style={style.titleWarpText}>订单信息</Text>
        </View>
        <View style={style.titleWarpContent}>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>订单编号</Text>
            <View style={style.rowCenter}>
              <Text style={style.titleWarpContentAddressText} numberOfLines={1}>
                {orderData?.outTradeNo ?? "-"}
              </Text>
              <CopyBox text={orderData?.outTradeNo ?? "-"} />
            </View>
          </View>
          <View style={style.titleWarpBox}>
            <Text style={style.titleWarpContentText}>下单时间</Text>
            <Text style={style.titleWarpContentText}>{orderData?.createdAt}</Text>
          </View>
          {orderData?.status === 3 ? (
            <Fragment>
              <View style={style.titleWarpBox}>
                <Text style={style.titleWarpContentText}>获取时间</Text>
                <Text style={style.titleWarpContentText}>
                  {orderData?.status === 3
                    ? dayjs(orderData?.payTime).format("YYYY-MM-DD HH:mm:ss")
                    : "-"}
                </Text>
              </View>
              <View style={style.titleWarpBox}>
                <Text style={style.titleWarpContentText}>付款方式</Text>
                <Text style={style.titleWarpContentText}>{orderData?.payTypeName ?? "-"}</Text>
              </View>
              <View style={style.titleWarpBox}>
                <Text style={style.titleWarpContentText}>地址</Text>
                <View style={style.rowCenter}>
                  <Text style={style.titleWarpContentAddressText} numberOfLines={1}>
                    {orderData?.contract ?? "-"}
                  </Text>
                  <CopyBox text={orderData?.contract ?? "-"} />
                </View>
              </View>
              <View style={style.titleWarpBox}>
                <Text style={style.titleWarpContentText}>区块链交易ID</Text>
                <View style={style.rowCenter}>
                  <Text style={style.titleWarpContentAddressText} numberOfLines={1}>
                    {orderData?.hashBlock ?? "-"}
                  </Text>
                  <CopyBox text={orderData?.hashBlock ?? "-"} />
                </View>
              </View>
            </Fragment>
          ) : null}
          <View style={[style.titleWarpBox, { marginBottom: 0 }]}>
            <Text style={style.titleWarpContentText}>状态</Text>
            <Text style={[style.titleWarpContentText, { color: "#ffffff" }]}>
              {orderData?.statusText ?? "-"}
            </Text>
          </View>
        </View>
        <View style={[style.row, { display: orderData?.status === 2 ? "flex" : "none" }]}>
          <Image
            source={Images.components.order_warning}
            style={[style.warningIcon, { tintColor: colors.palette.neutral100 }]}
          />
          <Text style={[style.warningText, { color: colors.palette.neutral100 }]}>
            由于数字藏品特殊性质，请耐心等待发货完成，稍后可在“我的-卡包”中进行后续操作
          </Text>
        </View>
        <View style={style.row}>
          <Image source={Images.components.order_warning} style={style.warningIcon} />
          <Text style={style.warningText}>
            荣耀炫金包内所含的所有藏品均为视频类数字藏品。一共能获得5枚视频数字藏品。其中100%必得一枚荣耀炫金等级藏品，以及四枚普通等级藏品。商品总共投放数量为50个包，售完即止。
          </Text>
        </View>
      </ScrollView>
      {orderData?.status === OrderStatus.nonPayment || orderData?.status === OrderStatus.Payed ? (
        <View
          style={[
            style.bottomContainer,
            {
              minHeight: fitSize(30) + bottom,
            },
          ]}
        >
          <TouchableDeep onPress={clearPayWay} style={style.clearNameBtn}>
            <Text style={style.clearText}>取消订单</Text>
          </TouchableDeep>
          <TouchableDeep
            onPress={() => {
              if (Platform.OS === "ios") {
                applePay(orderData)
              } else {
                setShowPayModal(true)
              }
            }}
            style={style.saleStatusNameBtn}
          >
            <Text style={style.saleStatusNameText}>
              立即支付
              {getActivityStartRestTimeDays(dayjs(orderData?.expiredTime).diff(currentTime))}
            </Text>
          </TouchableDeep>
        </View>
      ) : null}
      <PayModal
        info={orderData}
        list={payTypeList}
        isShow={showPayModal}
        closeModal={setShowPayModal}
        paymentCallBack={paymentCallBack}
      />
    </Screen>
  )
})

const style = StyleSheet.create({
  clearNameBtn: {
    width: fitSize(86),
    height: fitSize(36),
    borderRadius: fitSize(4),
    backgroundColor: "transparency",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginRight: fitSize(14),
    borderWidth: 1,
    borderColor: "#666666",
  },
  clearText: {
    color: "#666666",
    fontSize: fitSize(13),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  saleStatusNameText: {
    color: "#333333",
    fontSize: fitSize(13),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  bottomContainer: {
    width: "100%",
    flexDirection: "row",
    position: "absolute",
    bottom: 40,
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingHorizontal: fitSize(14),
  },
  saleStatusNameBtn: {
    width: fitSize(126),
    height: fitSize(36),
    borderRadius: fitSize(4),
    backgroundColor: "#6FEBE8",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  warningText: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(10),
    fontWeight: "400",
    color: "#666666",
    paddingRight: fitSize(10),
  },
  warningIcon: {
    width: fitSize(12),
    height: fitSize(12),
    marginRight: fitSize(3),
    marginTop: fitSize(3),
  },
  row: {
    flexDirection: "row",
    marginTop: fitSize(20),
  },
  playAmountText: {
    color: "#6FEBE8",
    fontSize: fitSize(23),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  playLabel: {
    color: "#666666",
    fontSize: fitSize(14),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginRight: fitSize(10),
    paddingBottom: fitSize(3),
  },
  playAmountBox: {
    alignSelf: "flex-end",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  rollText: {
    color: "#666666",
    fontSize: fitSize(10),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  titleWarpBox: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: fitSize(14),
  },
  titleWarpText: {
    color: colors.palette.neutral100,
    fontSize: fitSize(14),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  titleWarpContent: {
    width: fitSize(347),
    paddingVertical: fitSize(14),
    paddingHorizontal: fitSize(10),
    borderRadius: fitSize(5),
    marginTop: fitSize(14),
    borderWidth: 1,
    borderColor: "#333333",
    minHeight: fitSize(39),
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  titleWarpContentAddressText: {
    color: "#666666",
    fontSize: fitSize(12),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    width: fitSize(200),
    textAlign: "right",
  },
  titleWarpContentText: {
    color: "#666666",
    fontSize: fitSize(12),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  titleWarp: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: fitSize(20),
    overflow: "hidden",
  },
  goodsListContainer: {
    width: "100%",
    flexDirection: "column",
  },
  valueContent: {
    flex: 1,
    height: Dimensions.get("screen").height,
    width: "100%",
    paddingHorizontal: fitSize(14),
    marginTop: fitSize(45),
  },
  header: {
    height: fitSize(44),
  },
  headerLeftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  content: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
  unitIcon: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(10),
    fontWeight: "400",

    marginRight: fitSize(2),
    alignSelf: "flex-end",
  },
  assetItemContainer: {
    flexDirection: "row",
    width: "100%",
  },
  assetItemImage: {
    width: 113,
    height: fitSize(113),
    marginLeft: fitSize(16),
  },
  assetItemContent: {
    flexGrow: 1,
    paddingLeft: fitSize(32),
    flexDirection: "column",
    alignItems: "flex-start",
  },
  assetItemInfo: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  goodsName: {
    color: colors.palette.neutral100,
    fontSize: fitSize(16),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  amount: {
    color: colors.palette.neutral100,
    fontSize: fitSize(16),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  assetItemNumberBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: fitSize(6),
  },
  openNumber: {
    color: "#666666",
    fontSize: fitSize(14),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  countText: {
    color: "#666666",
    fontSize: fitSize(12),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
})
