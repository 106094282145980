import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { AccountModel } from "./account/account"

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model("RootStore")
  .props({
    accountStore: types.optional(AccountModel, {}),
  })
  .actions((self) => ({
    removeToken: self.accountStore.removeAuth,
    removeAll() {
      self.accountStore.removeAuth()
      self.accountStore.clearUserInfo()
    },
  }))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}
/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
