import { Platform, StyleSheet, View, useWindowDimensions, Text, ScrollView } from "react-native"
import React, { FC, useState } from "react"
import { Images, colors, deviceHeight, fitSize } from "../theme"

import Modal from "react-native-modal/dist/modal"
import { FlexView } from "../components/FlexView"
import { SizeImage } from "../components/SizeImage"
import { Screen, Touchable } from "../components"
import { PAtitle, privacyAgreementTxt } from "../config/privacyAgreement"
import { UPtitle, userProtocolTxt } from "../config/userProtocol"
import { TouchableOpacity } from "react-native"
import RNExitApp from "react-native-exit-app"
import { remove, saveString } from "../utils/storage"
import { translate } from "../i18n"

interface ProtocolTipModalProps {
  modalShow: boolean
  modalShowFC?: (value: boolean) => void
  isBack: boolean
  onClick?: () => void
  onClose?: () => void
}

export const ProtocolTipModal: React.FC<ProtocolTipModalProps> = ({
  modalShow,
  modalShowFC = (value: boolean) => {},
  isBack,
  onClick,
  onClose,
}) => {
  const { height } = useWindowDimensions()
  const compatibleHeight = Platform.OS === "web" ? height : deviceHeight
  const [isSwitching, setIsSwitching] = useState(true)
  const [type, setType] = useState<"protocol" | "policy">("policy")
  const [isShow, setIsShow] = useState(false)

  const setPermissions = async () => {
    onClose()
  }

  return (
    <Modal
      hasBackdrop={true}
      coverScreen={true}
      isVisible={modalShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={{ margin: 0, backgroundColor: "#202530", zIndex: 9999, overflow: "hidden" }}
      backdropOpacity={0.7}
      onBackButtonPress={() => {}}
      onBackdropPress={() => {}}
      swipeDirection={"down"}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
    >
      {!isShow ? (
        <React.Fragment>
          <View style={[styles.container, { height: fitSize(280), overflow: "hidden" }]}>
            <View style={styles.titleBox}>
              <Text ellipsizeMode="tail" numberOfLines={1} style={styles.titleText}>
                {isSwitching
                  ? translate("modal.protocolTipModal.oneTipTitle")
                  : translate("modal.protocolTipModal.twoTipTitle")}
              </Text>
            </View>
            <View style={{ width: "100%", height: fitSize(620) }}>
              {isSwitching ? (
                <React.Fragment>
                  <Text
                    style={{
                      color: "#f1f1f1d7",
                      fontSize: fitSize(13),
                      marginTop: fitSize(10),
                      fontWeight: "bold",
                    }}
                  >
                    {translate("modal.protocolTipModal.title")}
                    <Text
                      style={{
                        color: "#00ced1",
                        fontSize: fitSize(13),
                        marginTop: fitSize(10),
                        fontWeight: "bold",
                      }}
                      onPress={() => {
                        setIsShow(true)
                        setType("protocol")
                      }}
                    >
                      {translate("modal.protocolTipModal.userProtocol")}
                    </Text>
                    、
                    <Text
                      style={{
                        color: "#00ced1",
                        fontSize: fitSize(13),
                        marginTop: fitSize(10),
                        fontWeight: "bold",
                      }}
                      onPress={() => {
                        setIsShow(true)
                        setType("policy")
                      }}
                    >
                      {translate("modal.protocolTipModal.privacyAgreement")}
                    </Text>
                    {translate("modal.protocolTipModal.and")}
                  </Text>
                  <Text
                    style={{
                      color: "#f1f1f1",
                      fontSize: fitSize(14),
                      marginTop: fitSize(20),
                      marginBottom: fitSize(10),
                      fontWeight: "bold",
                    }}
                  >
                    {translate("modal.protocolTipModal.oneViceInfo")}
                  </Text>
                  <Text
                    style={{
                      color: "#f1f1f1d7",
                      fontSize: fitSize(13),
                      marginTop: fitSize(10),
                      fontWeight: "bold",
                    }}
                  >
                    {translate("modal.protocolTipModal.oneInfo")}
                  </Text>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Text
                    style={{
                      color: "#f1f1f1d7",
                      fontSize: fitSize(13),
                      marginTop: fitSize(10),
                      fontWeight: "bold",
                    }}
                  >
                    {translate("modal.protocolTipModal.twoInfo")}
                  </Text>
                </React.Fragment>
              )}
            </View>
          </View>
          <View
            style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}
          >
            <Touchable
              style={styles.backBtn}
              onPress={async () => {
                remove("privacyAgreement")
                remove("userProtocol")
                RNExitApp.exitApp()
              }}
            >
              <Text style={styles.backText}>
                {isSwitching
                  ? translate("modal.protocolTipModal.disagreeBtn")
                  : translate("modal.protocolTipModal.exitApp")}
              </Text>
            </Touchable>
            <Touchable
              style={styles.backBtn}
              onPress={async () => {
                if (isSwitching) {
                  await saveString("privacyAgreement", "true")
                  setIsSwitching(false)
                } else {
                  await saveString("userProtocol", "true")
                  setPermissions()
                }
              }}
            >
              <Text style={styles.backText}>
                {isSwitching
                  ? translate("modal.protocolTipModal.continue")
                  : translate("modal.protocolTipModal.agreeBtn")}
              </Text>
            </Touchable>
          </View>
        </React.Fragment>
      ) : (
        <TextScreen setShow={setIsShow} protocolPolicy={type} />
      )}
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    marginHorizontal: fitSize(15),
  },
  content: {
    width: "100%",
    height: "100%",
    padding: fitSize(15),
    paddingBottom: fitSize(15),
  },
  titleBox: {
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: fitSize(6),
  },
  timeBox: {
    flexDirection: "column",
    height: fitSize(50),
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
    marginBottom: fitSize(20),
    paddingHorizontal: fitSize(15),
  },
  titleText: {
    color: "#f1f1f1",
    fontSize: fitSize(17),
    marginBottom: fitSize(10),
    fontWeight: "bold",
  },
  timeText: {
    color: "#f1f1f1",
    fontSize: fitSize(13),
    marginBottom: fitSize(10),
  },
  contentText: {
    width: "100%",
    flexWrap: "wrap",
    color: "#9a9a9a",
  },
  backBtn: {
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    marginBottom: fitSize(10),
    width: fitSize(130),
    height: fitSize(40),
    borderRadius: fitSize(5),
    borderWidth: 1,
    borderColor: "#f1f1f1",
  },
  backText: {
    textAlign: "center",
    fontSize: fitSize(15),
    color: "#f1f1f1",
    paddingTop: fitSize(9),
  },
  userAgreement: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  checkbox: {
    width: fitSize(20),
    height: fitSize(20),
    borderRadius: fitSize(10),
    marginRight: fitSize(4),
    borderWidth: fitSize(1.5),
  },
  userAgreementText: {
    fontSize: fitSize(13),
  },

  modalScrollView: {
    flex: 1,
    padding: fitSize(5),
  },
  modalText: {
    width: "100%",
    flexWrap: "wrap",
  },

  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "white",
    alignItems: "center",
    width: fitSize(315),
    height: fitSize(432),
    borderRadius: fitSize(12),
    borderWidth: fitSize(1),
  },
  title: {
    fontSize: fitSize(17),
    fontWeight: "600",
    marginTop: fitSize(20),
    marginBottom: fitSize(15),
  },

  titleContainer: {
    alignItems: "center",
    width: "100%",
  },
})

interface TextScreenProps {
  protocolPolicy: "protocol" | "policy"
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const TextScreen: FC<TextScreenProps> = ({ protocolPolicy, setShow }) => {
  return (
    <ScrollView
      style={{
        width: "100%",
        paddingHorizontal: fitSize(15),
        flex: 1,
      }}
      removeClippedSubviews={true}
    >
      <TouchableOpacity activeOpacity={1}>
        <Touchable
          onPress={() => setShow(false)}
          style={{
            flexDirection: "row",
            marginTop: fitSize(20),
          }}
        >
          <SizeImage
            style={{ width: fitSize(20), height: fitSize(20) }}
            source={Images.components.header_back}
            resizeMode="cover"
          />
          <Text
            style={{
              alignSelf: "center",
              color: "#f1f1f1",
              fontSize: fitSize(14),
              fontWeight: "bold",
              marginLeft: fitSize(20),
            }}
          >
            {protocolPolicy === "policy" && PAtitle}
            {protocolPolicy === "protocol" && UPtitle}
          </Text>
        </Touchable>
        <Text
          style={{
            color: "#f1f1f1",
            fontSize: fitSize(14),
            fontWeight: "bold",
            marginTop: fitSize(20),
            height: "100%",
          }}
        >
          {protocolPolicy === "policy" && privacyAgreementTxt}
          {protocolPolicy === "protocol" && userProtocolTxt}
        </Text>
      </TouchableOpacity>
    </ScrollView>
  )
}
