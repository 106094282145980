import { FULL, Images, colors, fitSize } from "../../theme"
import { Image, Pressable, StyleSheet, View } from "react-native"
import React, { useState } from "react"
import { Screen, Text, Touchable, TouchableDeep } from "../../components"

import { ChooseItem } from "./components/ChooseItem"
import { RootStackNavProps } from "../../type/RouteType"
import { SelectModal } from "./components/SelectModal"
import { Sheader } from "../../components/Sheader"
import { translate } from "../../i18n"

interface ViewAvatarProps extends RootStackNavProps<"ViewAvatar"> {}

export const ViewAvatar: React.FC<ViewAvatarProps> = ({ navigation }) => {
  const [isShowChoose, setIsShowChoose] = useState(false)
  const close = () => {
    setIsShowChoose(false)
  }
  const saveToCameraRoll = (uri: string) => {
    //TODO: 保存图片到本地
    // CameraRoll.saveImageWithTag(uri)
    //   .then(function (result) {
    //     console.log("save succeeded " + result)
    //   })
    //   .catch(function (error) {
    //     console.log("save failed " + error)
    //   })
  }
  return (
    <View style={FULL}>
      <Sheader
        rightImage={""}
        title={translate("setting.personAvator")}
        leftImage={"black_back"}
        rightComp={
          <TouchableDeep
            onPress={() => {
              navigation.push("ChooseAvatar")
            }}
            style={[
              {
                width: fitSize(48),
                height: fitSize(24),
                backgroundColor: "#FAC033",
                borderRadius: fitSize(6),
                marginRight: fitSize(6),
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "flex-end",
              },
            ]}
          >
            <Text
              tx={"setting.edit"}
              style={{ color: "#333", fontWeight: "bold" ,fontSize:fitSize(14)}}

            ></Text>
          </TouchableDeep>
        }
        rightWrapStyle={{ width: fitSize(90) }}
      />
      <View
        style={{ backgroundColor: "#000", flex: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Pressable
          onLongPress={() => {
            setIsShowChoose(true)
          }}
        >
          <Image
            source={Images.test.m_owner1}
            style={{ width: fitSize(375), height: fitSize(375) }}
          />
        </Pressable>
      </View>
      {/* <SelectModal closeModal={close} isShow={isShowChoose}>
        <ChooseItem
          onPress={() => {
            close()
          }}
          title={translate("setting.savePic")}
        />
      </SelectModal> */}
    </View>
  )
}

const styles = StyleSheet.create({})
