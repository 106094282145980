import { Image, ImageStyle, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native"

import React from "react"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useTypeNavigation } from "../navigators/useTypeNavigation"
import { Touchable } from "./Button"
import { Images, deviceWidth, fitSize } from "../theme"
import { Text } from "./Text"
import { goBack } from "../navigators"

interface SheaderProps {
  title?: string
  rightImage?: string
  leftImage?: string
  rightAction?: () => void
  rightStyle?: StyleProp<ImageStyle>
  notShowLeft?: boolean
  leftAction?: () => void
  style?: StyleProp<ViewStyle>
  tintColor?: string
  rightComp?: React.ComponentType<any> | React.ReactElement | null
  textStyle?: StyleProp<TextStyle>
  isNotSafe?: boolean
  rightWrapStyle?: StyleProp<ViewStyle>
  leftStyle?: StyleProp<ImageStyle>
}

export const Sheader: React.FC<SheaderProps> = ({
  title,
  rightImage,
  rightAction,
  rightStyle,
  notShowLeft,
  leftAction,
  style,
  tintColor,
  rightComp,
  textStyle,
  leftImage,
  isNotSafe,
  rightWrapStyle,
  leftStyle,
}) => {
  const goToBack = () => {
    if (leftAction) {
      leftAction()
    } else {
      goBack()
    }
  }
  const insets = useSafeAreaInsets()
  const top = !isNotSafe ? insets.top : 0

  return (
    <View
      style={[styles.container, { height: top + fitSize(60), paddingTop: top, zIndex: 2 }, style]}
    >
      <Touchable
        onPress={notShowLeft ? () => {} : goToBack}
        style={[styles.backWrapper, rightWrapStyle]}
      >
        <Image
          source={Images.components[leftImage || "header_back"]}
          style={[styles.backImage, leftStyle]}
        />
      </Touchable>
      <Text numberOfLines={1} style={[styles.titleText, textStyle]}>
        {title}
      </Text>
      <View style={[styles.backWrapper, rightWrapStyle]}>
        {!!rightComp ? (
          <>{rightComp}</>
        ) : (
          <Touchable
            onPress={() => (rightAction ? rightAction() : null)}
            style={styles.rightCompStyle}
          >
            {rightImage === "" ? null : (
              <Image
                source={Images.common[rightImage || "header_more"]}
                style={[styles.rightImage, rightStyle]}
              />
            )}
          </Touchable>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: deviceWidth,
    height: fitSize(60),
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    overflow: "hidden",
    // backgroundColor: "blue",
  },
  backWrapper: {
    // width: fitSize(12),
    padding: fitSize(10),
    width: fitSize(60),
    flexShrink: 1,
    // backgroundColor: "blue",
  },
  backImage: {
    width: fitSize(38),
    height: fitSize(38),
    resizeMode: "contain",
  },
  rightImage: {
    width: fitSize(38),
    height: fitSize(38),
    resizeMode: "contain",
  },

  titleText: {
    color: "#373737",
    fontWeight: "bold",
    fontSize: fitSize(18),
    width: "30%",
    // minWidth: fitSize(250),
    textAlign: "center",
    flexGrow: 1,
  },
  rightCompStyle: {
    alignSelf: "flex-end",
  },
})
