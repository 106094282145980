import { View, Text, Image, StyleSheet, BackHandler } from "react-native"
import React, { FC, useEffect, useState } from "react"
import { Images, deviceHeight, deviceWidth, fitSize, gStyle } from "../theme"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import Modal from "react-native-modal"
import { Touchable, TouchableDeep } from "../components"
import { OrderDetail, PaymentTypeItem } from "../services/api"
import { SizeImage } from "../components/SizeImage"

interface PayModalProps {
  isShow: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  list: PaymentTypeItem[]
  info: OrderDetail
  paymentCallBack: (payWal: number) => void
}

export const PayModal: FC<PayModalProps> = ({
  isShow,
  closeModal,
  list,
  info,
  paymentCallBack,
}) => {
  const { bottom } = useSafeAreaInsets()
  const [choose, setChoose] = useState<{ payWay: number; type: string } | null>(null)

  useEffect(() => {
    if (list) {
      setChoose({ payWay: list?.[0]?.payWay, type: list?.[0]?.type })
    }
  }, [list])

  return (
    <Modal
      useNativeDriverForBackdrop
      hasBackdrop={true}
      coverScreen={true}
      isVisible={isShow}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      style={styles.modal}
      onBackButtonPress={() => closeModal(false)}
      onBackdropPress={() => closeModal(false)}
      swipeDirection={"down"}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      deviceHeight={deviceHeight}
    >
      <View
        style={[
          styles.container,
          {
            minHeight: fitSize(154) + bottom,
            paddingBottom: bottom,
          },
        ]}
      >
        <Touchable onPress={() => closeModal(false)} style={styles.clearBox}>
          <Image source={Images.components.modal_clear} style={styles.clearIcon} />
        </Touchable>
        <View style={styles.content}>
          <View style={[gStyle.horizontalView, { alignSelf: "center", marginTop: fitSize(48) }]}>
            <Text style={styles.payAmountUnit}>￥</Text>
            <Text style={styles.payAmount}>{info?.payAmount}</Text>
          </View>
        </View>
        <View style={[styles.warpView, { borderTopWidth: 0 }]}>
          <Text style={styles.choosePayText}>支付方式</Text>
        </View>
        {list?.map((t, i) => {
          return (
            <Touchable
              onPress={() => {
                setChoose({ payWay: t.payWay, type: t.type })
              }}
              key={i}
              style={styles.warpView}
            >
              <View style={gStyle.horizontalView}>
                {t?.icon && <SizeImage source={{ uri: t?.icon }} style={styles.commPic} />}
                <Text style={styles.title}>{t.name}</Text>
              </View>
              <SizeImage
                source={choose?.payWay === t.payWay ? Images.pay.choose : Images.pay.not_choose}
                style={styles.chooseImg}
              />
            </Touchable>
          )
        })}
        <View style={{ height: fitSize(20) }} />
        <TouchableDeep
          onPress={async () => {
            paymentCallBack(choose.payWay)
          }}
          style={[styles.buttonWrap, { marginTop: "auto", marginBottom: fitSize(4) }]}
        >
          <Text style={styles.buttonText}>确认支付</Text>
        </TouchableDeep>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  clearIcon: {
    width: fitSize(16),
    height: fitSize(16),
    tintColor: "#666",
  },
  clearBox: {
    width: fitSize(16 + 16),
    height: fitSize(16 + 16),
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 100,
  },
  container: {
    width: "100%",
    backgroundColor: "#fff",
  },
  modal: {
    margin: 0,
    justifyContent: "flex-end",
  },
  startImg: {
    width: fitSize(46),
    height: fitSize(46),
    resizeMode: "contain",
    alignSelf: "center",
    marginTop: fitSize(31),
  },
  content: {
    alignItems: "center",
  },
  payTitle: {
    color: "#4F4F4F",
    fontSize: fitSize(20),
    fontWeight: "bold",
    marginTop: fitSize(5),
  },
  payAmount: { fontSize: fitSize(40), fontWeight: "bold" },
  payAmountUnit: { fontSize: fitSize(24), color: "#333", marginTop: fitSize(5) },
  payRestTime: {
    color: "#929292",
    fontSize: fitSize(14),
  },
  restTime: {
    color: "#4F4F4F",
    fontSize: fitSize(20),
  },
  timeLine1: {
    backgroundColor: "#EDEDED",
    width: fitSize(28),
    height: fitSize(2),
    position: "absolute",
    bottom: 0,
    left: -fitSize(2),
  },
  timeLine2: {
    backgroundColor: "#EDEDED",
    width: fitSize(28),
    height: fitSize(2),
    position: "absolute",
    bottom: 0,
    right: -fitSize(2),
  },
  warpView: {
    width: deviceWidth,
    height: fitSize(54),
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#EDEDED",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(20),
  },
  choosePayText: {
    color: "#333",
    fontSize: fitSize(16),
    fontWeight: "bold",
  },
  commPic: {
    width: fitSize(24),
    height: fitSize(24),
    resizeMode: "contain",
    marginRight: fitSize(9),
  },
  title: {
    fontSize: fitSize(16),
    color: "#333",
  },
  chooseImg: {
    width: fitSize(20),
    height: fitSize(20),
    resizeMode: "contain",
  },
  buttonWrap: {
    alignSelf: "center",
    width: fitSize(343),
    height: fitSize(40),
    backgroundColor: "#FFBC1D",
    borderRadius: fitSize(6),
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonText: {
    fontSize: fitSize(16),
    fontWeight: "bold",
    color: "#333",
  },
})
