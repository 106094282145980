import {
  BackHandler,
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  useWindowDimensions,
} from "react-native"
import React, { useEffect, useState } from "react"

import { Images, colors } from "../theme"
import Modal from "react-native-modal"
import { Touchable } from "../components"
import { fitSize, deviceHeight } from "../theme/layout"
import { MultiPlatformVideoPlayer } from "../components/MultiPlatformVideoPlayer"
import * as ScreenOrientation from "expo-screen-orientation"
import { SizeImage } from "../components/SizeImage"
import { fontName } from "../theme/fonts"
import { useTypeNavigation } from "../navigators/useTypeNavigation"

interface VideoModalProps {
  isShow: boolean
  closeModal: () => void
  data: {
    src: string
    poster: string
    seek: number
    info: {
      id: number
      name: string
      cardBoxName: string
      seriesName: string
      rarityIcon: string
    }
  }
  showClose?: boolean
  onFinish?: () => void
  isPercentageWH?: boolean
  videoProps?: any
  style?: ViewStyle
  isFullScreen?: boolean
  onFullScreen?: (item: any, index?: number) => void
  isLoop?: boolean
  handlePaused?: (item: any, index?: number) => void
  isPaused?: boolean
  isVertical?: boolean
  onBack?: () => void
}

const WIDTH = Dimensions.get("window").width - (Platform.OS === "web" ? 0 : fitSize(14))
// const HEIGHT = Platform.OS === "web" ? Dimensions.get("window").height - fitSize(125) : fitSize(638)
const HEIGHT = fitSize(638)

export const VideoModal: React.FC<VideoModalProps> = ({
  closeModal,
  data,
  isShow,
  showClose = true,
  onFinish,
  style,
  isLoop = false,
  isVertical = false,
  isPercentageWH,
  onBack,
}) => {
  const windowHeight = useWindowDimensions().height
  const [muted, setMuted] = useState(true)
  const navigation = useTypeNavigation()

  useEffect(() => {
    const unsubscribe = navigation.addListener("state", () => {
      if (isShow) onBack()
    })
    return unsubscribe
  }, [navigation])

  const closeUI = () => {
    closeModal()
  }

  const compatibleHeight = Platform.OS === "web" ? windowHeight : deviceHeight

  const changeScreenCrossOrientation = async () => {
    try {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT)
    } catch (error) {
      console.log("error cross", error)
    }
  }

  const changeScreenVerticalOrientation = async () => {
    try {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
    } catch (error) {
      console.log("error vertical", error)
    }
  }
  useEffect(() => {
    if (isVertical) {
      try {
        if (isShow) {
          changeScreenCrossOrientation()
        }
      } catch (error) {
        console.log("error", error)
      }
    }
  }, [isShow, isVertical])

  useEffect(() => {
    if (!isShow) {
      changeScreenVerticalOrientation()
    }
  }, [!isShow])

  useEffect(() => {
    const backAction = () => {
      if (!isShow) {
        return false
      } else {
        return true
      }
    }
    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction)
    return () => backHandler?.remove()
  }, [isShow])

  return (
    <Modal
      useNativeDriverForBackdrop
      hasBackdrop={true}
      coverScreen={false}
      isVisible={isShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={[{ margin: 0, backgroundColor: colors.palette.neutral900 }, style]}
      onBackButtonPress={showClose ? closeUI : () => {}}
      onBackdropPress={showClose ? closeUI : () => {}}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      statusBarTranslucent
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
    >
      <Touchable
        style={[styles.backBox, { display: data.info ? "flex" : "none" }]}
        onPress={closeUI}
      >
        <Image source={Images.components.header_back} style={styles.backIcon} />
      </Touchable>
      <View>
        {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
          <MultiPlatformVideoPlayer
            uri={data.src}
            isPaused={false}
            muted={data.info ? muted : false}
            h={isPercentageWH ? Dimensions.get("window").height : HEIGHT}
            w={isPercentageWH ? Dimensions.get("window").width : WIDTH}
            isLook={false}
            loop={isLoop}
            resizeMode="contain"
            onFinish={() => {
              if (!!onFinish) {
                onFinish()
                closeUI()
              }
            }}
          />
        ) : null}
      </View>
      <View
        style={{
          position: "absolute",
          right: 18,
          top: HEIGHT - fitSize(100),
          flexDirection: "column",
          display: data.info ? "flex" : "none",
        }}
      >
        <Touchable
          style={styles.btnBox}
          onPress={() => {
            setMuted(!muted)
          }}
        >
          <Image
            source={Images.screen.store[muted ? "default_mute" : "not_mute"]}
            style={styles.bestView}
          />
        </Touchable>
        {/* <Touchable
          style={styles.btnBox}
          onPress={() => navigation.navigate("AssetDetails", { id: data?.info?.id })}
        >
          <Image source={Images.screen.store.video_info} style={styles.bestView} />
        </Touchable> */}
        {/* <Touchable style={style.btnBox}>
              <Image source={Images.screen.store.vide_share} style={style.bestView} />
            </Touchable> */}
      </View>
      <SizeImage
        source={Images.screen.store.video_bar}
        style={[styles.bar, { display: data.info ? "flex" : "none" }]}
      >
        <Text style={styles.name}>{data?.info?.name}</Text>
      </SizeImage>
      <View style={[styles.infoBox, { display: data.info ? "flex" : "none" }]}>
        <Text style={styles.infoContent}>{data?.info?.seriesName}</Text>
        <View style={styles.infoRow}>
          <Image source={{ uri: data?.info?.rarityIcon }} style={styles.icon} />
          <Text style={styles.action}>{data?.info?.cardBoxName}</Text>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  btnBox: {
    width: fitSize(44),
    height: fitSize(44),
    marginTop: fitSize(24),
  },
  barIcon: {
    width: fitSize(359),
    height: fitSize(60),
    position: "absolute",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingHorizontal: fitSize(14),
  },
  video: {},
  bestView: {
    width: "100%",
    height: "100%",
  },
  backBox: {
    position: "absolute",
    top: Platform.select({
      ios: fitSize(77),
      android: fitSize(77),
      web: fitSize(10),
    }),
    left: fitSize(12),
    zIndex: 999,
  },
  backIcon: {
    width: fitSize(20),
    height: fitSize(20),
  },
  bar: {
    position: "absolute",
    width: WIDTH,
    height: fitSize(55),
    left: Platform.OS === "web" ? fitSize(0) : fitSize(7),
    bottom: Platform.OS === "web" ? fitSize(18) : fitSize(85),
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontSize: fitSize(18),
    marginLeft: fitSize(6),
    fontFamily: fontName.PingFang_SC,
  },
  infoBox: {
    position: "absolute",
    width: WIDTH,
    left: Platform.OS === "web" ? fitSize(0) : fitSize(7),
    bottom: Platform.OS === "web" ? fitSize(10) : fitSize(70),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoContent: {
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontSize: fitSize(13),
    marginLeft: fitSize(6),
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  icon: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  action: {
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontSize: fitSize(13),
    marginLeft: fitSize(6),
    fontFamily: fontName.PingFang_SC,
  },
  infoRow: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: fitSize(6),
  },
})
