import { Dimensions, StatusBar, StyleSheet, View } from "react-native"
import React, { useEffect, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { colors, fitSize } from "../../theme"
import { Screen, Sheader } from "../../components"
import { HookFlatList } from "../../components/list/HookFlatList"
import { useAutoList } from "../../hooks/useAutoList"
import { api, resourceItem } from "../../services/api"
import { fontName } from "../../theme/fonts"
import { TimeItem } from "./ActivityNew"
import { useTokenAuthorization } from "../../hooks/useTokenAuthorization"
import { GToast } from "../../navigators"
import { FlashList } from "@shopify/flash-list"

interface OpenTimeListProps extends RootStackNavProps<"OpenTimeList"> {}

const PL = 50
export const OpenTimeList: React.FC<OpenTimeListProps> = observer(({ navigation, route }) => {
  const [data, setData] = useState<resourceItem[]>([])
  const fetchTimeList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getActivityBoxTimeList(route.params.id, lastId, size)
      if (res.kind === "ok") {
        setData(res.data.list)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  const { authorization } = useTokenAuthorization()

  useEffect(() => {
    fetchTimeList()
  }, [])

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        rightImage={""}
        title={"可获得时刻"}
        isNotSafe
        textStyle={{ color: colors.palette.neutral100 }}
        leftStyle={{
          tintColor: colors.palette.neutral100,
          width: fitSize(20),
          height: fitSize(20),
        }}
        style={{ height: fitSize(44) }}
      />
      <View style={style.body}>
        <HookFlatList
          isTransverseLoad={true}
          data={data}
          isShow={data.length > 0}
          numColumns={3}
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => <TimeItem item={item as resourceItem} />}
          estimatedItemSize={fitSize(102)}
          // estimatedListSize={{ height: fitSize(139), width: fitSize(102) }}
        />
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  content: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },
  body: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    // alignItems: "center",
  },
  actionName: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(12),
    fontWeight: "400",
    color: "#666666",
    alignSelf: "center",
    marginTop: fitSize(5),
  },
  timeItemName: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral100,
  },
  rarityIcon: {
    minWidth: fitSize(14),
    maxWidth: fitSize(24),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  timeItemInfo: {
    height: fitSize(14),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  timeItemContent: {
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  },
  timeItemIcon: {
    width: fitSize(102),
    height: fitSize(102),
    alignItems: "center",
    justifyContent: "center",
  },
  timeItemContainer: {
    width: fitSize(102),
    height: fitSize(139),
    flexDirection: "column",
    marginBottom: fitSize(24),
    marginRight: fitSize(14),
  },
  videoPlayIcon: {
    height: fitSize(20),
    width: fitSize(20),
  },
})
