import { Image, StatusBar, StyleSheet, Text, View } from "react-native"
import React, { useEffect, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { Images, colors, fitSize } from "../../theme"
import { Screen, Sheader, Touchable } from "../../components"
import { showError } from "../../utils/showUtil"
import { OrderDetail, api } from "../../services/api"
import { useTokenAuthorization } from "../../hooks/useTokenAuthorization"
import { fontName } from "../../theme/fonts"
import { GToast } from "../../navigators"

interface PaymentSuccessProps extends RootStackNavProps<"PaymentSuccess"> {}

export const PaymentSuccess: React.FC<PaymentSuccessProps> = observer(({ navigation, route }) => {
  const [orderData, setOrderData] = useState<OrderDetail | null>(null)
  const { authorization } = useTokenAuthorization()
  const init = async () => {
    try {
      const res = await api.getOrderDetail(route.params.id)
      if (res.kind === "ok") {
        setOrderData(res.data)
        if (res.data.status === 5) {
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={"订单详情"}
        isNotSafe
        textStyle={{ color: colors.palette.neutral100 }}
        leftStyle={style.headerLeftIcon}
        style={style.header}
      />
      <View style={style.content}>
        <Image source={Images.screen.activity.payment_success} style={style.icon} />
        <Text style={style.info}>支付成功</Text>
        <Touchable
          style={style.btn}
          onPress={() => navigation.navigate("ShowOrder", { id: orderData?.outTradeNo })}
        >
          <Text style={style.btnText}>查看订单</Text>
        </Touchable>
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  btnText: {
    color: colors.palette.neutral100,
    fontSize: fitSize(13),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  btn: {
    width: fitSize(86),
    height: fitSize(36),
    borderRadius: fitSize(4),
    backgroundColor: "transparency",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    borderWidth: 1,
    borderColor: "#666666",
    marginTop: fitSize(60),
  },
  info: {
    color: colors.palette.neutral100,
    fontSize: fitSize(16),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(10),
  },
  icon: {
    width: fitSize(160),
    height: fitSize(160),
  },
  content: {
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  header: {
    height: fitSize(44),
  },
  headerLeftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
})
