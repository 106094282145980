import React, { FC } from "react"
import { fitSize } from "../../../theme"
import { bannerColumnItem } from "../../../services/api"
import { UniversalPlaybackContainer } from "./UniversalPlaybackContainer"

interface PrizeCenterProps {
  data: bannerColumnItem[]
}

export const PrizeCenter: FC<PrizeCenterProps> = ({ data }) => {
  return <UniversalPlaybackContainer data={data} h={fitSize(178)} w={fitSize(315)} />
}
