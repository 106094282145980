import { Dimensions, Image, StyleSheet, Text, View } from "react-native"
import React, { useEffect, useState } from "react"
import { Touchable } from "../../../components"
import { translate } from "../../../i18n"
import { Images, colors, fitSize } from "../../../theme"
import { fontName } from "../../../theme/fonts"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { UserAssetItem, api } from "../../../services/api"
import { GToast } from "../../../navigators"
import { useAutoList } from "../../../hooks/useAutoList"
import { FlashList } from "@shopify/flash-list"
import { AssetItem, UserAssetItemObj } from "./AssetItem"

export type typeState = UserAssetItemObj

interface AssetListContentProps {
  clearModal: (value?: typeState) => void
  scope: {
    resourceId: number
    rarityId: number
    seriesId: number
    assetNumber: string
    compositeFuelId: number
  }
  isOpen: boolean
  filtrationData: number[]
}

type DataList = UserAssetItemObj

const PL = 20
export const AssetListContent = (props: AssetListContentProps) => {
  const { clearModal, scope, isOpen, filtrationData } = props
  const { top } = useSafeAreaInsets()
  const { bottom } = useSafeAreaInsets()
  const [data, setData] = useState<DataList[]>()

  const fetchGetUserAssetList = async (lastId?: number, size = PL) => {
    if (isOpen) {
      try {
        const res = await api.getUserCompAssetList(
          String(scope.compositeFuelId),
          lastId,
          size,
          scope.seriesId !== 0 ? String(scope.seriesId) : undefined,
          scope.rarityId !== 0 ? String(scope.rarityId) : undefined,
          scope.resourceId !== 0 ? String(scope.resourceId) : undefined,
        )
        if (res.kind === "ok") {
          return res.data
        } else {
          GToast.current.handleErrorResponse(res)
        }
      } catch (error) {
        console.log("error", error)
      }
    }
  }

  const [fetchList] = useAutoList(
    {
      fetchData: fetchGetUserAssetList,
      pageLimit: PL,
      isDestroy: isOpen === false,
    },
    [isOpen],
  )

  const effectData = () => {
    let arr = []
    const newArr = fetchList?.data as unknown as UserAssetItem[]
    arr = newArr.map((item) => {
      return {
        ...item,
        isSelect: scope.resourceId === item.resourceId && scope.assetNumber === item.assetNumber,
      }
    })
    setData(arr)
  }

  useEffect(() => {
    effectData()
  }, [fetchList.data])

  const setCard = (id: number) => {
    const arr = data.map((item) => {
      return { ...item, isSelect: item.id === id }
    })
    setData(arr)
  }

  const onSubmit = () => {
    const arr = data.filter((item) => {
      return item.isSelect
    })
    clearModal(arr[0])
  }

  function filterObjectsById(array1, idArray) {
    const idSet = new Set(idArray)

    const filteredArray = array1?.filter((obj) => !idSet.has(obj.id))

    return filteredArray
  }
  return (
    <View style={[style.container, { paddingTop: top }]}>
      <View style={style.header}>
        <Text style={style.headerTitle}>
          {translate("screen.challengeDetails.assetDrawerTitle")}
        </Text>
        <Touchable onPress={() => clearModal()}>
          <Image source={Images.components.modal_clear} style={style.clearIcon} />
        </Touchable>
      </View>
      <View style={style.list}>
        <FlashList
          {...fetchList}
          data={filterObjectsById(data, filtrationData)}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: fitSize(150),
          }}
          refreshing={false}
          estimatedItemSize={fitSize(159)}
          renderItem={({ item }) => {
            return <AssetItem item={item as UserAssetItemObj} setCard={setCard} />
          }}
          estimatedListSize={{ height: fitSize(159), width: fitSize(306) }}
        />
      </View>
      <View style={style.submitBox}>
        <Touchable onPress={onSubmit} style={style.submit}>
          <Text style={style.submitText}>{translate("screen.challengeDetails.submit")}</Text>
        </Touchable>
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  submitBox: {
    width: "100%",
    height: fitSize(100),
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
  },
  submitText: {
    fontSize: fitSize(14),
    color: colors.palette.neutral30,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  submit: {
    width: fitSize(268),
    height: fitSize(40),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.palette.neutral100,
    borderRadius: fitSize(4),
    alignSelf: "center",
  },
  container: {
    flex: 1,
    width: "100%",
    height: Dimensions.get("window").height,
  },
  header: {
    width: "100%",
    height: fitSize(44),
    paddingHorizontal: fitSize(14),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: fitSize(15),
  },
  headerTitle: {
    fontSize: fitSize(15),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  clearIcon: {
    width: fitSize(14),
    height: fitSize(13),
  },
  list: {
    flexGrow: 1,
    width: "100%",
    flexDirection: "column",
    paddingLeft: fitSize(14),
    marginBottom: fitSize(20),
    height: Dimensions.get("window").height,
  },
})
