export enum fontName {
  Poppins_ExtraBoldItalic_zitidi = "Poppins_ExtraBoldItalic_zitidi",
  YouSheBiaoTiHei_2 = "YouSheBiaoTiHei_2",
  PingFang_SC_Semibold = "PingFang_SC_Semibold",
  PingFang_SC = "PingFang_SC",
}

export const fontPath = {
  Poppins_ExtraBoldItalic_zitidi: require("../../assets/fonts/Poppins-ExtraBoldItalic_zitidi.com.ttf"), // number
  YouSheBiaoTiHei_2: require("../../assets/fonts/YouSheBiaoTiHei-2.ttf"), // 顶部斜文字
  PingFang_SC_Semibold: require("../../assets/fonts/PingFang-SC-Semibold.ttf"), // 加粗
  PingFang_SC: require("../../assets/fonts/PingFang-SC.ttf"), // 普通
}
