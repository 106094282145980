import { Image, ScrollView, StyleSheet, Text, View, Dimensions } from "react-native"
import React, { FC, useEffect, useReducer, useState } from "react"
import { fitSize } from "../../../theme/layout"
import { fontName } from "../../../theme/fonts"
import { Images, colors } from "../../../theme"
import { Touchable } from "../../../components/Button"
import { translate } from "../../../i18n/translate"
import { api } from "../../../services/api"
import { RarityItem, SeriesItem } from "../../../services/api/api.types"
import { useTokenAuthorization } from "../../../hooks/useTokenAuthorization"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { GToast } from "../../../navigators"

export type typeState = {
  series: number
  cardBox: number
  rarity: number
}

interface FuzzySearchContentProps {
  clearModal: (value: typeState) => void
}

const DEFAULT_SERIES = {
  id: 0,
  name: "全部",
  isDisplay: 1,
  disabled: 1,
  image: "",
  description: "",
  createdAt: "",
}

const DEFAULT_CARD = {
  id: 0,
  seriesId: 0,
  cardBoxName: "全部",
  seriesName: "",
  cardBoxId: 0,
  cardBoxImage: "",
  cardBoxDesc: "",
  cardBoxRarityImage: "",
  disabled: 1,
  icon: "",
  image: "",
  rarityName: "全部",
  frameHImage: "",
  frameVImage: "",
  backgroundImage: "",
  description: "",
  createdAt: "",
  updatedAt: "",
  rarityId: 0,
}

const initialUserAssetTotal = {
  series: 0,
  cardBox: 0,
  rarity: 0,
}

type typeAction = {
  type: "SERIES" | "CARD_BOX" | "RARITY" | "RESET"
  value?: number
}
const filterReducer = (state: typeState, action: typeAction) => {
  const { type, value } = action
  switch (type) {
    case "SERIES":
      return { ...state, series: value }
    case "CARD_BOX":
      return { ...state, cardBox: value }
    case "RARITY":
      return { ...state, rarity: value }
    case "RESET":
      return initialUserAssetTotal // 将状态重置为初始值
    default:
      throw new Error()
  }
}

export const FuzzySearchContent: FC<FuzzySearchContentProps> = ({ clearModal }) => {
  const [allSeries, setAllSeries] = useState<SeriesItem[]>([])
  const [allCardBox, setAllCardBox] = useState<RarityItem[]>([])
  const [allRarity, setAllRarity] = useState<RarityItem[]>([])
  const { authorization } = useTokenAuthorization()
  const [filter, setFilter] = useReducer(filterReducer, initialUserAssetTotal)
  const { bottom, top } = useSafeAreaInsets()

  const fetchAllSeries = async () => {
    try {
      let allData: SeriesItem[] = []
      let res = await api.getAllSeries()
      do {
        if (res.kind === "ok") {
          allData.push(...res.data.list)
          const lastId = res.data.lastId

          if (res.data.next) {
            res = await api.getAllSeries(lastId)
          }
        } else {
          GToast.current.handleErrorResponse(res)
        }
      } while (res.kind === "ok" && res.data.next)
      allData.unshift(DEFAULT_SERIES)
      setAllSeries((asset) => [...asset, ...allData])
    } catch (error) {
      console.log("error", error)
    }
  }

  const fetchAllCardBox = async (seriesId?: number, cardBox?: number) => {
    try {
      let allData: RarityItem[] = []
      let res = await api.getAllCardBoxRarity(undefined, undefined, undefined, seriesId, cardBox)
      do {
        if (res.kind === "ok") {
          allData.push(...res.data.list)
          const lastId = res.data.lastId
          if (res.data.next) {
            res = await api.getAllCardBoxRarity(lastId)
          }
        } else {
          GToast.current.handleErrorResponse(res)
        }
      } while (res.kind === "ok" && res.data.next)
      allData.unshift(DEFAULT_CARD)
      if (!cardBox) {
        const uniqueDataArray = allData.reduce((acc, current) => {
          const existingObject = acc.find((item) => item.cardBoxId === current.cardBoxId)
          if (!existingObject) {
            acc.push(current)
          }
          return acc
        }, [])
        setAllCardBox(uniqueDataArray)
      }
      const uniqueRarityDataArray = allData.reduce((acc, current) => {
        const existingObject = acc.find((item) => item.rarityId === current.rarityId)
        if (!existingObject) {
          acc.push(current)
        }
        return acc
      }, [])
      console.log(
        "uniqueRarityDataArray",
        allData.map((i) => i.rarityId),
      )
      setAllRarity(uniqueRarityDataArray)
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    fetchAllSeries()
    fetchAllCardBox()
  }, [])

  const onSubmit = () => {
    clearModal(filter)
  }

  return (
    <View style={style.container}>
      <View style={style.header}>
        <Text style={style.headerTitle}>{translate("screen.tabStore.filtrate")}</Text>
        <Touchable onPress={onSubmit}>
          <Image source={Images.components.modal_clear} style={style.clearIcon} />
        </Touchable>
      </View>
      <ScrollView style={style.content}>
        <View style={style.cardBox}>
          <Text style={style.cardTitle}>{translate("screen.tabStore.seriesTitle")}</Text>
          <View style={style.wap}>
            {allSeries?.map((item, index) => {
              return (
                <Touchable
                  style={[
                    style.box,
                    { backgroundColor: filter.series === item?.id ? "#6FEBE8" : "transparent" },
                  ]}
                  key={index}
                  onPress={() => {
                    // setAllCardBox((arr) => arr.splice(1))
                    setFilter({ type: "SERIES", value: item.id })
                    if (item?.id === 0) {
                      fetchAllCardBox()
                      setFilter({ type: "CARD_BOX", value: 0 })
                      setFilter({ type: "RARITY", value: 0 })
                    } else {
                      fetchAllCardBox(item.id)
                    }
                  }}
                  disabled={filter.series === item?.id}
                >
                  <Text
                    style={[
                      style.name,
                      {
                        color:
                          filter.series === item?.id
                            ? colors.palette.neutral30
                            : colors.palette.neutral50,
                      },
                    ]}
                    numberOfLines={1}
                  >
                    {item?.name}
                  </Text>
                </Touchable>
              )
            })}
          </View>
        </View>
        <View style={style.cardBox}>
          <Text style={style.cardTitle}>{translate("screen.tabStore.cardBagTitle")}</Text>
          <View style={style.wap}>
            {allCardBox?.map((item, index) => {
              return (
                <Touchable
                  style={[
                    style.box,
                    {
                      backgroundColor:
                        filter.cardBox === item?.cardBoxId ? "#6FEBE8" : "transparent",
                    },
                  ]}
                  key={index}
                  onPress={() => {
                    setFilter({ type: "CARD_BOX", value: item.cardBoxId })
                    if (item?.id === 0) {
                      fetchAllCardBox()
                      setFilter({ type: "RARITY", value: 0 })
                    } else {
                      fetchAllCardBox(filter.series, item.cardBoxId)
                    }
                  }}
                  disabled={filter.cardBox === item?.cardBoxId}
                >
                  <Text
                    style={[
                      style.name,
                      {
                        color:
                          filter.cardBox === item?.cardBoxId
                            ? colors.palette.neutral30
                            : colors.palette.neutral50,
                      },
                    ]}
                    numberOfLines={1}
                  >
                    {item?.cardBoxName}
                  </Text>
                </Touchable>
              )
            })}
          </View>
        </View>
        <View style={style.cardBox}>
          <Text style={style.cardTitle}>{translate("screen.tabStore.rarityTitle")}</Text>
          <View style={style.wap}>
            {allRarity?.map((item, index) => {
              return (
                <Touchable
                  style={[
                    style.box,
                    {
                      backgroundColor: filter.rarity === item?.rarityId ? "#6FEBE8" : "transparent",
                    },
                  ]}
                  key={index}
                  onPress={() => {
                    setFilter({ type: "RARITY", value: item.rarityId })
                  }}
                  disabled={filter.rarity === item?.rarityId}
                >
                  <Text
                    style={[
                      style.name,
                      {
                        color:
                          filter.rarity === item?.rarityId
                            ? colors.palette.neutral30
                            : colors.palette.neutral50,
                      },
                    ]}
                    numberOfLines={1}
                  >
                    {item?.rarityName}
                  </Text>
                </Touchable>
              )
            })}
          </View>
        </View>
        {/* <View style={style.cardBox}>
          <Text style={style.cardTitle}>位置</Text>
          <View style={style.wap}>
          </View>
        </View> */}
      </ScrollView>
      <View style={[style.bottomBar, { marginBottom: bottom }]}>
        <Touchable
          style={style.clearBtn}
          onPress={() => {
            setFilter({ type: "RESET" })
          }}
        >
          <Text style={style.clearText}>{translate("screen.tabStore.resetBtn")}</Text>
        </Touchable>
        <Touchable style={style.submitBtn} onPress={onSubmit}>
          <Text style={style.submitText}>{translate("screen.tabStore.saveBtn")}</Text>
        </Touchable>
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: Dimensions.get("window").height,
  },
  header: {
    width: "100%",
    height: fitSize(44),
    paddingHorizontal: fitSize(14),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: fitSize(15),
  },
  headerTitle: {
    fontSize: fitSize(15),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  clearIcon: {
    width: fitSize(14),
    height: fitSize(13),
  },
  content: {
    flexGrow: 1,
    width: "100%",
    flexDirection: "column",
    paddingLeft: fitSize(14),
    // height: fitSize(712),
    marginBottom: fitSize(20),
  },
  cardBox: {
    width: "100%",
    flexDirection: "column",
    overflow: "hidden",
    marginBottom: fitSize(24),
  },
  cardTitle: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginBottom: fitSize(16),
  },
  box: {
    width: fitSize(94),
    height: fitSize(29),
    borderRadius: fitSize(4),
    borderColor: "#282A29",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    marginRight: fitSize(10),
    marginBottom: fitSize(13),
  },
  name: {
    fontSize: fitSize(12),
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  wap: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    alignItems: "center",
  },
  bottomBar: {
    width: "100%",
    height: fitSize(40),
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    backgroundColor: colors.palette.neutral900,
  },
  clearBtn: {
    width: "50%",
    height: fitSize(40),
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#333333",
  },
  clearText: {
    fontSize: fitSize(15),
    color: "#333333",
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  submitBtn: {
    width: "50%",
    height: fitSize(40),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.palette.primary500,
  },
  submitText: {
    fontSize: fitSize(15),
    color: colors.palette.neutral900,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
})
