import { Platform, ScrollView, StyleSheet, View, useWindowDimensions } from "react-native"
import { colors, fitSize } from "../../theme"
import React, { useState } from "react"
import { AppInfo } from "../../config/AppInfo"
import RenderHtml from "react-native-render-html"
import { RootStackNavProps } from "../../type/RouteType"
import { Screen, Sheader } from "../../components"
import { Text } from "../../components"
import TextModal from "../../components/TextModal"
import { translate } from "../../i18n"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { fontName } from "../../theme/fonts"
import { api } from "../../services/api"
import { GToast } from "../../navigators"
import { InAppPurchases } from "../../utils/CompatibleUtils"

interface PasswordManageProps extends RootStackNavProps<"About"> {}

export enum textModal {
  agreement = "agreement",
  privacy = "privacy",
  service = "service",
}

export const About: React.FC<PasswordManageProps> = ({ navigation }) => {
  const { bottom } = useSafeAreaInsets()
  const { accountStore } = useStores()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>("")
  const [modalContent, setModalContent] = useState<string>("")
  const { width } = useWindowDimensions()

  const openTextModal = (type: textModal): void => {
    setModalVisible(true)
    if (type === textModal.agreement) {
      getAgreementAPI(0)
      setModalTitle("用户协议")
    } else if (type === textModal.privacy) {
      getAgreementAPI(1)
      setModalTitle("隐私政策")
    } else if (type === textModal.service) {
      getAgreementAPI(2)
      setModalTitle("中国移动认证服务条款")
    }
  }

  const getAgreementAPI = async (type: 0 | 1 | 2) => {
    const res = await api.getPrivacyAgreement(type)
    if (res.kind === "ok") {
      if (res.data) {
        setModalContent(res?.data?.content)
      }
    } else {
      setModalContent("")
      GToast.current.handleErrorResponse(res)
    }
  }

  const onTest = async () => {
    navigation.navigate("Welcome")
  }

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={translate("setting.aboutjiji")}
        textStyle={style.textStyle}
        leftStyle={style.leftIcon}
        style={style.header}
        isNotSafe
      />
      <View style={style.content}>
        <Text style={style.name}>{AppInfo.name}</Text>
        <Text style={style.version}>
          {translate("screen.about.version", { version: AppInfo.version })}
        </Text>
        <View style={[style.bottom, { bottom: bottom + fitSize(39) }]}>
          <Text style={style.bar}>
            <Text
              onPress={() => {
                openTextModal(textModal.agreement)
              }}
              style={style.textContent}
            >
              {translate("screen.about.userAgreement")}
            </Text>
            <Text style={style.vertical}>|</Text>
            <Text
              onPress={() => {
                openTextModal(textModal.privacy)
              }}
              style={style.textContent}
            >
              {translate("screen.about.privacyPolicy")}
            </Text>
          </Text>
        </View>
      </View>
      <TextModal modalVisible={modalVisible} setModalVisible={setModalVisible} title={modalTitle}>
        <ScrollView
          style={style.modalScrollView}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {Platform.OS === "web" ? (
            <div style={style.modalText} dangerouslySetInnerHTML={{ __html: modalContent }} />
          ) : (
            <RenderHtml
              contentWidth={width}
              source={{
                html: modalContent,
              }}
            />
          )}
        </ScrollView>
      </TextModal>
    </Screen>
  )
}

const style = StyleSheet.create({
  bar: {
    color: colors.palette.primary900,
    fontSize: fitSize(12),
  },
  vertical: {
    marginHorizontal: fitSize(4),
    color: colors.palette.primary900,
  },
  textContent: {
    color: colors.palette.primary900,
    fontSize: fitSize(12),
  },
  bottom: {
    position: "absolute",
    alignSelf: "center",
  },
  version: {
    color: colors.palette.neutral100,
    fontSize: fitSize(16),
    alignSelf: "center",
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(3),
  },
  name: {
    fontSize: fitSize(20),
    fontWeight: "bold",
    color: colors.palette.neutral100,
    marginTop: fitSize(200),
    alignSelf: "center",
    fontFamily: fontName.PingFang_SC,
  },
  content: {
    backgroundColor: "transparent",
    flex: 1,
  },
  modalScrollView: {
    flex: 1,
    padding: fitSize(5),
  },
  modalText: {
    width: "100%",
    flexWrap: "wrap",
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  textStyle: {
    fontSize: fitSize(18),
    fontWeight: "500",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  leftIcon: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    backgroundColor: colors.background,
    height: fitSize(44),
  },
})
