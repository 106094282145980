import { View, StyleSheet, Text, Platform, Image, ScrollView } from "react-native"
import React, { useEffect, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { observer } from "mobx-react-lite"
import { Images, colors, fitSize } from "../../theme"
import { Screen, Sheader, TouchableDeep } from "../../components"
import { OpenCardBoxDetail, api } from "../../services/api"
import { hideLoad, showLoad } from "../../utils/showUtil"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { GToast, goBack } from "../../navigators"
import { SizeImage } from "../../components/SizeImage"
import { fontName } from "../../theme/fonts"
import { useTokenAuthorization } from "../../hooks/useTokenAuthorization"

interface OpenCardBagProps extends RootStackNavProps<"OpenCardBag"> {}

export const OpenCardBag: React.FC<OpenCardBagProps> = observer(({ navigation, route }) => {
  const [activityData, setActivityData] = useState<OpenCardBoxDetail | null>(null)
  const { bottom, top } = useSafeAreaInsets()
  const { authorization } = useTokenAuthorization()
  const [cyclicVerify, setCyclicVerify] = useState(false)

  const { accountStore } = useStores()
  const { id } = route.params

  const init = async () => {
    try {
      const res = await api.getOpenCardBoxDetail(id)
      if (res.kind === "ok") {
        if (Platform.OS === "web") {
          window.document.title = res.data.name
        }
        setActivityData(res.data)
        if (res.data.isRead === 0) read()
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const read = async () => {
    try {
      const res = await api.readCurrentCardBag([route.params.id])
      if (res.kind === "ok") {
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const onCyclicVerify = async () => {
    try {
      const res = await api.getAssetBoxCallback(activityData.openId)
      if (res.kind === "ok") {
        if (res.data.isOpen) {
          hideLoad()
          setCyclicVerify(false)
          GToast.current.showOptionalVideoPlay(true, res.data.list)
          init()
          revivalBack()
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    let interval
    if (cyclicVerify) {
      interval = setInterval(() => {
        onCyclicVerify()
      }, 1000)
    } else {
      clearInterval(interval)
    }
    return () => {
      clearInterval(interval)
    }
  }, [cyclicVerify])

  const fetchOpenBox = async () => {
    try {
      const res = await api.openBox(activityData.openId)
      if (res.kind === "ok") {
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const neutralizeBack = () => {
    window.history.pushState(null, "", window.location.href)
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href)
      // callback()
    }
  }

  const revivalBack = () => {
    window.onpopstate = undefined
    window.history.back()
  }

  return (
    <Screen preset="scroll" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        rightImage={""}
        title={""}
        isNotSafe
        textStyle={{ color: colors.palette.neutral100 }}
        leftStyle={{
          tintColor: colors.palette.neutral100,
          width: fitSize(20),
          height: fitSize(20),
        }}
        style={{ height: fitSize(44) }}
        leftAction={() => {
          if (accountStore.beforeLogin.url) {
            navigation.navigate("Root", { screen: "TabOne" })
          } else {
            goBack()
          }
        }}
      />
      <SizeImage
        source={{ uri: activityData?.imageMain }}
        style={style.imageBox}
        resizeMode={"cover"}
      />
      <View style={style.textContainer}>
        <View style={style.textInfoContainer}>
          <View style={style.textInfoContent}>
            <Text style={style.nameText}>{activityData?.name ?? ""}</Text>
            <Text
              style={[
                style.saleAmuntText,
                { display: activityData?.canSaleIs === 0 ? "none" : "flex" },
              ]}
            >
              <Text style={style.unitIcon}>{activityData && "¥"}</Text>
              {activityData?.saleAmount ?? ""}
            </Text>
          </View>
          <Text style={style.description}>{activityData?.description}</Text>
          <View style={style.row}>
            <Image source={Images.components.warning} style={style.warningIcon} />
            <Text style={style.warningText}>
              本平台售出的数字藏品均为虚拟数字商品，而非实物，因区块链技术特性，平台售出的数字藏品不支持退换。
            </Text>
          </View>
        </View>
      </View>
      <View style={[style.bottomContainer, { minHeight: fitSize(30) + bottom }]}>
        <TouchableDeep
          onPress={() => {
            if (accountStore.token) {
              if (Platform.OS === "web") {
                window.addEventListener("keydown", function (e) {
                  if (e.key === "Backspace") {
                    e.preventDefault() // 阻止默认行为
                  }
                })
                neutralizeBack()
              }
              GToast.current.showVideo(
                true,
                {
                  url: activityData.videoUnpack,
                  poster: "",
                  seek: 0,
                },
                {},
                () => {},
                false,
                () => {
                  showLoad("上链中...")
                  setCyclicVerify(true)
                },
                true,
              )
              fetchOpenBox()
            } else {
              GToast.current.doLogin("ActivityNew", route.params)
            }
          }}
          disabled={activityData?.totalNum <= 0}
          style={style.saleStatusNameBtn}
        >
          <Text style={style.saleStatusNameText}>开启</Text>
        </TouchableDeep>
        <View style={style.rowCenter}>
          <Image source={Images.components.card_bag} style={style.cardBagIcon} />
          <Text style={style.cardBagNumber}>剩余：{activityData?.totalNum ?? 0}包</Text>
        </View>
      </View>
    </Screen>
  )
})

const style = StyleSheet.create({
  cardBagNumber: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(12),
    fontWeight: "400",
    color: "#999999",
  },
  cardBagIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(5),
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  row: {
    flexDirection: "row",
  },
  warningText: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(10),
    fontWeight: "400",
    color: "#666666",
  },
  warningIcon: {
    width: fitSize(12),
    height: fitSize(12),
    marginRight: fitSize(3),
    marginTop: fitSize(3),
  },
  description: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(14),
    fontWeight: "400",
    color: colors.palette.neutral100,
    marginTop: fitSize(15),
    marginBottom: fitSize(10),
  },
  unitIcon: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(13),
    fontWeight: "600",
    color: colors.palette.neutral100,
    alignSelf: "flex-end",
    marginRight: fitSize(1),
  },
  saleAmuntText: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(20),
    fontWeight: "600",
    color: colors.palette.neutral100,
  },
  nameText: {
    fontFamily: fontName.PingFang_SC,
    fontSize: fitSize(18),
    fontWeight: "600",
    color: colors.palette.neutral100,
  },
  textInfoContent: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textInfoContainer: {
    width: "100%",
    flexDirection: "column",
    paddingHorizontal: fitSize(14),
    paddingTop: fitSize(12),
    minHight: fitSize(140),
  },
  textContainer: {
    flexDirection: "column",
    width: "100%",
    marginTop: fitSize(40),
    minHeight: fitSize(130),
  },
  backImage: {
    width: fitSize(20),
    height: fitSize(20),
  },
  backContent: {
    width: fitSize(40),
    height: fitSize(40),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00000022",
    borderRadius: fitSize(50),
  },
  backBox: {
    width: "100%",
    position: "absolute",
    top: 20,
    left: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: fitSize(252),
    height: fitSize(192),
  },
  imageBox: {
    width: "100%",
    height: fitSize(413),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.palette.neutral900,
    overflow: "hidden",
  },
  scrollView: {
    paddingBottom: fitSize(80),
    backgroundColor: colors.palette.neutral900,
  },
  bottomContainer: {
    width: "100%",
    backgroundColor: colors.palette.neutral900,
    paddingBottom: fitSize(56),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  content: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
  saleStatusNameText: {
    color: "#333",
    fontSize: fitSize(14),
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  saleStatusNameBtn: {
    width: fitSize(196),
    height: fitSize(36),
    borderRadius: fitSize(6),
    backgroundColor: colors.palette.neutral100,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: fitSize(20),
    marginBottom: fitSize(15),
  },
})
