export const privacyAgreementTxt = `发布时间：2023年3月17日

生效时间：2023年3月17日


概述

本政策将帮助您了解以下内容：

一、我们如何收集个人信息

二、我们如何存储和保护个人信息

三、我们如何使用个人信息

五、您的权利

六、使用Cookie及相关技术

七、对第三方责任的声明

八、未成年人隐私权特别约定

九、本政策的适用及更新

十、本政策中关键词说明

十一、联系我们

十二、投诉反馈渠道


上海壹齐欧网络科技有限公司有限公司（注册地址：上海市长宁区茅台路270弄7号）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。

您使用我们的CBA时刻APP产品或服务时，我们将按照CBA时刻APP隐私权政策（以下简称“本政策”）收集、存储、使用及对外提供用户（以下称“您”或“用户“）的个人信息。本政策与您使用CBA时刻APP产品或服务关系紧密，请您在使用CBA时刻APP服务前仔细阅读本政策全部内容，确保对其内容尤其是采用粗体表示的重点内容的含义及相应的法律后果已全部知晓并充分理解。如您对本政策内容有任何疑问、意见或建议，请及时通过客服热线（15601942785）咨询，如果不同意本政策的任何内容或者无法理解本政策相关内容，请您应立即停止访问和使用CBA时刻。


一、我们如何收集个人信息

为了您可以正常使用CBA时刻服务，在下列情形中我们需要收集您的一些信息，用以向您提供服务、提升我们的服务质量、保障您的账户安全以及执行国家法律法规及监管规定：

（一）注册

当您注册CBA时刻APP时，我们需要您提供常用手机号码。

（二）登录

为了让您更安全、便捷地登录CBA时刻APP，您可选择我们提供的短信安全登录服务，向我们提供登录手机号的验证短信，以核验您的身份；同时为了您的账号安全，在登录过程中会可能要求您使用人脸识别来验证，此时需要提供您的人脸信息。

（三）支付

当您使用移动支付、扫码付款功能及其他本行或跨行的支付服务时，需要您提供银行卡卡号、银行卡查询密码、银行卡交易密码、银行卡CVV2、姓名、个人身份证件类型、证件号、银行预留手机号、银行卡卡片有效期、CBA时刻账户支付密码、生物识别信息。我们会将前述信息与发卡行、CBA时刻或终端设备进行验证。此外，我们还会收集您的相关收付款交易记录以便您查询。在需要短信验证码的环节，可能需要读取验证码短信内容，以协助您进行验证码填写。

（五）风险控制

为了保障向您提供服务的安全稳定运行，我们需获取您如下设备信息：包括网络SSID、设备型号、设备类型、设备名称、IP地址、Mac地址、设备版本信息、设备识别码（AndroidID、IMEI、IMSI、eSIM标识、硬件序列号、IDFA、UUID）、任务信息、所在位置、应用启动时间、语言或时区信息。我们将会通过采集到的设备信息对您的身份进行识别、验证，这将提高您使用我们服务的安全性，以防止您个人信息被不法分子获取。

（六）搜索

当您使用搜索功能时，我们将采集您的搜索记录以便于为您提供最近搜索服务。如您想清空您的搜索记录，可以通过搜索页上的清空键清除。

（七）语音搜索

当您需要使用语音搜索时，需要您授权我们获取您的麦克风设备访问权限，我们将使用该权限来进行语音输入，通过收集您的语音内容转换成语音文本记录以识别您的需求。如您不同意授权，您将无法使用上述功能。 

（八）设置头像

当您需要使用CBA时刻APP头像设置功能时，需要您授权我们获取您的摄像头或本地相册的访问权限以及读写存储权限，以便为您提供上传头像服务。如您不同意授权，您将无法使用上述功能。

（九）客服服务

当您需要直接联系客服热线（15601942785）或商户售后服务热线时，可能需要您授权我们获取您的拨打电话权限。当您使用“我的客服”相关服务时，为提升您的服务体验及改进服务质量，我们可能会收集必要的交互信息。

（十）使用过程信息

当您使用CBA时刻时，我们会合法收集您的设备、系统以及CBA时刻版本信息和使用过程信息，进行综合统计分析加工，以便为您提供更加准确、个性、流畅及便捷的服务，或帮助我们评估、改善或设计产品服务及运营活动。设备、系统以及CBA时刻版本信息是指根据您在软件安装及使用中所授予的权限，记录到的如下信息：设备型号、设备类型、设备名称、操作系统及版本、网络信息、MAC地址、AndroidID、IMEI、IMSI、IDFA、IDFV、手机号码、运营商信息。使用过程信息是指您在使用功能服务过程中的操作记录，譬如浏览、点击、刷新、返回、页面停留信息。您有权撤回采集授权及申请删除已采集的信息，您可直接联系客服热线（15601942785）撤销授权，用您的敏感信息才能提供相应服务功能的情况，我们会另行取得您的单独授权。

（十一）第三方SDK信息收集

当您使用CBA时刻APP的功能或服务时，在某些特定使用场景下，我们可能会使用具有相应业务资质及能力的第三方服务商提供的软件服务工具包（简称“SDK”）来为您提供服务，这些SDK可能会收集和使用您的个人信息，我们会在您授权同意后获取。CBA时刻APP会对SDK或其他类似的应用程序进行严格的安全检测，并要求第三方服务厂商采取严格的数据保护措施，切实保障您的合法权限。

（十二）根据相关法律法规、监管规定及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息而无需另行征得您的授权同意：

（1）为订立、履行您作为一方当事人的合同所必需；

（2）为履行法定职责或者法定义务所必需；

（3）为应对突发公共卫生事件，或者紧急情况下为保护您或其他个人的生命健康和财产安全所必需；

（4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；

（5）依照法律规定在合理的范围内处理您自行公开或者其他已经合法公开的个人信息；

（6）法律、行政法规规定的其他情形。

(十三)系统权限说明

此外，您在使用CBA时刻对应服务时可能需要调用或使用您的设备功能用于收集和使用您的个人信息。当您使用服务之前，您可选择是否授权我们开通以下权限：

1、读取及写入存储器权限：用于图片及必要文件的存储使用。如您选择不开启此权限，您将无法使用与此权限相关的特定功能，但不影响您使用CBA时刻APP提供的其他服务。

2、位置信息权限：开启定位权限，可便于您查看附近的本地生活服务及优惠商户信息。如您选择不开启此权限，您将不会收到基于所在地点位置的服务推荐，但不影响您使用CBA时刻APP提供的其他服务。您也可以在CBA时刻APP首页手动设置城市信息。同时，在采取脱敏、去标识化等数据保护措施的前提下，您使用餐饮美食等生活服务的信息可能会被用于为您综合推荐服务内容，您也可以按照页面提示对相关功能进行设置。

3、摄像头权限及相册权限：当您使用证件信息更新、扫一扫、头像图片设置、截图反馈、人脸识别功能时，您需要开启摄像头权限和/或相册权限（以您使用的具体功能为准），以便您进行实时拍摄或图片/视频上传。如您选择不开启相应权限，您将无法使用与实时拍摄或图片/视频上传相关的特定功能，但不影响您使用CBA时刻APP提供的其他服务。

4、麦克风权限：当您使用语音搜索、声纹及客服对话功能时，您需要开启此权限，并使用麦克风设备进行语音输入。如您选择不开启此权限，您将无法使用与语音输入相关的特定功能，但不影响您使用CBA时刻APP提供的其他服务。

5、日历权限：当您使用CBA时刻日历提醒功能过程时，CBA时刻需要您授权访问日历，为您添加日历提醒，如您不需要此功能，可以取消授权。

6、应用列表权限：用于CBA时刻交易风控。若您选择不开启此权限，我们将无法完成交易风控验证，但不影响您使用CBA时刻APP提供的其他服务。

7、剪切板权限：用于CBA时刻口令快捷跳转功能。如您选择不开启此权限，您将无法使用口令快捷跳转功能，但不影响您使用CBA时刻APP提供的其他服务。

8、系统广播：为确保桌面小组件功能稳定可用，我们会通过监听系统广播进行桌面小组件展示。

上述功能需要您在您的设备中向我们开启您的读取及写入存储器权限、位置信息权限、摄像头权限及相册权限、麦克风权限、基于面容等生物特征识别的功能、日历权限、应用列表权限、剪切板权限、NFC权限、重力及加速度传感器、系统广播，以实现这些功能所涉及的信息收集和使用。您确认并同意开启这些权限即代表您授权我们可以收集和使用这些信息；您也可以遵循您所使用设备的操作系统指示变更或者取消这些授权，则我们将不再继续收集和使用您的这些信息，也无法为您提供上述与这些授权所对应的功能，但这不会对您使用CBA时刻其他服务产生影响。

(二十六)其他

请您理解，我们向你提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵盖的其他服务，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程或协议约定的方式另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供和保护您的信息；如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。此外，第三方主体可能会通过CBA时刻APP向您提供服务。当您进入第三方主体运营的服务页面时，请注意相关服务由第三方主体向您提供。涉及到第三方主体向您收集个人信息的，建议您仔细查看第三方主体的隐私政策或协议约定。


二、我们如何存储和保护个人信息

1、原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。

2、我们仅在法律法规及监管规定要求的期限内，以及为实现本政策声明的目的所必需的时限内保留您的个人信息。当超出数据保存期限后，我们会对您的信息进行删除或匿名化处理，但法律法规或监管要求另有规定的除外。例如：

用户头像：当您设置了头像以后，我们会保存您的头像用于登录页面展示使用。当您选择恢复为默认头像后，我们将删除您之前设置的头像信息。

3、为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系在合理的安全水平内保障您的信息安全。尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如：通过网络安全层软件（SSL）进行加密传输、信息加密存储、严格限制数据中心的访问、使用专用网络通道及网络代理。同时我们设立了个人信息保护责任部门，建立了相关内控制度。另外，我们每年还会聘请外部独立第三方对我们的信息安全进行评估。

4、我们已制定个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。

5、请您务必妥善保管好您的CBA时刻登录名及其他身份要素（详见本政策第十条关键词说明）。您在使用CBA时刻APP服务时，我们会通过您的登录名及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能产生对您不利的法律后果。如您发现CBA时刻登录名及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。同时，请妥善保管您的手机、平板电脑等移动终端设备，避免他人使用您的可信设备登录您的账户。


三、我们如何使用个人信息

为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账户和资金安全，我们会在以下情形中使用您的信息：

1. 在我们向您提服务期间，我们将根据本隐私政策以及您的另行授权收集和使用您的相关个人信息，在您注销服务或取消授权时，我们将停止收集及使用您的相关个人信息，但我们会根据法律法规及国家有权机关的要求审计、国家有权机关协查等继续使用此前收集的您的相关个人信息。 

2. 为提升您的体验，我们会采取脱敏、去标识化等方式对您服务使用情况的相关信息进行综合统计、分析加工形用户成标签和画像维度，将会获取您的兴趣爱好和消费习惯，通过此类信息我们可能会对您提供个性化服务推荐、页面展示（如首页为你推荐、轮播展示及活动推荐），您将享受到更加准确、个性、流畅及便捷的服务。以上信息还将帮助我们评估、改善或设计产品、服务及运营活动等。如您不希望我们收集您此类信息，您可根据您的要求致电客服作删除，法律法规另有规定的除外。

3. 为防范风险以及保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、欺诈调查、预防或禁止非法活动、降低风险。

4. 您的个人信息将仅在中华人民共和国境内（不包括香港、澳门特别行政区及台湾地区）使用，不会传输至境外使用。如因业务需要，确需向境外提供的，将按照国家网信部门及相关部门制定的办法进行安全评估；除法律法规及国家有权机关另有规定的除外。

5. 将您的个人信息用于法律允许的其它用途。



五、您的权利
1、删除您的个人信息

如您发现我们使用您个人信息的行为，违反了法律法规规定或违反了与您的约定，您可通过在线客服，要求删除相应的信息，但如有相关个人信息按照法律法规规定的保存期限未届满或者其他依法不适宜删除的情形，我们将停止除存储和采取必要的安全保护措施之外的处理。

在以下情形中，您可以向我们提出删除个人信息的请求：

（1）如果我们处理个人信息的行为违反法律法规；

（2）如果我们收集、使用您的个人信息，却未征得您的同意；

（3）如果我们处理个人信息的行为违反了与您的约定；

（4）如果您不再使用我们的产品或服务，或您注销了账号；

（5）如果我们不再为您提供产品或服务。

若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。

3、账户注销

您随时可注销此前注册的APP账户，账户注销不仅是对您CBA时刻APP会员账户的处理。您注销CBA时刻APP账户后，我们将停止为您提供产品或服务，包括但不限于：（1）账户注销后，您将无法登录、使用本APP账户，我们将依据您的要求对以下个人信息进行清除（法律法规、监管要求另有规定的除外），包括注册手机号（2）您之前在APP中已获得的优惠券、资格、礼品、权益及其他卡券将不可以继续使用，相关历史订单将无法查询。请您在操作之前自行备份保存好订单、商品、礼品、票、券等信息。

请您注意，您自主注销CBA时刻账户的同时，将视同您撤回了对CBA时刻隐私政策的同意。当您收回同意后，我们将不再通过CBA时刻APP收集、使用或对外提供与该CBA时刻账号相关的个人信息，但您在使用CBA时刻服务期间提供或产生的信息我们按照法律、法规及监管要求的时间进行保存，且在该保存时间内依法配合有权机关的查询。

4、约束信息系统自动决策

在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。

5、响应您的上述请求

为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15日内作出答复。如您不满意，还可以通过“十二、投诉反馈渠道”中所列方式进行投诉。

对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。

在以下情形中，我们将无法响应您的请求：

（1）与个人信息控制者履行法律法规规定的义务相关的；

（2）与国家安全、国防安全直接相关的；

（3）与公共安全、公共卫生、重大公共利益直接相关的；

（4）与刑事侦查、起诉、审判和执行判决等直接相关的；

（5）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；

（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；

（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；

（8）涉及商业秘密的。


六、使用Cookie及相关技术

我们或我们的第三方合作方，可能通过放置安全的Cookie及相关技术收集您的信息，以便向您提供更个性化的用户体验和服务。您也可以设置管理Cookie，但如果停用Cookie，您可能无法享受最佳的服务体验，某些服务也可能无法正常使用。


七、对第三方责任的声明

对于第三方产品和服务的接入，我们会建立严格的管理机制和工作流程，并与第三方产品或服务提供方通过合同等形式明确双方的安全责任及实施的个人信息安全措施。在您使用的产品或服务由第三方提供时，我们会向您明确标识和说明。如第三方因服务所必需要采集您的信息，将会单独获得您的授权同意。

请您注意，您的交易相对方、您访问的第三方网站经营者、通过我们接入的第三方服务（例如：CBA时刻APP中第三方提供的应用）和由CBA时刻处接收您的个人信息的第三方可能有自己的隐私权保护政策；当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们的控制，且它们的使用不受本政策的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，请您与他们直接联系以获得关于他们的隐私权政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。


八、未成年人隐私权特别约定

1、我们期望父母或监护人指导未成年人使用我们的服务。我们将根据国家相关法律法规的规定保护未成年人的个人信息的保密性及安全性。

2、如您为未成年人，建议请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用我们的服务或向我们提供您的信息。对于经父母或监护人同意而使用您的信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。


九、本政策的适用及更新

1、本隐私政策适用于CBA时刻平台向您提供的所有服务，您确认就本政策中提及需要您授权提供、使用、提供的个人信息，在您同意本政策并使用相关服务时，我们将不再另行单独获取您的个别授权。

需要特别说明的是，本隐私政策不适用CBA时刻关联方、CBA时刻合作机构或其他第三方向你提供的服务。CBA时刻平台也可能含有到其他网站的链接，我们会依据法律法规的要求采取必要措施对相关网站进行审查（包括审查网站经营资质、通过技术手段对相关网站的安全情况进行合理且初步识别、督促该网站经营者根据法律规定保护您的个人信息安全），但我们无法保证该链接网站的运营方会按照我们的要求采取保护措施。我们建议您查看该网站的隐私权政策，了解他们如何处理你的信息，以便审慎决策。

2、我们与您达成的其他关于个人信息和隐私的条款视为对本政策的补充，本政策存在未涉及内容的，适用其他条款的约定。若因新增产品、服务、功能且需收集、使用、提供您的个人信息的，我们将另行获取您的同意。

3、本政策可能会发生变更。未经您明确同意，我们不会削减您按照本个人信息保护政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知，包括CBA时刻APP内弹窗提示、CBA时刻APP推送通知、发送邮件或者发送短消息的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您不同意本政策变更，请立即停止访问和使用CBA时刻APP。如您在本政策更新生效后继续使用CBA时刻APP服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。

您可以在CBA时刻APP中查看本政策。我们鼓励您在每次使用CBA时刻时都查阅我们的隐私政策。

本政策所指的重大变更包括但不限于：

（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；

（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；

（3）个人信息共享、转让或公开披露的主要对象发生变化；

（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；

（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；


十、本政策中关键词说明

本政策中的“身份要素”是指：CBA时刻用于识别您身份的信息要素，例如：您的CBA时刻登录名、密码、短信校验码、电话号码、手机号。

十一、联系我们

1、如您对本隐私政策或相关事宜有任何疑问、意见或建议，您可随时拨打24小时客服热线15601942785或通过在线客服联系反馈，一般情况下客服人员将于15日内响应您的建议或意见。

十二、投诉反馈渠道

服务监督热线电话：15601942785（工作日9:00-18:00）

邮箱地址：hello@spotlightlabs.top（受理书面申请核查）

当您因为本隐私权政策的实施与我们产生任何纠纷时，双方应首先协商友好解决；若不能协商解决，双方均有权向公司所在地有管辖权的法院提起诉讼。
`

export const PAreleaseTime = "发布时间：2023年3月17日"

export const PAeffectiveTime = "生效时间：2023年3月17日"

export const PAtitle = "CBA时刻APP隐私权政策"
