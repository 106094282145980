import React, { FC, ReactNode, useRef, useState } from "react"
import { Images, fitSize } from "../../../theme"
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet"
import "react-spring-bottom-sheet/dist/style.css"
import "./index.module.css"
import { useFocusEffect } from "@react-navigation/native"
import { TipsUpIcon } from "./TipsUpIcon"

interface CardDetailModalProps {
  children: ReactNode
  setScrollViewState?: React.Dispatch<React.SetStateAction<"top" | "initial">>
}

const HEIGHT = fitSize(130)
export const CardDetailModal: FC<CardDetailModalProps> = ({ children, setScrollViewState }) => {
  const sheetRef = useRef<BottomSheetRef>()
  const focusRef = useRef<HTMLButtonElement>()
  const [didHeight, setDidHeight] = useState(HEIGHT)
  const [currentH, setCurrentH] = useState(0)

  const [open, setOpen] = useState(true)

  useFocusEffect(
    React.useCallback(() => {
      setOpen(true)
      setDidHeight(HEIGHT)

      return () => {
        setOpen(false)
        setDidHeight(0)
      }
    }, []),
  )

  return (
    <BottomSheet
      open={open}
      skipInitialTransition
      ref={sheetRef}
      initialFocusRef={focusRef}
      defaultSnap={({ height }) => {
        setCurrentH(height)
        return didHeight
      }}
      snapPoints={({ height, maxHeight }) => {
        if (height > currentH) {
          setScrollViewState("top")
          // setDidHeight(fitSize(50))
        }
        return [didHeight, maxHeight]
      }}
      scrollLocking={true}
      expandOnContentDrag
      style={{ display: open ? "flex" : "none" }}
      header={<TipsUpIcon />}
      blocking={false}
    >
      {children}
    </BottomSheet>
  )
}
