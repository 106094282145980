import { observer } from "mobx-react-lite"
import { Screen, Sheader, Touchable } from "../../components"
import { StyleSheet, Text, View, Dimensions, Image } from "react-native"
import { Images, colors, fitSize } from "../../theme"
import { CardBoxItem, api } from "../../services/api"
import { useAutoList } from "../../hooks/useAutoList"
import { HookFlatList } from "../../components/list/HookFlatList"
import React, { useCallback, useEffect, useRef } from "react"
import dayjs from "dayjs"
import { fontName } from "../../theme/fonts"
import { BackTopButton } from "./components/BackTopButton"
import { GToast } from "../../navigators"
import { SizeImage } from "../../components/SizeImage"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"
import { RootStackNavProps } from "../../type"
import { translate } from "../../i18n"
import { useFocusEffect } from "@react-navigation/native"

interface CardBoxStoreProps extends RootStackNavProps<"CardBoxStore"> {}

const PL = 50
export const CardBoxStore: React.FC<CardBoxStoreProps> = observer(() => {
  const lisRef = useRef(null)
  const btnRef = useRef(null)
  const navigation = useTypeNavigation()

  const fetchCardList = async (lastId?: number, size = PL) => {
    try {
      const res = await api.getCardBoxList(lastId, size)
      if (res.kind === "ok") {
        return res.data
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchList.onRefresh()
    }, []),
  )

  const [fetchList] = useAutoList(
    {
      fetchData: fetchCardList,
      pageLimit: PL,
    },
    [],
  )

  const Card = ({ item }: { item: CardBoxItem }) => {
    return (
      <Touchable
        style={style.item}
        onPress={() => {
          navigation.navigate("OpenCardBag", {
            id: item?.id,
            blindBoxId: item?.blindBoxId,
          })
        }}
      >
        {item.total > 1 ? (
          <View
            style={[
              style.totalBox,
              {
                backgroundColor: item.isRead === 1 ? colors.palette.neutral40 : "#FF1F02",
              },
            ]}
          >
            <Text
              style={[
                style.total,
                { color: item.isRead === 1 ? colors.palette.neutral30 : colors.palette.neutral100 },
              ]}
            >
              {item?.total ?? 0}
            </Text>
          </View>
        ) : (
          <Image
            source={Images.components.red_tip}
            style={[style.red, { display: item.isRead === 1 ? "none" : "flex" }]}
          />
        )}
        <View style={{ width: fitSize(108), height: fitSize(125) }}>
          <View style={{ position: "relative" }}>
            {item?.total >= 3 ? (
              <SizeImage
                source={{ uri: "" }}
                style={[
                  style.img,
                  {
                    top: -4,
                    right: -1,
                  },
                ]}
              />
            ) : null}
            {item?.total >= 2 ? (
              <SizeImage
                source={{ uri: "" }}
                style={[
                  style.img,
                  {
                    top: -2,
                    right: 3,
                  },
                ]}
              />
            ) : null}
            <SizeImage source={{ uri: item?.imageMain }} style={style.img} />
          </View>
        </View>
        <Text style={style.name} numberOfLines={1}>
          {item?.name}
        </Text>
        <Text style={style.time} numberOfLines={1}>
          {dayjs(item?.createdAt).format("YYYY.MM.DD")}
        </Text>
      </Touchable>
    )
  }

  return (
    <Screen preset="fixed" safeAreaEdges={["top"]} contentContainerStyle={style.container}>
      <Sheader
        title={""}
        isNotSafe
        textStyle={style.textStyle}
        leftStyle={style.leftStyle}
        style={style.header}
      />
      <View style={style.headerContainer}>
        <Text style={style.forShortName}>
          {translate("screen.tabStore.cardBoxStore.forShortName")}
        </Text>
      </View>
      <View style={style.headerContent}>
        <Text style={style.titleTxt}>{translate("screen.tabStore.cardBoxStore.fullName")}</Text>
      </View>
      <View style={style.body}>
        <HookFlatList
          isTransverseLoad={true}
          {...fetchList}
          numColumns={3}
          isShow={fetchList.data.length > 0}
          showsHorizontalScrollIndicator={false}
          ref={lisRef}
          showsVerticalScrollIndicator={false}
          estimatedItemSize={fitSize(180)}
          estimatedListSize={{ width: fitSize(112), height: fitSize(180) }}
          renderItem={({ item }) => <Card item={item as CardBoxItem} />}
          onScroll={(event) => {
            const offsetY = event.nativeEvent.contentOffset.y
            const threshold = Dimensions.get("window").height
            if (offsetY > threshold) {
              btnRef.current.setFadeAnimate(true)
            } else {
              btnRef.current.setFadeAnimate(false)
            }
          }}
        />
      </View>
      <BackTopButton ref={btnRef} lisRef={lisRef} />
    </Screen>
  )
})

const style = StyleSheet.create({
  forShortName: {
    fontSize: fitSize(16),
    color: colors.palette.neutral20,
    fontWeight: "400",
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  headerContent: {
    height: fitSize(30),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(14),
  },
  headerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(14),
  },
  titleTxt: {
    fontSize: fitSize(25),
    color: colors.palette.neutral100,
    fontFamily: fontName.YouSheBiaoTiHei_2,
  },
  upIcon: {
    width: fitSize(46),
    height: fitSize(46),
  },
  upBtn: {
    position: "absolute",
    right: 30,
    bottom: 30,
    overflow: "hidden",
  },
  total: {
    fontWeight: "500",
    fontSize: fitSize(11),
    fontFamily: fontName.PingFang_SC_Semibold,
    right: 0,
    paddingHorizontal: 2,
  },
  red: {
    width: fitSize(12),
    height: fitSize(12),
    borderRadius: fitSize(10),
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "absolute",
    zIndex: 999,
    right: 2,
    top: 4,
  },
  totalBox: {
    minWidth: fitSize(21),
    height: fitSize(16),
    borderRadius: fitSize(10),
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "absolute",
    zIndex: 999,
    right: 0,
    top: 0,
  },
  time: {
    fontSize: fitSize(11),
    fontWeight: "400",
    color: colors.palette.neutral20,
    fontFamily: fontName.PingFang_SC,
  },
  name: {
    fontSize: fitSize(13),
    fontWeight: "400",
    color: colors.palette.neutral100,
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(8),
  },
  img: {
    width: fitSize(102),
    height: fitSize(125),
    borderRadius: fitSize(4),
    borderColor: colors.palette.neutral50,
    position: "absolute",
    overflow: "hidden",
    backgroundColor: colors.palette.neutral900,
    borderWidth: 1,
  },
  item: {
    width: fitSize(112),
    height: fitSize(180),
    alignItems: "center",
    paddingTop: fitSize(8),
    borderWidth: 1,
    marginLeft: fitSize(5),
    marginBottom: fitSize(20),
  },
  container: {
    flex: 1,
    backgroundColor: colors.palette.neutral900,
  },
  textStyle: {
    color: colors.palette.neutral100,
  },
  leftStyle: {
    tintColor: colors.palette.neutral100,
    width: fitSize(20),
    height: fitSize(20),
  },
  header: {
    height: fitSize(44),
  },
  body: {
    flex: 1,
    width: "100%",
    marginTop: fitSize(16),
    // paddingHorizontal: fitSize(10),
  },
  content: {
    width: "100%",
    flexGrow: 1,
    marginBottom: fitSize(20),
  },
})
