import { Image, Platform, StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { Images, fitSize } from "../../../theme"
import { Touchable, TouchableDeep } from "../../../components"
import { bannerColumnItem } from "../../../services/api/api.types"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"

import React, { Fragment, useCallback, useRef, useState } from "react"
import { SwiperFlatList } from "../../../components/react-native-swiper-flatlist/index"
import { useStores } from "../../../models"
import { GToast } from "../../../navigators"
import { SizeImage } from "../../../components/SizeImage"
import { MultiPlatformVideoPlayer } from "../../../components/MultiPlatformVideoPlayer"
import { VideoModal } from "../../../modal/VideoModal"
import { SwiperFlatListRefProps } from "../../../components/react-native-swiper-flatlist/src/components/SwiperFlatList/SwiperFlatListProps"
import { getColors } from "react-native-image-colors"
import { parseRouterURL, parseUrl } from "../../../utils/regexFunction"

interface SwiperProps {
  style?: StyleProp<ViewStyle>
  data: bannerColumnItem[]
  h?: number
  isShowPagination?: boolean
  isAutoplay?: boolean
  isAutoplayLoop?: boolean
  w?: number
  isVideoPlay?: boolean
  disabled?: boolean
  isFullScreen?: boolean
  isFocus?: boolean
  backdropColor?: React.Dispatch<React.SetStateAction<string[]>>
}

export const Swiper: React.FC<SwiperProps> = ({
  style,
  data,
  h = fitSize(190),
  isShowPagination = true,
  isAutoplay = true,
  isAutoplayLoop = true,
  w = fitSize(190),
  isVideoPlay = false,
  disabled,
  isFullScreen,
  isFocus = true,
  backdropColor,
}) => {
  const [list, setList] = useState(data)
  const navigation = useTypeNavigation()
  const { accountStore } = useStores()
  const isSafari = /apple/i.test(navigator.vendor)
  const [showVideo, setShowVideo] = useState(false)
  const [videoURL, setVideoURL] = useState({ src: "", poster: "" })
  const [fullScreen, setFullScreen] = useState(false)
  const videoRef = useRef(null)
  const [isPaused, setIsPaused] = useState(false)
  const refs = useRef<SwiperFlatListRefProps>()

  const goToActivity = (item: bannerColumnItem, currentIndex?: number) => {
    if (item.type === 1) {
      if (item.link && item.link.includes("https://")) {
        if (Platform.OS === "web") {
          window.location.href = item?.link
        } else {
          navigation.navigate("ActivityWebView", { uri: item?.link })
        }
      } else {
      }
    } else if (item.type === 2) {
      videoRef.current.toggleVideo()
    } else if (item.type === 3) {
      const result = parseRouterURL(item?.link)
      if (result) {
        navigation.navigate(result.route as any, { uri: item?.link, ...result.params })
      }
    }
  }

  const setDataVideoState = (currentIndex: number) => {
    const arr: bannerColumnItem[] = data.map((item, index) => {
      if (index === currentIndex) {
        return { ...item, isPaused: false }
      } else {
        return { ...item, isPaused: true }
      }
    })
    setList(arr)
  }

  const handlePlay = (item: bannerColumnItem, currentIndex?: number) => {
    if (isSafari && Platform.OS === "web") {
      setShowVideo(true)
      setVideoURL({ src: item.image, poster: item.poster })
      setIsPaused(true)
      setDataVideoState(currentIndex)
    } else {
      setDataVideoState(currentIndex)
    }
  }

  const handleFullScreen = (item: bannerColumnItem, currentIndex: number) => {
    const updatedList = list.map((listItem) => {
      return { ...listItem, isPaused: true }
    })
    setList(updatedList)
    GToast.current.showControlVideoModal(true, {
      src: item?.image,
      poster: item.poster,
      seek: 0,
    })
    setFullScreen(true)
  }

  const setVideoPaused = (currentIndex) => {
    const updatedData = data.map((item, index) => {
      if (index === currentIndex) {
        return { ...item, isPaused: true }
      } else {
        return item
      }
    })
    setList(updatedData)
  }

  const clearVideo = () => {
    setShowVideo(false)
    setFullScreen(false)
    setVideoURL({ src: "", poster: "" })
  }

  const fetchColors = (url: string) => {
    getColors(url, {
      fallback: "#000",
      cache: true,
      key: url,
      pixelSpacing: 5,
    })
      .then((res) => {
        if (backdropColor) {
          switch (res.platform) {
            case "web":
              backdropColor([
                res.vibrant,
                `${res.vibrant}CC`,
                `${res.vibrant}99`,
                `${res.vibrant}66`,
                `${res.vibrant}33`,
              ])
              break
            case "android":
              backdropColor([
                res.vibrant,
                `${res.vibrant}CC`,
                `${res.vibrant}99`,
                `${res.vibrant}66`,
                `${res.vibrant}33`,
              ])
              break
            case "ios":
              backdropColor([
                res.primary,
                `${res.primary}CC`,
                `${res.primary}99`,
                `${res.primary}66`,
                `${res.primary}33`,
              ])
              break
            default:
              break
          }
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const _onViewableItemsChanged = useCallback(({ viewableItems }) => {
    if (viewableItems.length === 1) {
      const url = viewableItems[0]?.item?.image
      fetchColors(url)
    }
  }, [])

  return (
    <React.Fragment>
      <View style={[styles.container, style, { height: h, width: w }]}>
        <SwiperFlatList
          data={list}
          autoplay={isAutoplay}
          autoplayDelay={5}
          autoplayLoop={isAutoplayLoop}
          index={0}
          showPagination={isShowPagination}
          paginationStyleItem={styles.normalDot}
          paginationStyleItemActive={styles.activeDot}
          paginationStyleItemInactive={styles.inactiveDot}
          style={styles.content}
          onViewableItemsChanged={_onViewableItemsChanged}
          ref={(ref) => {
            refs.current = ref
          }}
          renderItem={({ item, index }) => {
            const isControl = item.type === 2
            return (
              <React.Fragment key={index}>
                {isControl && item.isPaused ? (
                  <Touchable onPress={() => handlePlay(item, index)} style={[styles.videoPlayBox]}>
                    <SizeImage
                      source={Images.screen.home.time_video_pause}
                      style={styles.timeVideoPause}
                    />
                  </Touchable>
                ) : null}
                {isControl ? (
                  <Touchable
                    onPress={() => handleFullScreen(item, index)}
                    style={[styles.videoFullScreenBox]}
                  >
                    <SizeImage
                      source={Images.screen.home.fill_screen}
                      style={styles.timeVideoFillScreen}
                    />
                  </Touchable>
                ) : null}
                <TouchableDeep
                  key={item.id}
                  onPress={() => goToActivity(item, index)}
                  style={[styles.child, { height: h }]}
                  disabled={item.isPaused && isControl}
                >
                  {item?.type === 1 || item?.type === 3 ? (
                    <SizeImage
                      source={{ uri: item.image }}
                      style={[styles.imageStyle, { height: h }]}
                      resizeMode={"cover"}
                    />
                  ) : item?.type === 2 && !item?.isPaused ? (
                    <Fragment>
                      {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
                        <MultiPlatformVideoPlayer
                          uri={item?.image}
                          isLook={false}
                          style={{
                            width: w,
                            height: h,
                            overflow: "hidden",
                          }}
                          h={h}
                          w={w}
                          isPaused={item?.isPaused}
                          poster={item?.link}
                          loop={false}
                          index={index}
                          ref={videoRef}
                          muted={!!isFullScreen}
                        />
                      ) : null}
                    </Fragment>
                  ) : item?.type === 2 && item?.isPaused ? (
                    <SizeImage
                      source={{ uri: item?.link ?? "" }}
                      style={[
                        {
                          width: w,
                          height: h,
                          overflow: "hidden",
                        },
                      ]}
                      resizeMode={"stretch"}
                    />
                  ) : null}
                </TouchableDeep>
              </React.Fragment>
            )
          }}
        />
      </View>
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: fitSize(4),
  },
  child: {
    width: fitSize(348),
    justifyContent: "center",
    alignItems: "center",
    // marginRight: fitSize(19),
  },
  imageStyle: {
    width: fitSize(347),
    resizeMode: "cover",
    borderRadius: fitSize(5),
    marginRight: fitSize(2),
  },
  normalDot: {
    width: fitSize(5),
    height: fitSize(5),
    backgroundColor: "rgba(0,0,0,0.5)",
    marginHorizontal: fitSize(5),
    marginBottom: fitSize(14),
  },
  activeDot: {
    backgroundColor: "#ffffff",
  },
  inactiveDot: {
    backgroundColor: "rgba(255,255,255,0.35)",
  },
  content: {
    borderRadius: fitSize(4),
  },
  timeVideoPause: {
    width: fitSize(36),
    height: fitSize(36),
  },
  timeVideoFillScreen: {
    width: fitSize(16),
    height: fitSize(16),
  },
  videoPlayBox: {
    zIndex: 2147483647,
    position: "absolute",
    left: 160,
    bottom: 90,
  },
  videoFullScreenBox: {
    zIndex: 2147483647,
    position: "absolute",
    right: 10,
    bottom: 10,
  },
})
