import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Platform,
  StyleSheet,
  View,
  useWindowDimensions,
  Text,
  ImageBackground,
  Animated,
  Dimensions,
  ViewStyle,
  BackHandler,
} from "react-native"
import Modal from "react-native-modal/dist/modal"
import { colors, deviceHeight, fitSize } from "../theme"
import { AutoImage, Touchable, videoItem } from "../components"
import { SizeImage } from "../components/SizeImage"
import { Images } from "../theme"
import { translate } from "../i18n"
import { fontName } from "../theme/fonts"
import { api } from "../services/api"
import { GToast } from "../navigators"
import { AnimatedViewStyle } from "../type/ViewType"
import { hideLoad } from "../utils/showUtil"

interface OptionalVideoPlayModalProps {
  modalShow: boolean
  contentData: videoItem[]
  onBack?: () => void
  translateXValue?: number
}

const width = fitSize(290)
export const OptionalVideoPlayModal: React.FC<OptionalVideoPlayModalProps> = ({
  modalShow,
  contentData,
  onBack,
  translateXValue = 0,
}) => {
  const { height } = useWindowDimensions()
  const compatibleHeight = Platform.OS === "web" ? height : deviceHeight
  const [translateX, setTranslateX] = useState(0)
  const refs = useRef(null)
  const translation = useRef(new Animated.Value(translateX)).current

  const onScroll = useCallback((event) => {
    setTranslateX(event.nativeEvent.contentOffset.x)
  }, [])

  useEffect(() => {
    const backAction = () => {
      if (!modalShow) {
        return false
      } else {
        return true
      }
    }
    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction)
    refs.current && refs.current?.scrollTo()
    return () => {
      backHandler?.remove()
    }
  }, [modalShow])

  const $box: ViewStyle = {
    left: 0,
    position: "absolute",
    right: 0,
    top: 10,
    justifyContent: "center",
    alignItems: "center",
    zIndex: -1,
  }

  const $boxContainer: ViewStyle = {
    width,
    height: fitSize(257),
  }

  const $boxContent: ViewStyle = {
    width: fitSize(177),
    height: fitSize(207) * 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }

  useEffect(() => {
    Animated.timing(translation, {
      toValue: translateX,
      duration: 0.1,
      useNativeDriver: true,
    }).start()
  }, [translateX, translation])

  const lookNewAsset = (asset: videoItem) => {
    onBack()
    GToast.current.showVideo(
      true,
      {
        url: asset.video,
        poster: asset.image,
        seek: 0,
        info: {
          id: asset.assetsNumber,
          name: asset.name,
          cardBoxName: asset.cardBoxName,
          seriesName: asset.seriesName,
          rarityIcon: asset.rarityIcon,
        },
      },
      {},
      () => {
        GToast.current.showOptionalVideoPlay(true, contentData, translateX)
      },
      true,
    )
  }

  return (
    <Modal
      hasBackdrop={true}
      coverScreen={true}
      isVisible={modalShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={styles.modal}
      backdropOpacity={0.8}
      onBackButtonPress={() => {}}
      onBackdropPress={() => {}}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
    >
      <View style={[styles.container]}>
        <ImageBackground source={Images.screen.openCardBox.obtained_modal} style={styles.content}>
          <Text style={styles.title}>{translate("modal.optionalVideoPlayModal.title")}</Text>
          <View style={styles.body}>
            <Animated.ScrollView
              onScroll={onScroll}
              scrollEventThrottle={16}
              decelerationRate="fast"
              snapToInterval={width}
              horizontal
              showsHorizontalScrollIndicator={false}
              ref={(ref) => {
                refs.current = ref
              }}
              scrollEnabled={contentData.length > 1}
            >
              {contentData?.map((item, index) => {
                return (
                  <View
                    style={$boxContainer}
                    onStartShouldSetResponder={() => true}
                    key={index}
                    onLayout={(event) => {
                      const layout = event.nativeEvent.layout
                      const arr = []
                      arr[index] = layout.x
                    }}
                  >
                    <Touchable
                      style={{ width: "100%", height: "100%" }}
                      onPress={() => {
                        lookNewAsset(item)
                      }}
                    />
                  </View>
                )
              })}
            </Animated.ScrollView>
            <View style={$box}>
              {contentData?.map((product, index) => {
                const $animatedBox: AnimatedViewStyle = {
                  transform: [
                    {
                      translateX: translation.interpolate({
                        inputRange: [(index - 1) * width, index * width, (index + 1) * width],
                        outputRange: [width / 2, 0, -width / 2],
                      }),
                    },
                    {
                      scale: translation.interpolate({
                        inputRange: [(index - 1) * width, index * width, (index + 1) * width],
                        outputRange: [0.61, 1, 0.61],
                      }),
                    },
                  ],
                }
                return (
                  <Animated.View style={[$box, $animatedBox]} key={index}>
                    <Touchable style={$boxContent} onPress={() => {}}>
                      <View
                        style={{
                          width: fitSize(100),
                          height: fitSize(100),
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <AutoImage
                          source={{ uri: product.image }}
                          style={styles.productIcon}
                          resizeMode="contain"
                        />
                      </View>
                      <View style={styles.rarityBox}>
                        <SizeImage
                          source={{ uri: product.rarityIcon }}
                          style={styles.productRarityIcon}
                        />
                        <Text style={styles.productName}>{product.name}</Text>
                      </View>
                      <Text style={styles.productActionName}>{product.actionName}</Text>
                      <View style={styles.productAssetsBox}>
                        <Text style={styles.productAssetsNumber}>#{product.assetsNumber}</Text>
                        <Text style={styles.productCirculation}>/{product.circulation}</Text>
                      </View>
                    </Touchable>
                  </Animated.View>
                )
              })}
            </View>
          </View>
          <Touchable
            style={styles.btn}
            onPress={() => {
              onBack()
              hideLoad()
              refs.current?.scrollTo()
            }}
          >
            <Text style={styles.btnText}>{translate("modal.optionalVideoPlayModal.btnText")}</Text>
          </Touchable>
          <Text style={styles.tip}>{translate("modal.optionalVideoPlayModal.tip")}</Text>
        </ImageBackground>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  productCirculation: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  productAssetsNumber: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  productAssetsBox: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: fitSize(2),
  },
  productActionName: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(2),
  },
  productName: {
    fontSize: fitSize(13),
    color: colors.palette.neutral30,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  productRarityIcon: {
    width: fitSize(14),
    height: fitSize(14),
    marginRight: fitSize(2),
  },
  rarityBox: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: fitSize(15),
  },
  productIcon: {
    width: fitSize(99.91),
    height: fitSize(80),
  },
  btn: {
    width: fitSize(206),
    height: fitSize(40),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: fitSize(23),
    backgroundColor: "#60E3E0",
  },
  btnText: {
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  tip: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
    marginTop: fitSize(10),
  },
  body: {
    width: fitSize(295),
    height: fitSize(257),
    overflow: "hidden",
  },
  title: {
    fontSize: fitSize(22),
    color: colors.palette.neutral30,
    fontWeight: "600",
    fontFamily: fontName.PingFang_SC,
  },
  container: {
    width: fitSize(351),
    height: fitSize(468),
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
  },
  clearIcon: {
    width: fitSize(14),
    height: fitSize(14),
    tintColor: colors.palette.neutral20,
    marginLeft: fitSize(300),
  },
  modal: {
    margin: 0,
    overflow: "hidden",
  },
  content: {
    width: fitSize(351),
    height: fitSize(468),
    flexDirection: "column",
    paddingTop: fitSize(64),
    paddingBottom: fitSize(50),
    alignItems: "center",
    justifyContent: "center",
  },
})
