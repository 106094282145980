import { observer } from "mobx-react-lite"
import { Touchable } from "../../components"
import { FlatList, Image, PanResponder, Platform, StyleSheet, Text, View } from "react-native"
import { Images, colors, fitSize } from "../../theme"
import { useTokenAuthorization } from "../../hooks/useTokenAuthorization"
import { fontName } from "../../theme/fonts"
import { Dimensions } from "react-native"
import {
  Fragment,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { MultiPlatformVideoPlayer } from "../../components/MultiPlatformVideoPlayer"
import { SizeImage } from "../../components/SizeImage"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { api } from "../../services/api"
import { useAutoList } from "../../hooks/useAutoList"
import { GToast } from "../../navigators"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs"
import { useStores } from "../../models"
import { RarityIcon } from "../TabHome/components/RarityIcon"
import { FlashList } from "@shopify/flash-list"
import { useIsFocused } from "@react-navigation/native"

interface MomentPreviewProps {
  seriesId: string
  rarityId: string
  cardBoxId: string
  currentIndex: number
  isOpen: boolean
}

const PL = 20
export const MomentPreview = observer(
  forwardRef((props: MomentPreviewProps, ref) => {
    const { authorization } = useTokenAuthorization()
    const flatListRef = useRef(null)
    const [scrollIndex, setScrollIndex] = useState(0)
    const { top, bottom } = useSafeAreaInsets()
    const { seriesId, rarityId, currentIndex, cardBoxId, isOpen } = props
    const navigation = useTypeNavigation()
    // const bottomHeight = useBottomTabBarHeight()
    const bottomHeight = bottom
    const HEIGHT = Dimensions.get("window").height - bottomHeight - top
    const WIDTH = Dimensions.get("window").width
    const VIDEO_HEIGHT = HEIGHT - fitSize(10)
    const { accountStore } = useStores()
    const isFocused = useIsFocused()

    const _onViewableItemsChanged = useCallback(({ viewableItems }) => {
      if (viewableItems.length === 1) {
        setScrollIndex(viewableItems[0].index)
      }
    }, [])

    const fetchGetUserAssetList = async (lastId?: number, size = PL) => {
      try {
        const res = await api.getUserAssetList(
          lastId,
          size,
          seriesId,
          rarityId,
          undefined,
          cardBoxId,
        )
        if (res.kind === "ok") {
          res.data.list.forEach((item) => {
            item.muted = true
          })
          return res.data
        } else {
          GToast.current.handleErrorResponse(res)
        }
      } catch (error) {
        console.log("error", error)
      }
    }

    const [fetchList] = useAutoList(
      {
        fetchData: fetchGetUserAssetList,
        pageLimit: PL,
        isDestroy: isOpen && (seriesId !== "0" || rarityId !== "0" || cardBoxId !== "0"),
      },
      [seriesId, rarityId, cardBoxId],
    )

    useLayoutEffect(() => {
      if (accountStore.tabScrollUp.tabTwo && flatListRef.current && fetchList.data.length > 0) {
        try {
          flatListRef.current.scrollToIndex({ index: 0, animated: true })
        } catch (error) {
          console.log("error", error)
        }
      }
    }, [accountStore.tabScrollUp.tabTwo])

    useImperativeHandle(ref, () => ({
      onTop: () => {
        try {
          flatListRef.current.scrollToOffset({ offset: 0, animated: true })
        } catch (error) {
          console.log("error", error)
        }
      },
      onReset: () => {
        fetchList.onRefresh()
      },
    }))

    const calculateIsPreload = useCallback(
      (index: number) => {
        const lastIndex = fetchList.data.length - 1
        if (index === 0) {
          return index === scrollIndex + 1
        } else if (index === lastIndex) {
          return index === scrollIndex - 1
        } else {
          return index === scrollIndex - 1 || index === scrollIndex + 1
        }
      },
      [scrollIndex, fetchList.data.length],
    )

    const renderItems = ({ item, index }) => {
      return (
        <View
          style={{
            width: "100%",
            height: VIDEO_HEIGHT,
            backgroundColor: colors.palette.neutral900,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            borderRadius: fitSize(6),
            borderWidth: 1,
            borderColor: colors.palette.neutral30,
            marginBottom: fitSize(10),
          }}
        >
          {currentIndex === 1 && (
            <Fragment>
              {Platform.OS !== "windows" && Platform.OS !== "macos" ? (
                <MultiPlatformVideoPlayer
                  uri={item?.video}
                  isLook={false}
                  style={{
                    width: fitSize(359),
                    height: VIDEO_HEIGHT - fitSize(60),
                    zIndex: -1,
                    alignSelf: "center",
                  }}
                  isPaused={index !== scrollIndex}
                  loop={true}
                  muted={item?.muted}
                  h={VIDEO_HEIGHT - fitSize(60)}
                  w={fitSize(359)}
                  index={index}
                  isReset={true}
                  isPreload={calculateIsPreload(index)}
                />
              ) : null}
            </Fragment>
          )}
          <View
            style={{
              position: "absolute",
              right: 18,
              top: VIDEO_HEIGHT - fitSize(200),
              flexDirection: "column",
            }}
          >
            <Touchable
              style={style.btnBox}
              onPress={() => {
                item.muted = !item.muted
                fetchList.setData((prev) => [...prev])
              }}
            >
              <Image
                source={Images.screen.store[item.muted ? "default_mute" : "not_mute"]}
                style={style.bestView}
              />
            </Touchable>
            <Touchable
              style={style.btnBox}
              onPress={() => navigation.navigate("AssetDetails", { id: item.assetsId })}
            >
              <Image source={Images.screen.store.video_info} style={style.bestView} />
            </Touchable>
            {/* <Touchable style={style.btnBox}>
              <Image source={Images.screen.store.vide_share} style={style.bestView} />
            </Touchable> */}
          </View>
          <SizeImage source={Images.screen.store.video_bar} style={[style.barIcon, { bottom: 0 }]}>
            <Text style={style.nameText}>{item?.name}</Text>
            <View style={style.barInfo}>
              <Text style={style.clubName}>{item?.club}</Text>
              <View style={style.iconBar}>
                <RarityIcon icon={item?.rarityIcon} />
                <Text style={style.playerNum}>
                  #{item?.assetNumber}
                  <Text style={style.circulation}>/{item?.circulation}</Text>
                </Text>
              </View>
            </View>
          </SizeImage>
        </View>
      )
    }

    const keyExtractor = useCallback((challenge, i) => challenge.id, [])

    const snapToInterval = useMemo(() => Dimensions.get("window").height - bottomHeight - top, [])

    const getItemLayout = useCallback((data, index) => {
      return { length: HEIGHT, offset: HEIGHT * index, index }
    }, [])

    const panResponder = useRef(null)
    panResponder.current = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderRelease: (evt, gestureState) => {
        const { dy } = gestureState
        if (dy < 0 && scrollIndex === 0) {
          flatListRef.current.scrollToIndex({ index: 1, animated: true })
          setScrollIndex(1)
        } else if (dy < 0 && scrollIndex < fetchList.data.length - 1) {
          flatListRef.current.scrollToIndex({ index: scrollIndex + 1, animated: true })
          setScrollIndex((prev) => prev + 1)
        } else if (dy > 0 && scrollIndex > 0) {
          flatListRef.current.scrollToIndex({ index: scrollIndex - 1, animated: true })
          setScrollIndex((prev) => prev - 1)
        } else if (dy < 0 && currentIndex === fetchList.data.length - 1) {
          console.log("已经到达最底部，禁止继续向下滑动")
        }
      },
    })

    const ListComponent = Platform.OS !== "web" ? FlashList : FlatList

    return (
      <View style={{ height: HEIGHT, width: WIDTH, marginTop: fitSize(10) }}>
        <ListComponent
          {...fetchList}
          data={currentIndex === 1 && isFocused ? fetchList.data : []}
          keyExtractor={keyExtractor}
          snapToInterval={snapToInterval}
          renderItem={renderItems}
          getItemLayout={getItemLayout}
          onViewableItemsChanged={_onViewableItemsChanged}
          viewabilityConfig={{
            viewAreaCoveragePercentThreshold: 90,
          }}
          ref={flatListRef}
          pagingEnabled={true}
          decelerationRate={"fast"}
          refreshing={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          horizontal={false}
          onScroll={(event) => {
            const offsetY = event.nativeEvent.contentOffset.y
            const threshold = Dimensions.get("window").height
            if (offsetY < threshold && accountStore.tabScrollUp.tabTwo !== false) {
              accountStore.setTabScrollUp("tabTwo", false)
            }
          }}
          disableIntervalMomentum={true}
          initialNumToRender={3}
          windowSize={3}
          maxToRenderPerBatch={3}
          updateCellsBatchingPeriod={50}
          removeClippedSubviews={false}
          snapToAlignment={"center"}
          extraData={scrollIndex}
          estimatedItemSize={HEIGHT}
        />
      </View>
    )
  }),
)

const style = StyleSheet.create({
  btnBox: {
    width: fitSize(44),
    height: fitSize(44),
    marginTop: fitSize(24),
  },
  rightIconBar: {},
  icon: {
    minWidth: fitSize(14),
    maxWidth: fitSize(24),
    height: fitSize(14),
    marginRight: fitSize(4),
  },
  circulation: {
    fontSize: fitSize(12),
    color: colors.palette.neutral50,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  playerNum: {
    fontSize: fitSize(12),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
  },
  iconBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    height: fitSize(20),
  },
  clubName: {
    fontSize: fitSize(14),
    color: colors.palette.neutral100,
    fontWeight: "400",
    fontFamily: fontName.PingFang_SC,
  },
  barInfo: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameText: {
    fontSize: fitSize(18),
    color: colors.palette.neutral100,
    fontWeight: "500",
    fontFamily: fontName.PingFang_SC_Semibold,
    marginBottom: fitSize(2),
  },
  barIcon: {
    width: fitSize(359),
    height: fitSize(60),
    position: "absolute",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingHorizontal: fitSize(14),
  },
  video: {},
  bestView: {
    width: "100%",
    height: "100%",
  },
  container: {},
  backBox: {
    width: fitSize(20),
    height: fitSize(20),
    position: "absolute",
    left: 10,
    zIndex: 2147483647,
  },
  backIcon: {
    width: fitSize(20),
    height: fitSize(20),
  },
  list: {},
})
