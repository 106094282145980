/**
 * This Api class lets you define an API endpoint and methods to request
 * data and process it.
 *
 * See the [Backend API Integration](https://github.com/infinitered/ignite/blob/master/docs/Backend-API-Integration.md)
 * documentation for more details.
 */
import { ApiResponse, ApisauceInstance, create } from "apisauce"
import type {
  ApiConfig,
  GetNormalRequest,
  monitoringVersion,
  GetCardBagNumber,
  GetActivityList,
  GetLayoutRequest,
  GetwidgetList,
  GetBannerList,
  GetColumnTypeList,
  GetShowTimeList,
  GetActivityDetail,
  GetActivityBoxTimeList,
  PurchaseCardBagItem,
  GetOrderDetail,
  GetPaymentTypeList,
  AlipayPayment,
  WeChatPayPayment,
  GetUserAssetTotal,
  GetUserAssetList,
  GetAllSeriesList,
  GetAllRarityList,
  GetRarityCodexList,
  GetCardBoxList,
  NormalReturnData,
  GetUserCollectList,
  GetDetailedInfo,
  OpenBox,
  InvitePopupState,
  GetUserInfo,
  GetAssetDetail,
  GetPlayerInfo,
  GetUserBuyList,
  GetReceiveList,
  GetSendReceiveDetail,
  GetAvatarList,
  GetVerifyCode,
  ConfirmPhone,
  AfterSuccess,
  Login,
  GetPrivacyAgreement,
  GetSynthesisList,
  GetSystemTime,
  SynthesisDetails,
  GetGivingCardList,
  GetCompositeRecord,
  GetComposeDetails,
  UserSynthesis,
  CreateApple,
  GetOpenCardBoxDetail,
  GetCurrentSeries,
  GetAssetBoxCallback,
  GetNewNotice,
  GetNoticeList,
  GetNoticeInfo,
} from "./api.types"
import { GeneralApiProblem, getGeneralApiProblem } from "./apiProblem"
import { arg } from "../../../generateEnv"
import { env } from "../../../env"
import { Platform } from "react-native"
import { AppInfo } from "../../config/AppInfo"
import { showInfo } from "../../utils/showUtil"

const API_URL = Platform.OS === "web" ? "" : env[arg].MASS_BASE_URL
export const PREFIX = "/v1/api"

/**
 * Configuring the apisauce instance.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL + PREFIX,
  pathPrefix: PREFIX,
  timeout: 5 * 60 * 1000,
}

/**
 * Manages all requests to the API. You can use this class to build out
 * various requests that you need to call from your backend API.
 */
export class Api {
  apisauce: ApisauceInstance

  newApisauce: ApisauceInstance

  config: ApiConfig

  /**
   * Set up our API instance. Keep this lightweight!
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    })
    this.newApisauce = create({
      baseURL: env[arg].BASE_URL,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
        "APP-PLATFORM": Platform.OS,
        "APP-VERSION": `v${AppInfo.version}`,
      },
    })
  }
  async setup() {}

  //通用请求
  async normalRequest<T = any>(
    path: string,
    params?: any,
    way: string = "get",
  ): Promise<GetNormalRequest<T>> {
    try {
      let response: ApiResponse<any>
      //add by cc 此处用startWith判断后续用 列表判断
      if (path.startsWith("/payment-service/v1/")) {
        //同步head数据
        this.newApisauce.setHeaders(this.apisauce.headers)
        response = await this.newApisauce[way](path, params ?? {})
      } else {
        response = await this.apisauce[way](path, params ?? {})
      }
      if (Platform.OS !== "web") {
      }
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 验证版本更新
   */
  async monitoringVersion(version: string, platform: string): Promise<monitoringVersion> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `/system-service/v1/common/app/initialize`,
        { version, platform },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取卡包开启数量
   */
  async getCardBagNumber(id: string[]): Promise<GetCardBagNumber> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        "/market-service/v1/common/activity/open-count",
        { id: id.join(",") },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 活动列表
   */
  async getActivityList(lastId: number, size: number): Promise<GetActivityList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        "/assets-service/v1/common/blind-box/in-stock",
        {
          lastId,
          size,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 首页布局
   */
  async getHomeLayout(): Promise<GetLayoutRequest> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(`/user/layout/home`)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data?.layout }
    } catch (e) {
      console.log("getlayout", e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * banner列表
   */
  async getbannerList(bannerInfo: string): Promise<GetBannerList> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        `/user/home/banner/list/${bannerInfo}`,
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 小组件列表
   */
  async getwidgetList(widgetsInfo: string): Promise<GetwidgetList> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        `/user/home/widgets/${widgetsInfo}`,
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data?.widgets }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取对应栏位数组
   */
  async getColumnTypeList(module?: string, lastId?: number): Promise<GetColumnTypeList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/market-service/v1/common/column/list`,
        { module, lastId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取全部的时刻列表
   */
  async getShowTimeList(): Promise<GetShowTimeList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/market-service/v1/common/show-time/list`,
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取活动详情
   */
  async getActivityDetail(id: number): Promise<GetActivityDetail> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/assets-service/v1/blind-box/first-in-stock?id=${id}`,
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取开启卡包详情
   */
  async getOpenCardBoxDetail(id: number, blindBoxId?: number): Promise<GetOpenCardBoxDetail> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `assets-service/v1/user/box/first`,
        { id, blindBoxId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取活动内时刻列表
   */
  async getActivityBoxTimeList(
    blindBoxId: number,
    lastId?: number,
    size?: number,
  ): Promise<GetActivityBoxTimeList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/assets-service/v1/blind-box/resource/list-by-box-id`,
        { blindBoxId, lastId, size },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /* 用户订单列表
   */
  async getUserBuyList(lastId?: number, status?: number, size?: number): Promise<GetUserBuyList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        "/order-service/v1/order/user/list",
        {
          lastId,
          status,
          size,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取头像列表
   */
  async getAvatarList(lastId?: number, size?: number): Promise<GetAvatarList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        "/user-service/v1/user/avatar/option",
        {
          lastId,
          size,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 设置头像
   */
  async setAvatar(image: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.put(
        "/user-service/v1/user/avatar/change",
        {
          image,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: true }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 注销账号
   */
  async deregistration(userId: number): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        "/user-service/v1/user/destroy",
        {
          userId: userId,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 兑换码
   */

  async exchangeCode(transactionId: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post("/user/queryTransaction", {
        transactionId,
      })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 检查code
   */
  async checkCode(code: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post("/user/asset/exchangeCdkey", {
        cdkey: code,
      })
      return { kind: "ok", data: response }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 盲盒下单
   */
  async purchaseCardBagItem(
    count: number,
    goodsId: number,
    type?: 0 | 1,
    source?: number,
  ): Promise<PurchaseCardBagItem> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `/order-service/v1/order/trade`,
        { count, goodsId, type, source },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 订单详情
   */
  async getOrderDetail(id: string): Promise<GetOrderDetail> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/order-service/v1/order/first?outTradeNo=${id}`,
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取支付类型
   */
  async getPaymentTypeList(): Promise<GetPaymentTypeList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(`/payment-service/v1/pay/list`)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 支付宝支付下单
   */
  async alipayPayment(outTradeNo: string): Promise<AlipayPayment> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `/payment-service/v1/alipay/web/trade`,
        { outTradeNo },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 招商银行支付下单
   */
  async weChatPayPayment(outTradeNo: string): Promise<WeChatPayPayment> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `/payment-service/v1/cmb/generate/url-link`,
        { outTradeNo },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 取消支付
   */
  async clearPayWay(outTradeNo: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.put(
        `/order-service/v1/order/cancel-status`,
        { outTradeNo },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取用户资产数量
   */
  async getUserAssetTotal(
    seriesId?: number,
    rarityId?: number,
    dedup?: number,
  ): Promise<GetUserAssetTotal> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/assets-service/v1/user/assets/user-assets-count`,
        { seriesId, rarityId, dedup },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取用户资产列表
   */
  async getUserAssetList(
    lastId?: number,
    size?: number,
    seriesId?: string,
    rarityId?: string,
    resourceId?: string,
    cardBoxId?: string,
  ): Promise<GetUserAssetList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/assets-service/v1/user/assets/collect-list`,
        { lastId, size, seriesId, rarityId, resourceId, cardBoxId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取用户合成资产列表
   */
  async getUserCompAssetList(
    compositeFuelId: string,
    lastId?: number,
    size?: number,
    seriesId?: string,
    rarityId?: string,
    resourceId?: string,
    cardBoxId?: string,
  ): Promise<GetUserAssetList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/market-service/v1/composite/user-assets-list`,
        { compositeFuelId, lastId, size, seriesId, rarityId, resourceId, cardBoxId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取全部系列
   */
  async getAllSeries(lastId?: number, size?: number): Promise<GetAllSeriesList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `resource-service/v1/series/option`,
        { lastId, size },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取卡盒稀有度
   */
  async getAllCardBoxRarity(
    lastId?: number,
    size?: number,
    id?: number,
    seriesId?: number,
    cardBoxId?: number,
    rarityId?: number,
  ): Promise<GetAllRarityList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `resource-service/v1/card-box-rarity/options`,
        { lastId, size, id, seriesId, cardBoxId, rarityId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取稀有度图鉴
   */
  async getRarityCodexList(lastId?: number, size?: number): Promise<GetRarityCodexList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `resource-service/v1/card-box-rarity/illustrated-book`,
        { lastId, size },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取卡包
   */
  async getCardBoxList(lastId?: number, size?: number): Promise<GetCardBoxList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `assets-service/v1/user/box/collect-list`,
        { lastId, size },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 当前卡包已读
   */
  async readCurrentCardBag(id: number[]): Promise<NormalReturnData<any>> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.put(
        `assets-service/v1/user/box/read`,
        { id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取用户收藏资产
   */
  async getUserCollectList(
    lastId?: number,
    size?: number,
    seriesId?: number,
    rarityId?: number,
    cardBoxRarityId?: number,
    cardBoxId?: number,
  ): Promise<GetUserCollectList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `resource-service/v1/resource/collect-list`,
        { lastId, size, seriesId, rarityId, cardBoxRarityId, cardBoxId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取图鉴稀有度详情
   */
  async getDetailedInfo(id: number): Promise<GetDetailedInfo> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `resource-service/v1/card-box-rarity/illustrated-book-first`,
        { id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 当前资产已读
   */
  async readCurrentAsset(id: number[]): Promise<NormalReturnData<any>> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.put(
        `assets-service/v1/user/assets/read`,
        { id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取资产详情
   */
  async getAssetDetail(id: number, userAssetsId?: number): Promise<GetAssetDetail> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `assets-service/v1/user/assets/user-assets-info`,
        { id, userAssetsId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取球员信息
   */
  async getPlayerInfo(id: number): Promise<GetPlayerInfo> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `detail-service/v1/cba/getdetail`,
        { id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取当前用户是否弹出邀请二维码
   * @returns isPopup:是否弹出 qRCodeUrl:邀请二维码
   */
  async setInvitePopupState(): Promise<InvitePopupState> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        "/market-service/v1/share/popup",
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取用户收发记录
   * @param lastId 最后一条id
   * @param size 每页条数
   * @param type 0:盲盒 1:卡牌
   * @param operating 1:收到 2:发出
   * @param source 0:购买 1:空投 2:合成 3:邀请
   */
  async getReceiveList(
    lastId?: number,
    size?: number,
    type?: 0 | 1,
    operating?: 1 | 2,
    source?: 0 | 1 | 2 | 3,
  ): Promise<GetReceiveList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `assets-service/v1/user/history/list`,
        { lastId, size, type, operating, source },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取收发记录详情
   */
  async getSendReceiveDetail(id: number): Promise<GetSendReceiveDetail> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `assets-service/v1/user/history/first`,
        { id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 发送短信
   */
  async getVerifyCode(phone: string): Promise<GetVerifyCode> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/common/user/security/send-code`,
        { phone },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 手机号绑定
   */
  async confirmPhone(phone: string, code: string, sign: string): Promise<ConfirmPhone> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/user/phone/change-phone`,
        { phone, code, sign },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 当前手机号验证
   */

  async currentPhoneCheck(code: string): Promise<AfterSuccess> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/user/phone/verify-code`,
        { code },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 修改密码
   */
  async changePassword(
    password: string,
    newPassword: string,
    cfmPassword: string,
  ): Promise<AfterSuccess> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.put(
        `user-service/v1/user/security/change`,
        {
          password,
          newPassword,
          cfmPassword,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 忘记密码
   */
  async forgetPassword(
    code: string,
    phone: string,
    password: string,
    cfmPassword: string,
  ): Promise<AfterSuccess> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.put(
        `user-service/v1/common/user/security/forget`,
        {
          code,
          phone,
          password,
          cfmPassword,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 修改昵称
   */
  async editUserName(nickname: string): Promise<GetUserInfo> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.put(
        `user-service/v1/user/nickname/updated`,
        {
          nickname,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 手机号验证短信
   */
  async getVerifySMS(phone?: string, sign?: string): Promise<GetVerifyCode> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/user/phone/send-code`,
        { phone, sign },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 新手机号验证短信
   */
  async getNewVerifySMS(phone?: string, sign?: string): Promise<GetVerifyCode> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/user/phone/new-send-code`,
        {
          phone,
          sign,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 设置密码
   */
  async settingPassword(password: string, cfmPassword: string): Promise<AfterSuccess> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `/user-service/v1/user/security/set-login`,
        {
          password,
          cfmPassword,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * token验证
   */
  async tokenAuthentication(): Promise<AfterSuccess> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/common/user/token/verify`,
        {},
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 登录验证码
   */
  async verificationCode(phone: string): Promise<AfterSuccess> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/common/user/send-code`,
        { phone },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 用户登录
   */
  async login(type: 0 | 1, code: string, phone: string, password?: string): Promise<Login> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/common/user/login`,
        { type, code, phone, password },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 用户登录
   */
  async logout(): Promise<AfterSuccess> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/user/logout`,
        {},
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 创建新密码
   */
  async newPassWord(
    phone: string,
    code: string,
    password: string,
    cfmPassword: string,
  ): Promise<AfterSuccess> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `/user-service/v1/user/security/set-notlogin`,
        {
          phone,
          code,
          password,
          cfmPassword,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 一键登录
   */
  async updateLogin(userId: string | number): Promise<AfterSuccess> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `user-service/v1/common/user/online`,
        { userId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取隐私协议
   */
  async getPrivacyAgreement(type: 0 | 1 | 2): Promise<GetPrivacyAgreement> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/system-service/v1/common/privacy/first`,
        { type },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  async getInfo(): Promise<GetUserInfo> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get("user-service/v1/user/me")
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  /**
   * 获取合成列表
   */
  async getSynthesisList(lastId?: number, size?: number): Promise<GetSynthesisList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `market-service/v1/common/composite/find-in-stock`,
        { lastId, size },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 获取系统时间
   */
  async getSystemTime(): Promise<GetSystemTime> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `system-service/v1/common/system/time`,
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 获取挑战详情
   */
  async getSynthesisDetails(id: number): Promise<SynthesisDetails> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `market-service/v1/composite/first-in-stock`,
        { id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 获取合成卡
   */
  async getGivingCardList(
    compositeId?: number,
    lastId?: number,
    size?: number,
  ): Promise<GetGivingCardList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `market-service/v1/composite/fuel/find-in-stock`,
        { compositeId, lastId, size },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 用户合成
   */

  async userSynthesis(asset: {
    compositeId: number
    compFuelAsset: {
      compFuelId: number
      resourceId: number
      assetNumber: string
    }[]
  }): Promise<UserSynthesis> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `market-service/v1/composite/user-comp`,
        { ...asset },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 获取合成记录
   */
  async getCompositeRecord(lastId?: number, size?: number): Promise<GetCompositeRecord> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `market-service/v1/composite/user-comp-list`,
        { lastId, size },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 获取合成详情
   */
  async getComposeDetails(id: number): Promise<GetComposeDetails> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `market-service/v1/composite/user-comp-info`,
        { id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 创建苹果支付
   */
  async createApple(appleGoodsId: string, transactionReceipt: string): Promise<CreateApple> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `payment-service/v1/apple/web/trade`,
        { appleGoodsId, transactionReceipt },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 获取当前系列资产列表
   */
  async getCurrentSeries(id: number, lastId?: number, size?: number): Promise<GetCurrentSeries> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `assets-service/v1/user/assets/user-assets-number-list`,
        { lastId, size, id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 转增资产
   */
  async transferredAssets(id: number, to: string): Promise<NormalReturnData<any>> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `assets-service/v1/user/assets/transfer`,
        { id, to },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 开启卡包
   */
  async openBox(openId: number): Promise<OpenBox> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `assets-service/v1/user/box/open`,
        { openId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 获取卡包开启结果
   */
  async getAssetBoxCallback(openId: number): Promise<GetAssetBoxCallback> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `assets-service/v1/user/box/open-info`,
        { openId },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 是否有最新的公告
   */
  async getNewNotice(): Promise<GetNewNotice> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/system-service/v1/common/notice-isNew`,
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 获取公告列表
   */
  async getNoticeList(lastId?: number, size?: number): Promise<GetNoticeList> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/system-service/v1/notice/list`,
        { lastId, size },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 获取公告详情
   */
  async getNoticeInfo(id: number): Promise<GetNoticeInfo> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.get(
        `/system-service/v1/notice/first`,
        { id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 已读公告
   */
  async getReadNotice(id: number): Promise<NormalReturnData<any>> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.put(
        `/system-service/v1/notice/read`,
        { id },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 已读公告
   */
  async userReal(idcard: string, name: string): Promise<NormalReturnData<any>> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(`/user-service/v1/user/real`, {
        idcard,
        name,
      })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }

  /**
   * 已读公告
   */
  async getAwardFromCode(cdk: string): Promise<NormalReturnData<any>> {
    try {
      const response: ApiResponse<any> = await this.newApisauce.post(
        `/market-service/v1/cdk/exchange`,
        {
          cdk,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e?.message }
    }
  }
}

// Singleton instance of the API for convenience
export const api = new Api()

export function dealError(response: ApiResponse<any>) {
  if (!response.ok) {
    const problem = getGeneralApiProblem(response)
    if (problem) {
      // showInfo((problem as any).msg ?? "网络错误")
      return problem
    }
  }
  //add by cc 200也算成功
  if (response.data.code == 200) {
    return null
  }
  if (response.data.code !== 0) {
    if (response.data.code === 402) {
      return { kind: "forbidden", msg: "未实名" }
    } else if (response.data.code === 401) {
      return { kind: "unauthorized", msg: "未授权" }
    } else if (response.data.code === 200006) {
      return { kind: "captcha-traffic-limit", msg: "发送验证码1分钟限流" }
    } else {
      return { kind: "error", msg: response.data.msg }
    }
  }
  return null
}
